import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import { Box, Button, Card, CardContent, Grid, Paper, Typography } from '@mui/material'
import { ThemeProvider, createTheme, styled } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import { ErrorBoundary } from 'react-error-boundary'
import { v4 as uuidv4 } from 'uuid'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import ErrorFallback from '../ErrorFallback'
import Loader from '../Loader'
import { assetAllocationDataProcess, constraintDataProcess, factorTiltsDataProcess, industryDataProcess, instrRestrictionDataProcess, instrSubstitutionDataProcess, methodologyDataProcess, performanceDataProcess, benchmarkDataProcess, sectorDataProcess, tradeListDataProcess, transitionPortfolioDataProcess } from './DataFormatting'
import './ReportPage.css'
import { AccountInformation } from './components/AccountInformation'
import { AccountTranInformation } from './components/AccountTranInformation'
import { DownloadReport } from './components/DownloadReport'
import { ReportTab } from './components/ReportTab'
import ScenarioSelection from './components/ScenarioSelection'
import { TaxInformation } from './components/TaxInformation'
import AccountTitle from '../../components/AccountTitle'
import { useQueries } from '@tanstack/react-query'

const theme = createTheme({
  typography: {
    fontFamily: 'Open Sans'
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: 'Open Sans',
          backgroundColor: 'rgba(23, 92, 194, 0.8)',
          color: 'white',
          fontSize: '12px',
          fontWeight: '400'
        }
      }
    }
  }
})

theme.typography.h6 = {
  fontSize: '20px',
  '@media (min-width:600px)': {
    fontSize: '24px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '30px'
  }
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  boxShadow: 'none'
}))

export const methodologyContext = React.createContext()
export const tradeListContext = React.createContext()
export const allocationContext = React.createContext()
export const instrRestrictionContext = React.createContext()
export const sectorContext = React.createContext()
export const industryContext = React.createContext()
export const instrSubstitutionContext = React.createContext()
export const factorTiltsContext = React.createContext()
export const accountDataContext = React.createContext()
export const assetAllocationContext = React.createContext()
export const performanceDataContext = React.createContext()
export const benchmarkDataContext = React.createContext()
export const metricDataContext = React.createContext()
export const summaryDataContext = React.createContext()
export const portfolioDataContext = React.createContext()
export const portfolioReportDataContext = React.createContext()
export const taxSavingDataContext = React.createContext()
export const positionDataContext = React.createContext()
export const policyDataContext = React.createContext()
export const taxManagementContext = React.createContext()

const transitionReportTabs = ['Summary', 'Allocation', 'Performance', 'Trade List', 'Portfolio', 'Policy', 'Methodology']
const tradingReportTabs = ['Summary', 'Allocation', 'Performance', 'Portfolio', 'Policy', 'Methodology']

const ReportPage = () => {
  const [methodologyData, setMethodologyData] = useState([])
  const [tradeListData, setTradeListData] = useState([])
  const [instrRestrictionData, setInstrRestrictionData] = useState([])
  const [sectorData, setSectorData] = useState([])
  const [industryData, setIndustryData] = useState([])
  const [instrSubstitutionData, setInstrSubstitutionData] = useState([])
  const [factorTiltsData, setFactorTiltsData] = useState([])
  const [policyData, setPolicyData] = useState([])

  const [accountData, setAccountData] = useState({})
  const [assetAllocationData, setAssetAllocationData] = useState([])
  const [performanceData, setPerformanceData] = useState([])
  // const [performanceDate, setPerformanceDate] = useState([dayjs.utc('2023-10-01'), dayjs.utc()])
  const [metricData, setMetricData] = useState([])
  const [benchmarkReturnsData, setBenchmarkReturnsData] = useState([])
  const [taxManagementData, setTaxManagementData] = useState([])
  const [isSummaryDataPresent, SetIsSummaryDataPresent] = useState({ accountData: [], optimizationData: [] })
  const [summaryData, setSummaryData] = useState([])
  const [portfolioData, setPortfolioData] = useState([])
  const [transitionPortfolioData, setTransitionPortfolioData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedScenario, setSelectedScenario] = useState('')
  const [taxSavingData, setTaxSavingData] = useState(null)
  const [positionData, setPositionData] = useState(null)
  const [isRetrying, setIsRetrying] = useState(false)
  const [isTaxSavingAPIRetrying, setIsTaxSavingAPIRetrying] = useState(false)
  const [isDoneRetrying, setIsDoneRetrying] = useState(false)
  const { showError } = useErrorToast()
  const navigate = useNavigate()
  const location = useLocation()
  const screen = 'not main'
  const { user } = useAuth()
  const params = useParams()

  const [errorMessage, setErrorMessage] = useState('')

  // const changePerformanceDate = (date) => {
  //   setPerformanceDate(date)
  // }

  useEffect(() => {
    setAccountData(null)
  },[params?.accountId,params?.RequestId])

  const fetchSummaryData = () => {
    return API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/get-summary-data/${params?.accountId}`, { queryStringParameters: { ...(accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.optId ? { 'opt-id': accountData?.data?.accReportData[0]?.optId } : {}) } })
  }

  const fetchAccountData = () => {
    return API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/account-report/${params?.accountId}`, { queryStringParameters: { ...(params?.RequestId ? { requestId: params?.RequestId } : {}) } })
  }

  const fetchTradeList = () => {
    if (params?.RequestId) {
      return API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/trade-list/${params?.accountId}`, { queryStringParameters: { requestId: params?.RequestId } })
    } else {
      return API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/trade-list/${params?.accountId}`)
    }
  }
  const fetchPersonalization = () => {
    return API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/policy/${params?.accountId}`)
  }

  const fetchAssetAllocation = () => {
    if (params?.RequestId) {
      return API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/asset-allocations/${params?.accountId}`, { queryStringParameters: { requestId: params?.RequestId } })
    } else {
      return API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/asset-allocations/${params?.accountId}`)
    }
  }

  const fetchTaxManagementData = () => {
    const strategyId = accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.strategyId
    return API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/tax-management/account/${params?.accountId}/strategy/${strategyId}`)
  }

  const fetchPortfolioData = () => {
    return API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/portfolios/${params?.accountId}`)
  }

  const fetchMethodologyData = () => {
    let reportType = 'TR'
    if (accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.accountType === 'TRADING') {
      reportType = 'TR'
    }
    return API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/report/methodology/${reportType}`)
  }

  const fetchPerformanceData = () => {
    return API.post('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/performance/${params?.accountId}`, { body: {} })
  }

  const fetchBenchmarkData = () => {
    const strategyId = accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.strategyId
    return API.post('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/benchmark-returns/account/${params?.accountId}/strategy/${strategyId}`, { body: {} })
  }

  const fetchMetricData = () => {
    const startDate = '2023-09-15'
    const endDate = '2024-01-23'
    return API.post('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/metric-data/${params?.accountId}`, { body: {} })
  }

  const [summaryQuery, accountInfoQuery, tradeListQuery, portfolioQuery, personalizationQuery, assetAllocationQuery, performanceQuery, marketValueQuery, benchmarkQuery, taxManagementQuery, methodologyQuery] = useQueries({
    queries: [
      {
        queryKey: ['report-summary', `${params?.accountId || ''}${params?.RequestId || ''}`],
        queryFn: fetchSummaryData,
        enabled: Boolean(user !== null && accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.optId), // dependent condition
        retry: false // prevent retry if it fails
      },
      {
        queryKey: ['report-account-info', `${params?.accountId || ''}${params?.RequestId || ''}`],
        queryFn:
          fetchAccountData,
        enabled: user !== null, // dependent condition
        retry: false // prevent retry if it fails
      },
      {
        queryKey: ['report-trade', `${params?.accountId || ''}${params?.RequestId || ''}`],
        queryFn:
          fetchTradeList,
        enabled: Boolean(user !== null && accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.accountType === 'TRANSITION' && accountData?.data?.accReportData[0]?.optId), // dependent condition
        retry: false // prevent retry if it fails
      },
      {
        queryKey: ['report-portfolio', `${params?.accountId || ''}${params?.RequestId || ''}`],
        queryFn:
          fetchPortfolioData,
        enabled: Boolean(user !== null && accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.accountType === 'TRADING' && accountData?.data?.accReportData[0]?.optId), // dependent condition
        retry: false // prevent retry if it fails
      },
      {
        queryKey: ['report-personalization', `${params?.accountId || ''}${params?.RequestId || ''}`],
        queryFn:
          fetchPersonalization,
        enabled: Boolean(user !== null && accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.optId), // dependent condition
        retry: false // prevent retry if it fails
      },
      {
        queryKey: ['report-asset-allocation', `${params?.accountId || ''}${params?.RequestId || ''}`],
        queryFn:
          fetchAssetAllocation,
        enabled: Boolean(user !== null && accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.optId), // dependent condition
        retry: false // prevent retry if it fails
      },
      {
        queryKey: ['report-performance', `${params?.accountId || ''}${params?.RequestId || ''}`],
        queryFn:
          fetchPerformanceData,
        enabled: Boolean(user !== null && accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.optId), // dependent condition
        retry: false // prevent retry if it fails
      },
      {
        queryKey: ['report-metric', `${params?.accountId || ''}${params?.RequestId || ''}`],
        queryFn:
          fetchMetricData,
        enabled: Boolean(user !== null && accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.optId), // dependent condition
        retry: false // prevent retry if it fails
      },
      {
        queryKey: ['report-benchmark', `${params?.accountId || ''}${params?.RequestId || ''}`],
        queryFn:
          fetchBenchmarkData,
        enabled: Boolean(user !== null && accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.optId), // dependent condition
        retry: false // prevent retry if it fails
      },
      {
        queryKey: ['report-tax-management', `${params?.accountId || ''}${params?.RequestId || ''}`],
        queryFn:
          fetchTaxManagementData,
        enabled: Boolean(user !== null && accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.optId), // dependent condition
        retry: false // prevent retry if it fails
      },
      {
        queryKey: ['report-methodology', `${params?.accountId || ''}${params?.RequestId || ''}`],
        queryFn:
          fetchMethodologyData,
        enabled: Boolean(user !== null && accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.optId), // dependent condition
        retry: false // prevent retry if it fails
      }
    ]
  })

  useEffect(() => {
    setIsLoading(true)
    if (summaryQuery?.status === 'success') {
      if (summaryQuery?.data && summaryQuery?.data?.data && accountData?.data?.accReportData?.length && summaryQuery?.data?.data?.optId === accountData?.data?.accReportData[0]?.optId) {
        setSummaryData(summaryQuery?.data)
        SetIsSummaryDataPresent(summaryQuery?.data?.data)
        if (!summaryQuery?.data?.data?.optimizationData?.length) {
          setErrorMessage(summaryQuery?.data?.message)
        }
        setIsLoading(false)
      }
      else {
        if (!summaryQuery?.data?.data?.optimizationData?.length) {
          setErrorMessage(summaryQuery?.data?.message)
        }
        setIsLoading(false)
      }
    } else if (summaryQuery?.status === 'error') {
      setIsLoading(false)
      showError(summaryQuery?.error?.response?.data?.errorInfo?.userMessage || summaryQuery?.error?.message)
      Sentry.captureException(summaryQuery?.error?.response?.data?.errorInfo?.userMessage || summaryQuery?.error)
    } else if (!summaryQuery?.isLoading && !accountInfoQuery?.isLoading) {
      setIsLoading(false)
    }
  }, [summaryQuery?.isFetching, accountData])

  useEffect(() => {
    setIsLoading(true)
    setAccountData(null)
    if (accountInfoQuery?.status === 'success') {
      if (accountInfoQuery?.data) {
        setAccountData(accountInfoQuery?.data)
        const accReportData = accountInfoQuery?.data?.data?.accReportData || []
        const optId = accReportData?.length > 0 ? accReportData[0]?.optId : ''
        if (!accReportData?.length) {
          setErrorMessage(accountInfoQuery?.data?.message)
        }
        if (!optId) {
          setIsLoading(false)
          setSummaryData([])
          SetIsSummaryDataPresent({ accountData: [], optimizationData: [] })
        }
      }
    } else if (accountInfoQuery?.status === 'error') {
      setIsLoading(false)
      showError(accountInfoQuery?.error?.response?.data?.errorInfo?.userMessage || accountInfoQuery?.error?.message)
      Sentry.captureException(accountInfoQuery?.error?.response?.data?.errorInfo?.userMessage || accountInfoQuery?.error)
    }
  }, [accountInfoQuery?.isFetching, params?.accountId, params?.RequestId])

  useEffect(() => {
    if (tradeListQuery?.status === 'success') {
      if (tradeListQuery?.data) {
        setTradeListData(tradeListDataProcess(tradeListQuery?.data?.data))
      }
    } else if (tradeListQuery?.status === 'error') {
      showError(tradeListQuery?.error?.response?.data?.errorInfo?.userMessage || tradeListQuery?.error?.message)
      Sentry.captureException(tradeListQuery?.error?.response?.data?.errorInfo?.userMessage || tradeListQuery?.error)
    }
  }, [tradeListQuery?.isFetching])

  useEffect(() => {
    if (personalizationQuery?.status === 'success') {
      if (personalizationQuery?.data) {
        if (personalizationQuery?.data?.data) {
          setSectorData(sectorDataProcess(personalizationQuery?.data?.data?.sectorDataLabel))
          setIndustryData(industryDataProcess(personalizationQuery?.data?.data?.industryDataLabel))
          setInstrRestrictionData(instrRestrictionDataProcess(personalizationQuery?.data?.data?.restrictionDataLabel))
          setFactorTiltsData(factorTiltsDataProcess(personalizationQuery?.data?.data?.accountFactorDataLabel))
          setInstrSubstitutionData(instrSubstitutionDataProcess(personalizationQuery?.data?.data?.substitutionDataLabel))
        }
      }
    } else if (personalizationQuery?.status === 'error') {
      showError(personalizationQuery?.error?.response?.data?.errorInfo?.userMessage || personalizationQuery?.error?.message)
      Sentry.captureException(personalizationQuery?.error?.response?.data?.errorInfo?.userMessage || personalizationQuery?.error)
    }
  }, [personalizationQuery?.isFetching])

  useEffect(() => {
    if (taxManagementQuery?.status === 'success') {
      if (taxManagementQuery?.data?.data) {
        setTaxManagementData(taxManagementQuery?.data?.data)
      }
    } else if (taxManagementQuery?.status === 'error') {
      showError(taxManagementQuery?.error?.errorInfo?.userMessage || taxManagementQuery?.error?.message)
      Sentry.captureException(taxManagementQuery?.error?.errorInfo?.userMessage || taxManagementQuery?.error)
    }
  }, [taxManagementQuery?.isFetching])

  useEffect(() => {
    if (assetAllocationQuery?.status === 'success') {
      if (assetAllocationQuery?.data) {
        setAssetAllocationData(assetAllocationDataProcess(assetAllocationQuery?.data))
      }
    } else if (assetAllocationQuery?.status === 'error') {
      showError(assetAllocationQuery?.error?.response?.data?.errorInfo?.userMessage || assetAllocationQuery?.error?.message)
      Sentry.captureException(assetAllocationQuery?.error?.response?.data?.errorInfo?.userMessage || assetAllocationQuery?.error)
    }
  }, [assetAllocationQuery?.isFetching])

  useEffect(() => {
    if (performanceQuery?.status === 'success') {
      if (performanceQuery?.data) {
        setPerformanceData(performanceDataProcess(performanceQuery?.data))
      }
    } else if (performanceQuery?.status === 'error') {
      showError(performanceQuery?.error?.response?.data?.errorInfo?.userMessage || performanceQuery?.error?.message)
      Sentry.captureException(performanceQuery?.error?.response?.data?.errorInfo?.userMessage || performanceQuery?.error)
    }
  }, [performanceQuery?.isFetching])

  useEffect(() => {
    if (marketValueQuery?.status === 'success') {
      if (marketValueQuery?.data) {
        setMetricData(marketValueQuery?.data?.data)
      }
    } else if (marketValueQuery?.status === 'error') {
      showError(marketValueQuery?.error?.response?.data?.errorInfo?.userMessage || marketValueQuery?.error?.message)
      Sentry.captureException(marketValueQuery?.error?.response?.data?.errorInfo?.userMessage || marketValueQuery?.error)
    }
  }, [marketValueQuery?.isFetching])

  useEffect(() => {
    if (benchmarkQuery?.status === 'success') {
      if (benchmarkQuery?.data) {
        setBenchmarkReturnsData(benchmarkDataProcess(benchmarkQuery?.data))
      }
    } else if (benchmarkQuery?.status === 'error') {
      showError(benchmarkQuery?.error?.response?.data?.errorInfo?.userMessage || benchmarkQuery?.error?.message)
      Sentry.captureException(benchmarkQuery?.error?.response?.data?.errorInfo?.userMessage || benchmarkQuery?.error)
    }
  }, [benchmarkQuery?.isFetching])

  useEffect(() => {
    if (portfolioQuery?.status === 'success') {
      if (portfolioQuery?.data) {
        setPortfolioData(portfolioQuery?.data?.data?.portfolioData ? ({ ...portfolioQuery?.data.data, portfolioData: portfolioQuery?.data?.data?.portfolioData?.map((item) => ({ ...item, id: uuidv4() })) }) : ({}))
      }
    } else if (portfolioQuery?.status === 'error') {
      if (accountData?.data?.accReportData && accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.accountType === 'TRADING') {
        // showError(portfolioQuery?.error?.response?.data?.errorInfo?.userMessage || portfolioQuery?.error?.message)
        Sentry.captureException(portfolioQuery?.error?.response?.data?.errorInfo?.userMessage || portfolioQuery?.error)
      }
    }
  }, [portfolioQuery?.isFetching])

  useEffect(() => {
    if (methodologyQuery?.status === 'success') {
      if (methodologyQuery?.data) {
        setMethodologyData(methodologyDataProcess(methodologyQuery?.data))
      }
    } else if (methodologyQuery?.status === 'error') {
      showError(methodologyQuery?.error?.response?.data?.errorInfo?.userMessage || methodologyQuery?.error?.message)
      Sentry.captureException(methodologyQuery?.error?.response?.data?.errorInfo?.userMessage || methodologyQuery?.error)
    }
  }, [methodologyQuery?.isFetching])

  const openExpandedView = (chartName, data) => {
    let chartData = {
      data,
      accountId: params?.accountId,
      strategyId: (accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.strategyId) || '',
      selectedBenchmark: ([accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.strategyId]) || '',
      accountType: (accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.accountType) || '',
      startDate: benchmarkReturnsData?.startDate,
      endDate: benchmarkReturnsData?.endDate,
      strategyList: benchmarkReturnsData?.strategyList,
      calculatedMvPlusCashData: metricData?.calculatedMvPlusCashData,
      selectedAccountIds: [{ accountId: params?.accountId }]
    }
    if (chartName === 'MV') {
      // set present annotation data as selected annotation
      const annotationsArray = metricData?.marketData ? metricData?.marketData?.flatMap(entry => ({ annotations: entry.metric })) : []
      const uniqueAnnotations = [...new Set(annotationsArray.flatMap(entry => Object.keys(entry.annotations)))]
      chartData = {
        ...chartData,
        annotationList: uniqueAnnotations,
        selectedAnnotations: uniqueAnnotations
      }
    }
    navigate(`/account-review/expanded-charts/${params?.accountId}`, {
      state: {
        chartName,
        chartData
      }
    })
  }

  // const getTradeList = async () => {
  //   try {
  //     let response
  //     if (params.RequestId) {
  //       response = await API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/trade-list/${params.accountId}`, { queryStringParameters: { requestId: params.RequestId } })
  //     } else {
  //       response = await API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/trade-list/${params.accountId}`)
  //     }
  //     setTradeListData(tradeListDataProcess(response.data))
  //   } catch (error) {
  //     showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //     Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //   }
  // }

  // const getPersonalization = async () => {
  //   try {
  //     const response = await API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/policy/${params.accountId}`)
  //     setSectorData(sectorDataProcess(response.data.sectorDataLabel))
  //     setIndustryData(industryDataProcess(response.data.industryDataLabel))
  //     setInstrRestrictionData(instrRestrictionDataProcess(response.data.restrictionDataLabel))
  //     setFactorTiltsData(factorTiltsDataProcess(response.data.accountFactorDataLabel))
  //     setInstrSubstitutionData(instrSubstitutionDataProcess(response.data.substitutionDataLabel))
  //     setCashConstraintData(constraintDataProcess(response.data.cashConstraintsLabel))
  //     setTaxConstraintData(constraintDataProcess(response.data.taxConstraintsLabel))
  //     setOtherConstraintData(constraintDataProcess(response.data.otherConstraintsLabel))
  //   } catch (error) {
  //     showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //     Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //   }
  // }

  // const getAccountData = async () => {
  //   try {
  //     const response = await API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/account-report/${params?.accountId}`)
  //     setAccountData(response)
  //   } catch (error) {
  //     showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //     Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //   }
  // }

  // const getAssetAllocation = async () => {
  //   try {
  //     let response
  //     if (params.RequestId) {
  //       response = await API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/asset-allocations/${params.accountId}`, { queryStringParameters: { requestId: params.RequestId } })
  //     } else {
  //       response = await API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/asset-allocations/${params.accountId}`)
  //     }
  //     setAssetAllocationData(assetAllocationDataProcess(response))
  //   } catch (error) {
  //     showError(error.message)
  //     Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //   }
  // }

  // const getPerformanceData = async () => {
  //   try {
  //     const response = await API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/performance/${params.accountId}`)
  //     if (response?.success === true) {
  //       if (response?.data) {
  //         setPerformanceData(performanceDataProcess(response))
  //       }
  //     }
  //   } catch (error) {
  //     showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //     Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //   }
  // }

  // const getSummaryData = async () => {
  //   try {
  //     let response
  //     if (params.RequestId) {
  //       response = await API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/get-summary-data/${params.accountId}`, { queryStringParameters: { requestId: params.RequestId } })
  //     } else {
  //       response = await API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/get-summary-data/${params.accountId}`)
  //     }
  //     setSummaryData(response)
  //     SetIsSummaryDataPresent(response.data)
  //     if (!response?.data?.length) {
  //       setErrorMessage(response.message)
  //     }
  //   } catch (error) {
  //     showError(error.message)
  //     Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }

  // const getPortfolioAssetData = async () => {
  //   try {
  //     const response = await API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/portfolios/${params.accountId}`)
  //     setPortfolioData(response?.data?.portfolioData ? ({ ...response.data, portfolioData: response?.data?.portfolioData?.map((item) => ({ ...item, id: uuidv4() })) }) : ({}))
  //   } catch (error) {
  //     showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //     Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //   }
  // }
  // let reportType = 'TR'
  // if (accountData?.data?.accReportData && accountData?.data?.accReportData[0]?.accountType === 'TRADING') {
  //   reportType = 'TR'
  // }
  // const getTopAssignedModels = async () => {
  //   try {
  //     const response = await API.get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/report/methodology/${reportType}`)
  //     setMethodologyData(methodologyDataProcess(response))
  //   } catch (error) {
  //     showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //     Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //   }
  // }

  const getTransitionPortfolioData = () => {
    const optId = accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.optId
    API
      .get('baseUriReport', `reporting/v1/${user?.userGroup}/transition-portfolio-report/account/${params?.accountId}/opt/${optId}`)
      .then(response => {
        if (response?.data) {
          setTransitionPortfolioData(transitionPortfolioDataProcess(response))
        }
      }).catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  useEffect(() => {
    const accountInfo = accountData?.data?.accReportData?.length > 0 ? accountData?.data?.accReportData[0] : ''
    if (accountInfo?.optId && accountInfo?.accountType === 'TRANSITION') {
      getTransitionPortfolioData()
    }
  }, [accountData])

  const getPolicyData = () => {
    API
      .get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/policy/${params?.accountId}`)
      .then(response => {
        if (response.data) {
          setPolicyData(response.data)
        }
      }).catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }
  let taxSavingIntervalId
  const getTaxSavingReport = (retryCount = 3, retryDelay = 10000) => {
    const fetchData = () => {
      API
        .get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/tax-savings/${params?.accountId}`)
        .then(response => {
          if (response.data) {
            setIsTaxSavingAPIRetrying(false)
            setTaxSavingData(response.data)
            clearInterval(taxSavingIntervalId)
          }
        }).catch(error => {
          setIsTaxSavingAPIRetrying(true)
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)

          // Retry logic
          if (retryCount > 0) {
            retryCount--
          } else {
            setIsTaxSavingAPIRetrying(false)
            clearInterval(taxSavingIntervalId)
          }
        })
    }
    if (accountData?.data?.accReportData && accountData?.data?.accReportData[0]?.accountType === 'TRADING') {
      fetchData()

      // Set up setInterval to repeatedly call fetchData every retryDelay milliseconds
      taxSavingIntervalId = setInterval(fetchData, retryDelay)
    }
  }

  let intervalId
  const getPositionReport = (retryCount = 3, retryDelay = 10000) => {
    const fetchData = () => {
      API
        .get('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/position-report/${params?.accountId}`)
        .then(response => {
          if (response.data) {
            setIsRetrying(false)
            setPositionData(response.data)
            clearInterval(intervalId)
          }
        })
        .catch(error => {
          setIsRetrying(true)
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)

          // Retry logic
          if (retryCount > 0) {
            retryCount--
          } else {
            setIsRetrying(false)
            clearInterval(intervalId)
            setIsDoneRetrying(true)
          }
        })
    }

    if (accountData?.data?.accReportData && accountData?.data?.accReportData[0]?.accountType === 'TRADING') {
      fetchData()

      // Set up setInterval to repeatedly call fetchData every retryDelay milliseconds
      intervalId = setInterval(fetchData, retryDelay)
    }
  }

  const changeScenario = (scenarioName) => {
    setSelectedScenario(scenarioName)
  }

  useEffect(() => {
    if (user) {
      // getSummaryData()
      // getTopAssignedModels()
      // getPersonalization()
      // getAccountData()
      // getPerformanceData()
      // getAssetAllocation()
      // getTaxSavingReport()
      // getPositionReport()
    }
  }, [user])

  useEffect(() => {
    if (user && accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.optId) {
      // getTopAssignedModels()
      // getPersonalization()
      // getPerformanceData()
      // getAssetAllocation()
      getPolicyData()
      getTaxSavingReport()
      getPositionReport()
      // getTaxManagementData()
      // if (accountData?.data?.accReportData && accountData?.data?.accReportData[0]?.accountType === 'TRANSITION') {
      //   getTradeList()
      // }
      // if (accountData?.data?.accReportData && accountData?.data?.accReportData[0]?.accountType === 'TRADING') {
      //   getPortfolioAssetData()
      // }
    }
  }, [user, accountData])

  return (
    <>
      {isLoading
        ? <Loader />
        : <>
          {isSummaryDataPresent?.optimizationData?.length > 0
            ? <Box sx={{ flexGrow: 1, mt: 2 }} className='reportPage'>
              <AccountTitle title='Report' />
              <ThemeProvider theme={theme}>
                <Grid container item spacing={3}>
                  <Grid item xs={12} sm={9} order={{ xs: 2, sm: 1 }}>
                    <Grid container item spacing={3}>
                      <Grid item xs={12} order={{ xs: 1, sm: 1 }}>
                        <Item>
                          <summaryDataContext.Provider value={summaryData}>
                            <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
                              <AccountTranInformation />
                            </ErrorBoundary>
                          </summaryDataContext.Provider>
                        </Item>
                      </Grid>
                      <Grid item xs={12} order={{ xs: 4, sm: 3 }}>
                        <Card p={4} className='card-layout'>
                          <CardContent sx={{ backgroundColor: 'white', marginTop: '-0.8em' }}>
                            {accountData?.data?.length === 0
                              ? <Typography mt={2}>No Report Available</Typography>
                              : <Item>
                                <methodologyContext.Provider value={methodologyData}>
                                  <tradeListContext.Provider value={tradeListData}>
                                    <assetAllocationContext.Provider value={assetAllocationData}>
                                      <sectorContext.Provider value={sectorData}>
                                        <industryContext.Provider value={industryData}>
                                          <instrRestrictionContext.Provider value={instrRestrictionData}>
                                            <instrSubstitutionContext.Provider value={instrSubstitutionData}>
                                              <factorTiltsContext.Provider value={factorTiltsData}>
                                                <policyDataContext.Provider value={policyData}>
                                                  <taxManagementContext.Provider value={taxManagementData}>
                                                    <performanceDataContext.Provider value={{ performanceData }}>
                                                      <metricDataContext.Provider value={{ metricData }}>
                                                        <benchmarkDataContext.Provider value={{ benchmarkReturnsData }}>
                                                          <accountDataContext.Provider value={{ ...((accountData?.data?.accReportData && accountData?.data?.accReportData[0]) || {}), openExpandedView }}>
                                                            <summaryDataContext.Provider value={summaryData}>
                                                              <taxSavingDataContext.Provider value={taxSavingData}>
                                                                <portfolioDataContext.Provider value={portfolioData}>
                                                                  <portfolioReportDataContext.Provider value={transitionPortfolioData}>
                                                                    {(accountData?.data?.accReportData && accountData?.data?.accReportData[0]?.accountType === 'TRADING' && (
                                                                      <ReportTab repTabs={tradingReportTabs} selectedScenario={selectedScenario} />
                                                                    ))}
                                                                    {(accountData?.data?.accReportData && accountData?.data?.accReportData[0]?.accountType === 'TRANSITION' && (
                                                                      <ReportTab repTabs={transitionReportTabs} selectedScenario={selectedScenario} />
                                                                    ))}
                                                                  </portfolioReportDataContext.Provider>
                                                                </portfolioDataContext.Provider>
                                                              </taxSavingDataContext.Provider>
                                                            </summaryDataContext.Provider>
                                                          </accountDataContext.Provider>
                                                        </benchmarkDataContext.Provider>
                                                      </metricDataContext.Provider>
                                                    </performanceDataContext.Provider>
                                                  </taxManagementContext.Provider>
                                                </policyDataContext.Provider>
                                              </factorTiltsContext.Provider>
                                            </instrSubstitutionContext.Provider>
                                          </instrRestrictionContext.Provider>
                                        </industryContext.Provider>
                                      </sectorContext.Provider>
                                    </assetAllocationContext.Provider>
                                  </tradeListContext.Provider>
                                </methodologyContext.Provider>
                                </Item>}
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3} order={{ xs: 1, sm: 2 }}>
                    <Grid container item spacing={3}>
                      <Grid item xs={12} order={{ xs: 2, sm: 2 }}>
                        <Item>
                          <accountDataContext.Provider value={(accountData?.data?.accReportData && accountData?.data?.accReportData[0])}>
                            <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
                              <AccountInformation />
                            </ErrorBoundary>
                          </accountDataContext.Provider>
                        </Item>
                      </Grid>
                      <Grid item xs={12} order={{ xs: 3, sm: 4 }}>
                        <Item>
                          <accountDataContext.Provider value={(accountData?.data?.accReportData && accountData?.data?.accReportData[0])}>
                            <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
                              <TaxInformation />
                            </ErrorBoundary>
                          </accountDataContext.Provider>
                        </Item>
                      </Grid>
                      {(user?.userGroup === 'adv-classic' || user?.userGroup === 'spn-pm') && summaryData?.data?.showOptReport === false
                        ? ''
                        : <Grid item xs={12} order={{ xs: 3, sm: 4 }}>
                          <Item>
                            <accountDataContext.Provider value={(accountData?.data?.accReportData && accountData?.data?.accReportData[0])}>
                              <summaryDataContext.Provider value={summaryData}>
                                <ScenarioSelection changeScenario={changeScenario} />
                              </summaryDataContext.Provider>
                            </accountDataContext.Provider>
                          </Item>
                          </Grid>}
                      <Grid item xs={12} order={{ xs: 3, sm: 4 }}>
                        <Item>
                          <accountDataContext.Provider value={(accountData?.data?.accReportData && accountData?.data?.accReportData[0])}>
                            <taxSavingDataContext.Provider value={taxSavingData}>
                              <positionDataContext.Provider value={positionData}>
                                <DownloadReport isRetrying={isRetrying} isTaxSavingAPIRetrying={isTaxSavingAPIRetrying} isDoneRetrying={isDoneRetrying} optId={accountData?.data?.accReportData?.length > 0 && accountData?.data?.accReportData[0]?.optId} />
                              </positionDataContext.Provider>
                            </taxSavingDataContext.Provider>
                          </accountDataContext.Provider>
                        </Item>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ThemeProvider>
              {isLoading ? <Loader /> : ''}
              </Box>
            : <>
              <Box className='reportPage'>
                <AccountTitle title='Report' />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '300px'
                  }}
                >
                  <Typography variant='h6' className='no-report'>
                    {errorMessage || 'No report available'}
                  </Typography>

                  {errorMessage
                    ? <Link to={'/account-review/request/' + params?.accountId}>
                      <Button
                        variant='contained'
                        sx={{
                          mt: '20px',
                          paddingLeft: '50px',
                          paddingRight: '50px'
                        }}
                      >
                        Go to Requests
                      </Button>
                      </Link>
                    : ''}
                </Box>
              </Box>
              </>}
          </>}
    </>
  )
}
export default ReportPage
