import { getUserRole } from '../../utils/getUserRole'
import { GetAPI } from './components/Actions'

export const GetKeyData = async (name) => {
  switch (name) {
    case 'account':
      return await GetAccountData()
    case 'strategy': {
      // const [response1, response2, response3] = await Promise.all([
      //   GetStrategyData('SIMPLE'),
      //   GetStrategyData('MANAGED'),
      //   GetStrategyData('DERIVED')
      //   // pass different strategyType if needed for the second call
      // ])
      // return ({
      //   ...response1,
      //   data: {
      //     ...response1.data,
      //     data: [...(response1.data?.data || []), ...(response2.data?.data || []), ...(response3.data?.data || [])]
      //   }
      // })
      return await GetAllStrategies()
    }
    case 'request':
      return {}
    case 'sponsor':
      return {}
    case 'report':
      return {}
    case 'instrument':
      return {}
    case 'trade':
      return {}
    case 'optimization':
      return {}
    case 'strategyAllocation':
      return await GetStrategyData('DERIVED', '')
    case 'master':
      return {}
    // Add more cases as needed
    default:
      return null
  }
}

export const GetAccountData = async () => {
  const userRole = getUserRole()
  return GetAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts`
  )
}

// export const GetStrategyData = async (strategyName) => {
//   const userRole = getUserRole()
//   const queryParams = {}
//   if (strategyName) {
//     queryParams.strategyName = strategyName
//   }
//   return GetAPI(
//     'baseStrategyMaintainURL',
//     `data-maintenance/v1/${userRole}/strategies`, { ...queryParams })
// }

export const GetStrategyData = async (strategyType, strategyName) => {
  const userRole = getUserRole()
  const queryParams = {}
  if (strategyType) {
    queryParams.strategyType = strategyType
  }
  if (strategyName) {
    queryParams.strategyName = strategyName
  }
  return GetAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies`, { ...queryParams })
}

export const GetAllStrategies = async () => {
  const userRole = getUserRole()
  return GetAPI(
    'baseStrategyMaintainURL',
    `data-maintenance/v1/${userRole}/strategies`)
}
