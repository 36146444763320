import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import { Cached, TryOutlined } from '@mui/icons-material'
import { Box, Card, CardContent, Grid, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography, Divider } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // Import the UTC plugin
import '../../../../assets/styles/AccountDetailspage.scss'
// import { useAuth } from '../../../../contexts/AuthContext'
// import { useErrorToast } from '../../../../hooks/useErrorToast'
import TradeStatusColumnCell from './TradeStatusColumnCell'

dayjs.extend(utc)

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(14),
    padding: '16px',
    boxShadow: '0px 7px 29px rgba(100, 100, 111, 0.2)',
    borderRadius: '10px',
    marginRight: '10px'
  }
}))

const AdhocTradeHistory = ({ adhocTradeHistoryData, tradeAdhocHistoryLoading }) => {
  const [optmRun, setOptmRun] = useState(adhocTradeHistoryData)
  // const [syncIconClick, setSyncIconClick] = useState({})
  // const { user } = useAuth()
  // const { showError } = useErrorToast()

  useEffect(() => {
    setOptmRun(adhocTradeHistoryData)
  }, [adhocTradeHistoryData])

  const updateAdhocTradeData = (data, orderId) => {
    const index = optmRun.findIndex((obj) => obj?.orderId === orderId)
    setOptmRun(prevOptmRun => {
      const newArray = [...prevOptmRun]
      newArray[index] = {
        ...newArray[index],
        tradedPrice: data?.tradedPrice,
        tradedShares: data?.tradedShares,
        fixTradeStatus: data?.fixTradeStatus
      }
      return newArray
    })
  }

  // const getLatestFixTradeStatus = (orderId) => {
  //   API.get(
  //     'baseUriTrade',
  //     `trade/v1/${user?.userGroup}/adhoc/trades/${orderId}`
  //   )
  //     .then((response) => {
  //       if (response?.data?.tradeStatusCode) {
  //         const data = response.data
  //         const index = optmRun.findIndex((obj) => obj?.orderId === orderId)
  //         const newArray = [...optmRun]
  //         newArray[index] = {
  //           ...newArray[index],
  //           tradedPrice: data?.tradedPrice,
  //           tradedShares: data?.tradedShares,
  //           tradeStatusCode: data?.tradeStatusCode
  //         }
  //         setOptmRun(newArray)
  //         updateAdhocTradeData(newArray)
  //       }
  //       setSyncIconClick(prevState => ({
  //         ...prevState,
  //         [orderId]: false
  //       }))
  //     })
  //     .catch((error) => {
  //       setSyncIconClick(prevState => ({
  //         ...prevState,
  //         [orderId]: false
  //       }))
  //       showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
  //       Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error?.message)
  //     })
  // }

  // const handleSyncClick = (e, orderId) => {
  //   setSyncIconClick(prevState => ({
  //     ...prevState,
  //     [orderId]: true
  //   }))
  //   getLatestFixTradeStatus(orderId)
  // }

  // const getColorProperties = (data) => {
  //   if (data === 'TRADE_COMPLETED') {
  //     return {
  //       color: '#446b60',
  //       backgroundColor: '#3BBFA399',
  //       border: '1px solid #446b6022'
  //     }
  //   } else if (data === 'TRADE_IN_PROGRESS') {
  //     return {
  //       color: '#aaab29',
  //       backgroundColor: '#fbfa6c99',
  //       border: '1px solid #aaab2922'
  //     }
  //   } else if (data === 'TRADE_FAILED') {
  //     return {
  //       color: '#79454f',
  //       backgroundColor: '#ff616199',
  //       border: '1px solid #79454f22'
  //     }
  //   } else if (data === 'TRADE_STOPPED') {
  //     return {
  //       color: '#969696',
  //       backgroundColor: '#C0C0C099',
  //       border: '1px solid #96969622'
  //     }
  //   } else if (data === 'TRADE_PARTIAL_FILLED') {
  //     return {
  //       color: '#816f36',
  //       backgroundColor: '#d2992299',
  //       border: '1px solid #816f3622'
  //     }
  //   }
  // }

  const wholeNumberCurrencyFormatter = (amount) => {
    return Math.abs(Number(amount)) >= 1.0e9
      ? (Math.abs(Number(amount)) / 1.0e9).toFixed(0) + 'B'
      : Math.abs(Number(amount)) >= 1.0e6
        ? (Math.abs(Number(amount)) / 1.0e6).toFixed(0) + 'M'
        : Math.abs(Number(amount)) >= 1.0e3
          ? (Math.abs(Number(amount)) / 1.0e3).toFixed(0) + 'K'
          : Math.abs(Number(amount)).toFixed(0)
  }

  const tableHeaders = [
    {
      field: 'accountName',
      headerName: 'Account Name',
      valueGetter: (params) => params.row?.type === 'ACC' || params.row?.type === 'AGG' ? params.row?.accountName : ''
    },
    {
      field: 'accountCd',
      headerName: 'Account Code',
      valueGetter: (params) => params.row?.type === 'ACC' || params.row?.type === 'AGG' ? params.row?.accountCd || '' : ''
    },
    {
      field: 'type',
      headerName: 'Type',
      renderCell: (params) => {
        const type = params.row?.type || 'N/A'
        return type === 'NONARIS' ? 'ADHOC' : type
      }
    },
    {
      field: 'accountId',
      headerName: 'Account ID',
      valueGetter: (params) => params.row?.accountId
    },
    {
      field: 'aggName',
      headerName: 'Aggregate Name',
      valueGetter: (params) => params.row?.type === 'AGG' ? params.row?.aggName : ''
    },
    {
      field: 'aggCd',
      headerName: 'Aggregate Code',
      valueGetter: (params) => params.row?.type === 'AGG' ? params.row?.aggCd || '' : ''
    },
    {
      field: 'sponsorName',
      headerName: 'Sponsor Name',
      valueGetter: (params) => params?.row?.sponsorName ? params.row?.sponsorName : ''
    },
    {
      field: 'custodianAccountNumber',
      headerName: 'Custodian Account Number',
      renderCell: (params) => params.row?.custodianAccountNumber || 'N/A'
    },
    {
      field: 'masterAccountId',
      headerName: 'Master Account ID',
      renderCell: (params) => params.row?.masterAccountId || 'N/A'
    },
    {
      field: 'trdDate',
      headerName: 'Trade Date',
      valueFormatter: (params) => params?.value ? params?.value?.split('T')[0] : ''
    },
    {
      field: 'trdCode',
      headerName: 'Trade Code',
      renderCell: (params) => (<>
        <span style={{ color: params.row?.trdCode === 'SELL' ? '#FF6161' : params.row?.trdCode === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params?.row?.trdCode || 'N/A'}</span>
                               </>)
    },
    {
      field: 'fixTradeStatus',
      headerName: 'Fix Connectivity Status',
      width: 150,
      // flex: 2,
      renderCell: (params) => (<TradeStatusColumnCell {...params} updateAdhocTradeData={updateAdhocTradeData} />),
      // renderCell: (params) => {
      //   const id = params?.row?.orderId
      //   const currentDate = dayjs().format('YYYY-MM-DD')
      //   const date = params?.row?.trdDate?.split('T')[0]
      //   const data = params?.row?.tradeStatusCode || 'N/A'
      //   const syncStatusIcon = (!(data === 'TRADE_COMPLETED' || data === 'TRADE_FAILED') && currentDate === date) ? <Tooltip title='Refresh trade status'><Cached sx={{ width: '16px', height: '16px', ml: '5px', cursor: 'pointer' }} className={`sync-icon-rotate ${syncIconClick[id] ? 'rotate' : ''}`} fontSize='small' onClick={(e) => handleSyncClick(e, id)} /></Tooltip> : ''
      //   return data !== 'N/A'
      //     ? <><Box sx={{ borderRadius: '20px', fontSize: '10px', px: '10px', py: '2px', ...getColorProperties(data), fontWeight: 600 }}>{data}</Box>{syncStatusIcon}</>
      //     : <>{data}</>
      // },
      valueGetter: (params) => params?.row?.fixTradeStatus
      // valueGetter: (params) => params.row?.tradeStatusCode || 'N/A'
    },
    {
      field: 'tradeStatus',
      headerName: 'Trade Status',
      width: 160,
      // flex: 2,
      valueGetter: (params) => params?.row?.tradeStatus
    },
    {
      field: 'localSymbol',
      headerName: 'Local Symbol',
      renderCell: (params) => params.row?.localSymbol || 'N/A'
    },
    {
      field: 'instrName',
      headerName: 'Instrument Name',
      renderCell: (params) => params.row?.instrName || 'N/A'
    },
    {
      field: 'trdQty',
      headerName: 'Trade Shares',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params.row?.trdQty !== null && params.row?.trdQty !== undefined ? params.row?.trdQty : 'N/A'
    },
    {
      field: 'tradedShares',
      headerName: 'Traded Shares',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params.row?.tradedShares !== null && params.row?.tradedShares !== undefined ? params.row?.tradedShares : 'N/A'
    },
    {
      field: 'tradedPrice',
      headerName: 'Traded Price',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params.row?.tradedPrice !== null && params.row?.tradedPrice !== undefined
        ? params.row?.tradedPrice < 0
          ? `-$${wholeNumberCurrencyFormatter(params.row?.tradedPrice)}`
          : `$${wholeNumberCurrencyFormatter(params.row?.tradedPrice)}`
        : 'N/A',
      valueGetter: (params) => params.row?.tradedPrice || 'N/A'
    },
    {
      field: 'vspDate',
      headerName: 'Purchase Date',
      renderCell: (params) => params.row?.vspDate || 'N/A'
    },
    {
      field: 'vspPrice',
      headerName: 'Original Purchase Cost',
      renderCell: (params) => params.row?.vspPrice || 'N/A'
    },
    {
      field: 'optRemarks',
      headerName: 'Trade Comments',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params?.row?.comments) {
          return (
            <HtmlTooltip
              title={
                <Typography sx={{ fontSize: '14px' }} my={1}>{params?.row?.comments}</Typography>
              }
            >
              <TryOutlined />
            </HtmlTooltip>
          )
        } else {
          return 'N/A'
        }
      }
    }
  ]

  const getRowId = (row) => row.id
  const tableHeaderHTML = tableHeaders.map(({ headerName }, index) => <TableCell key={index}>{headerName}</TableCell>)
  const rowSkeleton = tableHeaders.map(({ headerName }, index) => <TableCell key={index}><Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} /></TableCell>)

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          {tradeAdhocHistoryLoading
            ? (
              <Card>
                <CardContent>
                  <Box className='table-responsive'>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {tableHeaderHTML}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>{rowSkeleton}</TableRow>
                        <TableRow>{rowSkeleton}</TableRow>{' '}
                      </TableBody>
                    </Table>
                  </Box>
                </CardContent>
              </Card>
              )
            : (
              <Card>
                <CardContent>
                  <Box className='table-responsive'>
                    {optmRun === undefined || optmRun?.length === 0
                      ? (
                        <>No adhoc trade found for given date</>
                        )
                      : (
                        <DataGridPro
                          density='compact'
                          columns={tableHeaders}
                          rows={optmRun}
                          autoHeight
                          pageSizeOptions={[15]}
                          initialState={{
                            columns: {
                              columnVisibilityModel: {
                                accountId: false,
                                aggName: false,
                                aggCd: false,
                                sponsorName: false,
                                custodianAccountNumber: false,
                                masterAccountId: false,
                                instrName: false
                              }
                            },
                            pagination: { paginationModel: { pageSize: 15 } }
                          }}
                          pagination
                          getRowId={getRowId}
                        />
                        )}
                  </Box>
                </CardContent>
              </Card>
              )}
        </Grid>
      </Grid>
    </Box>
  )
}
export default AdhocTradeHistory
