import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { API } from 'aws-amplify'
import { Box, Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import * as Sentry from '@sentry/react'
import { storeAccountData } from '../../store/dashboard-reducer/dashboard-reducer'
import { useAuth } from '../../contexts/AuthContext'
import CustomKeyFieldSearch from '../../components/CustomKeyFieldSearch'
import { useErrorToast } from '../../hooks/useErrorToast'
import { SelectListOfAccountLoader } from './SelectListAccountLoader'
import './selectelistofAccounts.css'
import { storeReviewPageAccounts } from '../../store/account-review-reducer/account-review-reducer'
import { RefreshOutlined } from '@mui/icons-material'

const ListOfaAllAccount = ({ title }) => {
  const { user } = useAuth()
  const dispatch = useDispatch()
  const { accountData } = useSelector((state) => state.dashboard)
  const { reviewPageAccounts } = useSelector((state) => state.accountReview)
  const { showError } = useErrorToast()
  const [Loading, setLoading] = useState(true)
  const [accounts, setAccounts] = useState([])
  const [currentPage, setCurrentPage] = useState(0)

  const columns = useMemo(() => ([
    {
      field: 'priority',
      headerName: 'Priority',
      flex: 1,
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => (
        <Box className={'priority-' + params.value} />
      )
    },
    {
      field: 'accountName',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        <Link
          className='linkId'
          to={`/account-review/${title}/${params.row.accountId}`}
          state={{
            // strategyId: params.row.strategyId,
            // accountName: params.row.accountName,
            // strategyName: params.row.strategyName,
            // accountType: params.row.accountType
          }}
        >
          {params?.value || 'N/A'}
        </Link>
      )
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      flex: 1,
      valueGetter: (params) => params.value?.toLowerCase()?.charAt(0)?.toUpperCase() + params.value?.slice(1)?.toLowerCase()
    },
    {
      field: 'isTaxable',
      headerName: 'Taxable',
      flex: 1,
      valueGetter: (params) => (params.value === 0 ? 'No' : 'Yes')
    },
    {
      field: 'accountCode',
      headerName: 'Account Code',
      flex: 1
    },
    {
      field: 'custodianAccountNumber',
      headerName: 'Custodian Account Number',
      flex: 1,
      valueFormatter: (params) => params.value || 'N/A'
    },
    {
      field: 'masterAccountId',
      headerName: 'Master Account Number',
      flex: 1,
      valueFormatter: (params) => params.value || 'N/A'
    },
    {
      field: 'portSpecName',
      headerName: 'Model',
      flex: 1
    },
    {
      field: 'totalMarketValue',
      headerName: 'Total Market Value',
      flex: 1,
      valueFormatter: (params) => `$${formatCurrency(params.value)}`,
      align: 'right'
    },
    {
      field: 'unrealizedGainLoss',
      headerName: 'Unrealized Gain/Loss',
      flex: 1,
      renderCell: (params) => params.row.unrealizedGainLoss < 0
        ? `-$${formatCurrency(params.row.unrealizedGainLoss || 0)} 
      (${(Number(params.row.unrealizedGainLossWeight || 0) * 100).toFixed(2)}%)`
        : `${formatCurrency(params.row.unrealizedGainLoss || 0)} 
      (${(Number(params.row.unrealizedGainLossWeight || 0) * 100).toFixed(2)}%)`,
      align: 'right'
    },
    {
      field: 'assetAllocationDrift',
      headerName: 'Asset Allocation Drift',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Grid container justifyContent='center' alignItems='center' spacing={0.5} flexWrap='nowrap'>
          <Grid item><Box className={Number(params.value) < 0.6666 ? 'progressbar bg-blue' : 'progressbar'} /></Grid>
          <Grid item><Box className={(Number(params.value) > 0.6666 && Number(params.value) < 1.3333) ? 'progressbar bg-blue' : 'progressbar'} /></Grid>
          <Grid item><Box className={Number(params.value) > 1.3333 ? 'progressbar bg-blue' : 'progressbar'} /></Grid>
        </Grid>
      )
    }
  ]), [title])

  useEffect(() => {
    if (user) {
      // check if data is present in redux then avoid API call
      if (accountData?.length) {
        setAccounts(accountData)
        const storeArray = []
        accountData.forEach((element) => {
          storeArray.push({
            accountName: element.accountName,
            accountType: element.accountType,
            accountCode: element.accountCode,
            strategyId: element.strategyId,
            custodianAccountNumber: element?.custodianAccountNumber,
            portSpecName: element.portSpecName,
            accountId: element.accountId,
            priority: element.priority,
            totalMarketValue: element.totalMarketValue
          })
        })
        localStorage.setItem('object', JSON.stringify(storeArray))
        setLoading(false)
      }
      // else if (reviewPageAccounts && reviewPageAccounts[title] && reviewPageAccounts[title].length) {
      //   // check if data is present in redux for that module then avoid API call
      //   setAccounts(reviewPageAccounts[title])
      //   const storeArray = []
      //   reviewPageAccounts[title].forEach((element) => {
      //     storeArray.push({
      //       accountName: element.accountName,
      //       accountType: element.accountType,
      //       accountCode: element.accountCode,
      //       strategyId: element.strategyId,
      //       custodianAccountNumber: element?.custodianAccountNumber,
      //       portSpecName: element.portSpecName,
      //       accountId: element.accountId,
      //       priority: element.priority,
      //       totalMarketValue: element.totalMarketValue
      //     })
      //   })
      //   localStorage.setItem('object', JSON.stringify(storeArray))
      //   setLoading(false)
      // }
      else {
        getAccountsList()
      }
    }
  }, [user,title])

  const getAccountsList = async (page = 1) => {
    setLoading(true)
    API.get(
      'baseURL',
      `account-master/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/summary/details`,
      {
        queryStringParameters: {
          page, perpage: 200, ...{ ...user?.userGroup === 'adv-classic' ? { resources: encodeURIComponent(JSON.stringify({ "serviceId": "book-of-business", "resourceId": "book-of-business" })) } : {} } },
      }
    )
      .then((response) => {
        if (response.data.accountsSummaryDetails) {
          setAccounts([...response.data.accountsSummaryDetails])
          // if (user?.userGroup === 'pm') {
            dispatch(storeAccountData(response.data.accountsSummaryDetails))
          // }
          // else {
          //   dispatch(storeReviewPageAccounts({ moduleName: title, accounts: response.data.accountsSummaryDetails }))
          // }
          const storeArray = []
          response.data.accountsSummaryDetails.forEach((element) => {
            storeArray.push({
              accountName: element.accountName,
              accountType: element.accountType,
              accountCode: element.accountCode,
              strategyId: element.strategyId,
              custodianAccountNumber: element?.custodianAccountNumber,
              portSpecName: element.portSpecName,
              accountId: element.accountId,
              priority: element.priority,
              totalMarketValue: element.totalMarketValue
            })
          })
          localStorage.setItem('object', JSON.stringify(storeArray))
        }
        setLoading(false)
      })
      .catch((error) => {
        showError(error.message)
        setLoading(false)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const formatCurrency = (amount) => {
    // Nine Zeroes for Billions
    return Math.abs(Number(amount)) >= 1.0e9
      ? (Math.abs(Number(amount)) / 1.0e9).toFixed(2) + 'B'
      : // Six Zeroes for Millions
      Math.abs(Number(amount)) >= 1.0e6
        ? (Math.abs(Number(amount)) / 1.0e6).toFixed(2) + 'M'
        : // Three Zeroes for Thousands
        Math.abs(Number(amount)) >= 1.0e3
          ? (Math.abs(Number(amount)) / 1.0e3).toFixed(2) + 'K'
          : Math.abs(Number(amount).toFixed(2))
  }

  const refreshData = () => {
    getAccountsList()
  }

  return (
    <Box className='selectListofAccount'>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography className='page-title' mb={2}>Select Account to Access Dashboard</Typography>
        <Button
          variant='outlined'
          onClick={refreshData}
          sx={{
            border: '2px solid #dee2e6',
            padding: 1,
            minWidth: 'auto',
            ':hover': {
              background: 'transparent',
              border: '2px solid #dee2e6'
            },
            marginBottom: '16px',
            // marginTop: '8px',
            // marginRight: '8px'
            marginRight: '0px'
          }}
          className='tooltip-trade'
        >
          <RefreshOutlined sx={{ height: 20, width: 20, color: '#74788D' }} />
          <span
            className='tooltiptext'
            style={{ marginLeft: '-15px' }}
          >
            Refresh
          </span>
        </Button>
      </Box>
      <Card className='card-layout'>
        <CardContent>
          {Loading
            ? <Box className='table-responsive'>
              <table>
                <thead>
                  <tr>
                    <th>Priority</th>
                    <th>Name</th>
                    <th>Account Type</th>
                    <th>Taxable</th>
                    <th>Account Code</th>
                    <th>Model</th>
                    <th>Total Market Value</th>
                    <th>Unrealized Gain/Loss</th>
                    <th>Asset Allocation Drift</th>
                  </tr>
                </thead>
                <SelectListOfAccountLoader colNum={12} />
              </table>
              </Box>
            : <DataGridPro
                rows={accounts}
                columns={columns}
                loading={Loading}
                getRowId={(row) => row.accountId}
                autoHeight
                pageSizeOptions={[10]}
                onPaginationModelChange={(event) => setCurrentPage(event.page)}
                paginationModel={{ pageSize: 10, page: currentPage }}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10, page: currentPage } },
                  columns: {
                    columnVisibilityModel: {
                      masterAccountId: false,
                      custodianAccountNumber: false
                    }
                  }
                }}
                pagination
                slots={{
                  toolbar: CustomKeyFieldSearch
                }}
                slotProps={{ toolbar: { filterData: (data) => { setAccounts(data); setCurrentPage(0) }, fieldName: 'accountName', tableData: accountData, fieldLabel: 'Account Name' } }}
                disableRowSelectionOnClick
                sx={{
                  border: 'none',
                  fontFamily: 'Open Sans'
                }}
              />}
        </CardContent>
      </Card>
    </Box>
  )
}
export default ListOfaAllAccount