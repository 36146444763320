import { randomId } from '@mui/x-data-grid-generator'

const mysqlToInputTypeMap = {
  varchar: 'text',
  int: 'number',
  float: 'number',
  double: 'number',
  date: 'date',
  datetime: 'date',
  boolean: 'checkbox',
  smallint: 'number',
  tinyint: 'select',
  decimal: 'number'
  // Add more mappings as needed
}

// fields which should be displayed in same format as in mysql
const disableFormattingFields = ['taxYear', 'currentTaxYear']

export const DataFormatting = (responseData, tabsList) => {
  const formattedData = {}

  responseData?.forEach((response, index) => {
    response?.data?.tableStructure && response?.data?.tableStructure?.forEach(column => {
      if (tabsList[index]?.id === 'dynamic-strategy' && column.columnName === 'weight') {
        column.dataType = 'text'
      } else {
        column.dataType = mysqlToInputTypeMap[column.dataType] || 'text'
        if (disableFormattingFields.includes(column.columnName)) {
          column.dataType = 'text'
        }
      }
    })

    formattedData[tabsList[index].id] = {
      ...response,
      data: {
        ...response?.data,
        data: response?.data?.data?.map(row => {
          row.id = randomId()
          if (response?.data?.tableStructure) {
            for (const column of response?.data?.tableStructure) {
              if (column.dataType === 'date' && row[column.columnName]) {
                row[column.columnName] = new Date(row[column.columnName]).toUTCString()
              }
            }
          }
          return row
        })
      }
    }
  })
  return formattedData
}
