import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { API } from 'aws-amplify'
import { TabContext } from '@mui/lab'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Divider, Skeleton } from '@mui/material'
import Tab from '@mui/material/Tab'
import * as Sentry from '@sentry/react'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import ErrorFallback from '../../ErrorFallback'
import { assetAllocationDataProcess } from '../../Report/DataFormatting'
import TradeSummaryChart from '../components/TradeSummaryPopup/TradeSummaryChart'
import TradeMarketCapChart from '../components/TradeSummaryPopup/TradeMarketCapChart'
import TradeFactorChart from '../components/TradeSummaryPopup/TradeFactorChart'
import TradeSectorTable from '../components/TradeSummaryPopup/TradeSectorTable'
import StyledTradePopupBox from '../components/StyledTradePopupBox'

const VIEW_TRADE_SUMMARY_TABS =
  ['Summary', 'Marketcap', 'Factor', 'Sector']

const ViewTradeSummaryChart = ({ accountData, fArray }) => {
  const [tradeSummaryLoading, setTradeSummaryLoading] = useState(true)
  const { user } = useAuth()
  const [tradeSummaryData, setTradeSummaryData] = useState()
  const { showError } = useErrorToast()
  const [activeTabIndex, setActiveTabIndex] = useState('0')
  const screen = 'not main'

  const handleTabPanelChange = (event, newValue) => {
    setActiveTabIndex(newValue)
  }

  const getTradeSummaryData = async () => {
    try {
      const response = await API.get('baseOptimizationURL', `optimization/v1/${user?.userGroup === 'adv-classic' ? 'spn-pm' : user?.userGroup}/asset-allocations/${accountData[3]}`)
      if (response && response.data) {
        setTradeSummaryData({ ...assetAllocationDataProcess(response), optFeasibilityReport: response?.data?.optFeasibilityReport })
      }
      setTradeSummaryLoading(false)
    } catch (error) {
      setTradeSummaryLoading(false)
      showError(error.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
    }
  }

  const renderTabPanel = (item) => {
    switch (item) {
      case 'Summary': {
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <TradeSummaryChart fArray={fArray} accountData={accountData} />
          </ErrorBoundary>
        )
      }
      case 'Marketcap': {
        return (
          <>{tradeSummaryLoading
            ? <Skeleton variant='rectangular' height={500} width='100%' />
            : <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
              <TradeMarketCapChart marketCapObj={{ marketCapLabels: tradeSummaryData?.marketCapLabels, marketCapData: tradeSummaryData?.marketCapData || [] }} selectedScenario={accountData[1]} />
              </ErrorBoundary>}
          </>
        )
      }
      case 'Factor': {
        return (
          <>{tradeSummaryLoading
            ? <Skeleton variant='rectangular' height={500} width='100%' />
            : <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
              <TradeFactorChart factorChartObj={{ factorLabels: tradeSummaryData?.factorLabels, factorData: tradeSummaryData?.factorData || [] }} selectedScenario={accountData[1]} />
              </ErrorBoundary>}
          </>
        )
      }
      case 'Sector': {
        return (
          <>{tradeSummaryLoading
            ? <Skeleton variant='rectangular' height={500} width='100%' />
            : <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
              <TradeSectorTable sectorDataObj={{ sectorLabels: tradeSummaryData?.sectorLabels, sectorData: tradeSummaryData?.sectorData || [], sectorDataTableColumnMapping: tradeSummaryData?.sectorDataTableColumnMapping || {} }} />
              </ErrorBoundary>}
          </>
        )
      }
      default:
        return 'Something went wrong'
    }
  }

  useEffect(() => {
    if (user) {
      getTradeSummaryData()
    }
  }, [user])

  return (
    <>
      <StyledTradePopupBox
        className='table-responsive'
        sx={{
          maxHeight: '600px !important',
          height: '600px'
        }}
      >
        <TabContext value={activeTabIndex}>
          <TabList
            onChange={handleTabPanelChange}
            variant='scrollable'
            scrollButtons={false}
            TabIndicatorProps={{
              style: {
                backgroundColor: '#34475A'
              }
            }}
            indicatorColor='#34475A'
          >
            {VIEW_TRADE_SUMMARY_TABS?.map((item, index) => (
              <Tab
                className='reportCardNav' key={index} value={index.toString()} label={item} disabled={item === 'Geography'} sx={{
                  opacity: item === 'Geography' ? 0.5 : 1
                }}
              />
            ))}
          </TabList>
          <Divider sx={{
            bgcolor: 'rgba(116, 120, 141, 0.15)'
          }}
          />
          {VIEW_TRADE_SUMMARY_TABS?.map((item, index) => (
            <TabPanel
              key={index}
              value={index.toString()}
              sx={{ px: 0 }}
            >
              <>
                {renderTabPanel(item)}
              </>
            </TabPanel>
          ))}
        </TabContext>
      </StyledTradePopupBox>
    </>
  )
}

export default ViewTradeSummaryChart
