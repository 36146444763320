import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { styled } from '@mui/system'
import { Box, Skeleton, Typography } from '@mui/material'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  marginBottom: '1.25rem',
  '@media (min-width: 768px)': {
    flexDirection: 'row',
    alignItems: 'center'
  }
})

const StrategyTitle = ({ title, strategyName, strategyCode, infoLoading, children }) => {
  const params = useParams()
  const location = useLocation()

  return (
    <>
      {children}
      <Container>
        <Box className='page-title' sx={{ paddingTop: '0 !important' }}>{title}</Box>
        <Box className='client' sx={{ marginTop: '10px' }}>
          <Box className='divider bg-yellow' mr={2} />
          <Box>

            {infoLoading
              ? <>
                <Skeleton variant='text' sx={{ width: '150px', fontSize: '20px' }} />
                </>
              : <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center' }}>{strategyName || ''}</Typography>}
            {/* <Box
                component='span'
                sx={{
                  backgroundColor: '#50CAF1',
                  color: 'common.white',
                  borderRadius: '16px',
                  marginLeft: '0.75rem',
                  paddingLeft: '0.5rem',
                  paddingRight: '0.5rem',
                  fontSize: '0.625rem'
                }}
              >
                {filterSecuredata[0]?.strTypeCode ? filterSecuredata[0]?.strTypeCode?.charAt(0)?.toUpperCase() + filterSecuredata[0]?.strTypeCode?.slice(1)?.toLowerCase() : ''}
              </Box> */}
            {infoLoading
              ? <Skeleton variant='text' sx={{ width: '50px', fontSize: '14px', mr: 1, display: 'inline-block' }} />
              : (
                <Typography variant='small' sx={{ fontSize: '0.875rem', mr: 1 }}>
                  {strategyCode || ''}
                </Typography>
                )}
            <Link to={location.pathname.replace(params.strategyId, '')} style={{ color: '#3A76D4' }}>
              Switch Strategy
            </Link>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default StrategyTitle
