import React, { useEffect, useState } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { useQueries } from '@tanstack/react-query'
import { API } from 'aws-amplify'
import { Box, Card, CardContent, Grid, Typography, Tooltip, IconButton, Skeleton } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import * as Sentry from '@sentry/react'
import { ErrorBoundary } from 'react-error-boundary'
import { IoIosExpand } from 'react-icons/io'
import { IoReload } from 'react-icons/io5'
import AccountTitle from '../../../components/AccountTitle'
import { useAuth } from '../../../contexts/AuthContext'
import { useAccountService } from '../../../hooks/useAccountService'
import { useErrorToast } from '../../../hooks/useErrorToast'
import ErrorFallback from '../../ErrorFallback'
import AccountAttributes from '../components/AccountAttributes'
import AccountRequests from './AccountRequests'
import AssetAllocation from '../components/AssetAllocation'
import { DashboardTableLoaders } from '../Dashboardloaders/Dashboardtableloader'
import { InternalNavigation } from '../components/NavigateFromDashboard'
import OverWeightedSecurity from './OverWeightedSecurity'
import PortfolioModel from './PortfolioModel'
import { RealativeVolatility } from './RelativeVolatility'
import TopSecurity from './TopSecurity'
import UnderWeightedSecurities from './UnderWeightedSecurities'
import MarketValueAreaChart from '../../Report/components/performanceComponents/MarketValueChart'
import AccountActivityTable from '../components/AccountActivityTable'
import { benchmarkDataProcess } from '../../Report/DataFormatting'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL } from '../../../contstants/constants'

const AccountDashboard = () => {
  const { user, checkAccess } = useAuth()
  const { showError } = useErrorToast()
  const { getAccountAttributes, getStrategyAttributes } = useAccountService()
  const { SectorAndSecurityLoader, MajorAssetAllocationLoader, TopSecurityLoader, DashboardRequestLoader, ActivitySummaryLoader } = DashboardTableLoaders()
  const [accountinstrumentDrift, setAccountInstrumentDrift] = useState({})
  const [accountParameters, setAccountParameters] = useState({})
  // const [accountInstruments, setAccountInstruments] = useState({})
  const [strategyParameters, setStrategyParameters] = useState({})
  const [strategyInstruments, setStrategyInstruments] = useState([])
  const [cardData, setCardData] = useState({})
  const [assetAllocationData, setAssetAllocationData] = useState([])
  const [accountRequests, setAccountRequests] = useState([])
  const [riskAttributes, setRiskAttributes] = useState({})
  const [marketValueData, setMarketValueData] = useState([])
  const [marketChartData, setMarketChartData] = useState([])
  const [accountActivitySummaryData, setAccountActivitySummaryData] = useState([])
  const [overWeight, setOverWeight] = useState()
  const [underWeight, setUnderWeight] = useState()
  const [value, setValue] = useState()
  const [noData, setNodata] = useState()
  const params = useParams()
  const [sectorLoading, setSectorLoading] = useState(true)
  const [assetLoading, setAssetLoading] = useState(true)
  const [topPositionLoader, setTopPositionLoader] = useState(true)
  const [requestLoader, setRequestLoader] = useState(true)
  const [resetKey, setResetKey] = useState(0)
  const [isChartZoomed, setIsChartZoomed] = useState(false)
  const [isMVChartLoading, setIsMVChartLoading] = useState(true)
  const [benchmarkReturnsData, setBenchmarkReturnsData] = useState([])
  const navigate = useNavigate()
  const location = useLocation()

  const dataSecure = localStorage.getItem('object')

  const getStoreData = dataSecure ? JSON.parse(dataSecure) : []

  const filterSecureData = getStoreData?.filter((item) => item?.accountId === params?.accountId)

  const strategyId = filterSecureData?.[0]?.strategyId ?? ''

  const routePath = useLocation()
  // add this scroll to top because when user come from book of business page
  // it will navigate to Account Dashboard this component is rendering but the window page location is same
  // so to avoid that add Auto scroll functionality
  const onTop = () => { window.scrollTo(0, 0) }

  useEffect(() => onTop(), [routePath])

  const resetChart = () => {
    setResetKey((prevKey) => prevKey + 1)
  }

  const handleZoomChange = (isZoomed) => {
    setIsChartZoomed(isZoomed)
  }

  // API call functions that will return promise
  const fetchAccountRequestData = () => {
    return API.get(
      'baseRequestURL',
      `request/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/requests/${params.accountId}`
    )
  }

  const fetchAssetAllocation = () => {
    return API.get(
      'baseURL',
      `account-master/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${params.accountId}/asset-allocations`
    )
  }

  const fetchRiskAttribute = () => {
    return API.get(
      'baseURL',
      `account-master/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${params.accountId}/risks/attributes`
    )
  }

  const fetchAccountInstrumentDriftData = () => {
    return getAccountAttributes(`${params.accountId}`, {
      'account-instruments-drift': true
    })
  }

  const fetchAccountParametersData = () => {
    return getAccountAttributes(`${params.accountId}`, {
      'account-parameters': true
    })
  }

  const fetchStrategyParameter = () => {
    return getStrategyAttributes(`${strategyId}`, {
      'strategy-parameters': true
    })
  }

  const fetchStrategyInstrumentsDrift = () => {
    return getStrategyAttributes(`${strategyId}`, {
      'strategy-instruments': true
    })
  }

  const fetchBenchmarkData = () => {
    return API.post('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/benchmark-returns/account/${params.accountId}/strategy/${strategyId}`, { body: {} })
  }

  const fetchMetricData = async () => {
    return API.post('baseUriReport', `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/metric-data/${params.accountId}`, { body: {} })
  }

  const fetchActivitySummary = () => {
    return API.get(
      'baseUriReport',
      `reporting/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/activity-summary/${params.accountId}`
    )
  }

  // useQuery hook to call all these APIs parallel
  const [accountParametersQuery, accountInstrumentsDriftQuery, strategyParametersQuery, strategyInstrumentsDriftQuery, accountRequestsQuery, accountAssetAllocationQuery, accountRiskAttributesQuery, marketValueQuery, benchmarkQuery, accountActivitySummaryQuery] = useQueries({
    queries: [
      {
        queryKey: ['account-parameter', params.accountId], // unique key for query caching
        queryFn: fetchAccountParametersData, // function that will return promise
        enabled: user !== null, // dependent condition
        retry: false // prevent retry if it fails
      },
      {
        queryKey: ['account-instrument', params.accountId],
        queryFn: fetchAccountInstrumentDriftData,
        enabled: user !== null,
        retry: false
      },
      {
        queryKey: ['strategy-parameter', params.accountId],
        queryFn: fetchStrategyParameter,
        enabled: user !== null && strategyId !== '',
        retry: false
      },
      {
        queryKey: ['strategy-instrument', params.accountId],
        queryFn: fetchStrategyInstrumentsDrift,
        enabled: user !== null && strategyId !== '',
        retry: false
      },
      {
        queryKey: ['account-request', params.accountId],
        queryFn: fetchAccountRequestData,
        enabled: user !== null,
        retry: false
      },
      {
        queryKey: ['account-allocation', params.accountId],
        queryFn: fetchAssetAllocation,
        enabled: user !== null,
        retry: false
      },
      {
        queryKey: ['account-risk', params.accountId],
        queryFn: fetchRiskAttribute,
        enabled: user !== null,
        retry: false
      },
      {
        queryKey: ['metric-data', params.accountId],
        queryFn: fetchMetricData,
        enabled: user !== null,
        retry: false
      },
      {
        queryKey: ['benchmark-returns', params?.accountId],
        queryFn: fetchBenchmarkData,
        enabled: user !== null,
        retry: false
      },
      {
        queryKey: ['account-activity', params.accountId],
        queryFn: fetchActivitySummary,
        enabled: user !== null,
        retry: false
      }
    ]
  }
  )

  // useEffect(() => {
  //   if (user) {
  //     getAccountInstrumentDriftDetails()
  //     getAccountParametersDetails()
  //     // getAccountInstrumentsDetails()
  //     getStrategyParametersDetails()
  //     getStrategyInstrumentsDetails()
  //     getAccountRequests()
  //     getAssetAllocationData()
  //     getRiskAttributes()
  // }
  // }, [user])

  // account parameter
  useEffect(() => {
    if (accountParametersQuery?.status === 'success') {
      if (accountParametersQuery?.data) {
        if (accountParametersQuery?.data?.data?.accountParameters && accountParametersQuery?.data?.data?.accountParameters && accountParametersQuery?.data?.data?.accountParameters?.length > 0) {
          setAccountParameters(accountParametersQuery?.data?.data?.accountParameters[0])
        }
      }
    } else if (accountParametersQuery?.status === 'error') {
      showError(accountParametersQuery?.error?.response?.data?.errorInfo?.userMessage || accountParametersQuery?.error?.message)
      Sentry.captureException(accountParametersQuery?.error?.response?.data?.errorInfo?.userMessage || accountParametersQuery?.error)
    }
  }, [accountParametersQuery?.isFetched])

  // account instrument drift
  useEffect(() => {
    setSectorLoading(accountInstrumentsDriftQuery?.isLoading)
    if (accountInstrumentsDriftQuery?.status === 'success') {
      if (accountInstrumentsDriftQuery?.data) {
        if (accountInstrumentsDriftQuery?.data?.data && accountInstrumentsDriftQuery?.data?.data?.accountInstrumentsDriftData) {
          accountInstrumentsDriftQuery?.data?.data?.accountInstrumentsDriftData?.forEach((item) => {
            if (item.localSymbol === '__USD') {
              item.localSymbol = 'CASH'
            }
          })

          setAccountInstrumentDrift(accountInstrumentsDriftQuery?.data?.data)

          setUnderWeight(
            accountInstrumentsDriftQuery?.data?.data?.accountInstrumentsDriftData?.filter(instr => instr.weightDrift < 0)?.sort(function (a, b) {
              return a.weightDrift - b.weightDrift
            })
          )
          const overWeightData =
            accountInstrumentsDriftQuery?.data?.data?.accountInstrumentsDriftData?.filter(instr => instr.weightDrift >= 0)?.slice()
          setOverWeight(
            overWeightData.sort(function (a, b) {
              return b.weightDrift - a.weightDrift
            })
          )
        }
      }
    } else if (accountInstrumentsDriftQuery?.status === 'error') {
      showError(accountInstrumentsDriftQuery?.error?.response?.data?.errorInfo?.userMessage || accountInstrumentsDriftQuery?.error?.message)
      Sentry.captureException(accountInstrumentsDriftQuery?.error?.response?.data?.errorInfo?.userMessage || accountInstrumentsDriftQuery?.error)
    }
  }, [accountInstrumentsDriftQuery?.isFetched])

  // strategy parameter
  useEffect(() => {
    if (strategyParametersQuery?.status === 'success') {
      if (strategyParametersQuery?.data) {
        if (strategyParametersQuery?.data?.data && strategyParametersQuery?.data?.data?.strategyParameters && strategyParametersQuery?.data?.data?.strategyParameters?.length > 0) {
          setStrategyParameters(strategyParametersQuery?.data?.data?.strategyParameters[0])
        }
      }
    } else if (strategyParametersQuery?.status === 'error') {
      showError(strategyParametersQuery?.error?.response?.data?.errorInfo?.userMessage || strategyParametersQuery?.error?.message)
      Sentry.captureException(strategyParametersQuery?.error?.response?.data?.errorInfo?.userMessage || strategyParametersQuery?.error)
    }
  }, [strategyParametersQuery?.isFetched])

  // strategy instrument drift
  useEffect(() => {
    setTopPositionLoader(strategyInstrumentsDriftQuery?.isLoading)
    if (strategyInstrumentsDriftQuery?.status === 'success') {
      if (strategyInstrumentsDriftQuery?.data) {
        if (strategyInstrumentsDriftQuery?.data?.data) {
          setStrategyInstruments(strategyInstrumentsDriftQuery?.data?.data?.strategyInstruments)
        }
      }
    } else if (strategyInstrumentsDriftQuery?.status === 'error') {
      showError(strategyInstrumentsDriftQuery?.error?.response?.data?.errorInfo?.userMessage || strategyInstrumentsDriftQuery?.error?.message)
      Sentry.captureException(strategyInstrumentsDriftQuery?.error?.response?.data?.errorInfo?.userMessage || strategyInstrumentsDriftQuery?.error)
    }
  }, [strategyInstrumentsDriftQuery?.isFetched])

  // account requests
  useEffect(() => {
    setRequestLoader(accountRequestsQuery?.isLoading)
    if (accountRequestsQuery?.status === 'success') {
      if (accountRequestsQuery?.data) {
        if (accountRequestsQuery?.data?.data?.length === 0) {
          setNodata(accountRequestsQuery?.data?.message)
        } else if (accountRequestsQuery?.data?.data) {
          setNodata() // Clear out the 'no data' message in case request is created after rendering
          setAccountRequests(accountRequestsQuery?.data?.data?.reqData)
        }
      }
    } else if (accountRequestsQuery?.status === 'error') {
      showError(accountRequestsQuery?.error?.response?.data?.errorInfo?.userMessage || accountRequestsQuery?.error?.message)
      Sentry.captureException(accountRequestsQuery?.error?.response?.data?.errorInfo?.userMessage || accountRequestsQuery?.error)
    }
  }, [accountRequestsQuery?.isFetched])

  // asset allocation
  useEffect(() => {
    setAssetLoading(accountAssetAllocationQuery?.isLoading)
    if (accountAssetAllocationQuery?.status === 'success') {
      if (accountAssetAllocationQuery?.data) {
        if (accountAssetAllocationQuery?.data?.data) {
          setAssetAllocationData(accountAssetAllocationQuery?.data?.data ? accountAssetAllocationQuery?.data?.data?.map((row) => ({ ...row, id: randomId() })) : [])
        }
      }
    } else if (accountAssetAllocationQuery?.status === 'error') {
      showError(accountAssetAllocationQuery?.error?.response?.data?.errorInfo?.userMessage || accountAssetAllocationQuery?.error?.message)
      Sentry.captureException(accountAssetAllocationQuery?.error?.response?.data?.errorInfo?.userMessage || accountAssetAllocationQuery?.error)
    }
  }, [accountAssetAllocationQuery?.isFetched])

  // account risk
  useEffect(() => {
    if (accountRiskAttributesQuery?.status === 'success') {
      if (accountRiskAttributesQuery?.data) {
        if (accountRiskAttributesQuery?.data?.data) {
          setRiskAttributes(accountRiskAttributesQuery?.data?.data)
          setValue(
            Number(accountRiskAttributesQuery?.data?.data?.accountRiskAttributes[0]?.statsValue).toFixed(2)
          )
        }
      }
    } else if (accountRiskAttributesQuery?.status === 'error') {
      showError(accountRiskAttributesQuery?.error?.response?.data?.errorInfo?.userMessage || accountRiskAttributesQuery?.error?.message)
      Sentry.captureException(accountRiskAttributesQuery?.error?.response?.data?.errorInfo?.userMessage || accountRiskAttributesQuery?.error)
    }
  }, [accountRiskAttributesQuery?.isFetched])

  useEffect(() => {
    if (marketValueQuery?.status === 'success') {
      if (marketValueQuery?.data) {
        if (marketValueQuery?.data?.data) {
          setMarketValueData(marketValueQuery?.data?.data)
          setIsMVChartLoading(marketValueQuery?.isLoading)
        }
      }
    } else if (marketValueQuery?.status === 'error') {
      showError(marketValueQuery?.error?.response?.data?.errorInfo?.userMessage || marketValueQuery?.error?.message)
      Sentry.captureException(marketValueQuery?.error?.response?.data?.errorInfo?.userMessage || marketValueQuery?.error)
      setIsMVChartLoading(marketValueQuery?.isLoading)
    }
  }, [marketValueQuery?.isFetched])

  useEffect(() => {
    setAssetLoading(accountActivitySummaryQuery?.isLoading)
    if (accountActivitySummaryQuery?.status === 'success') {
      if (accountActivitySummaryQuery?.data) {
        if (accountActivitySummaryQuery?.data?.data) {
          setAccountActivitySummaryData(accountActivitySummaryQuery?.data?.data)
        }
      }
    } else if (accountActivitySummaryQuery?.status === 'error') {
      showError(accountActivitySummaryQuery?.error?.response?.data?.errorInfo?.userMessage || accountActivitySummaryQuery?.error?.message)
      Sentry.captureException(accountActivitySummaryQuery?.error?.response?.data?.errorInfo?.userMessage || accountActivitySummaryQuery?.error)
    }
  }, [accountActivitySummaryQuery?.isFetched])

  useEffect(() => {
    if (accountParameters) {
      setCardData(prevData => ({
        ...prevData,
        faName: accountParameters?.faName ?? 0,
        taxSensitivity: accountParameters?.taxSensitivity ?? 0,
        totalMarketValue: accountParameters?.totalMarketValue ?? 0,
        unrealizedGainLoss:
          accountParameters?.unrealizedGainLoss ?? 0,
        unrealizedGainLossWeight:
          accountParameters?.unrealizedGainLossWeight ?? 0,
        ytdRealizedGainLoss:
          accountParameters?.ytdRealizedGainLoss ?? 0,
        assetAllocationDrift:
          accountParameters?.assetAllocationDrift ?? 0,
        incomeDivYield: accountParameters?.incomeDivYield ?? 0,
        cashLevel: accountParameters?.cashLevel ?? 0,
        cashDrift: accountParameters?.cashDrift ?? 0,
        cashAmount: accountParameters?.cashAmount ?? 0
      }))
    }
  }, [accountParameters])

  useEffect(() => {
    if (strategyParameters) {
      setCardData(prevData => ({
        ...prevData,
        strategyDividendYield:
          strategyParameters?.strategyDividendYield ?? 0,
        benchmarkLongName:
          strategyParameters?.benchmarkLongName ?? 0,
        cashFloor: strategyParameters?.cashLevel ?? 0
      }))
    }
  }, [strategyParameters])

  useEffect(() => {
    const convertDataForApexCharts = (marketValueData) => {
      if (!marketValueData || !marketValueData.marketData) {
        return []
      }
      // filter data-points which has valid market value and store it for chart data point
      const graphData = marketValueData?.marketData?.filter(marketValueObj => marketValueObj?.metric?.NET_MV_PLUS_CASH !== undefined && marketValueObj?.metric?.NET_MV_PLUS_CASH !== null)?.map((entry) => {
        const dataDate = new Date(entry.dataDate).getTime()
        const netMvPlusCash = entry.metric.NET_MV_PLUS_CASH
        return { x: dataDate, y: netMvPlusCash, annotation: entry.metric }
      })
      return graphData
    }
    const apexChartData = convertDataForApexCharts(marketValueData)
    setMarketChartData(apexChartData)
  }, [marketValueData])

  useEffect(() => {
    if (benchmarkQuery?.status === 'success') {
      if (benchmarkQuery?.data) {
        setBenchmarkReturnsData(benchmarkDataProcess(benchmarkQuery?.data))
      }
    } else if (benchmarkQuery?.status === 'error') {
      showError(benchmarkQuery?.error?.response?.data?.errorInfo?.userMessage || benchmarkQuery?.error?.message)
      Sentry.captureException(benchmarkQuery?.error?.response?.data?.errorInfo?.userMessage || benchmarkQuery?.error)
    }
  }, [benchmarkQuery?.isFetched])

  const openExpandedView = (chartName, data) => {
    let chartData = {
      data,
      accountId: params?.accountId,
      strategyId: (filterSecureData && filterSecureData[0])?.strategyId,
      selectedBenchmark: [(filterSecureData && filterSecureData[0])?.strategyId],
      accountType: (filterSecureData && filterSecureData[0])?.accountType,
      startDate: benchmarkReturnsData?.startDate,
      endDate: benchmarkReturnsData?.endDate,
      strategyList: benchmarkReturnsData?.strategyList,
      calculatedMvPlusCashData: marketValueData?.calculatedMvPlusCashData,
      selectedAccountIds: [{ accountId: params?.accountId }]
    }
    if (chartName === 'MV') {
      // set present annotation data as selected annotation
      const annotationsArray = marketValueData?.marketData ? marketValueData?.marketData?.flatMap(entry => ({ annotations: entry.metric })) : []
      const uniqueAnnotations = [...new Set(annotationsArray.flatMap(entry => Object.keys(entry.annotations)))]
      chartData = {
        ...chartData,
        annotationList: uniqueAnnotations,
        selectedAnnotations: uniqueAnnotations
      }
    }
    navigate(`/account-review/expanded-charts/${params?.accountId}`, {
      state: {
        chartName,
        chartData
      }
    })
  }
  // currently the below commented getAccountInstrumentsDetails api is not being use for future purpose

  // const getAccountInstrumentsDetails = () => {
  //   setSectorLoading(true)
  //   getAccountAttributes(`${params.accountId}`, {
  //     'account-instruments': true
  //   })
  //     .then((response) => {
  //       if (response.data) {
  //         setAccountInstruments(response.data)
  //       }
  //       setSectorLoading(false)
  //     })
  //     .catch((error) => {
  //       showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //       setSectorLoading(false)
  //       Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //     })
  // }

  // const getAccountInstrumentDriftDetails = () => {
  //   setSectorLoading(true)
  //   getAccountAttributes(`${params.accountId}`, {
  //     'account-instruments-drift': true
  //   })
  //     .then((response) => {
  //       if (response.data) {
  //         response.data.accountInstrumentsDriftData.forEach((item) => {
  //           if (item.localSymbol === '__USD') {
  //             item.localSymbol = 'CASH'
  //           }
  //         })

  //         setAccountInstrumentDrift(response.data)

  //         setUnderWeight(
  //           response.data.accountInstrumentsDriftData.sort(function (a, b) {
  //             return a.weight - b.weight
  //           })
  //         )
  //         const overWeightData =
  //           response.data.accountInstrumentsDriftData.slice()
  //         setOverWeight(
  //           overWeightData.sort(function (a, b) {
  //             return b.weight - a.weight
  //           })
  //         )
  //       }
  //       setSectorLoading(false)
  //     })
  //     .catch((error) => {
  //       showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //       setSectorLoading(false)
  //       Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //     })
  // }

  // const getAccountParametersDetails = () => {
  //   setSectorLoading(true)
  //   getAccountAttributes(`${params.accountId}`, {
  //     'account-parameters': true
  //   })
  //     .then((response) => {
  //       if (response?.data?.accountParameters && response?.data?.accountParameters && response?.data?.accountParameters?.length > 0) {
  //         setAccountParameters(response?.data?.accountParameters[0])
  //       }
  //       setSectorLoading(false)
  //     })
  //     .catch((error) => {
  //       showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //       setSectorLoading(false)
  //       Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //     })
  // }

  // const getUnderOverWeight = () => {
  //   setSectorLoading(true)
  //   getAccountAttributes(`${params.accountId}`, {
  //     'account-instruments-drift': true
  //   })
  //     .then((response) => {
  //       if (response.data.accountInstrumentsDriftData) {
  //         setUnderWeight(
  //           response.data.accountInstrumentsDriftData.sort(function (a, b) {
  //             return a.weight - b.weight
  //           })
  //         )
  //         const overWeightData =
  //           response.data.accountInstrumentsDriftData.slice()
  //         setOverWeight(
  //           overWeightData.sort(function (a, b) {
  //             return b.weight - a.weight
  //           })
  //         )
  //       }
  //       setSectorLoading(false)
  //     })
  //     .catch((error) => {
  //       showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //       setSectorLoading(false)
  //       Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //     })
  // }

  // const getStrategyParametersDetails = () => {
  //   setTopPostionLoader(true)
  //   getStrategyAttributes(`${strategyId}`, {
  //     'strategy-parameters': true
  //   })
  //     .then((response) => {
  //       if (response?.data && response?.data?.strategyParameters && response?.data?.strategyParameters?.length > 0) {
  //         setStrategyParameters(response?.data?.strategyParameters[0])
  //       }
  //       setTopPostionLoader(false)
  //     })
  //     .catch((error) => {
  //       showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //       setTopPostionLoader(false)
  //       Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //     })
  // }

  // const getStrategyInstrumentsDetails = () => {
  //   setTopPostionLoader(true)
  //   getStrategyAttributes(`${strategyId}`, {
  //     'strategy-instruments': true
  //   })
  //     .then((response) => {
  //       if (response?.data) {
  //         setStrategyInstruments(response?.data?.strategyInstruments)
  //       }
  //       setTopPostionLoader(false)
  //     })
  //     .catch((error) => {
  //       showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //       setTopPostionLoader(false)
  //       Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //     })
  // }

  //         setUnderWeight(
  //           response.data.accountInstrumentsDriftData.sort(function (a, b) {
  //             return a.weight - b.weight
  //           })
  //         )
  //         const overWeightData =
  //           response.data.accountInstrumentsDriftData.slice()
  //         setOverWeight(
  //           overWeightData.sort(function (a, b) {
  //             return b.weight - a.weight
  //           })
  //         )
  //       }
  //       setSectorLoading(false)
  //     })
  //     .catch((error) => {
  //       showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //       setSectorLoading(false)
  //       Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //     })
  // }
  // const getUnderOverWeight = () => {
  //   setSectorLoading(true)
  //   getAccountAttributes(`${params.accountId}`, {
  //     'account-instruments-drift': true
  //   })
  //     .then((response) => {
  //       if (response.data.accountInstrumentsDriftData) {
  //         setUnderWeight(
  //           response.data.accountInstrumentsDriftData.sort(function (a, b) {
  //             return a.weight - b.weight
  //           })
  //         )
  //         const overWeightData =
  //           response.data.accountInstrumentsDriftData.slice()
  //         setOverWeight(
  //           overWeightData.sort(function (a, b) {
  //             return b.weight - a.weight
  //           })
  //         )
  //       }
  //       setSectorLoading(false)
  //     })
  //     .catch((error) => {
  //       showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //       setSectorLoading(false)
  //       Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //     })
  // }
  // const getStrategyDetails = () => {
  //   setTopPostionLoader(true)
  //   getStrategyAttributes(`${strategyId}`, {
  //     'strategy-parameters': true,
  //     'strategy-instruments': true
  //   })
  //     .then((response) => {
  //       if (response.data) {
  //         setStrategyAttributes(response.data)
  //       }
  //       setTopPostionLoader(false)
  //     })
  //     .catch((error) => {
  //       showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //       setTopPostionLoader(false)
  //       Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //     })
  // }
  // const getAccountRequests = () => {
  //   setRequestLoader(true)
  //   API.get(
  //     'baseRequestURL',
  //     `request/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/requests/${params.accountId}`,
  //     { queryStringParameters: {} }
  //   )
  //     .then((response) => {
  //       if (response.data.length === 0) {
  //         setNodata(response.message)
  //       } else if (response.data) {
  //         setNodata() // Clear out the 'no data' message in case request is created after rendering
  //         setAccountRequests(response.data.reqData)
  //       }
  //       setRequestLoader(false)
  //     })
  //     .catch((error) => {
  //       showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //       setRequestLoader(false)
  //       Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //     })
  // }
  // const getAssetAllocationData = () => {
  //   setAssetLoading(true)
  //   API.get(
  //     'baseURL',
  //     `account-master/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${params.accountId}/asset-allocations`,
  //     { queryStringParameters: {} }
  //   )
  //     .then((response) => {
  //       if (response.data) {
  //         setAssetAllocationData(response.data ? response.data?.map((row) => ({ ...row, id: randomId() })) : [])
  //       }
  //       setAssetLoading(false)
  //     })
  //     .catch((error) => {
  //       showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //       setAssetLoading(false)
  //       Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //     })
  // }
  // accountRiskAttributes Data
  // const getRiskAttributes = async () => {
  //   API.get(
  //     'baseURL',
  //     `account-master/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/${params.accountId}/risks/attributes`,
  //     { queryStringParameters: {} }
  //   )
  //     .then((response) => {
  //       if (response.data) {
  //         setRiskAttributes(response.data)
  //         setValue(
  //           Number(response.data.accountRiskAttributes[0].statsValue).toFixed(2)
  //         )
  //       }
  //     })
  //     .catch((error) => {
  //       showError(error.response?.data?.errorInfo?.userMessage || error.message)
  //       Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
  //     })
  // }
  const screen = 'not main'

  return (
    <Box className='account-dashboard'>
      <AccountTitle title='Dashboard'><InternalNavigation /></AccountTitle>
      <ErrorBoundary
        fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Account Attributes' />)} onReset={() => {
          accountParametersQuery?.refetch()
          strategyParametersQuery?.refetch()
        }}
      >
        <AccountAttributes data={cardData} />
      </ErrorBoundary>
      <Grid container my={1} rowSpacing={2} columnSpacing={3}>
        <Grid item xs={12} lg={6}>
          <ErrorBoundary
            fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Allocation Chart' />)} onReset={() => {
              accountAssetAllocationQuery?.refetch()
            }}
          >
            <PortfolioModel data={assetAllocationData} />
          </ErrorBoundary>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ErrorBoundary
            fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Major Asset Class Allocation' />)} onReset={() => {
              accountAssetAllocationQuery?.refetch()
            }}
          >
            {assetLoading ? <MajorAssetAllocationLoader title='Major Asset Class Allocation' /> : <AssetAllocation data={assetAllocationData} />}
          </ErrorBoundary>
        </Grid>
      </Grid>
      <Grid container my={1} rowSpacing={2} columnSpacing={3}>
        <Grid item xs={12} lg={7}>
          <ErrorBoundary
            fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Portfolio Risk' />)} onReset={() => {
              accountRiskAttributesQuery?.refetch()
            }}
          >
            <RealativeVolatility riskAttributes={riskAttributes.accountRiskAttributes} />
          </ErrorBoundary>
        </Grid>
        <Grid item xs={12} lg={5}>
          <ErrorBoundary
            fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Requests' />)} onReset={() => {
              accountRequestsQuery?.refetch()
            }}
          >
            {requestLoader ? <DashboardRequestLoader title='Request' /> : <AccountRequests data={accountRequests || []} />}
          </ErrorBoundary>
        </Grid>
      </Grid>
      <Grid container my={1} rowSpacing={2} columnSpacing={3}>
        <Grid item xs={12} lg={6}>
          <ErrorBoundary
            fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Allocation Chart' />)} onReset={() => {
              accountAssetAllocationQuery?.refetch()
            }}
          >
            <Card className='card-layout' sx={{ height: '100%' }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography component='p' className='text-title header-text'>
                    Market Value
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: '3px' }}>
                    {isChartZoomed && (
                      <Tooltip title='Reset Chart'>
                        <IconButton id='basic-button' aria-haspopup='true' onClick={resetChart} sx={{ marginRight: '-8px' }}>
                          <IoReload size='18px' color='#74788D' />
                        </IconButton>
                      </Tooltip>
                    )}
                    {(!isMVChartLoading && checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: moduleConfig.EXPANDED_CHARTS })) && (
                      (filterSecureData && filterSecureData[0]?.accountType === 'TRADING') && (
                        <Tooltip title='Expand'>
                          <IconButton id='expand-button' onClick={() => openExpandedView('MV', marketChartData)}>
                            <IoIosExpand size='18px' color='#74788D' />
                          </IconButton>
                        </Tooltip>
                      )
                    )}
                  </Box>
                </Box>
                {isMVChartLoading
                  ? <Skeleton variant='rectangle' animation='wave' width='100%' height={330} />
                  : <MarketValueAreaChart data={marketChartData || []} resetKey={resetKey} onZoomChange={handleZoomChange} height={315} showAnnotations={false} />}
              </CardContent>
            </Card>
          </ErrorBoundary>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ErrorBoundary
            fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Activity Summary' />)} onReset={() => {
              accountAssetAllocationQuery?.refetch()
            }}
          >
            {assetLoading ? <ActivitySummaryLoader title='Activity Summary' /> : <AccountActivityTable data={accountActivitySummaryData} />}
          </ErrorBoundary>
        </Grid>
      </Grid>
      <Grid container my={1} rowSpacing={2} columnSpacing={3}>
        <Grid item xs={12} lg={4} md={6}>
          <ErrorBoundary
            fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Top Model Positions' />)} onReset={() => {
              strategyInstrumentsDriftQuery?.refetch()
            }}
          >
            {topPositionLoader ? <TopSecurityLoader title='Top Model Positions' /> : <TopSecurity data={strategyInstruments || []} />}
          </ErrorBoundary>
        </Grid>
        <Grid item xs={12} lg={4} md={6}>
          <ErrorBoundary
            fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Top OverWeighted Securities' />)} onReset={() => {
              accountInstrumentsDriftQuery?.refetch()
            }}
          >
            {sectorLoading ? <SectorAndSecurityLoader title='Top Over Weighted Securities' /> : <OverWeightedSecurity data={overWeight || []} />}
          </ErrorBoundary>
        </Grid>
        <Grid item xs={12} lg={4} md={6}>
          <ErrorBoundary
            fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Top UnderWeighted Securities' />)} onReset={() => {
              accountinstrumentDrift.refetch()
            }}
          >
            {sectorLoading ? <SectorAndSecurityLoader title='Top Under Weighted Securities' /> : <UnderWeightedSecurities underWeight={underWeight || []} />}
          </ErrorBoundary>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AccountDashboard
