export const isNumberOnly = (event) => {
  const regex = /^(\d*\.?\d*)$/
  if ((event.keyCode === 8 || (event.keyCode >= 35 && event.keyCode <= 40) || [46, 8, 9, 27, 13].includes(event.keyCode) || (event.keyCode === 65 && (event.ctrlKey === true || event.metaKey === true)))) {
    return true
  }
  const inputValue = event.target.value + event.key
  if (!regex.test(inputValue)) {
    event.preventDefault()
    return false
  }
  return true
}

export const isWholeNumber = (event) => {
  const regex = /^\d+$/
  if (event.ctrlKey || event.metaKey) {
    if (event.key === 'v') {
      return true
    }
    return false
  }
  if ((event.keyCode === 8 || (event.keyCode >= 35 && event.keyCode <= 40) || [46, 8, 9, 27, 13].includes(event.keyCode) || (event.keyCode === 65 && (event.ctrlKey === true || event.metaKey === true)))) {
    return true
  }
  const inputValue = event.target.value + event.key
  if (!regex.test(inputValue)) {
    event.preventDefault()
    return false
  }
  return true
}

// export const isNumberWithSign = (event) => {
//   const regex = /^[-+]?(\d*\.?\d*)$/
//   if ((event.keyCode === 8 || (event.keyCode >= 35 && event.keyCode <= 40) || [46, 8, 9, 27, 13].includes(event.keyCode) || (event.keyCode === 65 && (event.ctrlKey === true || event.metaKey === true)))) {
//     return true
//   }
//   const inputValue = event.target.value + event.key
//   if (!regex.test(inputValue)) {
//     event.preventDefault()
//     return false
//   }
//   return true
// }

export const isNumberWithSign = (event) => {
  const regex = /^-?\d*\.?\d*$/
  if (
    (event.keyCode === 8 || (event.keyCode >= 35 && event.keyCode <= 40) || [46, 8, 9, 27, 13].includes(event.keyCode) || (event.keyCode === 65 && (event.ctrlKey === true || event.metaKey === true)))
  ) {
    return true
  }
  const inputValue = event.target.value.slice(0, event.target.selectionStart) + event.key + event.target.value.slice(event.target.selectionEnd)

  if ((event.key === '-' && inputValue.indexOf('-') !== inputValue.lastIndexOf('-')) || (inputValue === '-0')) {
    event.preventDefault()
    return false
  }

  if (!regex.test(inputValue)) {
    event.preventDefault()
    return false
  }

  return true
}
