import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import SaveIcon from '@mui/icons-material/Save'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { LoadingButton } from '@mui/lab'
import { Box, FormControl, FormHelperText, Stack, Typography, styled } from '@mui/material'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { Formik } from 'formik'
import { object, ref, string } from 'yup'
import Alert from '../../../components/Alert'
import CustomOutlinedInput from '../../../components/CustomOutlinedInput'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { SignInMfa } from './SignInMfa'

const CustomFormLabel = styled(Typography)(({ theme }) => ({
  color: '#002A59',
  fontFamily: 'Open Sans !important',
  fontWeight: '600 !important'
}))

export const FormPasswordReset = (props) => {
  const [passChangeSuccess, setPassChangeSuccess] = useState(false)
  const { showError } = useErrorToast()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const navigate = useNavigate()
  const [user, setUser] = useState()

  const _handleModalClose = () => {
    setPassChangeSuccess(false)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show)
  }
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show)
  }
  const renderModal = () => {
    const onClick = () => {
      setPassChangeSuccess(false)
      props.updateUser(user)
      navigate('/login')
    }

    return (
      <Alert
        isOpen={passChangeSuccess}
        handleClose={_handleModalClose}
        handleSubmit={onClick}
        text='Password changed successfully'
        submitButtonText='Continue'
      />
    )
  }

  const _handleSubmit = async ({ newPass, confirmPass, setSubmitting, resetForm }) => {
    setIsSubmitted(true)
    setSubmitting(false)
    const signInDetails = await SignInMfa({ user: props?.user, action: props?.action, value: newPass })
    if (signInDetails?.type === 'SUCCESS') {
      setPassChangeSuccess(true)
      resetForm()
      setIsSubmitted(false)
      Auth.currentAuthenticatedUser()
        .then(user => setUser(user))
        .catch(err => console.log(err))
    } else {
      setIsSubmitted(false)
      showError(signInDetails?.value?.message)
    }
  }

  return (
    <Formik
      initialValues={{ newPass: '', confirmPass: '' }}
      validate={(values) => {
        const errors = {}
        if (!values.newPass) {
          errors.newPass = 'Required'
        } else if (values.newPass.length < 8 || !(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).*$/).test(values.newPass || '')) {
          errors.newPass = 'Password must have minimum 8 characters and must contain at least one uppercase, lowercase letter, one digit and one special character'
        }
        if (!values.confirmPass) {
          errors.confirmPass = 'Required'
        } else if (values.confirmPass !== values.newPass) {
          errors.confirmPass = 'Passwords do not match'
        }
        return errors
      }}
      validationSchema={object().shape({
        newPass: string()
          .required('New password is required')
          .min(8, 'Password must have minimum 8 characters and must contain at least one uppercase, lowercase letter, one digit and one special character')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).*$/,
            'Password must have minimum 8 characters and must contain at least one uppercase, lowercase letter, one digit and one special character'
          ),
        confirmPass: string()
          .oneOf([ref('newPass')], 'Passwords do not match')
          .required('Password is required')
      })}
      onSubmit={({ newPass, confirmPass }, { setSubmitting, resetForm }) =>
        _handleSubmit({ newPass, confirmPass, setSubmitting, resetForm })}
    >
      {(props) => {
        const { values, touched, errors, handleChange, handleBlur, handleSubmit, isValid, isSubmitting } = props

        return (
          <Stack sx={{ mt: 4 }}>
            <form className='form' onSubmit={handleSubmit}>
              <FormControl fullWidth margin='dense' error={Boolean(touched.newPass && errors.newPass)}>
                <CustomFormLabel gutterBottom>
                  New password
                </CustomFormLabel>
                <CustomOutlinedInput
                  id='password-new'
                  name='newPass'
                  type={showPassword ? 'text' : 'password'}
                  value={values.newPass}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitted}
                  error={Boolean(touched.newPass && errors.newPass)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                        sx={{
                          color: '#817D86',
                          fontSize: '22px'
                        }}
                      >
                        {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                  startAdornment={
                    <InputAdornment position='start'>
                      <LockOutlinedIcon
                        aria-label='password'
                        edge='end'
                        fontSize='small'
                        sx={{
                          color: '#3369A6',
                          fontSize: '22px'
                        }}
                      />
                    </InputAdornment>
                  }
                />
                <FormHelperText error={Boolean(touched.newPass && errors.newPass)}>
                  {touched.newPass && errors.newPass ? errors.newPass : ''}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth margin='dense' error={Boolean(touched.confirmPass && errors.confirmPass)}>
                <CustomFormLabel gutterBottom>
                  Confirm password
                </CustomFormLabel>
                <CustomOutlinedInput
                  id='password-confirm'
                  name='confirmPass'
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={values.confirmPass}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitted}
                  error={Boolean(touched.confirmPass && errors.confirmPass)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                        sx={{
                          color: '#817D86',
                          fontSize: '22px'
                        }}
                      >
                        {showConfirmPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                  startAdornment={
                    <InputAdornment position='start'>
                      <LockOutlinedIcon
                        aria-label='password'
                        edge='end'
                        sx={{
                          color: '#3369A6',
                          fontSize: '22px'
                        }}
                      />
                    </InputAdornment>
                  }
                />
                <FormHelperText error={Boolean(touched.confirmPass && errors.confirmPass)}>
                  {touched.confirmPass && errors.confirmPass ? errors.confirmPass : ''}
                </FormHelperText>
              </FormControl>
              <Box sx={{ ml: 'auto', mt: 4 }}>
                {isSubmitted
                  ? (
                    <LoadingButton
                      loading
                      loadingPosition='start'
                      startIcon={<SaveIcon />}
                      variant='outlined'
                      fullWidth
                      sx={{
                        padding: 2
                      }}
                    >
                      Reset Password
                    </LoadingButton>
                    )
                  : (
                    <Button
                      fullWidth
                      type='submit'
                      variant='contained'
                      disabled={Boolean(!isValid || isSubmitting)}
                      sx={{
                        padding: 2
                      }}
                    >
                      Reset Password
                    </Button>
                    )}
              </Box>
            </form>
            {passChangeSuccess ? renderModal() : ''}
          </Stack>
        )
      }}
    </Formik>
  )
}
