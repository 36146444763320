import { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Navigate, useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import { useAuth } from '../contexts/AuthContext'
import { useErrorToast } from '../hooks/useErrorToast'
import ErrorFallback from '../pages/ErrorFallback'
import PageNotFound from '../pages/PageNotFound'
import Loader from '../pages/Loader'
import { ACCESS_LEVEL } from '../contstants/constants'

const PrivateRoute = ({ roles, children }) => {
  const { user, loading, checkRouteAccess, isAclFetching } = useAuth()
  const screen = 'main'
  const navigate = useNavigate()
  const { showError } = useErrorToast()

  useEffect(() => {
    // interceptor to intercept all API calls and handle user session expiration
    const interceptor = axios.interceptors.response.use((response) => {
      return response
    }, async function (error) {
      if (!error?.response?.status || error?.response?.status === 403) {
        return Promise.reject(Auth.currentAuthenticatedUser()
          .then((res) => { return (error) })
          .catch((err) => {
            navigate('/login')
            setTimeout(() => {
              document.querySelectorAll('.Toastify__toast').forEach((errorToast) => errorToast.classList.add('d-none'))
            }, 300)
            setTimeout(() => {
              showError('Your session has expired. Please re-login', true)
            }, 400)
            return (error)
          }))
      }
      return Promise.reject(error)
    })
    return () => axios.interceptors.response.eject(interceptor)
  }, [])

  if (user && user.preferredMFA && user.preferredMFA === 'NOMFA') {
    return <Navigate to='/login' replace state={window.location.pathname} />
  }

  if (!user && !loading) {
    return <Navigate to='/login' replace state={window.location.pathname} />
  }

  if (user?.userGroup !== 'admin' && user?.userGroup !== undefined) {
    if (checkRouteAccess(window.location.pathname, ACCESS_LEVEL.ROUTE_ACCESS)) {
      return (
        <ErrorBoundary
          fallbackRender={(props) => <ErrorFallback {...props} screen={screen} />}
          onReset={() => window.location.reload()}
        >
          {(loading || isAclFetching) ? <Loader /> : children}
        </ErrorBoundary>
      )
    } else if (isAclFetching) {
      return <Loader />
    } else {
      return <PageNotFound />
    }
  }

  if ((roles && roles.length > 0 && user && user.userGroup && !roles.includes(user.userGroup))) {
    return <PageNotFound />
  }

  return (
    <ErrorBoundary
      fallbackRender={(props) => <ErrorFallback {...props} screen={screen} />}
      onReset={() => window.location.reload()}
    >
      {loading ? <Loader /> : children}
    </ErrorBoundary>
  )
}

export default PrivateRoute
