import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Box, FormControl, FormLabel, Grid, Input, Skeleton, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import sendIcon from '../../../../assets/images/sendIcon.svg'
import { useAuth } from '../../../../contexts/AuthContext'
import { useErrorToast } from '../../../../hooks/useErrorToast'
import AllComments from './AllComments'

export const ViewModal = ({ commentRequests, selectedItem, sponsorCd, loading }) => {
  const [comments, setComments] = useState(commentRequests)
  const [commentValue, setCommentValue] = useState('')
  const { user } = useAuth()
  const { showError } = useErrorToast()

  useEffect(() => {
    setComments(commentRequests)
  }, [commentRequests])

  const handleInputChange = (event) => {
    const sanitizedComment = event.target.value.replace(/(<([^>]+)>)/gi, '')
    setCommentValue(sanitizedComment)
  }

  const handlePostComment = (event) => {
    event.preventDefault()
    if (commentValue?.length > 0) {
      API.post(
        'baseRequestURL',
        `request/v1/${user.userGroup}/ips/comments/${selectedItem?.requestId}`,
        { body: { comment: commentValue } }
      )
        .then((response) => {
          setComments((comments) => [response.data[0], ...comments])
        })
        .catch((error) => {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        })
      setCommentValue('')
    }
  }
  if (loading) {
    return (
      <Box className='skel'>
        <Skeleton height={80} animation='wave' />
        <Skeleton height={60} animation='wave' />
        <Skeleton height={60} animation='wave' />
        <Skeleton height={60} animation='wave' />
        <Skeleton height={60} animation='wave' />
      </Box>
    )
  }

  return (
    <>
      <Box>
        <Grid container>
          <Grid xs={12}>
            <Grid container>
              <Grid container spacing={2} className='modal_area' sx={{ width: '100%' }}>
                <Grid item xs={12}>
                  <Grid container justifyContent='flex-start' alignItems='center' mb={2}>
                    <Grid item>
                      <Typography variant='h6' style={{ width: '100%', fontWeight: 400, fontFamily: 'Lora', color: '#34475A' }}>
                        View Request
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Box className='divider' mx={2} />
                    </Grid>
                    <Grid item>
                      <Typography sx={{ fontSize: '20px' }} fontFamily='Open Sans'>{sponsorCd}</Typography>
                      <Typography fontSize='12px' fontWeight='500' fontFamily='Open Sans'>{selectedItem.userRequestId}  {'->'}  {selectedItem.requestType}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container className='form-req'>
                <Grid item xs={12} sx={{ mb: 1 }}>
                  <FormLabel className='viewReq account-request-label'>Comment</FormLabel>
                  {comments.length ? <AllComments data={comments || []} /> : <p>N/A</p>}
                  {(selectedItem?.requestStatusCd === 'CANCELLED' || selectedItem?.requestStatusCd === 'REJECTED')
                    ? ''
                    : <Box className='reqEditCommnet'>
                      <FormControl fullWidth>
                        <Input
                          className='form-control'
                          placeholder='Write a Comment here'
                          required
                          sx={{ paddingX: '10px' }}
                          value={commentValue}
                          onChange={handleInputChange}
                        />
                      </FormControl>
                      <img
                        className='sendIcon' src={sendIcon} alt='' onClick={handlePostComment}
                      />
                    </Box>}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
