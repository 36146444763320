import React, { useContext, useEffect, useState } from 'react'
import { Divider, Stack, Typography } from '@mui/material'
import { left } from '@popperjs/core'
import * as ChartAnnotation from 'chartjs-plugin-annotation'
import { assetAllocationContext } from '../Index'
import { AllocationChart } from './AllocationChart'
import EnhancedTable from './DataTable'

export const Allocation = (props) => {
  const assetAllocationData = useContext(assetAllocationContext)
  const { selectedScenario } = props

  const marketCapLabels = assetAllocationData?.marketCapLabels || []
  const factorLabels = assetAllocationData?.factorLabels || []
  const sectorData = assetAllocationData.sectorData || []
  const sectorLabels = assetAllocationData.sectorLabels || []
  const labelsdata = assetAllocationData.factorLabels || []
  const [marketCapData, setMarketCapData] = useState([])
  const [factorData, setFactorData] = useState([])
  const [labelBackgroundColor, setLabelBackgroundColor] = useState([])

  const backgroundColor = ['#4B8CCA', '#4B8CCACC', '#4B8CCA80', '#4B8CCA4D']

  const marketCapLegend = []
  const factorLengend = []

  useEffect(() => {
    let count = 0
    const marketData = assetAllocationData?.marketCapData?.map((data) => {
      return data?.label?.toLowerCase() === selectedScenario?.toLowerCase() ? { ...data, backgroundColor: '#34C38F' } : { ...data, backgroundColor: backgroundColor[count++] }
    })

    count = 0
    const factorDataSet = assetAllocationData?.factorData?.map((data) => {
      return data?.label?.toLowerCase() === selectedScenario?.toLowerCase() ? { ...data, backgroundColor: '#34C38F' } : { ...data, backgroundColor: backgroundColor[count++] }
    })
    const labelsBackgroundColor = marketData?.map((data) => { return data.backgroundColor })
    setMarketCapData(marketData)
    setFactorData(factorDataSet)
    setLabelBackgroundColor(labelsBackgroundColor)
  }, [selectedScenario])

  if (assetAllocationData.marketCapData) {
    assetAllocationData.marketCapData?.map(md => {
      marketCapLegend.push(md.label)
    })
  }

  let maxFactorValue = 0
  if (assetAllocationData.factorData) {
    assetAllocationData.factorData?.map(fd => {
      factorLengend.push(fd.label)
      for (let i = 0; i < fd?.data?.length; i++) {
        if (maxFactorValue < Math.abs(fd.data[i])) maxFactorValue = Math.abs(fd.data[i])
      }
    })
  }
  maxFactorValue = Math.ceil(maxFactorValue)

  return (
    <>
      {marketCapData?.length
        ? <AllocationChart
            labels={marketCapLegend}
            backgroundColor={labelBackgroundColor}
            chartHeader='By Marketcap'
            options={{
              plugins: {
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: (context) => {
                      const label = context.dataset.label || ''
                      const value = context.dataset.data[context.dataIndex]
                      return [`${label} :  ${value.toFixed(1)}%`]
                    }
                  }
                },
                legend: {
                  display: false
                },
                datalabels: {
                  display: false
                },
                title: {
                  display: true
                }
              },
              responsive: true,
              interaction: {
                mode: 'index',
                intersect: false
              },
              scales: {
                x: {
                  grid: {
                    display: false
                  }
                },
                y: {
                  display: true,
                  grid: {
                    display: false
                  }
                }
              }
            }}
            data={{
              labels: marketCapLabels,
              datasets: marketCapData
            }}
          />
        : <Stack>
          <Typography sx={{ pt: 2.5, fontWeight: 600, color: '#74788D' }}>
            By Marketcap
          </Typography>
          <Typography className='no-data-text' my={2} textAlign={left}>
            No marketcap data available.
          </Typography>
        </Stack>}

      <Divider sx={{ bgcolor: 'rgba(116, 120, 141, 0.15)', marginTop: '22px', mx: -2 }} />
      {sectorData.length
        ? <EnhancedTable
            rows={{ sectorData, sectorLabels }}
          />
        : <Stack>
          <Typography sx={{ pt: 2.5, fontWeight: 600, color: '#74788D' }}>
            By Sector
          </Typography>
          <Typography className='no-data-text' my={2} textAlign={left}>
            No sector data available.
          </Typography>
        </Stack>}

      <Divider sx={{ bgcolor: 'rgba(116, 120, 141, 0.3)', marginTop: '22px', mx: -2 }} />
      {factorData?.length
        ? <AllocationChart
            chartHeader='By Factors'
            labels={factorLengend}
            backgroundColor={labelBackgroundColor}
            options={{
              plugins: {
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: (context) => {
                      const label = context.dataset.label || ''
                      const value = context.dataset.data[context.dataIndex]
                      return [`${label} :  ${value.toFixed(1)}`]
                    }
                  }
                },
                legend: {
                  display: false
                },
                ChartAnnotation,
                datalabels: {
                  display: false
                },
                title: {
                  display: true
                }
              },
              categoryPercentage: 0.6,
              responsive: true,
              interaction: {
                mode: 'index',
                intersect: false
              },
              scales: {
                x: {
                  display: true,
                  grid: {
                    display: false,
                    borderColor: 'white'
                  }
                },
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  }
                }],
                xAxes: [{
                  gridLines: {
                    zeroLineColor: 'rgba(0, 0, 0, 1)',
                    drawOnChartArea: false,
                    drawTicks: false
                  }
                }],
                y: {
                  display: true,
                  grid: {
                    beginAtZero: true,
                    display: true,
                    color: (context) => {
                      const ZeroLine = context.tick.value
                      const gridColor = ZeroLine === 0 ? '#74788D4D' : 'transparent'
                      return gridColor
                    }
                  }
                }
              },
              annotation: {
                annotations: [
                  {
                    type: 'line',
                    mode: 'vertical',
                    scaleID: 'x-axis-0',
                    borderColor: 'red',
                    label: {
                      content: '',
                      enabled: true,
                      position: 'top'
                    }
                  }
                ]
              }
            }}
            data={{
              labels: factorLabels,
              datasets: factorData
            }}
          />
        : <Stack>
          <Typography sx={{ pt: 2.5, fontWeight: 600, color: '#74788D' }}>
            By Factors
          </Typography>
          <Typography className='no-data-text' my={2} textAlign={left}>
            No factor allocation available.
          </Typography>
          </Stack>}
    </>
  )
}
