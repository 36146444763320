import React from 'react'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { formatNumbersWithSymbol } from '../../Report/components/dataProcess/DataProcess'

const AccountActivityTable = ({ data }) => {
  const rows = [
    {
      id: 'beginningMarketValue',
      label: 'Beginning Market Value',
      value: !isNaN(data?.beginningMarketValue?.total) ? formatNumbersWithSymbol(data?.beginningMarketValue?.total, 0, '$') : '-'
    },
    {
      id: 'contribution',
      label: 'Contribution (Deposits)',
      value: !isNaN(data?.contribution?.total) ? formatNumbersWithSymbol(data?.contribution?.total, 0, '$') : '-'
    },
    {
      id: 'distribution',
      label: 'Distribution (Withdrawal)',
      value: !isNaN(data?.distribution?.total) ? formatNumbersWithSymbol(data?.distribution?.total, 0, '$') : '-'
    },
    ...(data?.internalDeposit || data?.internalWithdrawal) // show both internal deposit and withdrawal rows if any one of them is present
      ? [{
          id: 'internalDeposit',
          label: 'Internal Deposit',
          value: !isNaN(data?.internalDeposit) ? formatNumbersWithSymbol(data?.internalDeposit, 0, '$') : '-'
        }]
      : [],
    ...(data?.internalDeposit || data?.internalWithdrawal)
      ? [{
          id: 'internalWithdrawal',
          label: 'Internal Withdrawal',
          value: !isNaN(data?.internalWithdrawal) ? formatNumbersWithSymbol(data?.internalWithdrawal, 0, '$') : '-'
        }]
      : [],
    {
      id: 'allIncome',
      label: 'All Income (Div + Int)',
      value: !isNaN(data?.allIncome?.total) ? formatNumbersWithSymbol(data?.allIncome?.total, 0, '$') : '-'
    },
    {
      id: 'netChangeInMv',
      label: 'Net Change in MV',
      value: !isNaN(data?.netChangeInMv) ? formatNumbersWithSymbol(data?.netChangeInMv, 0, '$') : '-'
    },
    {
      id: 'advisoryFeesPaid',
      label: 'Advisory Fees Paid',
      value: !isNaN(data?.advisoryFeesPaid?.total) ? formatNumbersWithSymbol(data?.advisoryFeesPaid?.total, 0, '$') : '-'
    },
    {
      id: 'endingMarketValue',
      label: 'Ending Market Value',
      value: !isNaN(data?.endingMarketValue) ? formatNumbersWithSymbol(data?.endingMarketValue, 0, '$') : '-'
    }
  ]

  const columns = [
    {
      field: 'label',
      headerName: '',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (params?.row?.label === 'Beginning Market Value') {
          return (
            <Typography sx={{
              fontFamily: 'Open Sans',
              color: '#34475a',
              fontSize: '14px',
              fontWeight: 600
            }}
            >
              Beginning Market Value <span style={{ color: '#74788D', fontWeight: 500, fontSize: '12px' }}>({data?.accStartDate || 'N/A'})</span>
            </Typography>
          )
        } else if (params?.row?.label === 'Ending Market Value') {
          return (
            <Typography sx={{
              fontFamily: 'Open Sans',
              color: '#34475a',
              fontSize: '14px',
              fontWeight: 600
            }}
            >
              Ending Market Value <span style={{ color: '#74788D', fontWeight: 500, fontSize: '12px' }}>({data?.accEndDate || 'N/A'})</span>
            </Typography>
          )
        } else {
          return params.row.label
        }
      }
    },
    { field: 'value', headerName: 'Total', flex: 1, align: 'right', headerAlign: 'right', sortable: false }
  ]
  const getRowStyle = (params) => ({
    paddingLeft: params.id !== 'row-0' && params.id !== `row-${gridRows.length - 1}` ? '0px' : '50px'
  })
  const getRowClassName = (params) => {
    const isLastRow = params.id === `row-${rows.length - 1}`
    return isLastRow ? 'last-row' : (params.id === 'row-0' ? '' : 'exclude-padding')
  }

  const gridRows = rows.map((row, index) => ({ ...row, id: `row-${index}` }))

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Typography component='p' className='text-title header-text'>
              Activity Summary
            </Typography>
          </Grid>
          <Box style={{ width: '100%' }}>
            <DataGridPro
              rows={gridRows}
              columns={columns}
              hideFooter
              disableSelectionOnClick
              density='compact'
              getRowStyle={getRowStyle}
              getRowClassName={getRowClassName}
              getRowId={(row) => row.id}
              isRowSelectable={() => false}
              disableColumnMenu
              disableColumnFilter
              sx={(theme) => ({
                [`.${gridClasses.columnHeaders}`]: {
                  position: 'sticky',
                  top: 0,
                  backgroundColor: theme.palette.background.paper,
                  zIndex: 1,
                  pointerEvents: 'none'
                },
                [`.${gridClasses.footerContainer}`]: {
                  position: 'sticky',
                  bottom: -20,
                  backgroundColor: theme.palette.background.paper,
                  zIndex: 1
                },
                [`.${gridClasses.toolbarContainer}`]: {
                  position: 'relative',
                  top: 0,
                  backgroundColor: theme.palette.background.paper,
                  zIndex: 1
                },
                '.MuiDataGrid-virtualScroller': {
                  overflow: 'hidden !important'
                },
                '.last-row': {
                  borderTop: '1px solid #34475A',
                  borderBottom: '1px solid #34475A'
                },
                '.exclude-padding .MuiDataGrid-cell': {
                  paddingLeft: '30px !important'
                },
                '.MuiDataGrid-row:hover': {
                  backgroundColor: 'transparent !important'
                },
                '.MuiDataGrid-cell': {
                  pointerEvents: 'none'
                }
              })}
            />
          </Box>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default AccountActivityTable
