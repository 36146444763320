import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Button, Menu, MenuItem, Modal, Paper, Slider, Typography } from '@mui/material'
import { Warning } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import { ReactComponent as BarChartSvg } from '../../../../assets/images/pdf-customizer/svg/barChart.svg'
import { ReactComponent as EditSvg } from '../../../../assets/images/pdf-customizer/svg/Edit.svg'
import { useAuth } from '../../../../contexts/AuthContext'
import position from '../../../../contstants/pdf-customizer/position.json'
import taxSaving from '../../../../contstants/pdf-customizer/taxSaving.json'
import transition from '../../../../contstants/pdf-customizer/transition.json'
import aggregateReport from '../../../../contstants/pdf-customizer/aggregateReport.json'
import {
  isLoadingSet,
  pageChange,
  resetThemeCustomization,
  userClickMode
} from '../../../../store/pdf-customizer-reducer/Action/allPageAction'
import {
  changeEditorName,
  reportDataSetPage,
  resetTemplateData,
  selectedTemplates
} from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import {
  chartDataState
} from '../../../../store/pdf-customizer-reducer/Action/chartStateAction'
import { saveAsElements, saveElements } from '../../../../utils/pdf-customizer/_data'
import { toastError, toastSuccess } from '../../../../utils/pdf-customizer/common'
import { useErrorToast } from '../../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../../hooks/useSuccessToast'
import { ZoomLevelContext } from '../../PDFLayout'
import './modal.css'

const ReportType = {
  'Transition Report': 'transition',
  'Tax Saving Report': 'tax-savings',
  'Position Report': 'position',
  'Aggregate Report': 'aggregateReport'
}

const CustomPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0),
  ...theme.typography.body2
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '100%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '750px',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 3
}

const ReportTemplate = ({ svg, name, description, selected, onSelect }) => (
  <div className={`item ${selected ? 'selected' : ''}`} onClick={onSelect}>
    <div className='margin-bottom-2'>{svg}</div>
    <div>{name}</div>
    <div>{description}</div>
  </div>
)
const GetHeaderButton = ({ pdfExportComponent, resetZoomLevel }) => {
  const dispatch = useDispatch()
  const { user } = useAuth()
  const {
    pages,
    previousEditorName,
    previousReportIdentifier,
    editorName: initialEditorName,
    templateName,
    pageHeadingFooter
  } = useSelector((state) => state.elementStates)
  const { pageSize, isLoading } = useSelector((state) => state.allPageStyle)
  const { userSelectMode } = useSelector((state) => state.allPageStyle)
  const templateData = useSelector(state => state.templateData)
  const navigate = useNavigate()
  const location = useLocation()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [show, setShow] = useState(
    location.pathname === '/pdf-customizer/report' ? !templateName.name : false
  )
  const [anchorEl, setAnchorEl] = useState(null)
  const [defaultCheck, setDefaultCheck] = useState(false)
  const [editorName, setEditorName] = useState(initialEditorName || '')
  const [isEditing, setIsEditing] = useState(false)
  const [isPDFAPILoading, setIsPDFAPILoading] = useState(false)
  const [zoomLevel, setZoomLevel] = useState(100)
  const [showNameConfirmationModal, setShowNameConfirmationModal] = useState(false)

  useEffect(() => {
    if (resetZoomLevel) {
      setZoomLevel(resetZoomLevel)
      if (document.getElementById('drop-area')) {
        document.getElementById('drop-area').style.zoom = '100%'
      }
    }
  }, [resetZoomLevel])

  useEffect(() => {
    setShowNameConfirmationModal(false)
    setAnchorEl(null)
  }, [location])

  useEffect(() => {
    setEditorName(initialEditorName)
  }, [initialEditorName])

  const changeZoomLevel = (event, newValue) => {
    setZoomLevel(newValue)
    if (document.getElementById('drop-area')) {
      document.getElementById('drop-area').style.zoom = `${newValue}%`
    }
  }

  const handleClose = () => {
    if (location.pathname === '/pdf-customizer/report') {
      navigate('/pdf-customizer')
      setZoomLevel(100)
    }
    setDefaultCheck(false)
    setShow(false)
  }

  const handleShow = () => {
    dispatch(resetTemplateData())
    dispatch(resetThemeCustomization())
    dispatch(chartDataState(false, 'IS_API_CALL'))
    setShow(true)
  }

  const handleNavigate = () => {
    handleClose()

    dispatch(userClickMode('create'))
    if (defaultCheck) {
      if (templateName.name === 'Transition Report') {
        dispatch(reportDataSetPage(transition))
      } else if (templateName.name === 'Position Report') {
        dispatch(reportDataSetPage(position))
      } else if (templateName.name === 'Tax Saving Report') {
        dispatch(reportDataSetPage(taxSaving))
      } else if (templateName.name === 'Aggregate Report') {
        dispatch(reportDataSetPage(aggregateReport))
      } else {
        dispatch(reportDataSetPage({ pages: [] }))
      }
    }
    navigate('/pdf-customizer/report')
  }

  const exportPDFWithComponent = async () => {
    if (isLoading) return
    // dispatch(removeActiveHandle(false))
    // dispatch(isLoadingSet(true))
    setIsPDFAPILoading(true)

    try {
      await new Promise((resolve) => setTimeout(resolve, 500))

      if (pdfExportComponent.current) {
        if ((pages || [])?.filter(page => page?.elements?.filter(element => (element.name === 'PDF Uploads' && element.value === 'processing') || (element.name === 'Image Holder' && element.value === null))?.length)?.length) {
          toastError('Some processing is still inprogress.')
        } else {
          let html = ''
          const pdfExportComponentCopy = pdfExportComponent?.current.cloneNode(true)
          const pageNumberClass = pdfExportComponentCopy?.querySelectorAll('.page-number-class')
          pageNumberClass.forEach(element => {
            element?.remove()
          })
          const children = pdfExportComponentCopy?.children
          // Loop through each child element and append its outerHTML to the 'html' variable
          for (let i = 0; i < children.length; i++) {
            if (pages[i]?.elements?.length === 1 && pages[i].elements[0]?.name === 'PDF Uploads') {
              html += `<div name="page" class="page relative margin-top-4" style="width: ${pageSize?.width}px; height: ${pageSize?.height}px;"></div>`
            } else {
              html += children[i].outerHTML
            }
          }
          html = html.replaceAll('isActive', '')
          // const response2 = await fetch('http://localhost:3001/headless-chrome', {
          //   method: 'POST',
          //   headers: {
          //     'Content-Type': 'application/json'
          //   },
          //   body: JSON.stringify({ htmlData: html }) // Send the HTML data as JSON
          // })
          const response = await API.post(
            'baseUriReportBuilder',
            `report-builder/v1/${user?.userGroup}/export/html-to-pdf/${templateData?.reportType === 'Aggregate Report' ? templateData?.apiData?.aggregateGroupId : templateData?.apiData?.accountId}`,
            {
              body: {
                reportType: ReportType[templateName?.name] || 'transition',
                landscape: pageSize.orientation === 'l',
                htmlData: html
              }
            }
          )
          // Check if the API call was successful
          if (response?.success) {
            const { success, message, data } = response
            if (success) {
              const responseFromReconflateAPI = await API.post(
                'baseUriReportBuilder',
                `report-builder/v1/${user.userGroup}/pdf/reconflate/${encodeURIComponent(data)}`,
                {
                  body: pages.map((page) => ({
                    ...page,
                    elements:
                      Array.isArray(page.elements) &&
                      page.elements.map((object) => {
                        if (object.name === 'PDF Uploads') {
                          return {
                            ...object,
                            value: ''
                          }
                        }
                        return { ...object }
                      })
                  })),
                  queryStringParameters: { landscape: pageSize.orientation === 'l', addPageNum: pageHeadingFooter?.footerElement?.name === 'Page Footer 3' }

                }
              )
              if (responseFromReconflateAPI && responseFromReconflateAPI?.data) {
                fetch(responseFromReconflateAPI?.data)
                  .then(response => {
                    if (!response.ok) {
                      throw new Error(`Network response was not ok: ${response.statusText}`)
                    }
                    return response.blob()
                  })
                  .then(blob => {
                    // Create a link element
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = `${initialEditorName}.pdf`

                    // Append the link to the document
                    document.body.appendChild(link)

                    // Simulate a click to trigger the download
                    link.click()

                    // Clean up
                    document.body.removeChild(link)
                    dispatch(isLoadingSet(false))
                    setIsPDFAPILoading(false)
                    handleCloseModal()
                  })
                  .catch(error => {
                    setIsPDFAPILoading(false)
                    console.error('Error downloading file:', error)
                  })
              }
            } else {
              setIsPDFAPILoading(false)
              console.error('Error generating PDF:', message)
            }
          } else {
            setIsPDFAPILoading(false)
            console.error('API Error:', response.status, response)
          }
        }
      }
    } catch (error) {
      console.error('Error exporting PDF:', error)
      dispatch(isLoadingSet(false))
      setIsPDFAPILoading(false)
    } finally {
      // dispatch(isLoadingSet(false))
    }
  }

  const saveFile = (save, overwrite) => {
    if (isLoading) return
    dispatch(isLoadingSet(true))
    const body = {}
    const data = { pages, pageHeadingFooter }
    if (data?.pages) {
      // storing lastindex as null before sending template in S3
      data?.pages?.forEach(page => {
        page?.elements?.forEach(element => {
          if (element?.canOverflow) {
            element.lastIndex = null
          }
        })
        // remove overflow elements
        page.elements = page.elements.filter(element => element?.canOverflow ? element?.id === element?.overflowElementId : true)
      })
      // remove pages
      data.pages = data?.pages?.filter(page => page?.elements?.length)
      // update the chart and page index
      data.pages = data?.pages.map((page, pageIndex) => ({
        ...page,
        elements: page?.elements?.map((element, chartIndex) => ({
          ...element,
          chartIndex,
          pageIndex
        }))
      }))
      body.pdfBody = data
      body.type = templateName.name
      body.orientation = pageSize.orientation === 'p' ? 'portrait' : 'landscape'
      body.currentIdentifier = editorName?.replaceAll(/[^a-zA-Z0-9]/g, '_') + templateName?.name?.replaceAll(/[^a-zA-Z0-9]/g, '_')
      body.currentReportName = editorName
      body.saveAs = !save
      body.overwrite = overwrite

      if (save) {
        if (previousEditorName && previousReportIdentifier) {
          body.previousIdentifier = previousReportIdentifier
          body.previousReportName = previousEditorName
        }
      }

      API.post('baseUriReportBuilder', `report-builder/v1/${user?.userGroup}/push-template/pdf-customization`, { body })
        .then((response) => {
          if (response?.success) {
            setShowNameConfirmationModal(false)
            dispatch(chartDataState(false, 'IS_API_CALL'))
            showSuccess('File saved successfully!')
            handleCloseModal()
            navigate('/pdf-customizer')
            setZoomLevel(100)
          }
        })
        .catch((error) => {
          if (error.response?.data?.errorInfo?.code === 'ARIS_ERR_DUPLICATE_KEY') {
            setShowNameConfirmationModal(true)
          } else {
            showError(error.response?.data?.errorInfo?.userMessage || error.message)
            Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
          }
        })
        .finally(() => {
          dispatch(isLoadingSet(false))
        })
    }
  }

  const handleInputChange = (e) => {
    if (e.target.value?.trim()?.length <= 100) { setEditorName(e.target.value) }
  }

  const handleEditorNameKeyDown = (e) => {
    if ('`~!%^*?.:;,/'.includes(e.key)) {
      e.preventDefault()
      return false
    }
    return true
  }

  const handleEditSave = () => {
    if (editorName?.trim() !== '') {
      dispatch(changeEditorName(editorName?.trim()))
    }
    setIsEditing(false)
  }

  const handleCloseEdit = () => {
    setIsEditing(false)
  }

  const handleEditClick = () => {
    setEditorName(initialEditorName)
    setIsEditing(true)
  }

  const handlePageChange = (e) => {
    const newPage = JSON.parse(e.target.value)
    dispatch(pageChange(newPage))
  }

  const svg = <BarChartSvg />

  const ModalData = [
    {
      svg,
      name: 'Transition Report',
      description: 'Overview of a model portfolio'
    },
    {
      svg,
      name: 'Position Report',
      description: 'Overview of a model portfolio'
    },
    {
      svg,
      name: 'Tax Saving Report',
      description: 'Overview of a model portfolio'
    },
    {
      svg,
      name: 'Aggregate Report',
      description: 'Overview of a model portfolio'
    }
  ]

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseModal = () => {
    setAnchorEl(null)
  }

  const disCards = () => {
    dispatch(resetTemplateData())
    dispatch(resetThemeCustomization())
    dispatch(chartDataState(false, 'IS_API_CALL'))
    navigate('/pdf-customizer')
    setZoomLevel(100)
  }

  const handleTemplateSelect = (templateName) => {
    dispatch(selectedTemplates(templateName))
  }

  return (
    <>
      <div className='top-header'>
        <div className='templates-header'>
          {location.pathname === '/pdf-customizer/report'
            ? (
              isEditing
                ? (
                  <div className='flexOrAlign'>
                    <input
                      className='report_edit_input'
                      type='text'
                      style={{ width: (editorName.length + 1) * 8 + 'px' }}
                      value={editorName}
                      onChange={handleInputChange}
                      onKeyDown={handleEditorNameKeyDown}
                    />
                    <div className='margin-x-1'>
                      <AiFillCheckCircle
                        color='#727474'
                        size={20}
                        onClick={handleEditSave}
                      />
                    </div>
                    <div className='margin-x-1'>
                      <AiFillCloseCircle
                        color='#727474'
                        size={20}
                        onClick={handleCloseEdit}
                      />
                    </div>
                  </div>
                )
                : (
                  <div className='flexOrAlign'>
                    {initialEditorName}
                    <div className='margin-x-2'>
                      <EditSvg onClick={handleEditClick} />
                    </div>
                  </div>
                )
            )
            : (
              'Report Templates'
            )}
        </div>

        <div className='showInRow'>
          {location.pathname === '/pdf-customizer/report'
            ? (
              <>
                <Box display='flex' alignItems='center'>
                  <Slider
                    min={50}
                    max={200}
                    sx={{
                      width: '150px',
                      '& .MuiSlider-track': {
                        border: 'none',
                        background: '#002A59'
                      },
                      '& .MuiSlider-valueLabel': {
                        display: 'none'
                      },
                      '& .MuiSlider-thumb': {
                        backgroundColor: '#002A59',
                        '&::before': {
                          boxShadow: '0 4px 8px rgba(0,0,0,0.4)'
                        },
                        '&:hover, &.Mui-focusVisible, &.Mui-active': {
                          boxShadow: 'none'
                        }
                      }
                    }}
                    value={zoomLevel}
                    onChange={changeZoomLevel}
                    size='small'
                    aria-label='Small'
                    valueLabelDisplay='auto'
                    marks={[{ value: 100 }]}
                  />
                  <Typography mx={1} width='50px' fontSize={14}>{zoomLevel}%</Typography>
                </Box>
                <div className='dropdown save-dropdown'>
                  <button
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    className='common-btn flexOrAlign showInCenter'
                  >
                    Save{' '}
                    {open
                      ? (
                        <MdExpandLess color='#ffffff' size={15} />
                      )
                      : (
                        <MdExpandMore color='#727474' size={15} />
                      )}
                  </button>
                  <CustomPaper elevation={0} square>
                    <Menu
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          boxShadow: '1px 1px 7px 2px rgba(0, 0, 0, 0.10)',
                          mt: 0,
                          minWidth: 140,
                          pt: '0px',
                          px: '8px',
                          borderRadius: '5px',
                          '& .MuiList-root': {
                            m: 0,
                            '& .MuiButtonBase-root': {
                              px: 1,
                              py: '5px',
                              borderRadius: '2px',
                              color: '#000',
                              fontSize: '13px',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              lineHeight: 'normal',
                              '&:hover': {
                                bgcolor: '#002a5911'
                              }
                            }
                          }
                        }
                      }}
                      sx={{
                        boxShadow: '1px 1px 7px 2px rgba(0, 0, 0, 0.10)',
                        margin: '9px 0px',
                        padding: '5px 5px'
                      }}
                      id='basic-menu'
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleCloseModal}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button'
                      }}
                    >
                      {['create', 'preview', 'edit'].includes(userSelectMode) && (
                        <MenuItem
                          className='explore-dropdown common-text-data'
                          onClick={() => saveFile(true, false)}
                        >
                          Save
                        </MenuItem>
                      )}
                      {['preview', 'edit'].includes(userSelectMode) && (
                        <MenuItem
                          className='explore-dropdown common-text-data'
                          onClick={() => saveFile(false, false)}
                        >
                          Save As
                        </MenuItem>
                      )}
                      {['preview'].includes(userSelectMode) && (
                        <MenuItem
                          className='explore-dropdown common-text-data'
                          onClick={exportPDFWithComponent}
                        >
                          Export Pdf
                        </MenuItem>
                      )}
                    </Menu>
                  </CustomPaper>
                </div>

                <button
                  type='button'
                  className='common-btn margin-x-2'
                  onClick={disCards}
                >
                  Discard
                </button>
              </>
            )
            : (
              <button
                type='button'
                className='common-btn active margin-x-2'
                onClick={handleShow}
              >
                Create
              </button>
            )}
        </div>

        <Modal
          open={show}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style} className='modal_body_padding'>
            <Typography
              sx={{
                borderBottom: '0.5px solid #727474',
                padding: '8px 0',
                fontSize: '16px'
              }}
              id='modal-modal-title'
              variant='h6'
              component='h2'
            >
              Select Report
            </Typography>

            <div className='flexOrAlign margin-top-3'>
              <div className='margin-right-4'>
                <label className='cl-checkbox flexOrAlign'>
                  <input
                    type='checkbox'
                    checked={defaultCheck}
                    onChange={() => setDefaultCheck(!defaultCheck)}
                    name='Default Template'
                  />
                  <div className='box-main'>
                    <div className='padding-0' />
                  </div>
                  <div className='checkbox-padding'>
                    <span>Default Template</span>
                  </div>
                </label>
              </div>
              <select
                className='form-select form-select-sm font-select-box width-auto padding-right-3'
                aria-label='Default select example'
                onChange={handlePageChange}
                defaultValue={JSON.stringify({
                  width: 457 + 8 + 20,
                  height: 647 - 45 + 20,
                  orientation: 'p'
                })}
              >
                <option
                  value={JSON.stringify({
                    width: 647 - 45 + 20,
                    height: 457 + 8 + 15,
                    orientation: 'l'
                  })}
                >
                  Landscape
                </option>
                <option
                  value={JSON.stringify({
                    width: 457 + 8 + 20,
                    height: 647 - 45 + 20,
                    orientation: 'p'
                  })}
                  selected
                >
                  Portrait
                </option>
              </select>
            </div>
            <div className='grid-card'>
              {Array.isArray(ModalData) &&
                ModalData.map((item, index) => (
                  <ReportTemplate
                    key={index}
                    {...item}
                    selected={templateName.name === item.name}
                    onSelect={() => handleTemplateSelect(item)}
                  />
                ))}
            </div>
            <div className='width-100 text-align-end'>
              <button
                type='button'
                disabled={!templateName}
                className='common-btn active next_btn'
                onClick={handleNavigate}
              >
                Next
              </button>
            </div>
          </Box>
        </Modal>
        {isPDFAPILoading
          ? (
            <div className='black_blur'>

              <div className='pdf-loader'>
                <div className='circle' />
                <div className='circle' />
                <div className='circle' />
                <div className='circle' />
              </div>
            </div>
          )
          : ''}
      </div>
      <Modal
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        open={showNameConfirmationModal}
        onClose={() => setShowNameConfirmationModal(false)}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '400px', minHeight: '150px', textAlign: 'center', justifyContent: 'space-between', backgroundColor: 'white', padding: '20px', outline: 'none', gap: '10px', borderRadius: '10px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'flex-start', width: '100%', textAlign: 'start' }}>
            <Typography variant='h6' style={{ fontWeight: 400, color: '#34475A' }}>File Exists</Typography>
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <Warning sx={{ color: '#34475A' }} fontSize='large' />
              <Box>
                <Typography sx={{ fontSize: '14px', color: 'black', wordBreak: 'break-word' }}>File name <span>{editorName}</span> already exists.</Typography>
                <Typography sx={{ fontSize: '14px', color: 'black' }}>Do you want to replace the old file?</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%', gap: '10px' }}>
            <Button sx={{ width: '100px', backgroundColor: '#3369A6', color: 'white', '&:hover': { backgroundColor: '#3369A6' }, textTransform: 'capitalize' }} onClick={() => saveFile(false, false)} size='small'>Create New</Button>
            <Button sx={{ width: '100px', backgroundColor: '#3369A6', color: 'white', '&:hover': { backgroundColor: '#3369A6' }, textTransform: 'capitalize' }} onClick={() => saveFile(true, true)} size='small'>Replace</Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default GetHeaderButton
