import React, { useState } from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'

export const TradeListTable = (props) => {
  const [pageSize, setPageSize] = useState(10)
  const rightAlignedColumns = ['weight', 'instruments', 'min', 'max', 'relative', 'sectorId', 'sectorExclude', 'industryExclude']

  const handlePageSizeChange = (newPageSize) => {
    // Update the state variable for pageSize
    setPageSize(newPageSize)
  }

  if (props.columns) {
    return (
      <Grid xs={12}>
        <Box className='trade-table-list-header' mb={0}>{props.tableHeader}</Box>
        {props.rows?.length > 0
          ? <Box
              sx={{
                '.MuiDataGrid-columnHeaderTitle': {
                  fontFamily: 'Open Sans',
                  color: '#74788D',
                  fontWeight: 600,
                  fontSize: '14px'
                },
                '.MuiDataGrid-cell': {
                  fontSize: '12px',
                  color: '#34475A',
                  fontWeight: 600
                },
                '.MuiDataGrid-cellContent': {
                  fontFamily: 'Open Sans',
                  color: '#34475A',
                  fontWeight: 600
                },
                '.MuiDataGrid-footerContainer': {
                  border: 'none'
                },
                '.MuiDataGrid-root': {
                  border: 'none'
                },
                '.MuiTablePagination-toolbar': {
                  alignItems: 'baseline'
                }
              }}
            >
            {/* there is a glitch when clicked on the pagination dropdown, the width of the table decreases */}
            <DataGridPro
              {...(props.showStandardView ? { density: 'standard' } : { density: 'compact' })} autoHeight
              rows={props.rows}
              // right aligning the fields 'instruments' and 'weight'
              columns={props.columns?.map((column) =>
                rightAlignedColumns.includes(column.field)
                  ? {
                      ...column,
                      headerAlign: 'right',
                      align: 'right'
                    }
                  : column
              )}
              pagination
              initialState={{
                pagination: { paginationModel: { pageSize } }
              }}
              pageSizeOptions={[10, 25, 50, 75, 100]}
              onPaginationModelChange={(model) => {
                // Handle the pagination model change
                handlePageSizeChange(model.pageSize)
              }}
              hideFooter={!(props.rows.length > pageSize)}
              disableRowSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              className={
                props.tableHeader === 'Factor Tilts' || props.tableHeader === 'Constraint'
                  ? 'TradeList-Table-module constraintTilts'
                  : 'TradeList-Table-module'
              }
            />
            </Box>
          : <Stack direction='row' pt={1.5}>
            <Typography variant='outlined' sx={{ fontSize: 14, color: '#34475A' }}>
              No {props.tableHeader.toLowerCase()} available.
            </Typography>
            </Stack>}
      </Grid>
    )
  } else {
    return (
      <>
        <Box className='trade-table-list-header' mb={0}>{props.tableHeader}</Box>
        <Stack direction='row' pt='0px' pl='20px'>
          <Typography variant='outlined' sx={{ fontSize: 14, color: '#34475A' }}>
            No {props.tableHeader.toLowerCase()} available.
          </Typography>
        </Stack>
      </>
    )
  }
}
