import React, { useRef, useEffect } from 'react'
import Chart from 'react-apexcharts'
import { formatCurrency, formatCurrencyWithSymbol } from '../dataProcess/DataProcess'

const MarketValueAreaChart = ({ data, resetKey, onZoomChange, height, isLoading, showAnnotations }) => {
  const chartRef = useRef(null)
  useEffect(() => {
    resetZoom()
  }, [resetKey, data])

  const resetZoom = () => {
    if (chartRef.current && data && data?.length > 0) {
      // Get the initial x-axis range based on the first and last dates in the data array
      const minX = new Date(data[0]?.x).getTime()
      const maxX = new Date(data[data?.length - 1]?.x).getTime()

      // Reset the zoom by updating the x-axis range
      chartRef.current.chart.updateOptions({
        xaxis: {
          min: minX,
          max: maxX
        }
      })
      if (onZoomChange) {
        onZoomChange(false)
      }
    }
  }

  const minYValue = Math.min(...data?.map(point => point.y))
  const annotationsArray = []
  data?.forEach((point) => {
    if (point.annotation && Object.keys(point.annotation).filter(key => key !== 'NET_MV_PLUS_CASH')?.length > 0) {
      annotationsArray.push({ annotations: point.annotation, date: point.x })
    }
  })

  const dividendTooltip = (annotationObj) => {
    if (annotationObj?.value?.length) {
      let tooltipHTML = `
            <div class='green-border'>
                <p id='heading'>Dividend</p>
                <div id='date-row'>
                    <p>Date</p>
                    <b>${new Date(annotationObj.date).toISOString().slice(0, 10)}</b>
                </div>`
      tooltipHTML += `
                <div id='data-row'>
                    <p>Total</p>
                    <b>${formatCurrencyWithSymbol(annotationObj?.value[0]?.total, 2, '$')}</b>
                </div>`
      tooltipHTML += `
                <div style='display: flex; gap:42px; margin-bottom: -10px'>
                    <p>Security</p>
                    <p>Amount</p>
                </div>`
      for (let i = 1; i < Math.min(annotationObj?.value?.length, 6); i++) {
        const dividend = annotationObj?.value[i]
        tooltipHTML += `
                <div id='data-row'>
                    <p>${dividend?.localSymbol}</p>
                    <b>${formatCurrencyWithSymbol(dividend?.dividendValue, 2, '$')}</b>
                </div>`
      }

      tooltipHTML += '</div>'
      return tooltipHTML
    } else {
      return ''
    }
  }

  const depositWithdrawalTooltip = (annotationObj) => {
    return "<div class='red-border'><p id='heading'>Deposit & Withdrawal</p><div id='date-row'><p>Date</p><b>" + new Date(annotationObj?.date).toISOString().slice(0, 10) + "</b></div><div id='data-row'><p>Amount</p><b>" + formatCurrencyWithSymbol(annotationObj?.value, 2, '$') + '</b></div></div>'
  }

  const tradeDetailsTooltip = (annotationObj) => {
    let buyTrades = []
    let sellTrades = []
    annotationObj?.value?.map((val) => {
      buyTrades = (val?.buyTrades || []).slice(0, 5)
      sellTrades = (val?.sellTrades || []).slice(0, 5)
      return { buyTrades, sellTrades }
    })
    let tooltipHTML = ''
    if (annotationObj?.value?.length) {
      tooltipHTML += "<div class='blue-border'>"
      tooltipHTML += "<p id='heading' style='padding-top: 5px'>Trade Details</p>"
      tooltipHTML += "<div id='date-row' style = 'display: flex; justify-content : space-between; align-items : center; margin-top: -20px'><p>Date</p><b>" + new Date(annotationObj.date).toISOString().slice(0, 10) + '</b></div>'
      tooltipHTML += "<div class='trade-columns'>"

      // Buy Section
      if (buyTrades?.length > 0) {
        tooltipHTML += `<div class='trade-section' style="border-right: ${sellTrades?.length > 0 && buyTrades?.length > 0 ? '1px dashed #ccc' : 'none'};"><p>Buy Trades</p>`

        for (let i = 0; i < buyTrades?.length; i++) {
          const buyTrade = buyTrades[i]

          tooltipHTML += "<div class='trade-row'>"
          tooltipHTML += `<p id='data-row' style="text-align : left ;"><p class="symbol">${buyTrade?.localSymbol}</p><p style="text-align : right;"  class='buy-trade'>${buyTrade?.tradedShares?.toFixed(2) || '-'}</p></p>`
          tooltipHTML += '</div>'
        }

        tooltipHTML += '</div>'
      }

      // Sell Section
      if (sellTrades?.length > 0) {
        tooltipHTML += "<div class='trade-section'><p>Sell Trades</p>"

        for (let i = 0; i < sellTrades?.length; i++) {
          const sellTrade = sellTrades[i]
          tooltipHTML += "<div class='trade-row'>"
          tooltipHTML += `<p class='data-row' style="text-align : left"><p class="symbol">${sellTrade?.localSymbol}</p><p style="text-align: right;"  class='sell-trade'>${sellTrade?.tradedShares?.toFixed(2) || '-'}</p></p>`
          tooltipHTML += '</div>'
        }

        tooltipHTML += '</div>'
      }

      tooltipHTML += '</div>'
      tooltipHTML += '</div>'
    }

    return tooltipHTML
  }

  // Create an array to store separate annotation entries
  const separateAnnotations = []
  // Iterate through each data entry
  annotationsArray?.forEach(entry => {
    // Extract annotations and date from each entry
    const annotations = entry.annotations
    const date = entry.date
    const annotationsKey = Object.keys(annotations).filter(key => key !== 'NET_MV_PLUS_CASH')
    let count = 0
    // Create separate entries for each annotation type
    annotationsKey?.forEach((annotationType, idx) => {
      let separateEntry = {}
      // create individual annotations for each optMapId for TRADE_DETAILS
      if (annotationType === 'TRADE_DETAILS' && annotations[annotationType]?.length > 1) {
        annotations[annotationType].map((item, index) => {
          separateEntry = {
            date,
            annotationType,
            value: [item]
          }
          if (annotations[annotationType]?.length > 1) {
            separateEntry.isSameDate = true
            separateEntry.offsetY = 0 - (25 * count)
            count++
          }
          // Add the separate entry to the array
          separateAnnotations.push(separateEntry)
        })
      } else {
        separateEntry = {
          date,
          annotationType,
          value: annotations[annotationType]
        }
        if (annotationsKey?.length > 1) {
          separateEntry.isSameDate = true
          separateEntry.offsetY = 0 - (25 * count)
          count++
        }
        // Add the separate entry to the array
        separateAnnotations.push(separateEntry)
      }
    })
  })

  const annotationsObj = {
    points: separateAnnotations?.map((annotObj, index) => ({
      id: 'tooltip-' + index,
      x: new Date(annotObj?.date).getTime(),
      y: minYValue,
      label: {
        mouseEnter: (point, event) => {
          // Calculate left and top positions of the ApexCharts container
          const chartContainer = document.getElementById('market-chart')
          const chartRect = chartContainer?.getBoundingClientRect()
          const chartLeft = chartRect?.left
          const chartTop = chartRect?.top

          // Create a tooltip div
          const tooltipDiv = document.createElement('div')
          tooltipDiv.innerHTML = annotObj?.annotationType === 'DIV_AND_INT' ? dividendTooltip(annotObj) : annotObj?.annotationType === 'DEP_AND_WIT' ? depositWithdrawalTooltip(annotObj) : annotObj?.annotationType === 'TRADE_DETAILS' ? tradeDetailsTooltip(annotObj) : ''
          tooltipDiv.id = 'tooltip-annotation-' + point.id

          // Set the position of the tooltip
          tooltipDiv.style.zIndex = '15'
          tooltipDiv.style.position = 'absolute'
          tooltipDiv.style.left = event?.clientX - chartLeft - 100 + 'px'
          tooltipDiv.style.top = annotObj?.annotationType === 'TRADE_DETAILS' ? event?.clientY - chartTop - 220 + 'px' : (annotObj?.annotationType === 'DIV_AND_INT' ? (annotObj?.value?.length > 3) ? event?.clientY - chartTop - 250 + 'px' : event?.clientY - chartTop - 180 + 'px' : event?.clientY - chartTop - 120 + 'px')
          // Check if the tooltip is going beyond the right edge of the chart container
          const tooltipWidth = 250
          tooltipDiv.style.boxShadow = 'rgba(116, 120, 141, 0.5) 0px 0px 4px'
          const chartWidth = chartRect.width
          if (event?.clientX - chartLeft - 100 + tooltipWidth > chartWidth) {
            tooltipDiv.style.left = chartWidth - tooltipWidth + 'px'
          }
          // Check if the tooltip exceeds the left edge of the chart container
          if (event?.clientX - chartLeft - 100 < 0) {
            tooltipDiv.style.left = '0'
          }

          // Append the tooltip to the body
          chartContainer?.appendChild(tooltipDiv)
        },
        mouseLeave: (point, event) => {
          if (document.getElementById('tooltip-annotation-' + point.id)) {
            document.getElementById('market-chart')?.removeChild(document.getElementById('tooltip-annotation-' + point.id))
          }
        },
        text: annotObj?.annotationType === 'DIV_AND_INT' ? 'D' : (annotObj?.annotationType === 'TRADE_DETAILS' ? 'T' : 'W'),
        borderColor: annotObj?.annotationType === 'DIV_AND_INT' ? '#33A68D' : (annotObj?.annotationType === 'TRADE_DETAILS' ? '#002A59' : '#d32f2f'),
        borderRadius: annotObj?.annotationType === 'TRADE_DETAILS' ? '4px' : '50%',
        offsetY: annotObj?.isSameDate ? annotObj?.offsetY : 0, // Adjust the vertical offset of the label
        style: {
          color: annotObj?.annotationType === 'DIV_AND_INT' ? '#33A68D' : (annotObj?.annotationType === 'TRADE_DETAILS' ? '#002A59' : '#d32f2f'),
          fontSize: '9.5px',
          fontWeight: 400,
          padding: {
            left: annotObj?.annotationType === 'DIV_AND_INT' ? 5.5 : 4,
            right: annotObj?.annotationType === 'DIV_AND_INT' ? 5.5 : 4,
            top: 4,
            bottom: 4
          }// Add padding to the label
        }
      }
    }))
  }

  const options = {
    chart: {
      events: {
        zoomed: function (chartContext, { xaxis, yaxis }) {
          if (onZoomChange) {
            onZoomChange(true)
          }
        }
      },
      toolbar: {
        show: false
      }
    },
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: true
      }
    },
    yaxis: [
      {
        title: {
          text: 'Total Portfolio Value ($)',
          offsetX: 0,
          style: {
            fontSize: '12px',
            color: '#74788D',
            fontWeight: 500
          }
        },
        min: Math.min(...data?.map(point => point.y)),
        labels: {
          formatter: function (value) {
            return formatCurrency(value)
          }
        },
        tickAmount: 7
      }
    ],
    markers: {
      size: 0
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      custom: function ({ dataPointIndex, w }) {
        const preTaxValue = w.globals.series[0][dataPointIndex]?.toFixed(2)
        return `<div style="padding: 8px; border-radius: 4px; color: #000; background-color: #fff;">
                      <div>
                        <span style="display: inline-block; width: 10px; height: 10px; background-color: ${w.config.colors[0]}; border-radius: 50%; margin-right: 5px;"></span>
                        <span>$${formatCurrency(preTaxValue, 1)}</span>
                      </div>
                  </div>`
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        enabled: true,
        opacityFrom: 0.4,
        opacityTo: 0
      }
    },
    stroke: {
      curve: 'straight',
      width: 1.8
    },
    colors: ['#002A59'],
    noData: {
      text: 'No market value data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '18px',
        fontFamily: undefined
      }
    },
    ...(showAnnotations ? { annotations: annotationsObj } : {})
  }

  const series = [
    {
      name: 'Market Value Returns',
      data: data?.map((point) => ({ x: point?.x, y: point?.y }))
    }
  ]

  return (
    <div>
      <Chart ref={chartRef} options={options} series={series} type='area' height={height || 500} id='market-chart' />
    </div>
  )
}
export default MarketValueAreaChart
