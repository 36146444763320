import React, { useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, TableBody, TableCell, TableRow, Typography, Tooltip } from '@mui/material'
import { DataGridPro, gridClasses, GridToolbarContainer, useGridApiRef } from '@mui/x-data-grid-pro'
import { randomId } from '@mui/x-data-grid-generator'
import 'rc-slider/assets/index.css'
import { ErrorBoundary } from 'react-error-boundary'
import redirect from '../../assets/images/pop-out-line.svg'
import ErrorFallback from '../ErrorFallback'
import { CardBodyRisk } from './RiskCardBody'
import { RiskTable } from './RiskTable'
import { RiskTableloader } from './RiskTableLoader'

const SecuritywiseRisk = ({ loading, securityWiseAccounts, screen }) => {
  const apiRef = useGridApiRef()
  const [showModal, setShowModal] = useState(false)
  const [securityAccounts, setSecurityAccounts] = useState([])
  const openModal = () => {
    document.body.classList.add('modal-open', 'pe-0')
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
    setTimeout(() => {
      document.body.classList.remove('modal-open', 'pe-0')
    }, 200)
  }

  function convertToCsv (data) {
    const customColumns = ['Sr No', 'Security Name', 'Weight', 'Risk', 'MCTE']

    const headerData = customColumns.join(',') + '\n'

    const rowValues = data?.map(obj => Object.values(obj).join(', ')).join('\n')

    return headerData + rowValues
  }

  const downloadCsv = () => {
    if (apiRef.current) {
      const originalData = securityAccounts?.map((originalRow) => {
        return {
          srNo: originalRow.srNo,
          security: originalRow.localSymbol || originalRow.instrId || '',
          securityName: originalRow.name || '',
          weight: originalRow.weight,
          riskColor: originalRow.riskColor,
          statsValue: originalRow.statsValue
        }
      })
      const csvData = convertToCsv(originalData)
      const blob = new Blob([csvData], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Security-Wise-Risk.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  useEffect(() => {
    setSecurityAccounts(securityWiseAccounts?.map((item, index) => ({ id: randomId(), srNo: index + 1, ...item })))
  }, [securityWiseAccounts])

  const getRowId = (row) => row.id

  const columns = [
    {
      field: 'srNo',
      headerName: 'Sr No',
      flex: 1,
      renderCell: (params) => params.row.srNo
    },
    { field: 'security', headerName: 'Security', flex: 1, valueGetter: (props) => props?.row?.localSymbol || props?.row?.instrId || '' },
    {
      field: 'securityName',
      headerName: 'Security Name',
      flex: 1,
      valueGetter: (params) => params?.row?.name || ''
    },
    {
      field: 'weight',
      headerName: 'Weight(%)',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
      renderCell: (props) => props?.row?.weight !== undefined ? ((props?.row?.weight) * 100).toFixed(2) : '0.00',
      valueGetter: (props) => props?.row?.weight !== undefined
        ? Number(((props?.row?.weight) * 100).toFixed(2))
        : 0.00
    },
    {
      field: 'riskColor',
      headerName: 'Risk',
      width: 150,
      sortComparator: (v1, v2, params1, params2) => {
        const order = { G: 1, Y: 2, R: 3 }
        return order[params1.value] - order[params2.value]
      },
      valueFormatter: ({ value }) => {
        // Map 'G' to 'LOW', 'Y' to 'MEDIUM', and 'R' to 'HIGH'
        const riskLabels = {
          G: 'LOW',
          Y: 'MEDIUM',
          R: 'HIGH'
        }
        return riskLabels[value] || value
      },
      sortingOrder: ['asc', 'desc'],
      renderCell: (params) => (
        <Grid display='flex' alignItems='center' justifyContent='center'>
          <Grid item>
            <Box
              className={
                params.row.riskColor === 'G'
                  ? 'progressbar me-2 bg-green'
                  : params.row.riskColor === 'Y'
                    ? 'progressbar me-2 bg-yellow'
                    : params.row.riskColor === 'R'
                      ? 'progressbar me-2 bg-red'
                      : 'progressbar me-2'
              }
            />
          </Grid>
          <Grid item>
            <Box
              className={
                params.row.riskColor === 'Y'
                  ? 'progressbar me-2 bg-yellow'
                  : params.row.riskColor === 'R'
                    ? 'progressbar me-2 bg-red'
                    : 'progressbar me-2'
              }
            />
          </Grid>
          <Grid item>
            <Box
              className={
                params.row.riskColor === 'R'
                  ? 'progressbar me-2 bg-red'
                  : 'progressbar me-2'
              }
            />
          </Grid>
        </Grid>
      )
    },
    {
      field: 'statsValue',
      headerName: 'MCTE',
      flex: 1,
      align: 'right',
      type: 'number',
      headerAlign: 'right',
      renderCell: (props) => props?.row?.statsValue !== undefined
        ? ((props?.row?.statsValue) * 100).toFixed(4)
        : '0.0000',
      valueGetter: (props) => props?.row?.statsValue !== undefined
        ? Number(((props?.row?.statsValue) * 100).toFixed(4))
        : 0.0000
    }
  ]

  return (
    <CardBodyRisk>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography component='p' className='text-title header-text'>Security Wise Risk</Typography>

        {loading
          ? (
              ''
            )
          : <IconButton
              onClick={openModal}
              className='tooltip-trade'
            >
            <img src={redirect} alt='' />
            <span
              className='tooltiptext'
              style={{ minWidth: '75px', marginLeft: '-20px' }}
            >
              Security Wise Risk
            </span>
            </IconButton>}
      </Box>
      <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
        <RiskTable headers='Security' loading={loading}>
          {loading
            ? (
              <RiskTableloader headers='Security' />
              )
            : (
              <>
                <TableBody>
                  {securityWiseAccounts?.length > 0
                    ? <>
                      {([...securityWiseAccounts].sort((a, b) => Math.abs(b.statsValue) - Math.abs(a.statsValue)).slice(0, 10) || [])?.map(
                        (item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{item.localSymbol || item.instrId || ''}</TableCell>
                              <TableCell sx={{ whiteSpace: 'nowrap', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <Tooltip title={item.name}>
                                  <Typography component='div' sx={{ width: '130px', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 600, fontSize: '14px' }}>
                                    {item.name || 'N/A'}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                              <TableCell align='right' sx={{ width: '90px' }}>
                                {item.weight !== undefined
                                  ? (Number(item.weight) * 100)
                                      .toFixed(2)
                                      .toString()
                                  : '0.00'}
                              </TableCell>
                              <TableCell>
                                <Box display='flex' alignItems='center' justifyContent='center'>
                                  <div
                                    className={
                                      item.riskColor === 'G'
                                        ? 'progressbar me-2 bg-green'
                                        : item.riskColor === 'Y'
                                          ? 'progressbar me-2 bg-yellow'
                                          : item.riskColor === 'R'
                                            ? 'progressbar me-2 bg-red'
                                            : 'progressbar me-2'
                                    }
                                  />
                                  <div
                                    className={
                                      item.riskColor === 'Y'
                                        ? 'progressbar me-2 bg-yellow'
                                        : item.riskColor === 'R'
                                          ? 'progressbar me-2 bg-red'
                                          : 'progressbar me-2'
                                    }
                                  />
                                  <div
                                    className={
                                      item.riskColor === 'R'
                                        ? 'progressbar me-2 bg-red'
                                        : 'progressbar me-2'
                                    }
                                  />
                                </Box>
                              </TableCell>
                              <TableCell align='right'>
                                {item.statsValue
                                  ? (Number(item.statsValue) * 100)
                                      .toFixed(4)
                                      .toString()
                                  : '0.0000'}
                              </TableCell>
                            </TableRow>
                          )
                        }
                      )}
                      </>
                    : <TableRow>
                      <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                        No Rows
                      </TableCell>
                      </TableRow>}
                </TableBody>
              </>
              )}
        </RiskTable>
      </ErrorBoundary>
      <Dialog
        fullWidth
        maxWidth='md'
        open={showModal}
        onClose={() => closeModal()}
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', paddingBottom: 0 }}>
          <Typography
            variant='h6'
            textAlign='center'
            color='#34475A'
            fontWeight={400}
          >
            Security Wise Risk
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <Button
              variant='text'
              color='primary'
              onClick={downloadCsv}
              startIcon={<FileDownloadOutlinedIcon />}
            >
              Export as CSV
            </Button>

            <IconButton
              aria-label='close'
              onClick={closeModal}
              sx={{
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <Box style={{ maxHeight: 650, width: '100%' }}>
              <DataGridPro
                columns={columns}
                apiRef={apiRef}
                rows={securityAccounts}
                pagination
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
                getRowId={getRowId}
                initialState={{
                  ...securityAccounts?.initialState,
                  pagination: { paginationModel: { pageSize: 10 } }
                }}
                autoHeight
                sx={(theme) => ({
                  [`.${gridClasses.main}`]: {
                    overflow: 'unset'
                  },
                  [`.${gridClasses.columnHeaders}`]: {
                    position: 'sticky',
                    top: 0,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1
                  },
                  [`.${gridClasses.footerContainer}`]: {
                    position: 'sticky',
                    bottom: -20,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1
                  },
                  [`.${gridClasses.toolbarContainer}`]: {
                    position: 'relative',
                    top: 0,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1
                  }
                })}
              />
            </Box>
          </ErrorBoundary>
        </DialogContent>
      </Dialog>
    </CardBodyRisk>
  )
}

export default SecuritywiseRisk
