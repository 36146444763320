import {
  Box,
  Card, CardContent,
  Grid
} from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import '../../pages/AccountReview/import-portfolio.scss'
import '../../pages/AccountOnboarding/AccountOnboarding.scss'
import { randomId } from '@mui/x-data-grid-generator'

export const CurrentImport = ({ taxlotData }) => {
  const currentPortfolioColumns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'symbol', headerName: 'Symbol', flex: 1 },
    {
      field: 'weight',
      headerName: 'Weight (%)',
      flex: 1,
      align: 'left',
      type: 'number',
      headerAlign: 'left',
      renderCell: (props) => props?.row?.weight !== undefined && props?.row?.weight !== null
        ? ((props?.row?.weight) * 100).toFixed(4)
        : '0.0000',
      valueGetter: (props) => props?.row?.weight !== undefined && props?.row?.weight !== null
        ? Number(((props?.row?.weight) * 100).toFixed(4))
        : 0.0000
    },
    { field: 'isoCountryCode', headerName: 'ISO Country Code', flex: 1 }
  ]

  const taxlotRows = (taxlotData || []).map((item, index) => ({
    id: randomId(),
    name: item.instrName || 'N/A',
    symbol: item.localSymbol || 'N/A',
    weight: item.weight || 0.0000,
    isoCountryCode: item?.isoCountryName
  }))
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card className='card-layout'>
            <CardContent>
              <Box
                sx={{
                  '& .MuiDataGrid-columnHeaderTitle': {
                    color: '#74788D'
                  },
                  '& .MuiDataGrid-cellContent': {
                    color: '#34475A',
                    fontWeight: 400
                  },
                  '& .MuiDataGrid-footerContainer': {
                    border: 'none'
                  },
                  '& .MuiDataGrid-root': {
                    border: 'none'
                  },
                  '& .MuiTablePagination-toolbar': {
                    alignItems: 'baseline'
                  }
                }}
              >
                <DataGridPro
                  columns={currentPortfolioColumns}
                  density='compact'
                  rows={taxlotRows}
                  autoHeight
                  pagination
                  pageSizeOptions={[15, 25, 50, 75, 100]}
                  initialState={{
                    ...taxlotRows?.initialState,
                    pagination: { paginationModel: { pageSize: 15 } }
                  }}
                  sx={(theme) => ({
                    '&.MuiDataGrid-root': {
                      height: 'calc(100vh - 48px - 15px - 20px - 66px - 20px - 48px - 48px - 16px - 35px - 18px - 16px - 24px)',
                      overflowY: 'auto',
                      scrollbarGutter: 'stable',
                      scrollbarWidth: 'none'
                    },
                    [`.${gridClasses.main}`]: {
                      overflow: 'unset'
                    },
                    [`.${gridClasses.columnHeaders}`]: {
                      position: 'sticky',
                      top: 0,
                      backgroundColor: theme.palette.background.paper,
                      zIndex: 1
                    },
                    [`.${gridClasses.footerContainer}`]: {
                      position: 'sticky',
                      bottom: 0,
                      backgroundColor: theme.palette.background.paper,
                      zIndex: 1
                    },
                    [`.${gridClasses.toolbarContainer}`]: {
                      position: 'relative',
                      top: 0,
                      backgroundColor: theme.palette.background.paper,
                      zIndex: 1
                    }
                  })}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
