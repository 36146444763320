import { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import * as ChartAnnotation from 'chartjs-plugin-annotation'
import { TradePopupChart } from './TradePopupChart'

const TradeFactorChart = ({ selectedScenario, factorChartObj }) => {
  const factorLabels = factorChartObj?.factorLabels || []
  const [factorData, setFactorData] = useState([])
  const factorLengend = []

  useEffect(() => {
    let count = 0
    const backgroundColor = ['#4B8CCA', '#4B8CCACC', '#4B8CCA80', '#4B8CCA4D']
    const factorDataSet = factorChartObj?.factorData?.map((data) => {
      return data.label === selectedScenario ? { ...data, backgroundColor: '#34C38F' } : { ...data, backgroundColor: backgroundColor[count++] }
    })
    setFactorData(factorDataSet)
  }, [factorChartObj?.factorData, selectedScenario])

  let maxFactorValue = 0
  if (factorChartObj.factorData) {
    factorChartObj.factorData?.forEach(fd => {
      factorLengend.push(fd.label)
      for (let i = 0; i < fd?.data?.length; i++) {
        if (maxFactorValue < Math.abs(fd.data[i])) maxFactorValue = Math.abs(fd.data[i])
      }
    })
  }
  maxFactorValue = Math.ceil(maxFactorValue)

  return (
    <>
      {factorData?.length
        ? <TradePopupChart
            options={{
              plugins: {
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: (context) => {
                      const label = context.dataset.label || ''
                      const value = context.dataset.data[context.dataIndex]
                      return [`${label} :  ${value.toFixed(2)}`]
                    }
                  }
                },
                ChartAnnotation,
                datalabels: {
                  display: false
                },
                title: {
                  display: true
                }
              },
              categoryPercentage: 0.6,
              responsive: true,
              maintainAspectRatio: false,
              devicePixelRatio: 3,
              interaction: {
                mode: 'index',
                intersect: false
              },
              scales: {
                x: {
                  display: true,
                  grid: {
                    display: false,
                    borderColor: 'white'
                  }
                },
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  }
                }],
                xAxes: [{
                  gridLines: {
                    zeroLineColor: 'rgba(0, 0, 0, 1)',
                    drawOnChartArea: false,
                    drawTicks: false
                  }
                }],
                y: {
                  display: true,
                  grid: {
                    beginAtZero: true,
                    display: true,
                    color: (context) => {
                      const ZeroLine = context.tick.value
                      const gridColor = ZeroLine === 0 ? '#74788D4D' : 'transparent'
                      return gridColor
                    }
                  }
                }
              },
              annotation: {
                annotations: [
                  {
                    type: 'line',
                    mode: 'vertical',
                    scaleID: 'x-axis-0',
                    borderColor: 'red',
                    label: {
                      content: '',
                      enabled: true,
                      position: 'top'
                    }
                  }
                ]
              }
            }}
            data={{
              labels: factorLabels,
              datasets: factorData
            }}
          />
        : (
          <Typography className='no-data-text' my={2} textAlign='left'>
            No factor allocation available.
          </Typography>
          )}
    </>
  )
}

export default TradeFactorChart
