export const sectorDataFormatter = (response, source) => {
  if (Array.isArray(response) && response.length > 0 && source) {
    const sectorDataEntry = response?.find((data) => data?.source === source)
    const fieldLabels = Object.entries((sectorDataEntry && sectorDataEntry?.labels) || {})
      .map(([field, headerName]) => ({
        field,
        headerName,
        flex: 1
      }))
      // filtering fieldLabels for sector data
      ?.filter(({ field }) => ['assetClassDesc', 'sectorExclude', 'min', 'max']?.includes(field))

    // setting the values of sector data
    const sectorData = sectorDataEntry?.value?.map((item, index) => {
      const sectorExcludeLabel = item?.sectorExclude === 1 ? 'Yes' : 'No'
      const minLabel = item?.min === null ? '-' : item.min
      const maxLabel = item?.max === null ? '-' : item.max
      return {
        id: index + 1,
        assetClassDesc: item?.assetClassDesc,
        sectorExclude: sectorExcludeLabel,
        min: minLabel,
        max: maxLabel
      }
    })
    return { sectorData, fieldLabels }
  } else {
    return { sectorData: [], fieldLabels: [] }
  }
}

export const industryDataFormatter = (response, source) => {
  if (Array.isArray(response) && response.length > 0 && source) {
    const industryDataEntry = response?.find((data) => data?.source === source)
    const fieldLabels = Object.entries((industryDataEntry && industryDataEntry?.labels) || {})
      .map(([field, headerName]) => ({
        field,
        headerName,
        flex: 1
      }))
      ?.filter(({ field }) => ['assetClassDesc', 'industryId', 'industryExclude', 'min', 'max']?.includes(field))

    const industryData = industryDataEntry?.value?.map((item, index) => {
      const industryExcludeLabel = item?.industryExclude === 1 ? 'Yes' : 'No'
      const minLabel = item?.min === null ? '-' : item.min
      const maxLabel = item?.max === null ? '-' : item.max
      return {
        id: index + 1,
        assetClassDesc: item?.assetClassDesc,
        industryId: item?.industryId,
        industryExclude: industryExcludeLabel,
        min: minLabel,
        max: maxLabel
      }
    })
    return { industryData, fieldLabels }
  } else {
    return { industryData: [], fieldLabels: [] }
  }
}

export const factoryDataFormatter = (response, source) => {
  if (Array.isArray(response) && response.length > 0 && source) {
    const factoryDataEntry = response?.find((data) => data?.source === source)
    const fieldLabels = Object.entries((factoryDataEntry && factoryDataEntry?.labels) || {})
      .map(([field, headerName]) => ({
        field,
        headerName,
        flex: 1
      }))
      ?.filter(({ field }) => ['attrLabel', 'statsValue']?.includes(field))

    const factoryData = factoryDataEntry?.value?.map((item, index) => {
      return {
        id: index + 1,
        attrLabel: item?.attrLabel,
        statsValue: item?.statsValue
      }
    })
    return { factoryData, fieldLabels }
  } else {
    return { factoryData: [], fieldLabels: [] }
  }
}

export const substitutionDataFormatter = (response, source) => {
  if (Array.isArray(response) && response.length > 0 && source) {
    const substitutionDataEntry = response?.find((data) => data?.source === source)
    const fieldLabels = Object.entries((substitutionDataEntry && substitutionDataEntry?.labels) || {})
      .map(([field, headerName]) => ({
        field,
        headerName,
        flex: 1
      }))
      ?.filter(({ field }) => ['sourceName', 'targetName']?.includes(field))

    const subData = substitutionDataEntry?.value?.map((item, index) => {
      return {
        id: index + 1,
        sourceName: item?.sourceName,
        targetName: item?.targetName
      }
    })
    return { subData, fieldLabels }
  } else {
    return { subData: [], fieldLabels: [] }
  }
}

export const instrumentDataFormatter = (response, source) => {
  if (Array.isArray(response) && response.length > 0 && source) {
    const instrumentDataEntry = response?.find((data) => data?.source === source)
    const fieldLabels = Object.entries((instrumentDataEntry && instrumentDataEntry?.labels) || {})
      .map(([field, headerName]) => ({
        field,
        headerName,
        flex: 1
      }))
      ?.filter(({ field }) => ['name', 'restrictionDesc', 'source']?.includes(field))

    const instrumentData = instrumentDataEntry?.value?.map((item, index) => {
      return {
        id: index + 1,
        name: item?.name,
        restrictionDesc: item?.restrictionDesc,
        source: item?.source
      }
    })
    return { instrumentData, fieldLabels }
  } else {
    return { instrumentData: [], fieldLabels: [] }
  }
}
