import { Fragment, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogTitle, Grid, IconButton, Menu, MenuItem, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, GridRowModes, GridToolbarContainer } from '@mui/x-data-grid-pro'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import CreateInputForm from './CreateInputForm'
import UpdateInputFrom from './UpdateInputFrom'

const PmUserTable = (props) => {
  const { setShowAdminPanel, setActiveTab, setShowEditTab, type, setType, setSelectedRow, PMUserRows, PMUserLoading, setPMUserRows, selectedRow } = props
  const { showError } = useErrorToast()
  const { user } = useAuth()
  const { showSuccess } = useSuccessToast()
  const [currentPage, setCurrentPage] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const [rowModesModel, setRowModesModel] = useState({})
  const [open, setOpen] = useState(false)
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false)
  const [formEditMode, setFormEditMode] = useState('add')
  const [activeRow, setActiveRow] = useState(null)

  const columns = [
    {
      field: 'userName',
      headerName: 'User Name',
      flex: 4
    },
    {
      field: 'userEmail',
      headerName: 'User Email',
      flex: 4
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 80,
      align: 'center',
      renderCell: (params) => (
        <>
          <IconButton
            aria-label='more'
            aria-haspopup='true'
            onClick={(event) => {
              setAnchorEl(event.currentTarget)
              setActiveRow(params.row)
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && activeRow?.userId === params.row?.userId}
            onClose={() => {
              setActiveRow(null)
              setAnchorEl(null)
            }}
            elevation={4}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            sx={{
              padding: 0,
              '.MuiMenuItem-root': {
                padding: '0 10px'
              },
              '.MuiButtonBase-root': {
                justifyContent: 'flex-start',
                textTransform: 'capitalize'
              }
            }}
          >
            <MenuItem>
              <Button
                fullWidth
                variant='text'
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  fontWeight: 600
                }}
                onClick={() => {
                  setType('ARIS User')
                  setActiveTab('')
                  setShowAdminPanel(false)
                  setShowEditTab(true)
                  setSelectedRow(params?.row)
                }}
                disableRipple
                startIcon={
                  <ManageAccountsOutlinedIcon
                    sx={{ fontSize: '8px' }}
                  />
                }
              >Manage User Permission
              </Button>
            </MenuItem>
            {/* <MenuItem>
              <Button
                fullWidth
                variant='text'
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  fontWeight: 600
                }}
                onClick={() => {
                  setOpen(true)
                  setSelectedRow(params?.row)
                  setAnchorEl(null)
                  setFormEditMode('edit')
                  setType('ARIS User')
                }}
                disableRipple
                startIcon={
                  <EditOutlinedIcon
                    sx={{ fontSize: '8px' }}
                  />
                }
              >
                Edit User
              </Button>
            </MenuItem> */}
            <MenuItem>
              <Button
                fullWidth
                sx={{
                  color: 'red',
                  fontWeight: 600
                }}
                variant='text'
                disableRipple
                onClick={() => {
                  setSelectedRow(params?.row)
                  setAnchorEl(null)
                  setOpenDeleteUserDialog(true)
                }}
                startIcon={
                  <DeleteIcon
                    sx={{ fontSize: '8px' }}
                  />
                }
              >Delete User
              </Button>
            </MenuItem>
          </Menu>
        </>
      )
    }
  ]

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const handleRowClick = (params) => {
    if (params?.field !== 'action') {
      setType('ARIS User')
      setActiveTab('')
      setShowAdminPanel(false)
      setShowEditTab(true)
      setSelectedRow(params?.row)
    }
  }

  const handleCancelClose = () => {
    setOpen(false)
  }

  const CreateUser = (value, setPMUserRows, setRowModesModel, setCreateApiLoading, generatePasswordSelect) => {
    const requestBody = {
      userName: value?.userName,
      userEmail: value?.userEmail
    }

    if (!generatePasswordSelect) {
      requestBody.tmpPwd = value?.tmpPwd
    }

    API.post('baseAclURL', `user-access-control/v1/${user?.userGroup}/user/pm?generatePwd=${generatePasswordSelect}`, {
      body: requestBody
    }).then((data) => {
      if (data && data.success) {
        setOpen(false)
        const userId = data?.data?.cognitoSub
        const newRow = {
          userId,
          ...{ ...(data?.data ? data?.data : value) },
          isNew: true
        }
        setPMUserRows((oldRows) => [...oldRows, newRow])
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [userId]: { mode: GridRowModes.View, fieldToFocus: 'name' }
        }))
        showSuccess(data?.message)
      }
    }).catch((error) => {
      setCreateApiLoading(false)
      showError(error?.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
    }).finally(() => setCreateApiLoading(false))
  }

  // const UpdateUser = () => {

  // }

  const EditToolbar = (props) => {
    const { setPMUserRows, setRowModesModel } = props
    const [createApiLoading, setCreateApiLoading] = useState(false)
    const [generatePasswordSelect, setGeneratePasswordSelect] = useState(true)
    const [updateApiLoading, setUpdateApiLoading] = useState(false)

    const handleCreateSaveClose = (values) => {
      CreateUser(values, setPMUserRows, setRowModesModel, setCreateApiLoading, generatePasswordSelect)
    }

    const handleUpdateSaveClose = (values) => {
      // UpdateUser(values, setPMUserRows, setRowModesModel, setUpdateApiLoading)
    }

    return (
      <GridToolbarContainer sx={{ marginBottom: '10px', marginTop: '10px' }}>
        <Button
          onClick={() => {
            setOpen(true)
            setFormEditMode('add')
            setType('ARIS User')
          }}
          color='primary'
          sx={{ position: 'absolute', right: '1px' }}
          startIcon={<AddIcon />}
        >
          Create User
        </Button>
        <Dialog open={open} onClose={handleCancelClose} fullWidth maxWidth='sm'>
          {formEditMode === 'add'
            ? (
              <CreateInputForm
                savedData={handleCreateSaveClose}
                setGeneratePasswordSelect={setGeneratePasswordSelect}
                generatePasswordSelect={generatePasswordSelect}
                type={type}
                createApiLoading={createApiLoading}
                setCreateApiLoading={setCreateApiLoading}
                selectedRow={selectedRow}
              />
              )
            : (
              <UpdateInputFrom
                savedData={handleUpdateSaveClose} type={type} updateApiLoading={updateApiLoading} setUpdateApiLoading={setUpdateApiLoading} selectedRow={selectedRow}
              />
              )}
        </Dialog>
      </GridToolbarContainer>
    )
  }

  const handleUserDelete = (e, id, setIsDeleteApiLoading) => {
    if (e.target.innerText === 'CONFIRM') {
      setIsDeleteApiLoading(true)
      API.del('baseAclURL', `user-access-control/v1/${user?.userGroup}/user/pm/${id}`)
        .then((res) => {
          if (res && res?.success) {
            setPMUserRows(PMUserRows?.filter((row) => row?.userId !== id))
            showSuccess(res?.message)
          }
        }).catch((error) => {
          setIsDeleteApiLoading(false)
          showError(error?.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
        }).finally(() => {
          setIsDeleteApiLoading(false)
          setOpenDeleteUserDialog(false)
          setAnchorEl(null)
        })
    }
  }

  const DeletePMUserConfirm = () => {
    const [isDeleteApiLoading, setIsDeleteApiLoading] = useState(false)
    return (
      <>
        <Dialog
          fullWidth
          maxWidth='xs'
          open={openDeleteUserDialog}
          onClose={() => setOpenDeleteUserDialog(false)}
        >
          <DialogTitle>
            {`Are you sure you want to delete ${selectedRow?.userName} user?`}
          </DialogTitle>
          <DialogActions sx={{ marginBottom: '5px' }}>
            {isDeleteApiLoading
              ? (
                <LoadingButton
                  loading
                  loadingPosition='start'
                  variant='outlined'
                  sx={{ width: '130px' }}
                >
                  Confirm
                </LoadingButton>
                )
              : (
                <Button variant='contained' onClick={(e) => handleUserDelete(e, selectedRow?.userId, setIsDeleteApiLoading)}>
                  Confirm
                </Button>
                )}
            <Button autoFocus onClick={() => setOpenDeleteUserDialog(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const UserLoading = () => {
    return (
      <>
        <Box className='table-responsive'>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '100vh' }}>User Name</TableCell>
                <TableCell sx={{ width: '100vh' }}>User Email</TableCell>
                <TableCell sx={{ width: '35vh' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
            [...Array(3)].map((e, i) => (
              <Fragment key={i}>
                <TableRow>
                  <TableCell>
                    <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={250} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={250} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                  </TableCell>
                </TableRow>
              </Fragment>
            ))
              }
            </TableBody>
          </Table>
        </Box>
      </>
    )
  }

  return (
    <>
      <CardContent>
        {PMUserLoading
          ? (
            <UserLoading />
            )
          : (
            <DataGridPro
              autoHeight
              rows={PMUserRows}
              columns={columns}
              density='compact'
              sx={{
                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer'
                }
              }}
              onCellClick={handleRowClick}
              rowSelection={false}
              getRowId={(row) => row?.userId}
              pageSizeOptions={[10]}
              onPaginationModelChange={(event) => setCurrentPage(event.page)}
              pagination
              paginationModel={{ pageSize: 10, page: currentPage }}
              editMode='row'
              unstable_cellSelection={{ sponsorName: true }}
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              slots={{
                toolbar: EditToolbar
              }}
              slotProps={{
                toolbar: { setPMUserRows, setRowModesModel }
              }}
            />
            )}
      </CardContent>
      <DeletePMUserConfirm />
    </>
  )
}

export default PmUserTable
