import React from 'react'
import { Grid, Typography, Box } from '@mui/material'

const AllComments = ({ data, name }) => {
  return (
    <Box className='comment-box'>
      <Grid container className='account-request'>
        <Grid item xs={12} p={1}>
          {(data || []).map((item, index) => (
            <React.Fragment key={index}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box className='title' px={2}>
                  <Typography variant='body1' className='time'>
                    {new Date(item.insTs).toDateString()}: <b>{name}</b>
                  </Typography>
                </Box>
                <Box className='awaiting-badge' mx={2}>
                  {item.statusType[0]}
                  {item.statusType.slice(1, 10).toLowerCase()}
                </Box>
              </Box>
              <Box px={2}>
                <Typography variant='body1' className='reqComment' sx={{ marginTop: '0px' }}>
                  {item.comment}
                </Typography>
              </Box>
              <Box className='tradeHr'>
                <hr />
              </Box>
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </Box>
  )
}

export default AllComments
