import { getUserRole } from '../../../utils/getUserRole'
import { DeleteAPI } from '../components/Actions'

export const DeleteAccountData = async (deleteRow, tabIndex) => {
  switch (tabIndex) {
    case 'external-gl':
      return await DeleteAccountExternalGL(deleteRow?.accountId, deleteRow?.accExtGl)
    case 'internal-gl':
      return await DeleteAccountInternalGL(deleteRow?.accountId, deleteRow?.accIntGl)
    case 'parameters':
      return await DeleteAccountParameters(deleteRow?.accountId)
    case 'portfolio-specification':
      return await DeletePortSpec(deleteRow?.portSpecId)
    case 'portfolio-specification-properties':
      return await DeletePortSpecProperties(deleteRow?.portSpecPropertiesId)
    case 'substitution':
      return await DeleteAccountSubstitution(deleteRow?.accountInstrSubsId)
    case 'restriction':
      return await DeleteAccountRestrictions(deleteRow?.accountInstrRestrId)
    case 'tax-rate':
      return await DeleteTaxRates(deleteRow?.accountId, deleteRow?.currentTaxYear)
    case 'properties':
      return await DeleteAccountProperties(deleteRow?.accountPropertiesId)
    default:
      return {}
  }
}

const DeleteAccountExternalGL = async (accountId, accExtGl) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/${accountId}/external-gain-loss/${accExtGl}`)
}

const DeleteAccountInternalGL = async (accountId, accIntGl) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/${accountId}/int-gl/${accIntGl}`)
}

// const DeleteAccountCashManagement = async (accountId) => {
//   const userRole = getUserRole()
//   return DeleteAPI(
//     'baseAccountURL',
//     `data-maintenance/v1/${userRole}/accounts/${accountId}/cash-management`)
// }

const DeleteAccountParameters = async (accountId) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/${accountId}/parameter`)
}

const DeletePortSpec = async (portSpecId) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseAccountPortSpecURL',
    `data-maintenance/v1/${userRole}/accounts/port-spec/${portSpecId}`)
}

const DeletePortSpecProperties = async (portSpecPropertiesId) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseAccountPortSpecURL',
    `data-maintenance/v1/${userRole}/portfolio/specifications/properties/${portSpecPropertiesId}`)
}

const DeleteAccountSubstitution = async (accountInstrSubsId) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/instr-substitution/${accountInstrSubsId}`)
}

const DeleteAccountRestrictions = async (accountInstrRestrId) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/instrument-restriction/${accountInstrRestrId}`)
}

const DeleteTaxRates = async (accountId, taxYear) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/${accountId}/tax-rates/${taxYear}`)
}

const DeleteAccountProperties = async (accountPropertiesId) => {
  const userRole = getUserRole()
  return DeleteAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/properties/${accountPropertiesId}`)
}
