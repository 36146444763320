import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // Import the UTC plugin

// Extend dayjs with the utc plugin
dayjs.extend(utc)

export const CurrentImport = ({ taxData, CashValue }) => {
  // const formatCurrency = (amount) => {
  //   // Nine Zeroes for Billions
  //   return Math.abs(Number(amount)) >= 1.0e+9
  //     ? (Math.abs(Number(amount)) / 1.0e+9).toFixed(2) + 'B'
  //     // Six Zeroes for Millions
  //     : Math.abs(Number(amount)) >= 1.0e+6
  //       ? (Math.abs(Number(amount)) / 1.0e+6).toFixed(2) + 'M'
  //       // Three Zeroes for Thousands
  //       : Math.abs(Number(amount)) >= 1.0e+3
  //         ? (Math.abs(Number(amount)) / 1.0e+3).toFixed(2) + 'K'
  //         : Math.abs(Number(amount).toFixed(2))
  // }

  const formateToUsdCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const columns = [
    { field: 'name', headerName: 'Name', flex: 2 },
    { field: 'symbol', headerName: 'Symbol', flex: 1 },
    { field: 'share', headerName: 'Share', flex: 1, type: 'number' },
    { field: 'purchaseCost', headerName: 'Purchase Cost', flex: 1, type: 'number' },
    { field: 'purchaseDate', headerName: 'Purchase Date', flex: 1, type: 'number' },
    { field: 'isoCountryName', headerName: 'Country Code', flex: 1 }
  ]

  const rows = taxData.map((item, index) =>
    ({
      id: index,
      name: item.instrName || 'N/A',
      symbol: item.instrId === '__USD' ? '__CASH' : item.instrId,
      share: item.shares || 0,
      purchaseCost: `${formateToUsdCurrency.format(
        (item.instrId === '__USD' || item.instrId === '__CASH') ? (item.shares || 0) : (item.purchaseCost || 0)
      )}`,
      purchaseDate: dayjs.utc(item.purchaseDate).format('YYYY-MM-DD'),
      isoCountryName: item?.isoCountryName
    }))

  return (
    <Grid container spacing={2} marginTop={2}>
      <Grid item xs={12}>
        {!CashValue
          ? (
              ''
            )
          : (
            <Typography variant='body1' component='div'>
              Total Cash Value
              <input type='text' value={CashValue} />
            </Typography>
            )}
        <div style={{ maxHeight: 400, width: '100%' }}>
          {!CashValue
            ? null
            : (
              <Typography variant='body1' component='div'>
                Total Cash Value
                <input type='text' value={CashValue} />
              </Typography>
              )}
          <Card className='card-layout' sx={{ overflow: 'auto', maxHeight: '400px' }}>
            <CardContent>
              {taxData?.length > 0
                ? (
                  <Box>
                    <DataGridPro
                      rows={rows}
                      autoHeight
                      columns={columns}
                      getRowId={(row) => row.id}
                      hideFooter
                      sx={{
                        border: 'none',
                        fontFamily: 'Open Sans',
                        '& .MuiDataGrid-columnHeaderTitle': {
                          fontWeight: 600,
                          fontFamily: 'Open Sans',
                          color: '#34475A'
                        },
                        '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                          fontWeight: 600,
                          fontFamily: 'Open Sans',
                          color: '#34475A'
                        }
                      }}
                    />
                  </Box>
                  )
                : (
                  <div>No Rows</div>
                  )}
            </CardContent>
          </Card>
        </div>
      </Grid>
    </Grid>
  )
}
