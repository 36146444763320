import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { Add, Close, EditOutlined, ExpandMore, FilterList } from '@mui/icons-material'
import { TabContext, TabPanel } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, IconButton, InputAdornment, InputBase, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Modal, Radio, RadioGroup, Select, Tab, Tabs, TextField, Typography, alpha, styled } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { BsSortDown, BsTrash } from 'react-icons/bs'
import { queueChange } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction.js'
import searchIcon from '../../../../assets/images/top-search-icon.svg'
import { useErrorToast } from '../../../../hooks/useErrorToast.js'
import { addChartValues, changeCustomTableOverflow } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { isNumberWithSign } from '../../../../utils/NumberUtils.js'
import { AGGREGATE_FUNCTIONS, DATA_TYPE, DATE_FORMAT, DEFAULT_FORMAT_FIELDS, DISPLAY_DATA_TYPE, TYPE_WISE_FILTERS, headers } from '../../common/componentData.js'

const columnSelectionModalStyles = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: '275px',
  p: 1,
  bgcolor: 'background.paper',
  boxShadow: 24,
  width: '200px',
  maxHeight: '350px',
  borderRadius: '4px',
  outline: 'none'
}

const CustomTab = styled(Tab)(({ theme, selected }) => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  textTransform: 'capitalize',
  fontSize: '14px',
  color: '#34475A',
  fontWeight: 400,
  padding: 0,
  minWidth: '65px',
  '&.Mui-selected': {
    fontWeight: 400,
    color: '#34475A'
  }
}))

const CommonTableEdit = ({ activeChart }) => {
  const dispatch = useDispatch()
  const { customTableMetadata } = useSelector(state => state.allPageStyle)
  const { pages } = useSelector(state => state.elementStates)
  const { isResponseElements } = useSelector(state => state.chartState)
  const [showPopupModal, setShowPopupModal] = useState(false) // open/close modal
  const [dataSourceValue, setDataSourceValue] = useState(null) // name of the selected data source
  const [selectedDataSourceObject, setSelectedDataSourceObject] = useState({}) //selected data source object with header
  const [selectedColumnValues, setSelectedColumnValues] = useState([]) // selected columns to show
  const [availableColumns, setAvailableColumns] = useState([]) // columns available for selected datasource
  const [selectedGroups, setSelectedGroups] = useState([]) // columns selected for group
  // const [isGroupingAvailable, setIsGroupingAvailable] = useState(false) // can be removed by checking canGroup from selectedDataSourceObject
  const [availableGroups, setAvailableGroups] = useState([])
  const [selectedColumnForFormat, setSelectedColumnForFormat] = useState(null) // column selected to apply format
  const [selectedDisplayFormat, setSelectedDisplayFormat] = useState(null) // selected display format for selected column
  const [modifiedColumnName, setModifiedColumnName] = useState('')
  const [formatFieldsForSelectedColumn, setFormatFieldsForSelectedColumn] = useState({}) // store formatted changes untill apply btn click
  const [filtersPerColumn, setFiltersPerColumn] = useState([]) // filters are dependent on the index of columns // store filters changes untill apply btn click
  const [filterType, setFilterType] = useState('include') // type of filter selected
  const [showOverflow, setShowOverflow] = useState(false) // boolean value to show overflow on multiple pages
  const [tabValue, setTabValue] = useState('0')
  const [prevFocusedElementId, setPrevFocusedElementId] = useState(activeChart?.id) // store previous focused element to reset some states
  const dragIndex = useRef(0)
  const draggedOverIndex = useRef(0)
  const { showError } = useErrorToast()
  const customTableTabs = ['Table', 'Format', 'Filter']

  useEffect(() => {
    setAvailableColumns(headers[activeChart?.name]?.columns)
    if (activeChart?.value?.datasets) {
      setFiltersPerColumn(activeChart?.value?.datasets?.map(colObj => colObj?.filterObj))
      setSelectedColumnValues(activeChart?.value?.datasets)
    }
    else
      setSelectedColumnValues([])
    if (activeChart?.value?.selectedGroups) {
      setSelectedGroups(activeChart?.value?.selectedGroups)
    }
    else {
      setSelectedGroups([])
    }
    if (activeChart?.value?.availableGroups) {
      setAvailableGroups(activeChart?.value?.availableGroups)
    } else {
      setAvailableGroups([])
    }
    if (activeChart?.value?.showOverflow) {
      setShowOverflow(activeChart?.value?.showOverflow)
    }
    else {
      setShowOverflow(false)
    }
    if (activeChart?.id !== prevFocusedElementId) {
      setSelectedColumnForFormat(null)
      setSelectedDisplayFormat(null)
      setFormatFieldsForSelectedColumn({})
      setPrevFocusedElementId(activeChart?.id)
      setModifiedColumnName('')
    }
  }, [activeChart])

  const handleInputColumnChange = (value) => {
    if (value.length > 0) {
      // add default format and filter object for every column if there isn't any
      value?.forEach(val => {
        if (!val?.formatObj) {
          val.formatObj = {
            ...DEFAULT_FORMAT_FIELDS[val?.defaultDisplayType],
            aggregateFunction: val?.headerType === DATA_TYPE.NUMBER ? 'sum' : 'blank'
          }
          val.displayType = val?.defaultDisplayType
        } else {
          val.formatObj = { ...val.formatObj, aggregateFunction: val?.formatObj?.aggregateFunction ? val?.formatObj?.aggregateFunction : val?.headerType === DATA_TYPE.NUMBER ? 'sum' : 'blank' }
        }
        if (!val?.filterObj) {
          val.filterObj = {
            logicalOperator: '',
            filters: [{
              operator: 'Equals',
              value: ''
            }]
          }
        }
        if (!val?.width) {
          val.width = '1fr'
        }
        if (val?.displayHeaderName === undefined || val?.displayHeaderName === null) {
          val.displayHeaderName = val?.headerName
        }
      })
    }
    // keep old filters as it is, add new filters for new columns
    const newAddedColumns = value?.filter(colObj => selectedColumnValues?.findIndex(obj => obj?.field === colObj?.field) === -1)
    const modifiedFilters = [
      ...filtersPerColumn,
      ...newAddedColumns?.map(obj => ({
        field: obj?.field,
        filters: [{
          operator: 'Equals',
          value: ''
        }]
      }))
    ]
    setFiltersPerColumn(modifiedFilters)
    setSelectedColumnValues(value)
    // if any group is selected then only clear table rows for re-calculating newly added column values
    if (selectedGroups?.length) {
      dispatch(addChartValues({ datasets: value, chartIndex: activeChart?.chartIndex, pageIndex: activeChart?.pageIndex, clearTableRows: true, dataSource: activeChart?.value?.dataSource }))
    }
    else {
      dispatch(addChartValues({ datasets: value, chartIndex: activeChart?.chartIndex, pageIndex: activeChart?.pageIndex, dataSource: activeChart?.value?.dataSource }))
    }
  }

  const handleDrop = (e, groupingFlag) => {
    e.preventDefault()
    if (!groupingFlag) {
      // change the index of dragged element to the element over which this dragged element is getting hovered
      const newLabelOrder = selectedColumnValues?.slice()
      const newFilterPerColumns = filtersPerColumn?.slice()
      // remove dragged element from previous position
      const [draggedItem] = newLabelOrder?.splice(dragIndex.current, 1)
      // add dragged element before the dragged over element
      newLabelOrder?.splice(draggedOverIndex.current, 0, draggedItem)
      // change the index of filter column also as filters are dependent on the index of columns
      const [filterItem] = newFilterPerColumns?.splice(dragIndex.current, 1)
      newFilterPerColumns.splice(draggedOverIndex.current, 0, filterItem)
      setFiltersPerColumn(newFilterPerColumns)
      setSelectedColumnValues(newLabelOrder)
      dispatch(addChartValues({ datasets: newLabelOrder, chartIndex: activeChart?.chartIndex, pageIndex: activeChart?.pageIndex }))
    } else {
      const newLabelOrder = selectedGroups?.slice()
      const [draggedItem] = newLabelOrder?.splice(dragIndex.current, 1)
      newLabelOrder.splice(draggedOverIndex.current, 0, draggedItem)
      dispatch(addChartValues({ selectedGroups: newLabelOrder, labels: [], chartIndex: activeChart?.chartIndex, pageIndex: activeChart?.pageIndex, clearTableRows: true, dataSource: activeChart?.value?.dataSource }))
    }
  }

  const removeColumnFromSelectedColumns = (e, removedColumnIndex) => {
    // if column is removed from the selected column and it's selected in format
    // remove the format selection
    if (selectedColumnForFormat && selectedColumnValues[removedColumnIndex]?.field === selectedColumnForFormat?.field) {
      setSelectedColumnForFormat(null)
      setFormatFieldsForSelectedColumn({})
    }
    // remove this column filters
    const modifiedFilters = filtersPerColumn?.filter((_, index) => index !== removedColumnIndex)
    setFiltersPerColumn(modifiedFilters)
    // filters are changed and column is removed then this column should also be removed from filters stored in redux
    // const lastModifiedFilters = []
    // if (activeChart?.value?.filters && activeChart?.value?.filters.length !== 0)
    //   lastModifiedFilters.push(activeChart?.value?.filters.filter((filterObj, index) => index !== filterObj))
    // filter the row that's removed from selected columns
    const modifiedSelectedColumnValues = selectedColumnValues?.filter((_, columnIndex) => (removedColumnIndex !== columnIndex))
    setSelectedColumnValues(modifiedSelectedColumnValues)
    dispatch(addChartValues({ datasets: modifiedSelectedColumnValues, chartIndex: activeChart?.chartIndex, pageIndex: activeChart?.pageIndex, dataSource: activeChart?.value?.dataSource }))
  }

  const handleGroupInputChange = (value) => {
    setSelectedGroups(value)
    if (!value?.length) {
      dispatch(addChartValues({ selectedGroups: [], datasets: activeChart?.value?.datasets, chartIndex: activeChart?.chartIndex, pageIndex: activeChart?.pageIndex, clearTableRows: true, dataSource: activeChart?.value?.dataSource }))
      return
    }
    // column added into the group
    // check if selected field is added in group
    // const newAddedGroups = value?.filter((colObj) => selectedGroups?.findIndex(obj => obj?.field === colObj?.field) === -1)
    // show fields which are not selected in group fields
    const newColumns = selectedColumnValues.slice()
    if (newColumns.findIndex(column => column?.field === 'groupName') === -1) {
      newColumns.unshift({ field: 'groupName', headerName: 'Group', displayHeaderName: 'Group', width: '1fr' })
    }
    setSelectedColumnValues(newColumns)
    dispatch(addChartValues({ selectedGroups: value, datasets: newColumns, chartIndex: activeChart?.chartIndex, pageIndex: activeChart?.pageIndex, clearTableRows: true, dataSource: activeChart?.value?.dataSource }))
  }

  const removeGroupOption = (e, removedColumnIndex) => {
    const modifiedSelectedColumnsForGroup = selectedGroups.filter((_, groupColumnIndex) => (removedColumnIndex !== groupColumnIndex))
    setSelectedGroups(modifiedSelectedColumnsForGroup)
    let selectedColumns = selectedColumnValues?.slice()
    if (!modifiedSelectedColumnsForGroup?.length) {
      selectedColumns = selectedColumnValues?.filter(column => !(column?.field === 'groupName'))
    }
    // update removed group column changes in redux
    dispatch(addChartValues({ selectedGroups: modifiedSelectedColumnsForGroup, ...(!modifiedSelectedColumnsForGroup?.length ? { datasets: selectedColumns } : {}), chartIndex: activeChart?.chartIndex, pageIndex: activeChart?.pageIndex, clearTableRows: true, dataSource: activeChart?.value?.dataSource }))
  }

  const handleEditColumnClick = (option) => {
    setTabValue('1')
    // set the format column field
    setSelectedColumnForFormat(option)
    if (option?.displayType) {
      setSelectedDisplayFormat(option?.displayType)
    } else {
      setSelectedDisplayFormat(option?.defaultDisplayType)
    }
    if (option?.formatObj) {
      setFormatFieldsForSelectedColumn(option?.formatObj)
    }
    if (option?.displayHeaderName !== undefined && option?.displayHeaderName !== null)
      setModifiedColumnName(option?.displayHeaderName)
    else
      setModifiedColumnName(option?.headerName)
  }

  const handleChangeDisplayFormatType = (e, value) => {
    setSelectedDisplayFormat(value)
    const currentColumnWithUpdatedFields = selectedColumnValues?.filter(obj => obj?.field === selectedColumnForFormat?.field)
    setFormatFieldsForSelectedColumn(currentColumnWithUpdatedFields?.length === 1 && currentColumnWithUpdatedFields[0]?.displayType === value ? { ...formatFieldsForSelectedColumn, ...DEFAULT_FORMAT_FIELDS[value] } : { ...DEFAULT_FORMAT_FIELDS[value], aggregateFunction: formatFieldsForSelectedColumn?.aggregateFunction || (currentColumnWithUpdatedFields[0]?.headerType === DATA_TYPE.NUMBER ? 'sum' : 'blank') })
  }

  const renderSelectedOptions = (option, index, groupingFlag, getOptionLabel = (option) => option) => {
    return (
      <div
        key={index}
        draggable
        onDragStart={(e) => (dragIndex.current = index)}
        onDragEnter={(e) => (draggedOverIndex.current = index)}
        onDragEnd={(e) => handleDrop(e, groupingFlag)}
        style={{ width: '100%', height: '30px', marginBlock: '5px', cursor: 'move', backgroundColor: '#f2f5f8', padding: '4px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#9496a7' }}
      >
        <Typography sx={{ fontSize: '12px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', color: '#9496a7' }}>{getOptionLabel(option)}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {
            !groupingFlag
              ? (
                <IconButton
                  sx={{ p: '4px' }}
                  onClick={() => handleEditColumnClick(option)}
                >
                  <EditOutlined style={{ fontSize: '14px', color: '#9496a7' }} />
                </IconButton>
              )
              : ''
          }
          <IconButton sx={{ p: '4px' }}>
            <BsSortDown fontSize='14px' />
          </IconButton>
          <IconButton
            sx={{ p: '4px' }}
            onClick={(e) => groupingFlag ? removeGroupOption(e, index) : removeColumnFromSelectedColumns(e, index)}
          >
            <Close sx={{ fontSize: '14px' }} />
          </IconButton>
        </Box>
      </div>
    )
  }

  function renderCurrencyFormatFields() {
    const handleOnChange = (e) => setFormatFieldsForSelectedColumn(prev => ({ ...prev, decimal: Number(e.target.value) }))
    return (
      <>
        <Box sx={{ mt: '10px' }}>
          <FormControlLabel
            control={
              <CustomCheckbox size='small' checked={formatFieldsForSelectedColumn?.commas} onChange={(e) => setFormatFieldsForSelectedColumn(prev => ({ ...prev, commas: e.target.checked }))} />
            }
            label={<Typography variant='body2' sx={{ fontSize: '12px' }}>use 1000 separator (,)</Typography>}
            sx={{
              fontSize: '12px',
              '&.MuiFormControlLabel-root': {
                ml: 0,
                mr: 0
              }
            }}
          />
          <WholeNumberInputField handleOnChange={handleOnChange} value={formatFieldsForSelectedColumn?.decimal} />
        </Box>
      </>
    )
  }

  function renderPercentageFormatFields() {
    const handleOnChange = (e) => setFormatFieldsForSelectedColumn(prev => ({ ...prev, decimal: Number(e.target.value) }))
    return (
      <>
        <Box sx={{ mt: '10px' }}>
          <FormControlLabel
            control={
              <Checkbox size='small' checked={formatFieldsForSelectedColumn?.symbol} onChange={(e) => setFormatFieldsForSelectedColumn(prev => ({ ...prev, symbol: e.target.checked }))} />
            }
            label={<Typography variant='body2' sx={{ fontSize: '12px' }}>show percentage symbol (%)</Typography>}
            sx={{
              fontSize: '12px',
              '.MuiFormControlLabel-root': {
                ml: 0,
                mr: 0
              }
            }}
          />
          <WholeNumberInputField value={formatFieldsForSelectedColumn?.decimal} handleOnChange={handleOnChange} />
        </Box>
      </>
    )
  }

  function renderNumberFormatFields() {
    const handleOnChange = (e) => e.target.value >= 0 && e.target.value <= 10 ? setFormatFieldsForSelectedColumn(prev => ({ ...prev, decimal: Number(e.target.value) })) : showError('value must be between 0 to 10')
    return <WholeNumberInputField value={formatFieldsForSelectedColumn?.decimal} handleOnChange={handleOnChange} />
  }

  function renderDateFormatFields() {
    return (
      <>
        <Box
          sx={{
            mt: '10px',
            clear: 'both',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <Typography sx={{ fontSize: '12px', color: '#74788D', fontWeight: 600 }}>Format</Typography>
          <Select
            variant='filled'
            value={formatFieldsForSelectedColumn?.type}
            onChange={(e) => setFormatFieldsForSelectedColumn(prev => ({ ...prev, type: e.target.value }))}
            size='small'
            sx={{
              fontSize: '14px',
              '.MuiSelect-select': {
                pt: '10px'
              }
            }}
          >
            {
              DATE_FORMAT?.map((format, index) =>
                <MenuItem value={format} key={index} sx={{ fontSize: '12px', py: '2px' }}>{dayjs().format(format)}</MenuItem>
              )
            }
          </Select>
        </Box>
      </>
    )
  }

  function renderTextFormatFields() {
    return <></>
  }

  const showFormatFieldsBasedOnDisplayFormat = (displayFormat) => {
    switch (displayFormat) {
      case 'Currency':
        return renderCurrencyFormatFields()
      case 'Percentage':
        return renderPercentageFormatFields()
      case 'Number':
        return renderNumberFormatFields()
      case 'Date':
        return renderDateFormatFields()
      case 'Text':
        return renderTextFormatFields()
      default:
        return <></>
    }
  }

  const handleFormatSaveClick = () => {
    // find the index of current selected format column in selected column values array
    const selectedColumnIndex = selectedColumnValues?.findIndex(columnObj => columnObj?.field === selectedColumnForFormat?.field)
    if (selectedColumnIndex > -1) {
      const modifiedSelectedColumnValues = selectedColumnValues.map((columnObj, index) => {
        if (index === selectedColumnIndex) {
          return { ...columnObj, formatObj: { ...formatFieldsForSelectedColumn }, displayType: selectedDisplayFormat, displayHeaderName: modifiedColumnName }
        } else {
          return columnObj
        }
      })
      setSelectedColumnValues(modifiedSelectedColumnValues)
      dispatch(addChartValues({ datasets: modifiedSelectedColumnValues, chartIndex: activeChart?.chartIndex, pageIndex: activeChart?.pageIndex, clearTableRows: true, dataSource: activeChart?.value?.dataSource }))
    }
  }

  const handleResetAllButtonClick = () => {
    const selectedColumnIndex = selectedColumnValues?.findIndex(columnObj => columnObj?.field === selectedColumnForFormat?.field)
    if (selectedColumnIndex > -1) {
      const modifiedSelectedColumnValues = selectedColumnValues.map((columnObj, index) => {
        if (index === selectedColumnIndex) {
          return {
            ...columnObj,
            formatObj: { ...DEFAULT_FORMAT_FIELDS[selectedColumnForFormat?.defaultDisplayType], aggregateFunction: columnObj?.headerType === DATA_TYPE.NUMBER ? 'sum' : 'blank' },
            displayType: selectedColumnForFormat?.defaultDisplayType,
            displayHeaderName: columnObj?.headerName
          }
        } else {
          return columnObj
        }
      })
      setModifiedColumnName(selectedColumnForFormat?.headerName)
      setSelectedColumnValues(modifiedSelectedColumnValues)
      setSelectedDisplayFormat(selectedColumnForFormat?.defaultDisplayType)
      setFormatFieldsForSelectedColumn({ ...DEFAULT_FORMAT_FIELDS[selectedColumnForFormat?.defaultDisplayType], aggregateFunction: selectedColumnForFormat?.headerType === DATA_TYPE.NUMBER ? 'sum' : 'blank' })
      dispatch(addChartValues({ datasets: modifiedSelectedColumnValues, chartIndex: activeChart?.chartIndex, pageIndex: activeChart?.pageIndex, clearTableRows: true, dataSource: activeChart?.value?.dataSource }))
    }
  }

  const handleOperatorChange = (e, filterIndex, columnIndex) => {
    const newFilters = filtersPerColumn?.map((colObj, colIndex) => {
      return {
        ...colObj,
        filters: colIndex === columnIndex ? colObj?.filters?.map((filterObj, index) => {
          if (filterIndex === index) {
            const newFilterObject = {
              ...filterObj, operator: e.target.value
            }
            // operator is changed to between than change value as object with from and to keys
            if (e.target.value === 'Between') {
              newFilterObject.value = {
                from: '',
                to: ''
              }
            } else {
              newFilterObject.value = ''
            }
            return newFilterObject
          } else
            return filterObj
        }) : colObj?.filters
      }
    })
    setFiltersPerColumn(newFilters)
  }

  const handleFilterInputKeyDown = (e, colObj) => {
    if (colObj?.headerType === DATA_TYPE.NUMBER) {
      return isNumberWithSign(e)
    }
    else {
      return true
    }
  }

  const handleAddMoreClick = (e, index) => {
    const newFilters = filtersPerColumn?.map((colObj, colIndex) => {
      return {
        ...colObj,
        logicalOperator: colObj?.logicalOperator || 'AND',
        filters: colIndex === index ? [
          ...colObj?.filters, {
            operator: 'Equals',
            value: ''
          }] : colObj?.filters
      }
    })
    setFiltersPerColumn(newFilters)
  }

  const handleRemoveFilter = (filterIndex, colIndex) => {
    const newFilters = filtersPerColumn?.map((obj, index) => ({
      ...obj,
      filters: colIndex === index ? obj?.filters?.filter((_, i) => (i !== filterIndex)) : obj?.filters,
      logicalOperator: obj?.filters?.length > 2 ? obj.logicalOperator : 'AND'
    }))
    setFiltersPerColumn(newFilters)
  }

  const handleFilterValueChange = (value, columnIndex, filterIndex) => {
    const newFilters = filtersPerColumn?.map((colObj, colIndex) => ({
      ...colObj,
      filters: colIndex === columnIndex ? colObj?.filters?.map((filterObj, index) => index === filterIndex ? ({ ...filterObj, value }) : filterObj) : colObj?.filters
    }))
    setFiltersPerColumn(newFilters)
  }

  const handleFiltersApplyClick = (e) => {
    const modifiedSelectedColumnValues = selectedColumnValues.map((colObj, index) => {
      return {
        ...colObj, filterObj: {
          ...filtersPerColumn[index], filters: filtersPerColumn[index]?.filters?.map(filter => ({
            ...filter, value:
              filter?.operator !== 'Between' ?
                colObj?.headerType === DATA_TYPE.NUMBER && filter?.value !== '' ?
                  Number(filter?.value)
                  : filter?.value
                : colObj?.headerType === DATA_TYPE.NUMBER && filter?.value?.from !== '' && filter?.value?.to !== '' ?
                  { from: Number(filter?.value?.from), to: Number(filter?.value?.to) }
                  : filter?.value
          }))
        }
      }
    })
    setSelectedColumnValues(modifiedSelectedColumnValues)
    dispatch(addChartValues({ datasets: modifiedSelectedColumnValues, chartIndex: activeChart?.chartIndex, pageIndex: activeChart?.pageIndex, filterType: filterType, clearTableRows: true, dataSource: activeChart?.value?.dataSource }))
  }

  const handleFilterResetClick = (e) => {
    const modifiedSelectedColumnValues = selectedColumnValues.map((colObj, index) => ({
      ...colObj,
      filterObj: {
        logicalOperator: '',
        filters: [{
          operator: 'Equals',
          value: colObj?.headerType !== DATA_TYPE.DATE ? '' : null
        }]
      }
    }))
    dispatch(addChartValues({ datasets: modifiedSelectedColumnValues, chartIndex: activeChart?.chartIndex, pageIndex: activeChart?.pageIndex, filterType: 'exclude', clearTableRows: true, dataSource: activeChart?.value?.dataSource }))
  }

  const handleFilterTypeChange = (e) => {
    setFilterType(e.target.value)
    dispatch(addChartValues({ filterType: e.target.value, chartIndex: activeChart?.chartIndex, pageIndex: activeChart?.pageIndex, clearTableRows: true, dataSource: activeChart?.value?.dataSource }))
  }

  const handleFormatColumnChange = (e, value) => {
    setSelectedColumnForFormat(value)
    if (value?.displayType)
      setSelectedDisplayFormat(value?.displayType)
    else
      setSelectedDisplayFormat(value?.defaultDisplayType)
    if (value?.formatObj)
      setFormatFieldsForSelectedColumn(value?.formatObj)
    else
      setFormatFieldsForSelectedColumn(DEFAULT_FORMAT_FIELDS[value?.defaultDisplayType])
    if (value?.displayHeaderName !== undefined && value?.displayHeaderName !== null)
      setModifiedColumnName(value?.displayHeaderName)
  }

  const handleAggregateFunctionChange = (e) => {
    setFormatFieldsForSelectedColumn(prev => ({ ...prev, aggregateFunction: e.target.value }))
  }

  const handleChangeShowOverflow = (e) => {
    dispatch(addChartValues({ chartIndex: activeChart?.chartIndex, pageIndex: activeChart?.pageIndex, showOverflow: e.target.checked }))
    if (e.target.checked) {
      if (isResponseElements) {
        dispatch(queueChange({ queueAction: 'push', item: activeChart?.id }))
      }
      dispatch(changeCustomTableOverflow({ pageIndex: activeChart?.pageIndex, overflowId: activeChart?.id }))
    } else {
      dispatch(changeCustomTableOverflow({ pageIndex: activeChart?.pageIndex, overflowId: undefined }))
    }
    setShowOverflow(e.target.checked)
  }

  const renderTabPanel = (item) => {
    switch (item) {
      case 'Table':
        return (
          <>
            {/* <Box sx={{ mt: '10px' }}>
							<Typography sx={{ fontSize: '12px', color: '#74788D', fontWeight: 600 }}>
								Type of Data
							</Typography>
							<Autocomplete
								disablePortal
								disabled={!customTableMetadata?.length}
								value={dataSourceValue}
								onChange={(e, value) => handleSourceChange(e, value)}
								ListboxProps={{ sx: { fontSize: 12, py: 1 } }}
								options={customTableMetadata?.map(source => source?.componentName.split('-')?.map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' '))}
								isOptionEqualToValue={(option, value) => option === value}
								size='small'
								sx={{
									'& .MuiInputBase-input': {
										fontSize: '12px'
									}
								}}
								renderInput={(params) => <TextField {...params} />}
							/>
						</Box> */}
            <Box sx={{ display: 'flex', alignItems: 'center', mt: '10px' }}>
              <CustomCheckbox
                labelId={'show-overflow'}
                checked={showOverflow}
                onChange={handleChangeShowOverflow}
                disabled={pages[activeChart?.pageIndex]?.overflowId !== undefined && pages[activeChart?.pageIndex]?.overflowId !== null && pages[activeChart?.pageIndex]?.overflowId !== activeChart?.id}
              />
              <Typography sx={{ fontSize: '12px', fontWeight: 600, color: '#74788d' }}>show overflow</Typography>
            </Box>
            {
              availableGroups?.length
                ? (
                  <Box sx={{ mt: '10px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: '12px', color: '#74788d', fontWeight: 600 }}>
                        Groupings
                      </Typography>
                      <Box>
                        {
                          selectedGroups.length > 0 ? (
                            <IconButton
                              sx={{ p: '4px' }}
                              onClick={() => handleEditColumnClick(selectedColumnValues[0])}
                            >
                              <EditOutlined style={{ fontSize: '14px', color: '#9496a7' }} />
                            </IconButton>
                          ) : ''
                        }
                        <IconButton
                          onClick={(e) => setShowPopupModal('Groupings')}>
                          <Add fontSize='small' sx={{ color: '#3A76D4' }} />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box>
                      {
                        selectedGroups.map((selectedOption, index) => renderSelectedOptions(selectedOption, index, true))
                      }
                    </Box>
                  </Box>
                )
                : ''
            }
            <Box sx={{ mt: '10px' }}>
              <Box
                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '12px', color: '#74788D', fontWeight: 600 }}>
                  Columns
                </Typography>
                <IconButton onClick={(e) => setShowPopupModal('Columns')}>
                  <Add fontSize='small' sx={{ color: '#3A76D4' }} />
                </IconButton>
              </Box >
              {
                selectedColumnValues.map((selectedOption, index) => selectedOption.field !== 'groupName' ? renderSelectedOptions(selectedOption, index, false, (option) => option.headerName) : '')
              }
            </Box>

          </>
        )
      case 'Format':
        return (
          <>
            <Box sx={{ mt: '10px' }}>
              <Typography sx={{ fontSize: '12px', color: '#74788D', fontWeight: 600 }}>
                Select Column
              </Typography>
              <Autocomplete
                filterSelectedOptions
                disabled={!selectedColumnValues?.length}
                value={selectedColumnForFormat}
                onChange={(e, value) => handleFormatColumnChange(e, value)}
                ListboxProps={{ sx: { fontSize: 12, py: 1 } }}
                getOptionLabel={(option) => option?.headerName}
                isOptionEqualToValue={(option, value) => option.field === value.field}
                options={selectedColumnValues}
                size='small'
                sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '12px'
                  }
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
            {
              selectedColumnForFormat !== null
                ? <>
                  <Box sx={{ mt: '10px' }}>
                    <Typography sx={{ fontSize: '12px', color: '#74788D', fontWeight: 600 }}>
                      Column Name
                    </Typography>
                    <TextField
                      size='small'
                      variant='standard'
                      type='text'
                      fullWidth
                      value={modifiedColumnName}
                      sx={{
                        '.MuiInputBase-root': {
                          fontSize: '14px'
                        },
                        mt: '5px'
                      }}
                      onChange={(event) => setModifiedColumnName(event.target.value?.trim())}
                    />
                  </Box>
                  {
                    selectedColumnForFormat?.field !== 'groupName' ? (
                      <>
                        <Box sx={{ mt: '10px' }}>
                          <Typography sx={{ fontSize: '12px', color: '#74788D', fontWeight: 600 }}>
                            Display Format
                          </Typography>
                          <Autocomplete
                            filterSelectedOptions
                            value={selectedDisplayFormat}
                            onChange={handleChangeDisplayFormatType}
                            ListboxProps={{ sx: { fontSize: 12, py: 1 } }}
                            options={DISPLAY_DATA_TYPE[selectedColumnForFormat?.headerType]}
                            size='small'
                            sx={{
                              '& .MuiInputBase-input': {
                                fontSize: '12px'
                              }
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Box>
                        {showFormatFieldsBasedOnDisplayFormat(selectedDisplayFormat)}
                        {
                          selectedGroups?.length > 0 ?
                            <Box
                              sx={{
                                mt: '10px',
                              }}>
                              <Typography sx={{ fontSize: '12px', color: '#74788D', fontWeight: 600 }}>Aggregate Function</Typography>
                              <Select
                                fullWidth
                                value={formatFieldsForSelectedColumn?.aggregateFunction}
                                onChange={(e) => handleAggregateFunctionChange(e)}
                                IconComponent={ExpandMore}
                                size='small'
                                sx={{
                                  fontSize: '12px',
                                  '.MuiSelect-select': {
                                    pt: '10px',
                                    color: '#34475A'
                                  }
                                }}
                              >
                                {
                                  AGGREGATE_FUNCTIONS[selectedColumnForFormat?.headerType]?.map((aggFunction, index) => (
                                    <MenuItem value={aggFunction} key={index} sx={{ fontSize: '12px', py: '4px' }}>{aggFunction}</MenuItem>
                                  ))
                                }
                              </Select>
                            </Box> : ''
                        }
                      </>)
                      : ''
                  }
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: '10px',
                      alignItems: 'center'
                    }}
                  >
                    <Button
                      onClick={handleResetAllButtonClick}
                      size='small'
                    // sx={{ textTransform: 'capitalize' }}
                    >
                      Reset All
                    </Button>
                    <Button
                      onClick={handleFormatSaveClick}
                      size='small'
                      variant='contained'
                    // sx={{ textTransform: 'capitalize' }}
                    >
                      Apply
                    </Button>
                  </Box>
                </>
                : ''
            }
          </>
        )
      case 'Filter':
        const hasOnlyGroupColumn = selectedGroups.length && selectedColumnValues.length === 1
        return (
          <>
            <Box sx={{ mt: '10px' }}>
              {
                <>
                  {
                    selectedGroups.length > 0 ? (
                      <FormControl>
                        <RadioGroup
                          row
                          value={filterType}
                          onChange={(e) => handleFilterTypeChange(e)}
                        >
                          <FormControlLabel
                            value='include'
                            control={<Radio size='small' />}
                            label={
                              <Typography sx={{ fontSize: '12px', color: '#34475A', fontWeight: '400' }}>
                                Include
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value='show'
                            control={<Radio size='small' />}
                            label={
                              <Typography sx={{ fontSize: '12px', color: '#34475A', fontWeight: '400' }}>
                                Show Only
                              </Typography>
                            }
                          />
                          {/* <FormControlLabel
                        value='combineAsOther'
                        control={<Radio size='small' />}
                        label={
                          <Typography sx={{ fontSize: '12px', color: '#34475A', fontWeight: '400' }}>
                            Combine
                          </Typography>
                        }
                      /> */}
                        </RadioGroup>
                      </FormControl>
                    ) : ''
                  }
                </>
              }
              {
                selectedColumnValues?.map((colObj, colIndex) => (
                  colObj.field !== 'groupName' ?
                    <Box key={colIndex} sx={{ mt: '10px' }}>
                      <Accordion
                        sx={{
                          boxShadow: 'none'
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          sx={{
                            boxShadow: 'none',
                            fontSize: '14px',
                            padding: 0,
                            minHeight: '36px',
                            '&.Mui-expanded': {
                              minHeight: '36px',
                            },
                            '.MuiAccordionSummary-content': {
                              margin: 0,
                            },
                            '.MuiAccordionSummary-content.Mui-expanded': {
                              margin: 0
                            }
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <Typography sx={{ fontSize: '14px', color: '#34475A' }}>{colObj?.headerName}</Typography>
                            {
                              filtersPerColumn[colIndex]?.filters?.some(filter => ((filter.operator === 'Blank' || filter.operator === 'Not blank') || filter.value !== '') && filter.value !== null) ? (
                                <FilterList sx={{ fontSize: '14px' }} />
                              )
                                : null
                            }
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            p: 0
                          }}
                        >
                          {
                            filtersPerColumn[colIndex]?.filters?.length > 1 ? (
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography sx={{ fontSize: '12px', color: '#34475A', fontWeight: '600', display: 'inline-block' }}>Operation: </Typography>
                                <FormControl sx={{ width: '100%' }}>
                                  <RadioGroup
                                    row
                                    name='row-radio-buttons-group'
                                    value={filtersPerColumn[colIndex].logicalOperator}
                                    onChange={(e) => setFiltersPerColumn(filtersPerColumn?.map((obj, i) => (i === colIndex ? { ...obj, logicalOperator: e.target.value } : { ...obj })))}
                                  >
                                    <FormControlLabel
                                      value='AND'
                                      control={<Radio size='small' />}
                                      sx={{
                                        ml: 0, mr: 0
                                      }}
                                      label={<Typography sx={{ fontSize: '12px', color: '#34475A', fontWeight: '400' }}>
                                        AND
                                      </Typography>}
                                    />
                                    <FormControlLabel
                                      value='OR'
                                      sx={{
                                        ml: 0, mr: 0
                                      }}
                                      control={<Radio size='small' />}
                                      label={<Typography sx={{ fontSize: '12px', color: '#34475A', fontWeight: '400' }}>
                                        OR
                                      </Typography>}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Box>
                            )
                              : ''
                          }
                          {
                            filtersPerColumn[colIndex]?.filters?.map((filterObj, filterIndex) => (
                              <>
                                <Box sx={{ mt: '10px' }} key={filterIndex}>
                                  {
                                    // remove filter on click of delete icon
                                    filtersPerColumn[colIndex]?.filters.length > 1 ? (
                                      <Box
                                        onClick={(e) => handleRemoveFilter(filterIndex, colIndex)}
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'flex-end',
                                          mb: '5px',
                                          cursor: 'pointer'
                                        }}>
                                        <BsTrash fontSize='14px' />
                                      </Box>
                                    )
                                      : ''
                                  }
                                  <Select
                                    key={filterIndex}
                                    fullWidth
                                    value={filterObj?.operator || 'Equals'}
                                    onChange={(e) => handleOperatorChange(e, filterIndex, colIndex)}
                                    IconComponent={ExpandMore}
                                    size='small'
                                    sx={{
                                      fontSize: '14px',
                                      '.MuiSelect-select': {
                                        pt: '10px',
                                        color: '#34475A'
                                      }
                                    }}
                                  >
                                    {
                                      TYPE_WISE_FILTERS[colObj?.headerType]?.map((operator, index) => (
                                        <MenuItem value={operator} key={index} sx={{ fontSize: '12px', py: '4px' }}>{operator}</MenuItem>
                                      ))
                                    }
                                  </Select>
                                  {
                                    colObj?.headerType !== DATA_TYPE.DATE ?
                                      filterObj?.operator === 'Between' ? (
                                        <>
                                          <TextFieldHelperComponent
                                            value={filterObj?.value?.from}
                                            onKeyDown={(e) => handleFilterInputKeyDown(e, colObj)}
                                            onChange={(e) => handleFilterValueChange({ ...filterObj?.value, from: colObj?.headerType === DATA_TYPE.NUMBER ? e.target.value !== '' ? Number(e.target.value) : e.target.value : '' }, colIndex, filterIndex)}
                                            placeholder={'From'}
                                          />
                                          <TextFieldHelperComponent
                                            value={filterObj?.value?.to}
                                            onKeyDown={(e) => handleFilterInputKeyDown(e, colObj)}
                                            onChange={(e) => handleFilterValueChange({ ...filterObj?.value, to: colObj?.headerType === DATA_TYPE.NUMBER ? e.target.value !== '' ? Number(e.target.value) : e.target.value : '' }, colIndex, filterIndex)}
                                            placeholder={'To'}
                                          />
                                        </>
                                      ) : filterObj?.operator !== 'Blank' && filterObj?.operator !== 'Not blank' ? (
                                        <TextFieldHelperComponent
                                          value={filterObj?.value}
                                          onKeyDown={(e) => handleFilterInputKeyDown(e, colObj)}
                                          onChange={(e) => handleFilterValueChange(e.target.value, colIndex, filterIndex)}
                                          placeholder={'Filter...'}
                                        />
                                      ) : ''
                                      : (
                                        filterObj?.operator === 'Between' ? (
                                          <>
                                            <DatePickerHelperComponent value={filterObj?.value?.from || null} onChange={(newValue) => handleFilterValueChange({ ...filterObj?.value, from: newValue }, colIndex, filterIndex)} />
                                            <DatePickerHelperComponent value={filterObj?.value?.to || null} onChange={(newValue) => handleFilterValueChange({ ...filterObj?.value, to: newValue }, colIndex, filterIndex)} />
                                          </>
                                        ) : filterObj?.operator !== 'Blank' && filterObj?.operator !== 'Not blank' ? (
                                          <DatePickerHelperComponent value={filterObj?.value || null} onChange={(newValue) => handleFilterValueChange(newValue, colIndex, filterIndex)} />
                                        ) : ''
                                      )
                                  }
                                </Box>
                              </>
                            ))
                          }
                          <Button
                            sx={{
                              mt: '5px', fontSize: '10px', color: '#3A76D4', textDecoration: 'underLine', cursor: 'pointer', float: 'inline-end'
                            }}
                            onClick={(e) => handleAddMoreClick(e, colIndex)}
                          >
                            + Add More
                          </Button>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                    : ''
                ))
              }
              {
                hasOnlyGroupColumn ? (
                  ''
                ) : (
                  selectedColumnValues?.length > 0 ? (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: '5px' }}>
                      <Button
                        variant='text'
                        size='small'
                        onClick={(e) => handleFilterResetClick(e)}
                      >
                        Reset All
                      </Button>
                      <Button
                        variant='contained'
                        size='small'
                        onClick={(e) => handleFiltersApplyClick(e)}
                      >
                        Apply
                      </Button>
                    </Box>
                  ) : ''
                )
              }
            </Box >
          </>
        )
      default:
        return <></>
    }
  }

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue)
  }

  return (
    <>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={(e, newValue) => handleTabChange(e, newValue)}
            variant='standard'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#34475A'
              }
            }}
            indicatorColor='#34475A'
            sx={{
              p: 0,
              height: '35px',
              minHeight: '40px'
            }}
          >
            {
              customTableTabs.map((item, index) => (
                <CustomTab key={index} value={index?.toString()} label={item} />
              ))
            }
          </Tabs>
        </Box>
        {customTableTabs.map((item, index) => (
          <TabPanel
            key={index}
            index={index}
            value={index?.toString()}
            sx={{ padding: 0, position: 'relative' }}
          >
            {renderTabPanel(item)}
          </TabPanel>
        ))}
      </TabContext>
      {showPopupModal &&
        <Modal
          onClose={() => setShowPopupModal(false)}
          open={Boolean(showPopupModal)}
          sx={{
            '.MuiModal-backdrop': {
              backgroundColor: 'transparent'
            }
          }}
        >
          <Box sx={{ ...columnSelectionModalStyles }}>
            {
              showPopupModal === 'Columns'
                ? (
                  <ColumnSelectionPopup
                    value={selectedColumnValues}
                    isOptionEqualToValue={(option, value) => option.field === value?.field}
                    getOptionLabel={(option) => option?.headerName}
                    onConfirm={handleInputColumnChange}
                    onCancel={() => setShowPopupModal(false)}
                    options={availableColumns}
                  />
                )
                : showPopupModal === 'Groupings' ?
                  (
                    <ColumnSelectionPopup
                      value={selectedGroups}
                      onConfirm={handleGroupInputChange}
                      onCancel={() => setShowPopupModal(false)}
                      options={availableGroups}
                    />
                  ) : ''
            }
          </Box>
        </Modal>
      }
    </>
  )
}

const TextFieldHelperComponent = (props) => {
  const { value, onKeyDown, onChange, placeholder } = props
  return (
    <TextField
      value={value}
      onKeyDown={onKeyDown}
      onChange={onChange}
      variant='outlined'
      size='small'
      fullWidth
      placeholder={placeholder}
      InputProps={{
        endAdornment: <InputAdornment position='end'>
          <img src={searchIcon} alt='search' style={{ height: 16, display: 'flex', color: '#4d68eb' }} />
        </InputAdornment>
      }}
      sx={{
        '.MuiInputBase-root': {
          fontSize: '14px'
        },
        marginTop: '10px'
      }}
    />
  )
}

const DatePickerHelperComponent = (props) => {
  const { value, onChange } = props
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          onChange={onChange}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
            textField: { size: 'small' }
          }}
          format='YYYY-MM-DD'
          sx={{
            '.MuiInputBase-root': {
              fontSize: '14px'
            },
            marginTop: '10px'
          }}
        />
      </LocalizationProvider>
    </>
  )
}

const WholeNumberInputField = (props) => {
  const { value, handleOnChange } = props
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      mt: '10px'
    }}>
      <Typography sx={{ fontSize: '12px' }}>Decimal Places</Typography>
      <Select
        variant='filled'
        value={value}
        onChange={handleOnChange}
        size='small'
        sx={{
          fontSize: '14px',
          '.MuiSelect-select': {
            pt: '10px'
          }
        }}
      >
        {
          Array.from({ length: 11 }).map((_, index) =>
            <MenuItem key={index} value={index} sx={{ fontSize: '12px', py: '2px', justifyContent: 'center' }}>{index}</MenuItem>
          )
        }
      </Select>
    </Box>
  )
}

const SearchContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  width: '100%',
  border: '1px solid #74788d'
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1), // equivalent to 0px 8px 0px 8px
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  height: '30px',
  '& .MuiInputBase-input': {
    padding: '4px 4px 4px 0px',
    paddingLeft: `calc(16px + ${theme.spacing(2)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    fontSize: '12px'
  }
}))

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 14,
  height: 14,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5'
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)'
  }
}))

const CheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 14,
    height: 14,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""'
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3'
  }
})

function CustomCheckbox(props) {
  const { checked, labelId, onChange, disabled } = props
  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      sx={{
        p: '4px',
        '&:hover': { bgcolor: 'transparent' }
      }}
      disableRipple
      color='default'
      checkedIcon={<CheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': labelId }}
      disabled={disabled}
    />
  )
}

const ColumnSelectionPopup = (props) => {
  const { options, value, onConfirm, onCancel, getOptionLabel = (option) => option, isOptionEqualToValue = (option, value) => option === value } = props
  const [availableOptions, setAvailableOptions] = useState(options?.filter(option => value?.findIndex(obj => isOptionEqualToValue(option, obj)) === -1))
  const [optionsToShow, setOptionsToShow] = useState(options?.filter(option => value?.findIndex(obj => isOptionEqualToValue(option, obj)) === -1))
  const [selectedItems, setSelectedItems] = useState([])
  const [searchInputText, setSearchInputText] = useState('')

  // filter available options with value and show options which are not in the value
  const handleToggle = (e, value) => {
    const currentIndex = selectedItems?.findIndex(obj => isOptionEqualToValue(obj, value))
    if (currentIndex === -1) {
      setSelectedItems([...selectedItems, value])
    } else {
      setSelectedItems(selectedItems?.filter((_, index) => index !== currentIndex))
    }
  }

  const handleCancelClick = (e) => {
    onCancel()
  }

  const handleConfirmClick = (e) => {
    // update the options to show on popup and send new created array back to the parent component
    const newArray = [...value, ...selectedItems]
    setOptionsToShow(availableOptions?.filter(option => newArray?.findIndex(obj => isOptionEqualToValue(option, obj)) === -1))
    onConfirm(newArray)
    setSelectedItems([])
    setSearchInputText('')
  }

  const handleSelectAll = (e) => {
    setSelectedItems([...optionsToShow])
  }

  const handleClearAll = (e) => {
    setSelectedItems([])
  }

  const handleSearchInputChange = (e) => {
    setSearchInputText(e.target.value)
    if (e.target.value !== '') { setOptionsToShow(availableOptions?.filter(option => getOptionLabel(option)?.toLowerCase()?.includes(e.target.value?.toLowerCase()))) } else { setOptionsToShow(options?.filter(option => value?.findIndex(obj => isOptionEqualToValue(option, obj)) === -1)) }
  }

  return (
    <>
      <SearchContainer>
        <SearchIconWrapper>
          <img src={searchIcon} alt='search' style={{ height: 16, display: 'flex', color: '#4d68eb' }} />
        </SearchIconWrapper>
        <StyledInputBase
          value={searchInputText}
          onChange={handleSearchInputChange}
          placeholder='Search…'
          inputProps={{ 'aria-label': 'search' }}
        />
      </SearchContainer>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pt: '4px',
        pb: '8px'
      }}
      >
        <Button
          size='small'
          sx={{ fontSize: '12px' }}
          onClick={handleSelectAll}
        >
          Select All
        </Button>
        <Button
          size='small'
          sx={{ fontSize: '12px' }}
          onClick={handleClearAll}
        >
          Clear
        </Button>
      </Box>
      <List dense sx={{ width: '100%', overflowY: 'auto', scrollbarWidth: 'none', height: '200px', pt: 0, pb: 0 }}>
        {
          optionsToShow.length === 0 ?
            <Typography sx={{ textAlign: 'center', fontSize: '14px', color: '#74788d' }}>No Columns</Typography>
            : optionsToShow.map((option, index) => {
              const labelId = `checkbox-list-label-${option?.field || option}`
              return (
                <ListItem
                  key={index}
                  disablePadding
                >
                  <ListItemButton
                    dense
                    onClick={(e) => handleToggle(e, option)}
                    sx={{ px: '8px' }}
                  >
                    <ListItemIcon sx={{ minWidth: '35px' }}>
                      <CustomCheckbox checked={selectedItems?.findIndex(row => isOptionEqualToValue(row, option)) !== -1} labelId={labelId} />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={<Typography sx={{ fontSize: '12px' }}>{getOptionLabel(option)}</Typography>} />
                  </ListItemButton>
                </ListItem>
              )
            })
        }
      </List>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pb: '4px',
        pt: '8px'
      }}
      >
        <Button
          size='small'
          sx={{ fontSize: '12px' }}
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
        <Button
          size='small'
          variant='contained'
          onClick={handleConfirmClick}
          sx={{ fontSize: '12px' }}
          disabled={selectedItems?.length === 0}
        >
          Confirm
        </Button>
      </Box>
    </>
  )
}

export default CommonTableEdit