import React from 'react'
import { useNavigate } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Tooltip, Typography } from '@mui/material'
import { formatCurrency } from '../../../utils/FormateCurrenyInMilion'

const GroupCard = ({ borderColor, groupCode, groupDesc, aggGroupId, aggGroupName, totalMarketValue, accounts, index, setSelectedGroup, selectedCardFlag }) => {
  // const [isViewDetailsClicked, setIsViewDetailsClicked] = useState(false)
  // const [anchorEl, setAnchorEl] = useState(false)
  const navigate=useNavigate()
  const handleViewDetails = () => {
    // setIsViewDetailsClicked((prevIsViewDetailsClicked) => {
    //   const updatedValue = groupCode !== 'IPS' ? !prevIsViewDetailsClicked : false
    //   onViewDetails(updatedValue)
    //   return updatedValue
    // })
    if (groupCode === 'ENTITYMAP') {
      navigate(`/aggregate/entity-mapping/${aggGroupId}`) 
    }
    else {
      setSelectedGroup({ index: index, type: groupDesc || groupCode, id: aggGroupId, name: aggGroupName })
    }
  }

  // const handleEditIconClick = (e) => {
  //   setAnchorEl(e.currentTarget)
  //   e.stopPropagation()
  // }

  // const handleOptionClick = (e) => {
  //   setAnchorEl(false)
  //   e.stopPropagation()
  // }

  const renderAccountDataChips = () => {
    const elements = []
    for (let i = 0; i < accounts?.length; i++) {
      const amount = accounts[i].accountMarketValue
      elements.push(
        <Box key={i} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '10px'}}>
          <Tooltip placement='top' title={accounts[i]?.accountName !== 'undefined' ? accounts[i].accountName : ''}>
            <Typography sx={{ fontSize: '11px', color: '#34475A', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{accounts[i].accountName}</Typography>
          </Tooltip>
          <Tooltip placement='top' title={amount !== 'undefined' ? (amount >= 0 ? '$' + amount?.toFixed(2) : '-$' + Math.abs(amount)?.toFixed(2)) : ''}>
            <Typography sx={{ fontSize: '11px', color: '#34475A' }}>{amount !== 'undefined' ? amount >= 0 ? '$'+formatCurrency(amount) : '-$'+formatCurrency(amount) : 'NA'}</Typography>
          </Tooltip>
        </Box>
      )
    }
    return elements
  }

  return (
    <>
      <Box mb={2}>
        <Accordion
          // className={`${selectedCardFlag ? 'move-to-top' : ''}`}
          sx={{
            top: selectedCardFlag ? `${(index - 1) * 80}px` : '',
            '.MuiAccordionSummary-expandIconWrapper': {
              display: 'none'
            },
            borderLeft: `5px solid ${borderColor}`,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            sx={{ height: '70px' }}
          >
            <Box sx={{ display:'flex', width: '100%', flexDirection: 'column' }}>
              <Box
                display='flex' justifyContent='space-between' width='100%'
              >
                {/* {data
                  ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <Typography sx={{ color: '#002A59', fontFamily: 'Open Sans', fontSize: '21px', fontWeight: 400 }}>{data.name}</Typography>
                      {!data.totalAccount
                        ? (
                          <>
                            <EditIcon onClick={handleEditIconClick} />
                            <Menu
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                              }}
                              open={anchorEl}
                              onClose={handleOptionClick}
                            >
                              <MenuItem onClick={handleOptionClick}>Link from existing account</MenuItem>
                              <MenuItem onClick={handleOptionClick}>Create new account</MenuItem>
                            </Menu>
                          </>
                          )
                        : ''}
                    </Box>
                    )
                  : <Typography sx={{ color: '#002A59', fontFamily: 'Open Sans', fontSize: '16px', fontWeight: 400 }}>{groupDesc || groupCode}</Typography>} */}
                <Typography sx={{ color: '#002A59', fontFamily: 'Open Sans', fontSize: '16px', fontWeight: 400 }}>{groupDesc ? groupDesc : groupCode}</Typography>
                {/* <Typography sx={{ color: '#3B79BF', fontFamily: 'Open Sans', fontSize: '16px', fontWeight: 600 }}>{data ? data.amount : totalMarketValue !== null ? totalMarketValue >= 0 ? '$' + totalMarketValue?.toFixed(0) : '-$' + totalMarketValue?.toFixed(0) : 'NA'}</Typography> */}
                <Typography sx={{ color: '#3B79BF', fontFamily: 'Open Sans', fontSize: '16px', fontWeight: 600 }}>{totalMarketValue !== null ? totalMarketValue >= 0 ? '$' + formatCurrency(totalMarketValue) : '-$' + formatCurrency(totalMarketValue) : 'NA'}</Typography>
              </Box>
              {/* {data
                ? (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography sx={{ fontSize: '12px', color: '#727474' }}>{data.totalAccount ? data.totalAccount + ' Accounts' : data.strategy}</Typography>
                    <Typography sx={{ fontSize: '12px', color: '#727474' }}>{data.percentage}</Typography>
                  </Box>
                  )
                : <Typography sx={{ fontSize: '12px', color: '#727474', textAlign: 'left', mx: '8px' }}>{aggGroupName}</Typography>} */}
              <Typography sx={{ fontSize: '12px', color: '#727474', textAlign: 'left' }}>{aggGroupName}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {/* <Box display='flex' justifyContent='space-between'>
                <Typography sx={{ color: '#727474', fontFamily: 'Open Sans', fontSize: '12px', fontWeight: 400, marginTop: '-32px' }}>{strategy}</Typography>
                <Typography sx={{ color: '#727474', fontFamily: 'Open Sans', fontSize: '12px', fontWeight: 400, marginTop: '-32px' }}>{indexPercentage}</Typography>
              </Box> */}
              <Stack direction='column' spacing={1}>
                {renderAccountDataChips()}
              </Stack>
              <Button onClick={handleViewDetails} variant='contained' sx={{ marginTop: '5px', width: '100%', textTransform: 'capitalize' }} disabled={selectedCardFlag}>View Details</Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  )
}

export default GroupCard
