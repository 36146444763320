export const formatCurrencyWithSymbol = (amount, decimal, symbol) => {
  const absAmount = Math.abs(Number(amount))
  const sign = Math.sign(amount) === -1 ? '-' : ''
  if (absAmount >= 1.0e9) {
    return sign + symbol + (absAmount / 1.0e9).toFixed(decimal) + 'B'
  } else if (absAmount >= 1.0e6) {
    return sign + symbol + (absAmount / 1.0e6).toFixed(decimal) + 'M'
  } else if (absAmount >= 1.0e3) {
    return sign + symbol + (absAmount / 1.0e3).toFixed(decimal) + 'K'
  } else if (absAmount === 0) {
    return symbol + (absAmount).toFixed(decimal)
  } else {
    return sign + symbol + absAmount.toFixed(decimal)
  }
}

export const formatCurrency = (amount, decimal) => {
  const absAmount = Math.abs(Number(amount))
  const sign = Math.sign(amount) === -1 ? '-' : ''
  if (absAmount >= 1.0e9) {
    return sign + (absAmount / 1.0e9).toFixed(decimal) + 'B'
  } else if (absAmount >= 1.0e6) {
    return sign + (absAmount / 1.0e6).toFixed(decimal) + 'M'
  } else if (absAmount >= 1.0e3) {
    return sign + (absAmount / 1.0e3).toFixed(decimal) + 'K'
  } else if (absAmount === 0) {
    return (absAmount).toFixed(decimal)
  } else {
    return sign + absAmount.toFixed(decimal)
  }
}

export const decTwoPercentage = (string, decimal) => {
  return (Number(string) * 100).toFixed(decimal).toString()
}
export const decTwoPercentageBeta = (string, decimal) => {
  return (Number(string)).toFixed(decimal).toString()
}

export const decTwoPlaces = (string, decimal) => {
  return (Number(string)).toFixed(decimal).toString()
}

export const capitalized = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const formatNumbersWithSymbol = (amount, decimal, symbol) => {
  const sign = Math.sign(amount) === -1 ? '-' : ''
  const absAmount = Math.abs(Number(amount))

  return sign + symbol + absAmount.toLocaleString('en-Us', { maximumFractionDigits: decimal })
}

export const formatNumbers = (amount, decimal, symbol) => {
  const absAmount = Math.abs(Number(amount))

  return symbol + absAmount.toLocaleString('en-Us', { maximumFractionDigits: decimal })
}
