export const tradeModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 4,
  py: 2,
  width: '100%',
  minWidth: '600px',
  maxWidth: '1140px',
  borderRadius: '4px',
  outline: 'none',
  '.MuiDataGrid-root': {
    border: 'none'
  },
  '.MuiDataGrid-main': {
    // remove overflow hidden overwise sticky does not work
    overflow: 'unset'
  },
  '.MuiDataGrid-columnHeaders': {
    position: 'sticky',
    top: '-4px',
    zIndex: 99,
    background: 'white'
  },
  '.MuiDataGrid-virtualScroller': {
    // remove the space left for the header
    marginTop: '-4px!important'
  }
}
