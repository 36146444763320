import { Card, CardContent, Box } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'

export const SampleTaxlotTable = () => {
  const columns = [
    { field: 'name', headerName: 'Name', flex: 2 },
    { field: 'symbol', headerName: 'Symbol', flex: 1 },
    { field: 'shares', headerName: 'Shares', flex: 1, type: 'number' },
    { field: 'purchaseCost', headerName: 'Total Purchase Cost', flex: 1, type: 'number' },
    { field: 'purchaseDate', headerName: 'Purchase Date', flex: 1, type: 'number' },
    { field: 'isoCountryCode', headerName: 'ISO Country Name', flex: 1 }
  ]

  const rows = [
    { id: 1, name: 'Apple Private Limited', symbol: 'APPL', shares: '1000.005', purchaseCost: '$230,359', purchaseDate: '20221201', isoCountryCode: 'US' },
    { id: 2, name: 'Investo defensive', symbol: 'ABC', shares: '100.005', purchaseCost: '$230,359', purchaseDate: '20221129', isoCountryCode: 'US' },
    { id: 3, name: 'Equity ETF', symbol: 'DEF', shares: '1000.005', purchaseCost: '$230,359', purchaseDate: '20221030', isoCountryCode: 'US' },
    { id: 4, name: 'Agilent', symbol: 'XYZ', shares: '1000.005', purchaseCost: '$230,359', purchaseDate: '20220104', isoCountryCode: 'US' },
    { id: 5, name: 'Technologies Inc', symbol: 'A', shares: '989', purchaseCost: '$230,359', purchaseDate: '20220805', isoCountryCode: 'US' }
  ]

  return (
    <Card className='card-layout'>
      <CardContent>
        <Box
          sx={{
            width: '100%',
            height: 350
          }}
        >
          <DataGridPro
            rows={rows}
            columns={columns}
            hideFooter
            sx={{
              border: 'none',
              fontFamily: 'Open Sans',
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 600,
                fontFamily: 'Open Sans',
                color: '#34475A'
              },
              '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                fontWeight: 600,
                fontFamily: 'Open Sans',
                color: '#34475A'
              }
            }}
          />
        </Box>
      </CardContent>
    </Card>
  )
}
