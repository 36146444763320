import { FormatDateAPI, FormatPayload } from '../components/FormatAPIRequest'
import { CreateAPI } from '../components/Actions'
import { getUserRole } from '../../../utils/getUserRole'

export const CreateAccountData = async (newRow, tabIndex) => {
  switch (tabIndex) {
    case 'external-gl':
      return await CreateAccountExternalGL(newRow)
    case 'internal-gl':
      return await CreateAccountInternalGL(newRow)
    case 'parameters':
      return await CreateAccountParameters(newRow)
    case 'portfolio-specification':
      return await CreatePortSpec(newRow)
    case 'portfolio-specification-properties':
      return await CreatePortSpecProperties(newRow)
    case 'substitution':
      return await CreateAccountSubstitution(newRow)
    case 'restriction':
      return await CreateAccountRestriction(newRow)
    case 'tax-rate':
      return await CreateTaxRates(newRow)
    case 'properties':
      return await CreateAccountProperties(newRow)
    case 'account-optimization-status':
      return await CreateAccountOptimizationStatus(newRow)
    default:
      return {}
  }
}

const CreateAccountInternalGL = async (newRow) => {
  const userRole = getUserRole()
  const gainDateValue = newRow.gainDate ? FormatDateAPI(newRow.gainDate) : null

  let payload = {
    ytdRealLongGains: newRow?.ytdRealLongGains,
    ytdRealShortGains: newRow?.ytdRealShortGains,
    gainDate: gainDateValue
  }
  payload = FormatPayload(payload)
  return CreateAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/${newRow?.accountId}/int-gl`,
    payload
  )
}

const CreateAccountExternalGL = async (newRow) => {
  const userRole = getUserRole()
  const startDateValue = newRow.startDate ? FormatDateAPI(newRow.startDate) : null
  const endDateValue = newRow.endDate ? FormatDateAPI(newRow.endDate) : null

  let payload = {
    taxYear: newRow?.taxYear,
    ytdRealLongGains: newRow?.ytdRealLongGains,
    ytdRealShortGains: newRow?.ytdRealShortGains,
    startDate: startDateValue,
    endDate: endDateValue
  }
  payload = FormatPayload(payload)
  return CreateAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/${newRow?.accountId}/external-gain-loss`,
    payload
  )
}

// const CreateAccountCashManagement = async (newRow) => {
//   const userRole = getUserRole()
//   const withdrawalStartDate = newRow.withdrawalStartDate ? FormatDateAPI(newRow.withdrawalStartDate) : null
//   const withdrawalEndDate = newRow.withdrawalEndDate ? FormatDateAPI(newRow.withdrawalEndDate) : null
//   let payload = {
//     needCashWithdrawal: newRow?.needCashWithdrawal === 1,
//     cashWithdrawalAmount: newRow?.cashWithdrawalAmount,
//     withdrawalFrequency: newRow?.withdrawalFrequency,
//     withdrawalStartDate,
//     withdrawalEndDate
//   }
//   payload = FormatPayload(payload)
//   return CreateAPI(
//     'baseAccountURL',
//     `data-maintenance/v1/${userRole}/accounts/${newRow?.accountId}/cash-management`,
//     payload
//   )
// }

const CreateAccountParameters = async (newRow) => {
  const userRole = getUserRole()
  const startDateValue = newRow.startDate ? FormatDateAPI(newRow.startDate) : null
  const endDateValue = newRow.endDate ? FormatDateAPI(newRow.endDate) : null

  let payload = {
    accountType: newRow?.accountType?.toUpperCase(),
    fundingSource: newRow?.fundingSource?.toUpperCase(),
    sellLogicId: newRow?.sellLogicId,
    isTaxable: newRow?.isTaxable === 1,
    custodianId: newRow?.custodianId,
    faId: newRow?.faId,
    secondaryFaId: newRow?.secondaryFaId,
    brokerId: newRow?.brokerId,
    cashInstrId: newRow?.cashInstrId,
    pctCashCushion: newRow?.pctCashCushion,
    portSpecId: newRow?.portSpecId,
    maxShortTermGain: newRow?.maxShortTermGain,
    maxLongTermGain: newRow?.maxLongTermGain,
    startDate: startDateValue,
    endDate: endDateValue
  }

  payload = FormatPayload(payload)
  return CreateAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/${newRow?.accountId}/parameter`,
    payload
  )
}

const CreatePortSpec = async (newRow) => {
  const userRole = getUserRole()
  const startDateValue = newRow.startDate ? FormatDateAPI(newRow.startDate) : null
  const endDateValue = newRow.endDate ? FormatDateAPI(newRow.endDate) : null

  let payload = {
    portSpecName: newRow?.portSpecName,
    portSpecCode: newRow?.portSpecCode?.toUpperCase(),
    strategyId: newRow?.strategyId,
    sponsorId: newRow?.sponsorId,
    startDate: startDateValue,
    endDate: endDateValue
  }
  payload = FormatPayload(payload)
  return CreateAPI(
    'baseAccountPortSpecURL',
    `data-maintenance/v1/${userRole}/accounts/port-spec`,
    payload
  )
}

const CreatePortSpecProperties = async (newRow) => {
  const userRole = getUserRole()
  const startDateValue = newRow.startDate ? FormatDateAPI(newRow.startDate) : null
  const endDateValue = newRow.endDate ? FormatDateAPI(newRow.endDate) : null

  let payload = {
    portSpecId: newRow?.portSpecId,
    propertyCode: newRow?.propertyCode?.toUpperCase(),
    propertyValue: newRow?.propertyValue,
    startDate: startDateValue,
    endDate: endDateValue
  }
  payload = FormatPayload(payload)
  return CreateAPI(
    'baseAccountPortSpecURL',
    `data-maintenance/v1/${userRole}/portfolio-specifications/properties`,
    payload
  )
}

const CreateAccountRestriction = async (newRow) => {
  const userRole = getUserRole()
  const startDateValue = newRow.startDate ? FormatDateAPI(newRow.startDate) : null
  const endDateValue = newRow.endDate ? FormatDateAPI(newRow.endDate) : null

  let payload = {
    instrIdData: newRow?.instrId,
    restrictionId: newRow?.restrictionId,
    weight: newRow?.weight,
    startDate: startDateValue,
    endDate: endDateValue
  }
  payload = FormatPayload(payload)
  return CreateAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/${newRow?.accountId}/instr-restriction`,
    payload
  )
}

const CreateAccountSubstitution = async (newRow) => {
  const userRole = getUserRole()
  const startDateValue = newRow.startDate ? FormatDateAPI(newRow.startDate) : null
  const endDateValue = newRow.endDate ? FormatDateAPI(newRow.endDate) : null

  let payload = {
    sourceInstrIdData: newRow?.sourceInstrId,
    targetInstrIdData: newRow?.targetInstrId,
    startDate: startDateValue,
    endDate: endDateValue
  }
  payload = FormatPayload(payload)
  return CreateAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/${newRow?.accountId}/instr-substitution`,
    payload
  )
}

const CreateTaxRates = async (newRow) => {
  const userRole = getUserRole()
  let payload = {
    currentTaxYear: newRow?.currentTaxYear,
    taxSensitivity: newRow?.taxSensitivity,
    stateId: newRow?.stateId,
    stateLongTax: newRow?.stateLongTax,
    stateMedTax: newRow?.stateMedTax,
    stateShortTax: newRow?.stateShortTax,
    fedLongTax: newRow?.fedLongTax,
    fedMedTax: newRow?.fedMedTax,
    fedShortTax: newRow?.fedShortTax
  }
  payload = FormatPayload(payload)
  return CreateAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/${newRow?.accountId}/tax-rates`,
    payload
  )
}

const CreateAccountProperties = async (newRow) => {
  const userRole = getUserRole()
  const startDateValue = newRow.startDate ? FormatDateAPI(newRow.startDate) : null
  const endDateValue = newRow?.endDate ? FormatDateAPI(newRow?.endDate) : null

  let payload = {
    accountId: newRow?.accountId,
    propertyCode: newRow?.propertyCode?.toUpperCase(),
    propertyValue: newRow?.propertyValue,
    startDate: startDateValue,
    endDate: endDateValue
  }
  payload = FormatPayload(payload)
  return CreateAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/properties`,
    payload
  )
}

const CreateAccountOptimizationStatus = async (newRow) => {
  const userRole = getUserRole()
  let payload = {
    optStatusCode: newRow?.optStatusCode?.toUpperCase(),
    remarks: newRow?.remarks
  }
  payload = FormatPayload(payload)
  return CreateAPI(
    'baseUriOptimizationDataMaintenance',
    `data-maintenance/v1/${userRole}/optimizations/account-opt-status/${newRow?.accountId}`,
    payload
  )
}
