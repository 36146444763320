
import { Card, CardContent, Grid, Typography } from '@mui/material'
import Slider from 'rc-slider'
import TotalPortfolioRiskIcon from '../../assets/images/icons/total-portfolio-risk.svg'
import TargetPortfolioRiskIcon from '../../assets/images/icons/target-portfolio-risk.svg'
import ActiveWeightIcon from '../../assets/images/icons/active-weight.svg'
import TrackingErrorIcon from '../../assets/images/icons/tracking-error.svg'
import StatsWidget from '../../components/StatsWidget'

export const RiskAttributes = ({ riskAttri, riskStrAttri }) => {
  return (
    <>
      <Grid container mb={4} spacing={3}>
        <Grid item xs={12} lg={7}>
          <Grid container spacing={4} pr='3px'>
            <Grid item xs={6}>
              <StatsWidget
                title='Total Portfolio Risk'
                stats={
                  riskAttri.PORT_RISK_STD === undefined
                    ? 'N/A'
                    : (Number(riskAttri.PORT_RISK_STD) * 100)
                      .toFixed(2)
                      .toString() + '%'
                }
                imgSrc={TotalPortfolioRiskIcon}
              />
            </Grid>
            <Grid item xs={6}>
              <StatsWidget
                title='Target Portfolio Risk'
                stats={
                  riskStrAttri.PORT_RISK_STD === undefined
                    ? 'N/A'
                    : (Number(riskStrAttri.PORT_RISK_STD) * 100)
                      .toFixed(2)
                      .toString() + '%'
                }
                imgSrc={TargetPortfolioRiskIcon}
              />
            </Grid>
            <Grid item xs={6}>
              <StatsWidget
                title='Active Weight'
                stats={
                  riskAttri.AA_DRIFT === undefined
                    ? 'N/A'
                    : (Number(riskAttri.AA_DRIFT) * 100).toFixed(2).toString() +
                    '%'
                }
                imgSrc={ActiveWeightIcon}
              />
            </Grid>
            <Grid item xs={6}>
              <StatsWidget
                title='Tracking Error'
                stats={
                  riskAttri.PORT_TRACKING_ERROR === undefined
                    ? 'N/A'
                    : (Number(riskAttri.PORT_TRACKING_ERROR) * 100)
                      .toFixed(2)
                      .toString() + '%'
                }
                imgSrc={TrackingErrorIcon}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={5} mb={{ xs: 4, lg: 0 }}>
          <Card sx={{ textAlign: 'center', height: '100%', mt: { xs: 4, lg: 0 } }} className='slider-card'>
            <CardContent sx={{ position: 'relative' }}>
              <Typography className='text-title'>
                Relative Volatility:{' '}
                {riskAttri.PORT_REL_VOL === undefined
                  ? (
                      '0'
                    )
                  : (
                    <>{Number(riskAttri.PORT_REL_VOL).toFixed(2)}</>
                    )}
              </Typography>
              <span
                style={{
                  position: 'absolute',
                  top: '60px',
                  left: '0%',
                  fontWeight: 500,
                  fontSize: 'px',
                  lineHeight: '34px'
                }}
              >
                0
              </span>
              <span
                style={{
                  position: 'absolute',
                  top: '60px',
                  right: '0%',
                  fontWeight: 500,
                  fontSize: 'px',
                  lineHeight: '34px'
                }}
              >
                2
              </span>
              <span
                style={{
                  position: 'absolute',
                  top: '60px',
                  right: '40%',
                  fontWeight: 500,
                  fontSize: 'px',
                  lineHeight: '34px'
                }}
              />
              <Slider
                min={0}
                max={2}
                step={0.01}
                included={false}
                draggableTrack={false}
                disabled
                value={Number(riskAttri.PORT_REL_VOL).toFixed(2)}
                handleStyle={{ opacity: 1 }}
                dotStyle={{ display: 'none' }}
                railStyle={{ visibility: 'hidden' }}
                tipFormatter={(value) => `$${value}`}
                tipProps={{
                  placement: 'top',
                  visible: true
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
