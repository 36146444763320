import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import '../assets/styles/FallbackUi.css'

function ErrorFallback ({ resetErrorBoundary, screen, header }) {
  const location = useLocation()
  const errorLocation = useRef(location.pathname)
  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      resetErrorBoundary()
    }
  }, [location.pathname])

  return (
    <Box className={screen === 'main' ? 'error-main-card' : 'error-card'}>
      <Card sx={{ height: '100%' }}>
        <CardContent>
          {header ? <Typography className='text-title'>{header}</Typography> : ''}
          <Typography className='error-title'>
            <br />Something went wrong :(
          </Typography>
          <Typography className='error-subtitle'>
            Please
            <Button onClick={resetErrorBoundary}>Retry</Button> after sometime
          </Typography>
        </CardContent>
      </Card>
    </Box>
  )
}

export default ErrorFallback
