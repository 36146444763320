import { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { TradePopupChart } from './TradePopupChart'

const TradeMarketCapChart = ({ selectedScenario, marketCapObj }) => {
  const marketCapLabels = marketCapObj?.marketCapLabels || []
  const [marketCapData, setMarketCapData] = useState([])

  const marketCapLegend = []

  useEffect(() => {
    let count = 0
    const backgroundColor = ['#4B8CCA', '#4B8CCACC', '#4B8CCA80', '#4B8CCA4D']
    const marketData = marketCapObj?.marketCapData?.map((data) => {
      return data.label === selectedScenario ? { ...data, backgroundColor: '#34C38F' } : { ...data, backgroundColor: backgroundColor[count++] }
    })
    setMarketCapData(marketData)
  }, [marketCapObj?.marketCapData, selectedScenario])

  if (marketCapObj.marketCapData) {
    marketCapObj.marketCapData?.forEach(md => {
      marketCapLegend.push(md.label)
    })
  }

  return (
    <>
      {marketCapData?.length
        ? <TradePopupChart
            options={{
              plugins: {
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: (context) => {
                      const label = context.dataset.label || ''
                      const value = context.dataset.data[context.dataIndex]
                      return [`${label} :  ${value.toFixed(2)}%`]
                    }
                  }
                },
                datalabels: {
                  display: false
                },
                title: {
                  display: true
                }
              },
              responsive: true,
              maintainAspectRatio: false,
              devicePixelRatio: 3,
              interaction: {
                mode: 'index',
                intersect: false
              },
              scales: {
                x: {
                  grid: {
                    display: false
                  }
                },
                y: {
                  display: true,
                  grid: {
                    display: false
                  }
                }
              }
            }}
            data={{
              labels: marketCapLabels,
              datasets: marketCapData
            }}
          />
        : (
          <Typography className='no-data-text' my={2} textAlign='left'>
            No marketcap data available.
          </Typography>
          )}
    </>
  )
}

export default TradeMarketCapChart
