import { randomId } from '@mui/x-data-grid-generator'
import { decTwoPercentage } from './components/dataProcess/DataProcess'

export const methodologyDataProcess = (response) => {
  const groupedData = response?.data?.data?.reduce((result, item) => {
    const { pageSequence, pageName, sectionSequence, sectionName, longText } = item
    if (!result[pageSequence]) {
      result[pageSequence] = { pageName, sections: [] }
    }
    result[pageSequence].sections.push({ sectionSequence, sectionName, longText })
    return result
  }, {})

  for (const pageSequence in groupedData) {
    groupedData[pageSequence].sections.sort((a, b) => a.sectionSequence - b.sectionSequence)
  }

  return groupedData
}

export const tradeListDataProcess = (response) => {
  const tradeListData = response?.data?.map((item, index) => {
    const result = {
      id: index + 1,
      localSymbol: item.localSymbol,
      securityName: item.securityName
    }
    item.scenarios.forEach(scenario => {
      result[scenario.scenarioCode] = scenario.trdCode
    })
    return result
  })

  const fieldLabels = Object.entries(response?.fieldLabels)
    .map(([field, headerName]) => (
      {
        field,
        headerName: headerName.charAt(0).toUpperCase() + headerName.slice(1).toLowerCase(),
        flex: headerName === 'Security' ? 3 : 1
      }
    ))
  return { tradeListData, fieldLabels }
}

// data processing for industry data
export const industryDataProcess = (response) => {
  const fieldLabels = Object.entries(response?.fieldLabels)
    .map(([field, headerName]) => ({
      field,
      headerName,
      flex: 1
    }))
    // filtering fieldLabels for industry data
    .filter(({ field }) => ['assetClassDesc', 'industryId', 'relative', 'min', 'max', 'sectorId', 'industryExclude'].includes(field))
  // setting the values of industry data
  const industryData = response?.data?.map((item, index) => {
    const industryExcludeLabel = item.industryExclude === 1 ? 'Yes' : 'No'
    const relativeLabel = item.relative === 1 ? 'Yes' : 'No'
    const minLabel = item.min === null ? '-' : item.min
    const maxLabel = item.max === null ? '-' : item.max
    return {
      id: index + 1,
      assetClassDesc: item.assetClassDesc,
      relative: relativeLabel,
      industryId: item.industryId,
      min: minLabel,
      max: maxLabel,
      sectorId: item.sectorId,
      industryExclude: industryExcludeLabel
    }
  })

  return { industryData, fieldLabels }
}

// data processing for sector data
export const sectorDataProcess = (response) => {
  const fieldLabels = Object.entries(response?.fieldLabels)
    .map(([field, headerName]) => ({
      field,
      headerName,
      flex: 1
    }))
    // filtering fieldLabels for sector data
    .filter(({ field }) => ['assetClassDesc', 'relative', 'sectorExclude', 'min', 'max'].includes(field))

  // setting the values of sector data
  const sectorData = response?.data?.map((item, index) => {
    const sectorExcludeLabel = item.sectorExclude === 1 ? 'Yes' : 'No'
    const relativeLabel = item?.relative === 1 ? 'Yes' : 'No'
    const minLabel = item.min === null ? '-' : item.min
    const maxLabel = item.max === null ? '-' : item.max
    return {
      id: index + 1,
      assetClassDesc: item?.assetClassDesc,
      relative: relativeLabel,
      sectorExclude: sectorExcludeLabel,
      min: minLabel,
      max: maxLabel
    }
  })

  return { sectorData, fieldLabels }
}

export const instrRestrictionDataProcess = (response) => {
  const fieldLabels = Object.entries(response?.fieldLabels)
    .map(([field, headerName]) => ({
      field,
      headerName: headerName.charAt(0).toUpperCase() + headerName.slice(1).toLowerCase(),
      flex: 1
    }))
    .filter(({ field }) => ['name', 'source', 'instrId', 'restrictionId', 'restrictionCode', 'restrictionDesc'].includes(field))

  const instrRestrictionData = response?.data?.map((item, index) => {
    return {
      id: index + 1,
      name: item.name,
      source: item.source,
      instrId: item.instrId,
      restrictionId: item.restrictionId,
      restrictionCode: item.restrictionCode,
      restrictionDesc: item.restrictionDesc
    }
  })

  return { instrRestrictionData, fieldLabels }
}

export const instrSubstitutionDataProcess = (response) => {
  const fieldLabels = Object.entries(response?.fieldLabels)
    .map(([field, headerName]) => ({
      field,
      headerName: headerName.charAt(0).toUpperCase() + headerName.slice(1).toLowerCase(),
      flex: 1
    }))
    .filter(({ field }) => ['sourceName', 'targetName', 'sourceInstrId', 'targetInstrId'].includes(field))

  const instrSubstitutionData = response?.data?.map((item, index) => {
    return {
      id: index + 1,
      sourceName: item.sourceName,
      targetName: item.targetName,
      sourceInstrId: item.sourceInstrId,
      targetInstrId: item.targetInstrId
    }
  })

  return { instrSubstitutionData, fieldLabels }
}

export const factorTiltsDataProcess = (response) => {
  const fieldLabels = Object.entries(response?.fieldLabels)
    .map(([field, headerName]) => ({
      field,
      headerName: headerName.charAt(0).toUpperCase() + headerName.slice(1).toLowerCase(),
      flex: 1
    }))
    .filter(({ field }) => ['attrLabel', 'statsValue', 'statsLevelValue'].includes(field))

  const factorTiltsData = response?.data?.map((item, index) => {
    return {
      id: index + 1,
      attrLabel: item.attrLabel,
      statsValue: item.statsValue,
      statsLevelValue: item.statsLevelValue
    }
  })
  return { factorTiltsData, fieldLabels }
}

export const constraintDataProcess = (response) => {
  const fieldLabels = [
    { field: 'description', headerName: 'Description', flex: 1 },
    { field: 'value', headerName: 'Value', flex: 1 }
  ]

  const constraintData = Object.entries(response?.fieldLabels).map(([key, value], index) => {
    const dataObj = response.data[0]
    const dataValue = ((key === 'maxShortTermGain' || key === 'maxLongTermGain') && dataObj[key] === '$0') ? 'NA' : dataObj[key]
    // Check if the value is null or undefined
    if (dataValue === null || dataValue === undefined) {
      return null // Return null to indicate that the entry should be skipped
    }

    return {
      id: index + 1,
      description: value,
      value: dataValue
    }
  })

  // Remove the null entries from the constraintData array using filter
  const filteredConstraintData = constraintData.filter((entry) => entry !== null)

  return { constraintData: filteredConstraintData, fieldLabels }
}

export const performanceDataProcess = (response) => {
  const date = []; const preTaxReturns = []; const postTaxReturns = []
  response?.data?.forEach((obj) => {
    date.push(obj?.dataDate)
    preTaxReturns.push((obj?.cumulatedPreTaxDailyReturns !== null && obj?.cumulatedPreTaxDailyReturns !== undefined) ? obj?.cumulatedPreTaxDailyReturns : null)
    postTaxReturns.push((obj?.cumulatedPostTaxDailyReturns !== null && obj?.cumulatedPostTaxDailyReturns !== undefined) ? obj?.cumulatedPostTaxDailyReturns : null)
  })
  return { date, preTaxReturns, postTaxReturns }
}

export const benchmarkDataProcess = (response) => {
  const date = []; const preTaxReturns = []; const accPreTaxReturns = []
  response?.data?.data?.forEach((obj) => {
    date.push(obj?.dataDate)
    preTaxReturns.push((obj?.strCumulatedPreTaxDailyReturns !== null && obj?.strCumulatedPreTaxDailyReturns !== undefined) ? obj?.strCumulatedPreTaxDailyReturns : null)
    accPreTaxReturns.push((obj?.accCumulatedPreTaxDailyReturns !== null && obj?.accCumulatedPreTaxDailyReturns !== undefined) ? obj?.accCumulatedPreTaxDailyReturns : null)
  })
  return {
    date, preTaxReturns, accPreTaxReturns, accountId: response?.data?.accountId, startDate: response?.data?.startDate, endDate: response?.data?.endDate, strategyList: response?.data?.strategyList
  }
}

export const assetAllocationDataProcess = (response) => {
  const marketCapColor = ['#4B8CCA', '#4B8CCACC', '#34C38F', '#4B8CCA80', '#4B8CCA4D']// ['#4B8CCA', '#4B8CCA80', '#34C38F', '#4B8CCA4D', '#4B8CCA1A']

  // Market Cap
  let marketCapLabels = []
  let marketCapData = []
  if (Array.isArray(response?.data?.marketCapAllocationData)) {
    marketCapLabels = response?.data?.marketCapAllocationData?.map(item => item.marketCapName) || []
    marketCapData = Object.keys(response?.data?.fieldLabels || {}).map((key, index) => {
      const label = response.data.fieldLabels[key] && response.data.fieldLabels[key].toLowerCase().split(' ').map(function (s) {
        return s.charAt(0).toUpperCase() + s.substring(1)
      }).join(' ')
      const backgroundColor = marketCapColor[index] || `#${Math.floor(Math.random() * 16777215).toString(16)}`
      const values = (response?.data?.marketCapAllocationData || []).map(item => parseFloat(item[key]) * 100 || 0)

      return {
        label,
        data: values,
        backgroundColor,
        stack: `Stack ${index}`,
        grid: false
      }
    })
  } else {
    marketCapData = []
  }

  // Factor
  const factorColor = ['#4B8CCA', '#4B8CCACC', '#34C38F', '#4B8CCA80', '#4B8CCA4D']// ['#4B8CCA', '#4B8CCA80', '#34C38F', '#4B8CCA4D', '#4B8CCA1A']
  let factorLabels = []
  let factorData = []
  if (Array.isArray(response?.data?.factorAllocationData)) {
    factorLabels = response?.data?.factorAllocationData?.map(item => item.factorName) || []
    factorData = Object.keys(response?.data?.fieldLabels || {}).map((key, index) => {
      const label = response?.data?.fieldLabels[key] && response.data.fieldLabels[key].toLowerCase().split(' ').map(function (s) {
        return s.charAt(0).toUpperCase() + s.substring(1)
      }).join(' ')
      const backgroundColor = factorColor[index] || `#${Math.floor(Math.random() * 16777215).toString(16)}`
      const values = (response?.data?.factorAllocationData || []).map(item => parseFloat(item[key]) || 0)

      return {
        label,
        data: values,
        backgroundColor,
        stack: `Stack ${index}`,
        grid: false
      }
    })
  } else {
    factorData = []
  }
  // Sector
  let sectorLabels = []
  let sectorData = []
  let sectorDataTableColumnMapping = {}
  if (Array.isArray(response?.data?.sectorWiseAllocationData)) {
    sectorLabels = ['Sector', ...Object.entries(response?.data?.fieldLabels || []).map(([key, value]) => {
      return key === 'portfolio'
        ? 'Portfolio (%)'
        : key === 'strategy'
          ? 'Strategy (%)'
          : String(value).toLowerCase().split(' ').map(function (s) {
            return s.charAt(0).toUpperCase() + s.substring(1)
          }).join(' ') + ' (%)'
    })]
    sectorData = (response?.data?.sectorWiseAllocationData || []).map(item => ({
      assetClassDesc: item.assetClassDesc,
      ...Object.fromEntries(Object.entries(item).filter(([key, value]) => key in response?.data?.fieldLabels).map(([key, value]) => [response?.data?.fieldLabels[key], parseFloat(value) || 0]))
    }))
    sectorDataTableColumnMapping = {
      Sector: 'assetClassDesc'
    }
    Object.entries(response?.data?.fieldLabels || []).forEach(([key, value]) => {
      if (key === 'portfolio') {
        sectorDataTableColumnMapping['Portfolio (%)'] = 'Portfolio'
      } else if (key === 'strategy') {
        sectorDataTableColumnMapping['Strategy (%)'] = response?.data?.fieldLabels[key]
      } else {
        sectorDataTableColumnMapping[`${String(value).toLowerCase().split(' ').map(function (s) {
              return s.charAt(0).toUpperCase() + s.substring(1)
            }).join(' ')} (%)`] = response?.data?.fieldLabels[key]
      }
    })
  } else {
    sectorData = []
  }

  return { marketCapData, sectorData, factorData, marketCapLabels, sectorLabels, factorLabels, sectorDataTableColumnMapping }
}

export const transitionPortfolioDataProcess = (response) => {
  if (!Object.keys(response?.data?.fieldLabels || {})?.length) {
    return { securityDataTableColumn: [], securityRows: [] }
  }
  const securityDataTableColumn = [
    { field: 'instrId', headerName: 'Security Name', flex: 1 },
    ...Object.keys(response?.data?.fieldLabels || {})?.sort()?.map((key) => {
      return {
        field: key,
        headerName: key === 'strategy'
          ? 'Strategy (%)'
          : `${response?.data?.fieldLabels[key]} (%)`,
        flex: 1,
        type: 'number',
        valueFormatter: ({ value }) => value !== null ? decTwoPercentage(value, 2) : '0.00'
      }
    })
  ]

  const securityRows = (response?.data?.accountModelPortfolioData || [])?.map((row, index) => ({
    id: randomId(),
    ...row
  }))

  return { securityDataTableColumn, securityRows }
}
