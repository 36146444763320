import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Box, Chip, Grid, Typography } from '@mui/material'

const AccountTitle = ({ title, children }) => {
  const params = useParams()
  const location = useLocation()
  const [custodianAccountNumber, setCustodianAccountNumber] = useState('')

  const datasecure = localStorage.getItem('object')
  const getStoreData = JSON.parse(datasecure)
  const filterSecuredata = getStoreData?.filter(
    (item) => item?.accountId === params?.accountId
  ) || []

  useEffect(() => {
    if (filterSecuredata && filterSecuredata[0] && filterSecuredata[0]?.custodianAccountNumber && filterSecuredata[0]?.accountType === 'TRADING') {
      setCustodianAccountNumber(` - ${filterSecuredata[0]?.custodianAccountNumber}`)
    } else {
      setCustodianAccountNumber('')
    }
  }, [filterSecuredata])

  return (
    <>
      <Grid container justifyContent='space-between' alignItems='center' mb={2}>
        <Grid item display='flex' flexDirection={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'flex-start', md: 'center' }}>
          <Typography component='div' className='page-title' sx={{ paddingTop: '0 !important' }}>{title}</Typography>
          <Box className='client' mt={{ xs: 3, md: 0 }}>
            <Box sx={{
              borderRadius: '4px',
              width: '3.05px',
              height: '53.8px',
              mx: 2,
              background: filterSecuredata[0]?.priority === 1 ? '#F05F5F' : filterSecuredata[0]?.priority === 2 ? '#169D6C' : '#EFAE3A'
            }}
            />
            <Box>
              <Typography variant='h6' display='flex' alignItems='center'>
                {filterSecuredata[0]?.accountName}
                <Chip label={filterSecuredata[0]?.accountType} size='small' sx={{ background: '#50CAF1', color: 'white', fontSize: '10px', fontWeight: '500', ml: 1, height: '16px' }} />
              </Typography>
              <Typography variant='small' lineHeight='1.25rem' fontSize='0.875rem' mr={1}> {filterSecuredata[0]?.accountCode}{custodianAccountNumber || ''}{' -> '}{filterSecuredata[0]?.portSpecName}</Typography>
              <Link to={location.pathname.replace(params.accountId, '').replace('/' + params.RequestId, '')} style={{ color: '#3A76D4' }}>                Switch Account
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid item>
          {children}
        </Grid>
      </Grid>
    </>
  )
}

export default AccountTitle