import React, { useCallback, useLayoutEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Download, Edit, Save, WorkspacesOutlined } from '@mui/icons-material'
import { IconButton, Menu, MenuItem } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import ELK from 'elkjs/lib/elk.bundled.js'
import ReactFlow, {
  ControlButton,
  Controls,
  ReactFlowProvider,
  addEdge,
  useEdgesState,
  useNodesState,
  useReactFlow
} from 'reactflow'
import 'reactflow/dist/style.css'
import clickIcon from '../../../../assets/images/icon-click.svg'
import { downloadImageFromHtml } from '../../../../utils/HTMLToImageUtil'
import BorderedNode from './nodes/BorderedNode'
import DefaultNode from './nodes/DefaultNode'
import IPSNode from './nodes/IPSNode'

const elk = new ELK()

const elkOptions = {
  'elk.algorithm': 'mrtree',
  'elk.layered.spacing.nodeNodeBetweenLayers': '100',
  'elk.spacing.nodeNode': '80'
}

const getLayoutedElements = (nodes, edges, options = {}) => {
  const isHorizontal = options?.['elk.direction'] === 'RIGHT'
  const graph = {
    id: 'root',
    layoutOptions: options,
    children: nodes.map((node) => ({
      ...node,
      // Adjust the target and source handle positions based on the layout
      // direction.
      targetPosition: isHorizontal ? 'left' : 'top',
      sourcePosition: isHorizontal ? 'right' : 'bottom',

      // Hardcode a width and height for elk to use when layouting.
      width: 250,
      height: 200
    })),
    edges
  }

  return elk
    .layout(graph)
    .then((layoutedGraph) => ({
      // nodes: layoutedGraph.children.reduce((result, current) => {
      //   result.push({
      //     ...current,
      //     position: { x: current.x, y: current.y }
      //   });

      //   (current.children || []).forEach((child) =>
      //     result.push({
      //       ...child,
      //       position: { x: child.x, y: child.y },
      //       parentNode: current.id
      //     })
      //   )
      //   return result
      // }, []),
      nodes: layoutedGraph.children.map((node) => ({
        ...node,
        // React Flow expects a position property on the node instead of `x`
        // and `y` fields.
        position: { x: node.x, y: node.y }
      })),

      edges: layoutedGraph.edges
    }))
    .catch(console.error)
}

const nodeTypes = {
  default: DefaultNode,
  account: BorderedNode,
  IPS: IPSNode
}

const defaultViewport = { x: 0, y: 0, zoom: 1.5 }

function TreeView ({ aggregateData, isInitialChart, updateChart, refreshChart }) {
  const createNodeHTML = (nodeData) => {
    // let node = {
    //   data: { label: <Box><Typography fontSize='14px'>{nodeData?.nodeInfo?.name}</Typography><Typography fontSize='14px'>{nodeData?.nodeInfo?.marketValue}</Typography></Box> }
    // }
    // if (nodeData?.nodeInfo?.type === 'group') {
    //   node = {
    //     data: { label: null }
    //   }
    // }
    // if (nodeData?.nodeInfo?.category === 'IPS') {
    //   node = {
    //     data: {
    //       label: <><Box sx={{ position: 'absolute', top: '-25px', left: 0, right: 0, display: 'flex', justifyContent: 'space-between' }}>
    //         {nodeData?.nodeInfo?.allocation}
    //         {
    //                         nodeData?.nodeInfo?.parentAccountData
    //                           ? <Box display='flex' alignItems='center'>{nodeData?.nodeInfo?.parentAccountData.map((obj) => (
    //                             <Box key={obj.id}>
    //                               <Box sx={{ bgcolor: obj.color, height: '12px', width: '12px', borderRadius: '50%', mr: '5px' }} />
    //                             </Box>
    //                           ))}
    //                           </Box>
    //                           : ''
    //                     }
    //       </Box><Box><Typography fontSize='14px'>{nodeData?.nodeInfo?.name}</Typography></Box>
    //       </>
    //     }
    //   }
    // }
    // if (nodeData?.nodeInfo?.parentData) {
    //   node = {
    //     data: {
    //       label: (
    //         <Box>
    //           <Typography fontSize='14px'>{nodeData?.nodeInfo?.name}</Typography>
    //           <Typography fontSize='14px' mb='10px'>{nodeData?.nodeInfo?.marketValue}</Typography>
    //           {
    //                             nodeData?.nodeInfo?.parentData.map((obj) => (
    //                               <Box key={obj.id} display='flex' alignItems='center' justifyContent='space-between' fontSize='12px'>
    //                                 <Box display='flex' alignItems='center'><Box sx={{ bgcolor: obj.color, height: '12px', width: '12px', borderRadius: '50%', mr: '5px' }} /> <Typography fontSize='12px' whiteSpace='nowrap'>{obj.name}</Typography></Box>
    //                                 <Typography fontSize='12px'>{obj.allocation}</Typography>
    //                               </Box>
    //                             ))
    //                         }
    //         </Box>
    //       )
    //     }
    //   }
    // }
    const node = {
      data: {
        nodeData: nodeData?.nodeInfo
      }
    }
    if (nodeData?.nodeInfo?.category === 'account') {
      node.type = 'account'
    }
    return node
  }

  const createNodes = (data) => {
    const nodes = []
    const addNode = (nodeData, parentId) => {
      const node = {
        id: nodeData.id,
        type: nodeData.category || 'default',
        data: { nodeData },
        // data: { label: <Box><Typography fontSize='14px'>{nodeData.name}</Typography><Typography fontSize='14px'>{nodeData.marketValue}</Typography></Box> },
        position: { x: 0, y: 0 },
        name: nodeData.name,
        nodeInfo: nodeData
        // parentNode: parentId
      }
      // if (nodeData.type === 'group') {
      //   node = {
      //     ...node,
      //     //   type: 'group',
      //     data: { label: null },
      //     children: nodeData.children.map((child, index) => ({
      //       id: child.id,
      //       type: 'default',
      //       parentNode: parentId,
      //       extent: 'parent',
      //       width: 150,
      //       height: 50,
      //       position: { x: 0, y: 0 },
      //       ...{
      //         ...child.parentData
      //           ? {
      //               data: {
      //                 label: (
      //                   <Box>
      //                     <Typography fontSize='14px'>{child.name}</Typography>
      //                     <Typography fontSize='14px' mb='10px'>{child.marketValue}</Typography>
      //                     {
      //                                       child.parentData.map((obj) => (
      //                                         <Box key={obj.id} display='flex' alignItems='center' justifyContent='space-between' fontSize='12px'>
      //                                           <Box display='flex' alignItems='center'><Box sx={{ bgcolor: obj.color, height: '12px', width: '12px', borderRadius: '50%', mr: '5px' }} /> <Typography fontSize='12px'>{obj.name}</Typography></Box>
      //                                           <Typography fontSize='12px'>{obj.allocation}</Typography>
      //                                         </Box>
      //                                       ))
      //                                   }
      //                   </Box>
      //                 )
      //               }
      //             }
      //           : child.category === 'IPS'
      //             ? {
      //                 data: {
      //                   label: <><Box sx={{ position: 'absolute', top: '-25px', left: 0, right: 0, display: 'flex', justifyContent: 'space-between' }}>
      //                     {child.allocation}
      //                     {
      //                                   nodeData.parentAccountData && index === 0
      //                                     ? <Box display='flex' alignItems='center'>{nodeData.parentAccountData.map((obj) => (
      //                                       <Box key={obj.id}>
      //                                         <Box sx={{ bgcolor: obj.color, height: '12px', width: '12px', borderRadius: '50%', mr: '5px' }} />
      //                                       </Box>
      //                                     ))}
      //                                       </Box>
      //                                     : ''
      //                               }
      //                   </Box><Box><Typography fontSize='14px'>{child.name}</Typography></Box>
      //                   </>
      //                 }
      //               }
      //             : {}
      //       }
      //     })),
      //     style: {
      //       width: nodeData.groupType === 'strategy' ? '200px' : nodeData.children.length * 220,
      //       height: nodeData.groupType === 'strategy' ? nodeData.children.length * 120 : '250px',
      //       backgroundColor: 'transparent',
      //       border: '4px dashed orange'
      //     }
      //   }
      // }
      // if (nodeData.category === 'IPS') {
      //   node = {
      //     ...node
      //     // parentNode: parentId,
      //     //   extent: 'parent',
      //     // data: {
      //     //   label: <><Box sx={{ position: 'absolute', top: '-25px', left: 0, right: 0, display: 'flex', justifyContent: 'space-between' }}>
      //     //     {nodeData.allocation}
      //     //     {
      //     //             nodeData.parentAccountData
      //     //               ? <Box display='flex' alignItems='center'>{nodeData.parentAccountData.map((obj) => (
      //     //                 <Box key={obj.id}>
      //     //                   <Box sx={{ bgcolor: obj.color, height: '12px', width: '12px', borderRadius: '50%', mr: '5px' }} />
      //     //                 </Box>
      //     //               ))}
      //     //               </Box>
      //     //               : ''
      //     //       }
      //     //   </Box><Box><Typography fontSize='14px'>{nodeData.name}</Typography></Box>
      //     //   </>
      //     // }
      //   }
      // }
      // if (nodeData.parentData) {
      //   node = {
      //     ...node,
      //     parentNode: parentId,
      //     extent: 'parent',
      //     data: {
      //       label: (
      //         <Box>
      //           <Typography fontSize='14px'>{nodeData.name}</Typography>
      //           <Typography fontSize='14px' mb='10px'>{nodeData.marketValue}</Typography>
      //           {
      //                       nodeData.parentData.map((obj) => (
      //                         <Box key={obj.id} display='flex' alignItems='center' justifyContent='space-between' fontSize='12px'>
      //                           <Box display='flex' alignItems='center'><Box sx={{ bgcolor: obj.color, height: '12px', width: '12px', borderRadius: '50%', mr: '5px' }} /> <Typography fontSize='12px' whiteSpace='nowrap'>{obj.name}</Typography></Box>
      //                           <Typography fontSize='12px'>{obj.allocation}</Typography>
      //                         </Box>
      //                       ))
      //                                   }
      //         </Box>
      //       )
      //     }
      //   }
      // }
      // if (nodeData.category === 'individual') {
      //   node = {
      //     ...node,
      //     style: { backgroundColor: 'transparent', color: nodeData.color, borderColor: nodeData.color }
      //   }
      // }
      nodes.push(node)

      // if (nodeData.children && nodeData.type !== 'group') {
      if (nodeData.children) {
        nodeData.children.forEach((child, index) => {
          if (nodeData.groupType === 'strategy' && index === 0 && nodeData.parentAccountData) {
            addNode({ ...child, parentAccountData: nodeData.parentAccountData }, nodeData.id)
          } else addNode(child, nodeData.id)
        })
      }
    }

    addNode(data, null)
    return nodes
  }

  const createEdges = (data) => {
    const edges = []

    const addEdges = (parentNode) => {
      if (parentNode.children) {
        const sourceId = parentNode.id
        parentNode.children.forEach((child) => {
          edges.push({
            id: `e-${sourceId}-${child.id}`,
            source: sourceId,
            target: child.id,
            style: {
              stroke: 'skyblue'
            }
          })
          if (child.parentNodes && child.category !== 'IPS') {
            child.parentNodes.forEach(node => {
              if (node.id !== parentNode.id) {
                edges.push({
                  id: `e-${node.id}-${child.id}`,
                  source: node.id,
                  target: child.id,
                  style: {
                    stroke: 'skyblue'
                  }
                })
              }
            })
          }
          addEdges(child)
        })
      }
    }

    addEdges(data)

    return edges
  }
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const { fitView } = useReactFlow()
  const [isEditMode, setIsEditMode] = useState(false)
  const navigate = useNavigate()
  const params = useParams()
  const [selectedGroups, setSelectedGroups] = useState([])

  const [groupAnchorEl, setGroupAnchorEl] = useState(null)
  const [requestAnchorEl, setRequestAnchorEl] = useState(null)
  const openGroupDropDown = Boolean(groupAnchorEl)
  const openRequestDropDown = Boolean(requestAnchorEl)
  const handleGroupClick = (event) => {
    document.body.classList.add('model-open')
    setGroupAnchorEl(event.currentTarget)
  }
  const handleGroupClose = () => {
    document.body.classList.remove('model-open')
    setGroupAnchorEl(null)
  }

  const handleRequestClick = (event) => {
    document.body.classList.add('model-open')
    setRequestAnchorEl(event.currentTarget)
  }
  const handleRequestClose = () => {
    document.body.classList.remove('model-open')
    setRequestAnchorEl(null)
  }

  const groupOptions = [
    {
      id: 1, group: 'washsales', name: 'Wash Sales', value: 'washsales'
    },
    {
      id: 2, group: 'settlement', name: 'Settlement', value: 'settlement'
    },
    {
      id: 3, group: 'asset-allocation', name: 'Asset Allocation', value: 'asset-allocation'
    },
    {
      id: 4, group: 'cash-program', name: 'Cash Program', value: 'cash-program'
    }
  ]

  const requestOptions = [
    {
      id: 1, name: 'Cash Raise', value: 'cash-raise'
    },
    {
      id: 2, name: 'Taxlot Swap', value: 'taxlot-swap'
    },
    {
      id: 3, name: 'Transition Analysis', value: 'transition-analysis'
    },
    {
      id: 4, name: 'Allocation Change', value: 'allocation-change'
    },
    {
      id: 5, name: 'Gain Analysis', value: 'gain-analysis'
    }
  ]

  const handleGroupChange = (value) => {
    if (['washsales', 'settlement'].includes(value)) {
      const nextList = selectedGroups.filter(group => group === value)?.length ? selectedGroups.filter(group => group !== value) : [value]
      setSelectedGroups(nextList)
      setNodes((nds) =>
        nds.map((node) => {
          if (nextList.length) {
            if (nextList?.some(selectedGroup => node?.nodeInfo?.group?.includes(selectedGroup))) {
              // it's important that you create a new object here
              // in order to notify react flow about the change
              node.style = { ...node.style, opacity: 1 }
            } else {
              node.style = { ...node.style, opacity: 0.2 }
            }
          } else {
            node.style = { ...node.style, opacity: 1 }
          }

          return node
        })
      )
    }
  }

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [])
  const onLayout = useCallback(
    ({ direction, useInitialNodes = false }) => {
      const initialNodes = [...aggregateData?.nodes?.map(node => ({ ...node, ...createNodeHTML(node) }))]
      const initialEdges = [
        ...aggregateData?.edges
      ]
      const opts = { 'elk.direction': direction, ...elkOptions }
      const ns = useInitialNodes
        ? initialNodes
        : nodes
      const es = useInitialNodes
        ? initialEdges
        : edges
      getLayoutedElements(ns, es, opts).then(({ nodes: layoutedNodes, edges: layoutedEdges }) => {
        setNodes(layoutedNodes)
        setEdges(layoutedEdges)
        if (isInitialChart && initialNodes.length && initialEdges?.length) {
          updateChart({ nodes: layoutedNodes, edges: layoutedEdges }, true)
        }

        window.requestAnimationFrame(() => fitView())
      })
    },
    [nodes, edges, aggregateData]
  )
  // const schema = flowJson
  // Calculate the initial layout on mount.
  useLayoutEffect(() => {
    // if (aggregateData && isInitialChart) {
    //   onLayout({ direction: 'DOWN', useInitialNodes: true })
    // } else {
    setNodes(aggregateData?.nodes?.map(node => ({ ...node, ...createNodeHTML(node) })))
    setEdges(aggregateData?.edges)
    // }
  }, [aggregateData])

  const [rfInstance, setRfInstance] = useState(null)

  // const updateAggregateTree = () => {
  //   updateChart(null, false)
  // }

  const onSave = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject()
      flow.nodes.forEach(node => {
        node.data = null
      })
      try {
        // navigator.clipboard.writeText(JSON.stringify(flow))
        // alert('Copied to clipboard!')
        // updateAggregateTree(flow)
        updateChart(flow, true)
      } catch (err) {
        console.error(
          'Unable to copy to clipboard.',
          err
        )
      } finally {
        setIsEditMode(false)
      }
    }
  }, [rfInstance])

  // by clicking on refresh icon will get latest flow chart data
  const onRefresh = useCallback(() => {
    if (rfInstance) {
      refreshChart()
    }
  }, [rfInstance])

  const onNodeClick = (node) => {
    if (node?.nodeInfo?.category?.toUpperCase() === 'IPS') {
      navigate(`/aggregate/uma-aggregate/${node?.nodeInfo?.id}`)
    } else if (node?.nodeInfo?.category?.toUpperCase() === 'ACCOUNTS' && node?.nodeInfo?.tagged) {
      navigate(`/account-review/account-dashboard/${node?.nodeInfo?.id}`)
    }
  }

  return (
    <ReactFlow
      className='dynamic-tree'
      nodes={nodes}
      edges={edges}
      onConnect={onConnect}
      onNodeClick={(event, node) => onNodeClick(node)}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodesDraggable={isEditMode}
      nodesConnectable={isEditMode}
      elementsSelectable={isEditMode}
      onInit={setRfInstance}
      nodeTypes={nodeTypes}
      fitView
    >
      <Controls showInteractive={false} position='top-right'>
        <ControlButton title='view groups'>
          <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={openGroupDropDown ? 'long-menu' : undefined}
            aria-expanded={openGroupDropDown ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleGroupClick}
          >
            <WorkspacesOutlined />
          </IconButton>
          <Menu
            id='long-menu'
            MenuListProps={{
              'aria-labelledby': 'long-button'
            }}
            anchorEl={groupAnchorEl}
            open={openGroupDropDown}
            onClose={handleGroupClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {groupOptions.map((option) => (
              <MenuItem key={option.id} selected={selectedGroups.includes(option.value)} onClick={() => handleGroupChange(option.value)}>
                {option.name}
              </MenuItem>
            ))}
          </Menu>
        </ControlButton>
        <ControlButton title='view request'>
          <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={openRequestDropDown ? 'long-menu' : undefined}
            aria-expanded={openRequestDropDown ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleRequestClick}
          >
            <img src={clickIcon} alt='' height={14} width={14} />
          </IconButton>
          <Menu
            id='long-menu'
            MenuListProps={{
              'aria-labelledby': 'long-button'
            }}
            anchorEl={requestAnchorEl}
            open={openRequestDropDown}
            onClose={handleRequestClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {requestOptions.map((option) => (
              <MenuItem key={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Menu>
        </ControlButton>
        <ControlButton onClick={() => isEditMode ? onSave() : setIsEditMode(true)}>
          {isEditMode ? <Save fontSize='14px' title='save changes' /> : <Edit fontSize='14px' title='edit chart' />}
        </ControlButton>
        <ControlButton onClick={() => onRefresh()} title='refresh chart'>
          <RefreshIcon fontSize='14px' />
        </ControlButton>
        <ControlButton onClick={() => downloadImageFromHtml('.react-flow__viewport', (nodes?.length ? nodes[0]?.name : params?.familyId), 'png')} title='download image'>
          <Download fontSize='14px' />
        </ControlButton>
      </Controls>
    </ReactFlow>
  )
}

const TreeComponent = ({ aggregateData, isInitialChart, updateChart, refreshChart }) => {
  return (
    <ReactFlowProvider>
      <TreeView aggregateData={aggregateData} isInitialChart={isInitialChart} updateChart={updateChart} refreshChart={refreshChart} />
    </ReactFlowProvider>
  )
}

export default TreeComponent
