import { useGridApiContext } from '@mui/x-data-grid-pro'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'

export default function CustomEditDateComponent (props) {
  const { id, value, field, hasFocus } = props
  const apiRef = useGridApiContext()
  const ref = useRef()
  const [selectedDateTime, setSelectedDateTime] = useState('')

  useEffect(() => {
    setSelectedDateTime(value ? new Date(value).toISOString().split('T')[0] : '')
  }, [value])

  useLayoutEffect(() => {
    if (hasFocus) {
      ref.current.focus()
    }
  }, [hasFocus])

  const handleValueChange = (event) => {
    const newValue = event.target.value // The new value entered by the user
    setSelectedDateTime(event.target.value)
    apiRef.current.setEditCellValue({ id, field, value: newValue })
  }

  return (
    <input
      ref={ref}
      type='date'
      value={selectedDateTime}
      onChange={handleValueChange}
      className='date-input'
    />
  )
}
