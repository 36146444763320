import React, { useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'

export const TradeListDataTable = (props) => {
  const [currentPage, setCurrentPage] = useState(1)

  const rightAlignedColumns = ['weight', 'instruments', 'min', 'max', 'sectorExclude', 'industryExclude', 'statsValue']

  if (props?.columns) {
    return (
      <Grid xs={12}>
        {props?.rows?.length > 0
          ? <Box>
            <DataGridPro
              density='compact'
              autoHeight
              rows={props?.rows}
              columns={props?.columns?.map((column) =>
                rightAlignedColumns.includes(column.field)
                  ? {
                      ...column,
                      headerAlign: 'right',
                      align: 'right'
                    }
                  : column
              )}
              pagination
              pageSizeOptions={[10]}
              paginationModel={{ pageSize: 10, page: currentPage }}
              onPaginationModelChange={(event) => setCurrentPage(event.page)}
              initialState={{
                pagination: { paginationModel: { pageSize: 10, page: currentPage } }
              }}
              className={
                props?.tableHeader === 'Factor Tilts' || props?.tableHeader === 'Constraint'
                  ? 'TradeList-Table-module constraintTilts'
                  : 'TradeList-Table-module'
              }
            />
            </Box>
          : <Typography sx={{ fontSize: 16, color: '#34475A', textAlign: 'center' }}>
            No {props?.tableHeader?.toLowerCase()} available.
            </Typography>}
      </Grid>
    )
  } else {
    return (
      <>
        <Typography sx={{ fontSize: 16, color: '#34475A', textAlign: 'center' }}>
          No {props?.tableHeader?.toLowerCase()} available.
        </Typography>
      </>
    )
  }
}
