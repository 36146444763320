import React, { Fragment, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogTitle, Grid, IconButton, Menu, MenuItem, DialogContent, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, GridRowModes, GridToolbarContainer } from '@mui/x-data-grid-pro'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import PreviewLeftSidebar from '../../layout/PreviewLeftSidebar'
import CreateSponsorInputFrom from './CreateSponsorInputFrom'
import PermissionTable from './PermissionTable'
import PmUserTable from './PmUserTable'
import UpdateSponsorInputForm from './UpdateSponsorInputForm'
import UserTable from './UserTable'
import './acl.css'

export const capitalizeName = (name) => {
  name = name?.toLowerCase()
  const words = name?.split(' ')
  for (let i = 0; i < words?.length; i++) {
    words[i] = words[i]?.charAt(0)?.toUpperCase() + words[i]?.slice(1)
  }
  return words?.join(' ')
}

const SponsorTable = () => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [currentPage, setCurrentPage] = useState(0)
  const [showAdminPanel, setShowAdminPanel] = useState(true)
  const [showEditTab, setShowEditTab] = useState(false)
  const [open, setOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState([])
  const [rows, setRows] = useState([])
  const [PMUserRows, setPMUserRows] = useState([])
  const [rowModesModel, setRowModesModel] = useState({})
  const [type, setType] = useState('')
  const [activeTab, setActiveTab] = useState('')
  const [userRows, setUserRows] = useState([])
  const [roleRows, setRoleRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [PMUserLoading, setPMUserLoading] = useState(true)
  const [updatedRole, setUpdatedRole] = useState('')
  const [spanLoading, setSpnLoading] = useState(false)
  const [openDeleteSpnDialog, setOpenDeleteSpnDialog] = useState(false)
  const [formEditMode, setFormEditMode] = useState('add')
  const [anchorEl, setAnchorEl] = useState(null)
  const [activeRow, setActiveRow] = useState(null)
  const [spnParams, setSpnParams] = useState({})

  useEffect(() => {
    if (user && ((Object.keys(selectedRow).length > 0 && selectedRow?.sponsorName) || updatedRole !== '')) {
      getSponsorDetails()
    }
  }, [user, selectedRow, updatedRole])

  useEffect(() => {
    if (user) {
      fetchSponsorData()
      fetchPmUsersData()
    }
  }, [user])

  const fetchSponsorData = () => {
    setLoading(true)
    API.get('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsors`)
      .then((res) => {
        if (res && res?.data) {
          setRows(res.data)
        }
      }).catch((error) => {
        setLoading(false)
        showError(error?.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
      }).finally(() => {
        setLoading(false)
      })
  }

  const fetchPmUsersData = () => {
    API.get('baseAclURL', `user-access-control/v1/${user?.userGroup}/user/pm`)
      .then((res) => {
        if (res && res?.data) {
          setPMUserRows(res.data)
        }
      })
      .catch((error) => {
        setPMUserLoading(false)
        showError(error?.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setPMUserLoading(false)
      })
  }

  const getSponsorDetails = () => {
    setSpnLoading(true)
    API.get('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor/${selectedRow?.sponsorId || spnParams?.sponsorId}`)
      .then((resData) => {
        if (resData && resData.data) {
          setUserRows(
            resData?.data?.users?.map((data) => {
              return data
            }) || []
          )
          setRoleRows(
            resData?.data?.roles?.map((data) => {
              return { ...data, id: randomId() }
            }) || []
          )
        }
      }).catch((error) => {
        setSpnLoading(false)
        showError(error?.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
      }).finally(() => {
        setSpnLoading(false)
      })
  }

  const handleRowClick = (params) => {
    if (params?.field !== 'action') {
      setShowAdminPanel(false)
      setActiveTab('')
      setType('Sponsor')
      setShowEditTab(false)
      setSelectedRow(params?.row)
    }
  }

  const columns = [
    {
      field: 'sponsorName',
      headerName: 'Sponsor Name',
      flex: 4
    },
    {
      field: 'sponsorCd',
      headerName: 'Sponsor Code',
      flex: 4
    },
    {
      field: 'primaryColor',
      headerName: 'primaryColor',
      flex: 4
    },
    {
      field: 'secondaryColor',
      headerName: 'secondaryColor',
      flex: 4
    },
    {
      field: 'brandTextColor',
      headerName: 'brandTextColor',
      flex: 4
    },
    {
      field: 'mainLightLogoUrl',
      headerName: 'mainLightLogoUrl',
      flex: 4
    },
    {
      field: 'mainDarkLogoUrl',
      headerName: 'mainDarkLogoUrl',
      flex: 4
    },
    {
      field: 'mobLightLogoUrl',
      headerName: 'mobLightLogoUrl',
      flex: 4
    },
    {
      field: 'mobDarkLogoUrl',
      headerName: 'mobDarkLogoUrl',
      flex: 4
    },
    {
      field: 'favIconUrl',
      headerName: 'favIconUrl',
      flex: 4
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 80,
      align: 'center',
      renderCell: (params) => (
        <>
          <IconButton
            aria-label='more'
            aria-haspopup='true'
            onClick={(event) => {
              setAnchorEl(event.currentTarget)
              setActiveRow(params.row)
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && activeRow?.sponsorId === params.row?.sponsorId}
            onClose={() => {
              setActiveRow(null)
              setAnchorEl(null)
            }}
            elevation={4}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            sx={{
              padding: 0,
              '.MuiMenuItem-root': {
                padding: '0 10px'
              },
              '.MuiButtonBase-root': {
                justifyContent: 'flex-start',
                textTransform: 'capitalize'
              }
            }}
          >
            <MenuItem>
              <Button
                fullWidth
                variant='text'
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  fontWeight: 600
                }}
                onClick={() => {
                  setShowAdminPanel(false)
                  setActiveTab('')
                  setAnchorEl(null)
                  setShowEditTab(true)
                  setSelectedRow(params?.row)
                }}
                disableRipple
                startIcon={
                  <ManageAccountsOutlinedIcon
                    sx={{ fontSize: '8px' }}
                  />
                }
              >Manage Sponsor Permission
              </Button>
            </MenuItem>
            <MenuItem>
              <Button
                fullWidth
                variant='text'
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  fontWeight: 600
                }}
                onClick={() => {
                  setSpnParams(params?.row)
                  // setSelectedRow(params?.row)
                  setOpen(true)
                  setAnchorEl(null)
                  setFormEditMode('edit')
                  setType('Sponsor')
                }}
                disableRipple
                startIcon={
                  <EditOutlinedIcon
                    sx={{ fontSize: '8px' }}
                  />
                }
              >
                Edit Sponsor
              </Button>
            </MenuItem>
            <MenuItem>
              <Button
                fullWidth
                sx={{
                  color: 'red',
                  fontWeight: 600
                }}
                variant='text'
                disableRipple
                onClick={() => {
                  setSpnParams(params?.row)
                  setAnchorEl(null)
                  setOpenDeleteSpnDialog(true)
                }}
                startIcon={
                  <DeleteIcon
                    sx={{ fontSize: '8px' }}
                  />
                }
              >Delete Sponsor
              </Button>
            </MenuItem>
          </Menu>
        </>
      )
    }
  ]

  const handleSpnDelete = (e, sponsorId, setIsSpnDeleteLoading) => {
    if (e.target.innerText === 'CONFIRM') {
      setIsSpnDeleteLoading(true)
      API.del('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor/${sponsorId}`)
        .then((res) => {
          if (res && res?.success) {
            setRows(rows?.filter((row) => row?.sponsorId !== sponsorId))
            showSuccess(res?.message)
          }
        }).catch((error) => {
          setIsSpnDeleteLoading(false)
          showError(error?.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
        }).finally(() => {
          setIsSpnDeleteLoading(false)
          setOpenDeleteSpnDialog(false)
          setAnchorEl(null)
        })
    }
  }

  const DeleteSpnConfirm = () => {
    const [isSpnDeleteLoading, setIsSpnDeleteLoading] = useState(false)

    return (
      <>
        <Dialog
          fullWidth
          maxWidth='xs'
          open={openDeleteSpnDialog}
          onClose={() => setOpenDeleteSpnDialog(false)}
        >
          <DialogTitle>
            {`Are you sure you want to delete ${spnParams?.sponsorName} sponsor?`}
          </DialogTitle>
          <DialogActions sx={{ marginBottom: '5px' }}>
            {isSpnDeleteLoading
              ? (
                <LoadingButton
                  loading
                  loadingPosition='start'
                  variant='outlined'
                  sx={{ width: '130px' }}
                >
                  Confirm
                </LoadingButton>
                )
              : (
                <Button variant='contained' onClick={(e) => handleSpnDelete(e, spnParams?.sponsorId, setIsSpnDeleteLoading)}>
                  Confirm
                </Button>
                )}
            <Button autoFocus onClick={() => setOpenDeleteSpnDialog(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const handleCancelClose = () => {
    setOpen(false)
  }

  // get logo details if exists
  // filter out logos in which files exits to send updated logo to s3 bucket (files will only present if logo is updated or uploaded)
  // for create and update api check getUploadedLogos length if is grater then 0 means logo exist then pass logo details in payload
  const getUploadedLogos = (values) => {
    const res = []
    if (values?.mainLightLogoUrl !== null) {
      res.push({
        logoType: 'main_light_logo',
        file: values?.mainLightLogo,
        fileName: values?.mainLightLogo.name || values?.mainLightLogo
      })
    }
    if (values?.mainDarkLogoUrl !== null) {
      res.push({
        logoType: 'main_dark_logo',
        file: values?.mainDarkLogo,
        fileName: values?.mainDarkLogo.name || values?.mainDarkLogo
      })
    }
    if (values?.mobLightLogoUrl !== null) {
      res.push({
        logoType: 'mob_light_logo',
        file: values?.mobLightLogo,
        fileName: values?.mobLightLogo.name || values?.mobLightLogo
      })
    }
    if (values?.mobDarkLogoUrl !== null) {
      res.push({
        logoType: 'mob_dark_logo',
        file: values?.mobDarkLogo,
        fileName: values?.mobDarkLogo.name || values?.mobDarkLogo
      })
    }
    if (values?.favIconUrl !== null) {
      res.push({
        logoType: 'fav_icon_logo',
        file: values?.favIcon,
        fileName: values?.favIcon.name || values?.favIcon
      })
    }

    return res
  }

  // get file for the all uploaded logos to store in s3 bucket
  const getCurrentFile = (itemKey, fetchUrl, values) => {
    let file = ''
    let logoUrl = ''
    switch (itemKey) {
      case 'main_light_logo' :
        file = values.mainLightLogo
        logoUrl = fetchUrl
        break
      case 'main_dark_logo' :
        file = values.mainDarkLogo
        logoUrl = fetchUrl
        break
      case 'mob_light_logo' :
        file = values.mobLightLogo
        logoUrl = fetchUrl
        break
      case 'mob_dark_logo' :
        file = values.mobDarkLogo
        logoUrl = fetchUrl
        break
      case 'fav_icon_logo' :
        file = values.favIcon
        logoUrl = fetchUrl
        break
      default:
    }
    return { file, logoUrl }
  }

  const handleLogoUpload = async (value, userGroup) => {
    try {
      // filter out logos in which files exits to send updated logo to s3 bucket (files will only present if logo is updated or uploaded)
      const logosTOUpload = getUploadedLogos(value)?.filter((data) => data.file instanceof File)

      // if logos with files exists (it will only consider the logos which is reupload or updated if logos are already present while updating and if not changes i will send it to s3)
      if (logosTOUpload.length > 0) {
        const res = await API.post('baseAclURL', `user-access-control/v1/${userGroup}/sponsor/logos/upload-url`, {
          body: {
            logos: logosTOUpload
          }
        })
        if (Object.keys(res?.data).length > 0) {
          const logoUrls = {}
          for (const key of Object.keys(res?.data)) {
            const item = res?.data[key]
            const formData = new FormData()
            const fileName = getCurrentFile(key, res?.data[key]?.fetchUrl, value)
            logoUrls[key] = fileName.logoUrl
            formData.append('bucket', item?.fields?.bucket)
            formData.append('key', item?.fields.key)
            formData.append('Content-Type', item?.fields ? item?.fields['Content-Type'] : '')
            formData.append('Policy', item?.fields?.Policy)
            formData.append('X-Amz-Algorithm', item?.fields ? item?.fields['X-Amz-Algorithm'] : '')
            formData.append('X-Amz-Credential', item?.fields ? item?.fields['X-Amz-Credential'] : '')
            formData.append('X-Amz-Date', item?.fields ? item?.fields['X-Amz-Date'] : '')
            formData.append('X-Amz-Security-Token', item?.fields ? item?.fields['X-Amz-Security-Token'] : '')
            formData.append('X-Amz-Signature', item?.fields ? item?.fields['X-Amz-Signature'] : '')
            formData.append('file', fileName.file)

            await fetch(item?.url, {
              method: 'POST',
              body: formData
            })
          }
          return logoUrls
        }
      }
    } catch (error) {
      showError(error?.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
      throw error
    }
  }

  const CreateSponsor = async (value, setRows, setRowModesModel, setCreateApiLoading) => {
    let logoUrls = {}
    // if logo exists in form
    if (getUploadedLogos(value)?.length > 0) {
      logoUrls = await handleLogoUpload(value, user?.userGroup)
      API.post('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor`, {
        body: {
          sponsorCd: value?.sponsorCd,
          sponsorName: value?.sponsorName,
          primaryColor: value?.primaryColor,
          secondaryColor: value?.secondaryColor,
          brandTextColor: value?.brandTextColor,
          mainLightLogoUrl: logoUrls?.main_light_logo,
          mainDarkLogoUrl: logoUrls?.main_dark_logo,
          mobLightLogoUrl: logoUrls?.mob_light_logo,
          mobDarkLogoUrl: logoUrls?.mob_dark_logo,
          favIconUrl: logoUrls?.fav_icon_logo,
          opacity: value?.opacity
        }
      })
        .then((data) => {
          if (data && data?.success) {
            setOpen(false)
            const sponsorId = randomId()
            const newRow = {
              sponsorId,
              ...{ ...(data?.data[0] ? data?.data[0] : value) },
              isNew: true
            }
            setRows((oldRows) => [...oldRows, newRow])
            setRowModesModel((oldModel) => ({
              ...oldModel,
              [sponsorId]: { mode: GridRowModes.View, fieldToFocus: 'name' }
            }))
            showSuccess(data?.message)
          }
        }).catch((error) => {
          setCreateApiLoading(false)
          showError(error?.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
        }).finally(() => setCreateApiLoading(false))
    } else {
      // if logo does not exists in form
      API.post('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor`, {
        body: {
          sponsorCd: value?.sponsorCd,
          sponsorName: value?.sponsorName,
          primaryColor: value?.primaryColor,
          secondaryColor: value?.secondaryColor,
          brandTextColor: value?.brandTextColor,
          opacity: value?.opacity
        }
      })
        .then((data) => {
          if (data && data?.success) {
            setOpen(false)
            const sponsorId = randomId()
            const newRow = {
              sponsorId,
              ...{ ...(data?.data[0] ? data?.data[0] : value) },
              isNew: true
            }
            setRows((oldRows) => [...oldRows, newRow])
            setRowModesModel((oldModel) => ({
              ...oldModel,
              [sponsorId]: { mode: GridRowModes.View, fieldToFocus: 'name' }
            }))
            showSuccess(data?.message)
          }
        }).catch((error) => {
          setCreateApiLoading(false)
          showError(error?.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
        }).finally(() => setCreateApiLoading(false))
    }
  }

  const updateSponsorLogoUrl = (value, logoUrls) => {
    const body = {
      sponsorName: value?.sponsorName,
      primaryColor: value?.primaryColor,
      secondaryColor: value?.secondaryColor,
      brandTextColor: value?.brandTextColor,
      opacity: value?.opacity,
      mainLightLogoUrl: logoUrls?.main_light_logo ? logoUrls?.main_light_logo : value?.mainLightLogoUrl,
      mainDarkLogoUrl: logoUrls?.main_dark_logo ? logoUrls?.main_dark_logo : value?.mainDarkLogoUrl,
      mobLightLogoUrl: logoUrls?.mob_light_logo ? logoUrls?.mob_light_logo : value?.mobLightLogoUrl,
      mobDarkLogoUrl: logoUrls?.mob_dark_logo ? logoUrls?.mob_dark_logo : value?.mobDarkLogoUrl,
      favIconUrl: logoUrls?.fav_icon_logo ? logoUrls?.fav_icon_logo : value?.favIconUrl
    }

    for (const key in body) {
      if (!body[key]) {
        delete body[key]
      }
    }

    return body
  }

  const UpdateSponsor = async (value, setRows, setRowModesModel, setUpdateApiLoading) => {
    let logoUrls = {}
    // if logo exists in form
    if (getUploadedLogos(value)?.length > 0) {
      logoUrls = await handleLogoUpload(value, user?.userGroup)
      API.patch('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor/${spnParams?.sponsorId}`, {
        body: updateSponsorLogoUrl(value, logoUrls)
      })
        .then((res) => {
          if (res && res?.success) {
            setOpen(false)
            const updatedRows = rows.map(row => {
              if (row.sponsorId === res?.data[0]?.sponsorId) {
                return {
                  ...row,
                  sponsorName: res?.data[0]?.sponsorName,
                  primaryColor: res?.data[0]?.primaryColor,
                  secondaryColor: res?.data[0]?.secondaryColor,
                  brandTextColor: res?.data[0]?.brandTextColor,
                  mainLightLogoUrl: res?.data[0]?.mainLightLogoUrl,
                  mainDarkLogoUrl: res?.data[0]?.mainDarkLogoUrl,
                  mobLightLogoUrl: res?.data[0]?.mobLightLogoUrl,
                  mobDarkLogoUrl: res?.data[0]?.mobDarkLogoUrl,
                  favIconUrl: res?.data[0]?.favIconUrl,
                  opacity: res?.data[0]?.opacity
                }
              }
              return row
            })
            setRows(updatedRows)
            setUpdateApiLoading(false)
            showSuccess(res?.message)
          }
        }).catch((error) => {
          setUpdateApiLoading(false)
          showError(error?.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
        }).finally(() => {
          setUpdateApiLoading(false)
          setAnchorEl(null)
        })
    } else {
      // if logo does not exists in form
      API.patch('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor/${spnParams?.sponsorId}`, {
        body: {
          sponsorName: value?.sponsorName,
          primaryColor: value?.primaryColor,
          secondaryColor: value?.secondaryColor,
          brandTextColor: value?.brandTextColor,
          opacity: value?.opacity
        }
      })
        .then((res) => {
          if (res && res?.success) {
            setOpen(false)
            const updatedRows = rows.map(row => {
              if (row.sponsorId === res?.data[0]?.sponsorId) {
                return {
                  ...row,
                  sponsorName: res?.data[0]?.sponsorName,
                  primaryColor: res?.data[0]?.primaryColor,
                  secondaryColor: res?.data[0]?.secondaryColor,
                  brandTextColor: res?.data[0]?.brandTextColor,
                  mainLightLogoUrl: res?.data[0]?.mainLightLogoUrl,
                  mainDarkLogoUrl: res?.data[0]?.mainDarkLogoUrl,
                  mobLightLogoUrl: res?.data[0]?.mobLightLogoUrl,
                  mobDarkLogoUrl: res?.data[0]?.mobDarkLogoUrl,
                  favIconUrl: res?.data[0]?.favIconUrl,
                  opacity: res?.data[0]?.opacity
                }
              }
              return row
            })
            setRows(updatedRows)
            setUpdateApiLoading(false)
            showSuccess(res?.message)
          }
        }).catch((error) => {
          setUpdateApiLoading(false)
          showError(error?.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
        }).finally(() => {
          setUpdateApiLoading(false)
          setAnchorEl(null)
        })
    }
  }

  const EditToolbar = (props) => {
    const { setRows, setRowModesModel } = props
    const [createApiLoading, setCreateApiLoading] = useState(false)
    const [updateApiLoading, setUpdateApiLoading] = useState(false)
    const [formikData, setFormikData] = useState({})
    const [prevOpen, setPrevOpen] = useState(false)

    const handleCreateSaveClose = (values) => {
      CreateSponsor(values, setRows, setRowModesModel, setCreateApiLoading)
    }

    const handleUpdateSaveClose = (values) => {
      UpdateSponsor(values, setRows, setRowModesModel, setUpdateApiLoading)
    }

    return (
      <>
        <GridToolbarContainer sx={{ marginBottom: '10px', marginTop: '10px' }}>
          <Button
            onClick={() => {
              setOpen(true)
              setFormEditMode('add')
              setType('Sponsor')
            }}
            color='primary'
            sx={{ position: 'absolute', right: '1px' }}
            startIcon={<AddIcon />}
          >
            Create Sponsor
          </Button>
          <Dialog open={open} onClose={handleCancelClose} fullWidth maxWidth='md'>
            {formEditMode === 'add'
              ? (
                <CreateSponsorInputFrom
                  savedData={handleCreateSaveClose} setPrevOpen={setPrevOpen} prevOpen={prevOpen} setFormikData={setFormikData} type={type} createApiLoading={createApiLoading} setCreateApiLoading={setCreateApiLoading}
                />
                )
              : (
                <UpdateSponsorInputForm
                  savedData={handleUpdateSaveClose} setPrevOpen={setPrevOpen} prevOpen={prevOpen} setFormikData={setFormikData} type={type} updateApiLoading={updateApiLoading} setUpdateApiLoading={setUpdateApiLoading} spnParams={spnParams}
                />
                )}
          </Dialog>
        </GridToolbarContainer>
        <Dialog
          open={prevOpen}
          fullScreen
          maxWidth='xl'
          PaperProps={{
            sx: {
              maxWidth: '1720px',
              width: '1720px',
              maxHeight: '100vh',
              height: '100vh'
            }
          }}
        >
          <IconButton
            sx={{ position: 'absolute', right: '0px', top: '0px', zIndex: 1 }}
            color='inherit'
            onClick={() => setPrevOpen(false)}
            aria-label='close'
          >
            <CancelIcon />
          </IconButton>
          <DialogContent>
            <PreviewLeftSidebar leftSidebarProps={formikData} />
          </DialogContent>
        </Dialog>

      </>
    )
  }

  const SpnLoading = () => {
    return (
      <>
        <Box className='table-responsive'>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '100vh' }}>Sponsor Name</TableCell>
                <TableCell sx={{ width: '100vh' }}>Sponsor Code</TableCell>
                <TableCell sx={{ width: '35vh' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
            [...Array(3)].map((e, i) => (
              <Fragment key={i}>
                <TableRow>
                  <TableCell>
                    <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={250} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={250} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                  </TableCell>
                </TableRow>
              </Fragment>
            ))
              }
            </TableBody>
          </Table>
        </Box>
      </>
    )
  }

  return (
    <>
      <Box className='selectListofAccount'>
        {showAdminPanel || (activeTab === 'spn-tab') || (activeTab === 'aris-user-tab')
          ? (
            <>
              <Typography
                component='h3' className='page-title' sx={{ marginBottom: '10px', marginLeft: '5px' }}
              >
                Sponsor Access Controller
              </Typography>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <Card variant='outlined'>
                    <CardContent>
                      {loading
                        ? (
                          <SpnLoading />
                          )
                        : (
                          <DataGridPro
                            autoHeight
                            rows={rows}
                            columns={columns}
                            density='compact'
                            sx={{
                              '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer'
                              }
                            }}
                            initialState={{
                              columns: {
                                columnVisibilityModel: {
                                  primaryColor: false,
                                  secondaryColor: false,
                                  brandTextColor: false,
                                  mainLightLogoUrl: false,
                                  mainDarkLogoUrl: false,
                                  mobLightLogoUrl: false,
                                  mobDarkLogoUrl: false,
                                  favIconUrl: false
                                }
                              }
                            }}
                            onCellClick={handleRowClick}
                            rowSelection={false}
                            getRowId={(row) => row?.sponsorId}
                            pageSizeOptions={[10]}
                            onPaginationModelChange={(event) => setCurrentPage(event.page)}
                            pagination
                            paginationModel={{ pageSize: 10, page: currentPage }}
                            editMode='row'
                            unstable_cellSelection={{ sponsorName: true }}
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={handleRowModesModelChange}
                            slots={{
                              toolbar: EditToolbar
                            }}
                            slotProps={{
                              toolbar: { setRows, setRowModesModel }
                            }}
                          />
                          )}
                    </CardContent>
                  </Card>
                </Grid>
                <Typography
                  component='h3' className='page-title' sx={{ marginTop: '35px', marginBottom: '3px', marginLeft: '28px' }}
                >
                  ARIS User Access Controller
                </Typography>
                <Grid item xs={12}>
                  <Card variant='outlined'>
                    <PmUserTable
                      setShowAdminPanel={setShowAdminPanel}
                      setActiveTab={setActiveTab}
                      type={type}
                      setSelectedRow={setSelectedRow}
                      setType={setType}
                      setShowEditTab={setShowEditTab}
                      PMUserRows={PMUserRows}
                      PMUserLoading={PMUserLoading}
                      selectedRow={selectedRow}
                      setPMUserRows={setPMUserRows}
                    />
                  </Card>
                </Grid>
              </Grid>
            </>
            )
          : (
              showEditTab
                ? (
                  <PermissionTable
                    type={type}
                    setActiveTab={setActiveTab}
                    setType={setType}
                    roleRows={roleRows}
                    selectedRow={selectedRow}
                  />
                  )
                : (
                  <UserTable
                    selectedRow={selectedRow}
                    activeTab={activeTab}
                    setUserRows={setUserRows}
                    setRoleRows={setRoleRows}
                    setUpdatedRole={setUpdatedRole}
                    updatedRole={updatedRole}
                    userRows={userRows}
                    roleRows={roleRows}
                    type={type}
                    setType={setType}
                    spanLoading={spanLoading}
                    setActiveTab={setActiveTab}
                  />
                  )
            )}
      </Box>
      <DeleteSpnConfirm />
    </>
  )
}

export default SponsorTable
