import React from 'react'
import { ExpandMore } from '@mui/icons-material'
import { Box, IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridFilteredDescendantCountLookupSelector, gridClasses, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro'
import { Link } from 'react-router-dom'
import redirect from '../../../assets/images/pop-out-line.svg'
import { formatNumbersWithSymbol } from '../../Report/components/dataProcess/DataProcess'

const PortfolioTable = ({ data, loading }) => {

  const treeData = []

  // group by asset class
  let groupByAssetClass = data.reduce((acc, element) => {
    const key = element?.assetClassDesc
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(element)
    return acc
  }, {})

  // for each assetClassDesc group data using acccountId
  for (let assetClassDesc in groupByAssetClass) {
    groupByAssetClass[assetClassDesc] =
      groupByAssetClass[assetClassDesc].reduce((acc, element) => {
        const key = element?.accountId
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(element)
        return acc
      }, {})
  }

  // convert grouped data to pass into tree
  for (let assetClassDesc in groupByAssetClass) {
    const categorySumUpData = {}
    if(assetClassDesc !== 'null') {
      for (let accountId in groupByAssetClass[assetClassDesc]) {
        const companySumUpData = {}
        if (accountId !== 'AGGTAG') {
          groupByAssetClass[assetClassDesc][accountId].map((data) => {
            companySumUpData.marketValue = typeof companySumUpData.marketValue !== 'undefined' ? companySumUpData.marketValue + data?.marketValue : data?.marketValue
            companySumUpData.weight = typeof companySumUpData.weight !== 'undefined' ? companySumUpData.weight + data?.weight : data?.weight
            const tempRandomId = randomId()
            treeData.push({
              id: tempRandomId,
              name: data.instrumentName,
              hierarchy: [assetClassDesc, data?.accountName + accountId, data?.instrumentName + tempRandomId],
              marketValue: data?.marketValue,
              weight: data?.weight,
              shares: data?.shares,
              purchaseDate: data?.purchaseDate
            })
          })
          treeData.push({
            id: randomId(),
            name: groupByAssetClass[assetClassDesc][accountId][0]?.accountName,
            hierarchy: [assetClassDesc, groupByAssetClass[assetClassDesc][accountId][0]?.accountName + accountId],
            accountId: accountId,
            ...companySumUpData
          })
          categorySumUpData.marketValue = typeof categorySumUpData.marketValue !== 'undefined' ? categorySumUpData.marketValue + companySumUpData.marketValue : companySumUpData.marketValue
          categorySumUpData.weight = typeof categorySumUpData.weight !== 'undefined' ? categorySumUpData.weight + companySumUpData.weight : companySumUpData.weight
        }
        else {
          // for untagged value show on 1st depth (under their respective assetClassDesc)
          groupByAssetClass[assetClassDesc][accountId].map(untaggedData => {
            const tempRandomId = randomId()
            treeData.push({
              id: tempRandomId,
              name: untaggedData?.instrumentName,
              hierarchy: [assetClassDesc, untaggedData?.instrumentName + tempRandomId],
              marketValue: untaggedData?.marketValue,
              weight: untaggedData?.weight,
              shares: untaggedData?.shares,
              purchaseDate: untaggedData?.purchaseDate
            })
            // sumup these values on assetClassDesc level not at the account level
            categorySumUpData.marketValue = typeof categorySumUpData.marketValue !== 'undefined' ? categorySumUpData.marketValue + untaggedData?.marketValue : untaggedData?.marketValue
            categorySumUpData.weight = typeof categorySumUpData.weight !== 'undefined' ? categorySumUpData.weight + untaggedData?.weight : untaggedData?.weight
          })
        }
      }
      treeData.push({
        id: randomId(),
        name: assetClassDesc,
        hierarchy: [assetClassDesc],
        ...categorySumUpData
      })
    } else {
      for (let accountId in groupByAssetClass[assetClassDesc]) {
        treeData.push({
          ...groupByAssetClass[assetClassDesc][accountId][0],
          id: randomId(),
          noHighlight: true,
          hierarchy: [groupByAssetClass[assetClassDesc][accountId][0].accountName],
          name: groupByAssetClass[assetClassDesc][accountId][0].accountName
        })
      }
    }
  }

  const columns = [
    {
      field: 'marketValue',
      headerName: 'Market Value',
      type: 'number',
      align: 'right',
      flex: 1,
      valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
      valueFormatter: (params) => typeof params?.value !== 'undefined' ? params?.value !== null ? formatNumbersWithSymbol(params?.value, 0, '$') : 'NA' : '',
    },
    {
      field: 'weight',
      headerName: 'Weight',
      type: 'number',
      align: 'right',
      flex: 1,
      valueGetter: (params) => typeof params?.row?.weight !== 'undefined' && params?.row?.weight !== null ? params?.row?.weight?.toFixed(2) : 'NA'
    },
    {
      field: 'shares',
      headerName: 'Shares',
      type: 'number',
      align: 'right',
      flex: 1,
      valueGetter: (params) => typeof params?.row?.shares !== 'undefined' ? params?.row?.shares !== null ? params?.row?.shares : 'NA' : ''
    },
    {
      field: 'purchaseDate',
      headerName: 'Purchase Date',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      valueGetter: (params) => typeof params?.row?.purchaseDate !== 'undefined' ? params?.row?.shares !== null ? params?.row?.purchaseDate?.split('T')[0] : 'NA' : ''
    },
    {
      field: ' ',
      headerName: ' ',
      width: 50,
      sortable: false,
      disableColumnMenu: true,
      align: 'right',
      renderCell: (params) => (
        params?.row?.hierarchy?.length === 2
          ? <Link to={`/account-review/account-dashboard/${params?.row?.accountId}`} style={!params?.row?.accountId ? { opacity: '0.3', pointerEvents: 'none' } : {}}>
            <img src={redirect} alt='' />
          </Link>
          : ''
      )
    }
  ]

  return (
    <>
      <Box sx={{ position: 'relative' }} className='aggregate-portfolio-table'>
        {
          loading
            ? <TableContainer mt={5}>
                <Table className='risk-page-table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      {columns.map((item, index) => {
                        return (
                          <TableCell key={index}>{item.headerName}</TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from({ length: 5 }).map((_, i) => (
                      <TableRow key={i}>
                        {Array.from({ length: columns.length }).map(_ => (
                          <TableCell>
                            <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                          </TableCell>
                          ))}
                          <TableCell><Skeleton variant='text' /></TableCell>
                      </TableRow>))}
                  </TableBody>
                </Table>
              </TableContainer>
            : <DataGridPro
                density='compact'
                treeData
                rows={treeData}
                columns={columns}
                autoHeight
                getRowId={(row) => row.id}
                disableRowSelectionOnClick
                defaultGroupingExpansionDepth={1}
                getTreeDataPath={(row) => row.hierarchy}
                groupingColDef={{
                  headerName: 'Name',
                  width: 250,
                  renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />
                }}
                localeText={{ noRowsLabel: 'No Portfolio Found' }}
                hideFooter={data?.length === 0}
                getRowClassName={(params) => params?.row?.hierarchy?.length === 1 && !params?.row?.noHighlight ? 'datagrid-main-row' : ''}
                pageSizeOptions={[10]}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } }
                }}
                pagination
                sx={(theme) => ({
                  [`.${gridClasses.main}`]: {
                    overflow: 'unset'
                  },
                  [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                    color: '#74788d',
                    fontWeight: 600
                  },
                  [`.${gridClasses.columnHeaders}`]: {
                    position: 'sticky',
                    top: '-20px',
                    zIndex: 3,
                    backgroundColor: 'white'
                  },
                  [`.${gridClasses.footerContainer}`]: {
                    position: 'sticky',
                    bottom: '-21px',
                    zIndex: 3,
                    backgroundColor: 'white'
                  },
                })}
              />
        }
      </Box>
    </>
  )
}

const CustomGridTreeDataGroupingCell = (props) => {
  const { id, field, rowNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  );
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick = (event) => {
    if (rowNode.type !== 'group') {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Box sx={{ ml: rowNode.depth * 2 }}>
      <div>
        {filteredDescendantCount > 0 && props?.row?.hierarchy?.length > 1 ? (
          <>
          <IconButton
            onClick={handleClick}
            size="small"
            tabIndex={-1}
            aria-label={rowNode.childrenExpanded ? 'Close' : 'Open'}
          >
            <ExpandMore
              sx={{
                transform: `rotateZ(${rowNode.childrenExpanded ? 360 : 270}deg)`,
                transition: (theme) =>
                  theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                  }),
              }}
              fontSize="inherit"
            />
          </IconButton>
          <span style={{marginLeft: rowNode.depth * 2}}>
            {props?.row?.name}
          </span>
          </>
        ) : (
          <span style={{ marginLeft: rowNode.depth * 8}}>{props?.row?.name}</span>
        )}
      </div>
    </Box>
  );
}

export default PortfolioTable