import { Box, Typography } from '@mui/material'
import React, { memo } from 'react'
import { Handle, Position } from 'reactflow'
import { formatCurrencyWithSymbol } from '../../../../Report/components/dataProcess/DataProcess'

export default memo(({ data, isConnectable }) => {
  return (
    <>
      <Handle
        type='target'
        position={Position.Top}
        style={{ top: '-4px', background: '#555' }}
        isConnectable={isConnectable}
      />
      <Box className='inner-box'>
        <Typography fontSize='14px'>{data?.nodeData?.name}</Typography>
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize='14px' className='row-div'>Market Value <span>{data?.nodeData?.marketValue !== undefined && data?.nodeData?.marketValue !== null ? formatCurrencyWithSymbol(data?.nodeData?.marketValue, 2, '$') : 'NA'}</span></Typography>
          {data?.nodeData?.category === 'ips' || data?.nodeData?.category === 'accounts'
            ? <Typography fontSize='14px' className='row-div'>Tracking Error <span>{data?.nodeData?.te === null || data?.nodeData?.te === undefined ? 'NA' : `${data?.nodeData?.te}%`}</span></Typography>
            : <Typography fontSize='14px' className='row-div'>Total Risk <span>{data?.nodeData?.totalRisk === null || data?.nodeData?.totalRisk === undefined ? 'NA' : `${data?.nodeData?.totalRisk}%`}</span></Typography>}
        </Box>
      </Box>
      <Handle
        type='source'
        position={Position.Bottom}
        style={{ bottom: '-4px', top: 'auto', background: '#555' }}
        isConnectable={isConnectable}
      />
    </>
  )
})
