import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import pagenotfound from '../assets/images/pagenotfound.svg'
import '../assets/styles/common.scss'
import { useEffect } from 'react'
import { useAuth } from '../contexts/AuthContext'

const PageNotFound = () => {
  const navigate = useNavigate()
  const { user, loading, curPath } = useAuth()
  const navigateToHome = () => {
    const userGroup = localStorage.getItem('userGroup')
    switch (userGroup) {
      case 'pm':
        // instead if navigating to custom default path redirect to first path accessible by the acl af page not found
        navigate(curPath)
        return
      case 'spn-pm':
        navigate('/book-of-business')
        return
      case 'adv-classic':
        navigate(curPath)
        return
      case 'admin':
        navigate('/admin')
        return
      default:
        return '/'
    }
  }

  useEffect(() => {
    if (!user && !loading) {
      navigate('/login')
    }
  }, [user, loading])

  return (
    <Box className='Pagenotfound'>
      <Box>
        <img src={pagenotfound} />
        <Typography sx={{ color: '#2F2F2F', fontSize: 30, fontFamily: 'Open Sans', fontWeight: '600', wordWrap: 'break-word' }}>
          Page Not Found
        </Typography>
        <Box variant='p' sx={{ color: '#727474', textAlign: 'center' }}>
          <Typography>The page you are looking for doesn't exist.</Typography>
          <Typography>Please check the URL and try again.</Typography>
        </Box>
      </Box>
      <Button variant='contained' color='primary' onClick={navigateToHome}>
        Back To Home
      </Button>
    </Box>
  )
}
export default PageNotFound
