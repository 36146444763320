export const moduleConfig = {
  // dashboard and account review
  ACCOUNT_REVIEW: 'account-review',
  ACCOUNT_DASHBOARD: 'acc-dashboard',
  ACCOUNT_ATTRIBUTES: 'acc-attributes',
  TOTAL_MARKET_VALUE: 'acc-total-market-value',
  TAX_SENSITIVITY: 'acc-tax-sensitivity',
  AVAILABLE_CASH: 'acc-available-cash',
  URGL: 'acc-unrealized-gain-loss',
  INVESTMENT_ADVISOR: 'acc-investment-advisor',
  YTD_RGL: 'acc-realized-gain-loss',
  ACCOUNT_INCOME_DIV_YIELD: 'acc-div-yield',
  ACTIVE_WEIGHT: 'acc-active-weight',
  STRATEGY_ATTRIBUTES: 'str-attributes',
  CASH_FLOOR: 'str-cash-floor',
  STRATEGY_INCOME_DIV_YIELD: 'str-div-yield',
  BENCHMARK: 'str-benchmark',
  ALLOCATION_CHART: 'chart-allocation',
  MAJOR_ASSET_ALLOCATION: 'major-asset-allocation',
  PORTFOLIO_RISK: 'acc-portfolio-risk',
  ACCOUNT_REQUESTS: 'acc-requests',
  TOP_MODEL_POSITIONS: 'instr-positions',
  TOP_OVER_WEIGHTED_SECURITIES: 'instr-overweighted',
  TOP_UNDER_WEIGHTED_SECURITIES: 'instr-underweighted',
  COMMENTS: 'req-comments',
  REQUEST: 'acc-request',

  // account-review actions
  PERSONALIZATION: 'personalization',
  VIEW_PERSONALIZATION: 'view-personalization',
  EDIT_TRADING_PERSONALIZATION: 'edit-trading-personalization',
  EDIT_TRANSITION_PERSONALIZATION: 'edit-transition-personalization',

  IMPORT_PORTFOLIO: 'import-portfolio',
  CURRENT_PORTFOLIO: 'current-portfolio',
  IMPORT_TRADING_PORTFOLIO: 'import-trading-portfolio',
  IMPORT_TRANSITION_PORTFOLIO: 'import-transition-portfolio',
  // model actions

  STRATEGY_PORTFOLIO: 'strategy-portfolio',
  STRATEGY_ONBOARDING: 'strategy-onboarding',
  MODEL: 'model',
  CURRENT_STRATEGY_PORTFOLIO: 'current-strategy-portfolio',
  UPLOAD_STRATEGY_PORTFOLIO: 'upload-strategy-portfolio',
  STRATEGY_MODEL_CHANGES: 'strategy-model-changes',
  HOLDING_DATE: 'holding-date',
  DOWNLOAD_SAMPLE_PORTFOLIO: 'download-sample-portfolio',
  DOWNLOAD_LATEST_PORTFOLIO: 'download-latest-portfolio',
  DOWNLOAD_CURRENT_STRATEGY_PORTFOLIO: 'download-current-strategy-portfolio',
  DOWNLOAD_PORTFOLIO_IMPORT_FORMAT: 'download-portfolio-in-import-format',
  DOWNLOAD_RAW_STRATEGY_PORTFOLIO: 'download-raw-strategy-portfolio',
  CREATE_STRATEGY: 'create-strategy',
  DELIMITER_CHANGE: 'delimiter-change',
  DYNAMIC_PORTFOLIO: 'dynamic-portfolio',

  // trade
  TRADE: 'trade',
  TRADE_APPROVAL: 'trade-approvals',
  TRADE_BLOTTER: 'trade-blotter',
  TRADE_HISTORY: 'trade-history',
  APPROVAL1: 'app1',
  APPROVAL2: 'app2',
  DOWNLOAD_TRANSACTION: 'download-transactions',
  MIN_CASH_POLICY_CHECK: 'min-cash-policy-check',
  POLICY_CHECK: 'policy-check',
  RUN_ADHOC: 'run-adhoc',
  TRADE_OPT_TRADE_DETAILS: 'view-opt-trade-details',
  TRADE_SUMMARY: 'trade-summary',
  VIEW_OPT_TRADE_PROPERTIES: 'view-opt-trade-properties',
  VIEW_TRADE_DETAILS: 'view-trade-details',
  VIEW_TRADE_PROPERTIES: 'view-trade-properties',
  DELIST_INSTRUMENTS: 'delist-instruments',
  FEASIBILITY_REPORT: 'feasibility-report',
  VIEW_TRADE_BLOTTER: 'view-trade-blotter',
  MODEL_VARIANCE: 'model-variance',

  // policy
  IPS: 'ips',
  POLICY: 'policy',
  POLICY_EDIT: 'policy-edit-access',
  POLICY_VIEW: 'policy-view-access',
  STATEMENT: 'statement',
  STATEMENT_EDIT: 'statement-edit-access',
  STATEMENT_VIEW: 'statement-view-access',
  MONITORING: 'monitoring',
  MONITORING_EDIT: 'monitoring-edit-access',
  MONITORING_VIEW: 'monitoring-view-access',
  EVIDENCE: 'evidence',
  EVIDENCE_EDIT: 'evidence-edit-access',
  EVIDENCE_VIEW: 'evidence-view-access',
  // expanded-chart
  EXPANDED_CHARTS: 'expanded-charts',
  // Account Migration
  ACCOUNT_MIGRATION: 'account-migration',
  EXPORT_ACCOUNT: 'export-account',
  IMPORT_ACCOUNT: 'import-account',

  // Pdf-customizer
  PDF_CUSTOMIZER: 'pdf-customizer',
  PDF_REPORT: 'report'
}
