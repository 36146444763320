import { useState } from 'react'
import { Box, IconButton, Modal } from '@mui/material'
import eyefill from '../../../../assets/images/eye-fill.svg'
import { tradeModalStyle } from './tradeModalStyle'
import ViewTradeDetails from '../ViewTradeDetails'
import StyledTooltip from './StyledTooltip'

const CustomTradeDetailsCell = (params) => {
  const [editIndex, setEditIndex] = useState()
  const [tradeAccountId, setTradeAccountId] = useState()
  const [isShown, setIsShown] = useState(false)

  const viewHandleShow = (index, opt, accId) => {
    setEditIndex(index)
    setTradeAccountId(accId)
    setIsShown(true)
  }

  return (
    <>
      <IconButton
        size='small'
        className={params.row?.failedScenario ? 'disabled-action-btn action-clock-btn' : 'action-clock-btn'}
        onClick={() => {
          !params.row?.failedScenario && viewHandleShow(
            params.row?.accOptMapId,
            params.row?.account?.optId,
            params?.row?.account?.accountId
          )
        }}
      >
        <StyledTooltip title='Trade Details'>
          <img
            src={eyefill}
            alt='eyefill'
          />
        </StyledTooltip>
      </IconButton>
      <Modal
        open={isShown}
        onClose={() => setIsShown(false)}
      >
        <Box sx={{ ...tradeModalStyle, maxWidth: '1600px' }}>
          <ViewTradeDetails
            index={editIndex}
            accID={tradeAccountId}
            setIsShown={setIsShown}
            optId={params.row?.account?.optId}
            accountCode={params?.row?.accountCd}
          />
        </Box>
      </Modal>
    </>
  )
}

export default CustomTradeDetailsCell
