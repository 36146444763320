import React, { useRef, useState } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { Tooltip, Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import CustomOutlinedInput from '../../../components/CustomOutlinedInput'

export const CopyToClipboardSecret = (props) => {
  const inputRef = useRef(null)
  const [showPassword, setShowPassword] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const handleClickShowPassword = (event) => {
    setShowPassword((show) => !show)
    if (inputRef.current && !showPassword) {
      inputRef.current.focus()
    }
  }

  const handleClickCopyPassword = () => {
    navigator.clipboard.writeText(props?.mfaCode)
    setTooltipOpen(true)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <FormControl sx={{ width: '100%' }} variant='outlined' disabled>
      <Typography sx={{
        color: '#002A59',
        fontFamily: 'Open Sans !important',
        fontWeight: '600 !important',
        marginBottom: '6px'
      }}
      >Secret key
      </Typography>
      <CustomOutlinedInput
        inputRef={inputRef}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
            </IconButton>
            <Tooltip
              title='Copied to clipboard'
              open={tooltipOpen}
              onClose={() => setTooltipOpen(false)}
            >
              <ContentCopyIcon
                sx={{ ml: 2 }}
                aria-label='password copy'
                onClick={() => {
                  handleClickCopyPassword()
                  setTimeout(() => {
                    setTooltipOpen(false)
                  }, 2000)
                }}
                edge='end'
                fontSize='small'
              />
            </Tooltip>
          </InputAdornment>
        }
        value={props?.mfaCode}
      />
    </FormControl>
  )
}
