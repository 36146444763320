import React from 'react'
import { Card, CardContent, Grid, Typography, Stack, Tooltip } from '@mui/material'
import { formatCurrency } from '../../../utils/FormateCurrenyInMilion'

const AccountInfo = ({ headers, values }) => {

  return (
    <Card className='report-card'>
      <CardContent sx={{ px: '20px', py: '15px', height: '90px', display: 'flex' }} style={{paddingBlock: '15px'}}>
        <Grid className='report-card-border' container alignItems='center'>
        {
          headers.map((field, index) => (
            <Grid key={index} xs={2} sm={3} item>
              <Stack>
                <Typography sx={{ color: '#727474', fontFamily: 'Open Sans', fontSize: '14px', fontWeight: 400, ':hover': { cursor: 'pointer' } }}>
                  {field}
                </Typography>
                <Tooltip title={typeof values[index] === 'number' ? '$' + values[index].toFixed(2) : ''}>
                  <Typography sx={{ fontFamily: 'Open Sans', color: '#2F2F2F', fontSize: '21px', fontWeight: 600 }}>
                    {
                      values[index] !== undefined ? values[index] >= 0 ? '$'+formatCurrency(values[index]) : '-$'+formatCurrency(values[index]) : '-'
                    }
                  </Typography>
                </Tooltip>
              </Stack>
            </Grid>
          ))
        }
        </Grid>
      </CardContent>
    </Card>
  )
  
}

export default AccountInfo