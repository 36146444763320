import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { API } from 'aws-amplify'
import dayjs from 'dayjs'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import * as Sentry from '@sentry/react'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import { Autocomplete, Box, Button, Card, CardContent, Divider, FormControlLabel, FormHelperText, Grid, MenuItem, Modal, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useAuth } from '../../../../contexts/AuthContext'
import { useErrorToast } from '../../../../hooks/useErrorToast'
import { isNumberOnly, isWholeNumber } from '../../../../utils/NumberUtils'
import { InputAdornmentTextField } from '../../../AccountOnboarding/components/inputAdornmentTextfield'
import Loader from '../../../Loader'
import { formatCurrencyWithSymbol } from '../../../Report/components/dataProcess/DataProcess'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: '4px'
}

const CreateAdhocModal = ({ onConfirm, accountsList, aggregateFamilyData, custodianList, sponsorsList, isCustodianLoading }) => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [sourceOptions, setSourceOptions] = useState([])
  const [isLoadingSource, setIsLoadingSource] = useState(false)
  const [accountAutocompleteValue, setAccountAutocompleteValue] = useState('')
  const [selectedSponsor, setSelectedSponsor] = useState('')
  const [buyTradeAutocompleteValue, setBuyTradeAutocompleteValue] = useState('')
  const [sellAdhocAutocompleteValue, setSellAdhocAutocompleteValue] = useState('')
  const [aggregateAutocompleteValue, setAggregateAutocompleteValue] = useState('')
  const [aggTaggedAccountId, setAggTaggedAccountId] = useState({})
  const [purchaseDate, setPurchaseDate] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [buyValidationData, setBuyValidationData] = useState({})
  const [custodianValue, setCustodianValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [additionalError, setAdditionalError] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [taxlot, setTaxlot] = useState([])
  const [aggregateAccountData, setAggregateAccountData] = useState([])
  const [isBuyTickerValueEnteredManually, setIsBuyTickerValueEnteredManually] = useState(false)
  const [isSellTickerValueEnteredManually, setIsSellTickerValueEnteredManually] = useState(false)
  const [aggregatePortfolio, setAggregatePortfolio] = useState([])

  const handleCloseModalOnSubmit = () => {
    setIsModalOpen(false)
  }

  const handleOnConfirm = () => {
    setIsModalOpen(false)
    // Switch to 'Adhoc Trade History' tab
    sendAdhocTrade()
    // onConfirm()
  }

  const validationSchema = yup.object({
    quantity: yup.string().when('tradeSide', (tradeSide, schema) => {
      if (tradeSide.length && tradeSide[0] === 'buy') {
        return schema
          .required('Quantity is required')
          .test({
            name: 'greaterThanZero',
            exclusive: true,
            message: 'Quantity must be greater than 0',
            test: value => parseFloat(value) > 0
          })
      }
      return schema
    }),
    accountType: yup.string().required('Account Type is required'),
    accountName: yup.string()
      .when('accountType', (accountType, schema) => {
        if (accountType.length && accountType[0] === 'account') { return schema.required('Account Name is required') }
        return schema
      }),
    aggregateName: yup.string()
      .when('accountType', (accountType, schema) => {
        if (accountType.length && accountType[0] === 'aggregate') { return schema.required('Aggregate Name is required') }
        return schema
      }),
    securityName: yup.string()
      .when('tradeSide', (tradeSide, schema) => {
        if (tradeSide.length && tradeSide[0] === 'buy') {
          if (isBuyTickerValueEnteredManually) {
            return schema
              .required('Security Name is required')
              .max(30, 'Security Name cannot be more than 30 characters')
          } else {
            return schema.required('Security Name is required')
          }
        }
        return schema
      }),
    sponsorId: yup.string()
      .when('accountType', (accountType, schema) => {
        if (accountType.length && accountType[0] === 'adhoc') { return schema.required('Sponsor is required') }
        return schema
      }),
    aggTaggedId: yup.string()
      .when('accountType', (accountType, schema) => {
        if (accountType.length && accountType[0] === 'aggregate') { return schema.required('Tagged Account is required') }
        return schema
      }),
    localSymbol: yup.string(),
    masterId: yup.string()
      .when(['accountType'], (accountType, schema) => {
        if (accountType && accountType[0] === 'adhoc' && custodianValue?.custodianName === 'Charles Schwab') {
          return schema
            .trim()
            .required('Master ID is required')
            .max(9, 'Master ID cannot be more than 9 characters')
            .min(3, 'Master ID cannot be less than 3 characters')
        } else {
          return schema
            .trim()
            .required('Master ID is required')
        }
      }),
    custodian: yup.string().required('Custodian is required'),
    custodianId: yup.string()
      .when(['accountType'], (accountType, schema) => {
        if (accountType && accountType[0] === 'adhoc' && custodianValue?.custodianName === 'Charles Schwab') {
          return schema
            .trim()
            .required('Custodian Number is required')
            .max(9, 'Custodian Number cannot be more than 9 characters')
            .min(3, 'Custodian Number cannot be less than 3 characters')
        } else {
          return schema
            .trim()
            .required('Custodian Number is required')
        }
      }),
    tradeSide: yup.string().required('Trade Side is required'),
    tradeSellOption: yup.string()
      .when('tradeSide', (tradeSide, schema) => {
        if (tradeSide.length && tradeSide[0] === 'sell') { return schema.required('Sell Type is required') }
        return schema
      }),
    sellAdhocSecurityName: yup.string()
      .when('tradeSellOption', (tradeSellOption, schema) => {
        if (tradeSellOption.length && tradeSellOption[0] === 'adhoc') {
          if (isSellTickerValueEnteredManually) {
            return schema
              .required('Security Name is required')
              .max(30, 'Security Name cannot be more than 30 characters')
          } else {
            return schema.required('Security Name is required')
          }
        }
        return schema
      }),
    sellAdhocLocalSymbol: yup.string(),
    sellAdhocQuantity: yup.string().when('tradeSellOption', (tradeSellOption, schema) => {
      if (tradeSellOption.length && tradeSellOption[0] === 'adhoc') {
        return schema
          .required('Quantity is required')
          .test({
            name: 'greaterThanZero',
            exclusive: true,
            message: 'Quantity must be greater than 0',
            test: value => parseFloat(value) > 0
          })
      }
      return schema
    }),
    shareQty: yup.string().when('tradeSellOption', (tradeSellOption, schema) => {
      if (tradeSellOption.length && tradeSellOption[0] === 'taxlot') {
        return schema
          .required('Quantity is required')
          .test({
            name: 'greaterThanZero',
            exclusive: true,
            message: 'Quantity must be greater than 0',
            test: value => parseFloat(value) > 0
          })
      }
      return schema
    }),
    enteredShares: yup.string().when('shareQty', {
      is: 'custom',
      then: schema => schema
        .required('Shares should be a number')
        .test('maxShares', 'Shares should be less than or equal to available shares', function (value) {
          const maxShares = selectedRow?.shares
          return !maxShares || parseFloat(value) <= maxShares
        })
    })

  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    trigger,
    getValues,
    clearErrors
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues:
    {
      securityName: '',
      quantity: '',
      accountName: '',
      masterId: '',
      custodianId: '',
      custodian: '',
      accountType: '',
      selectedOption: '',
      tradeSide: '',
      tradeSellOption: '',
      sellAdhocSecurityName: '',
      sellAdhocQuantity: '',
      orgPurchaseCost: '',
      aggregateName: '',
      purchaseDate: '',
      shareQty: 'fullQty',
      enteredShares: '',
      sponsorId: '',
      aggTaggedId: ''
    }
  })

  const handleSellButtonClick = (row) => {
    trigger('masterId')
    trigger('custodianId')
    if (watch('masterId') && watch('custodianId')) {
      setSelectedRow(row)
      setOpenModal(true)
    } else {
      showError('Please fill required information')
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setSelectedRow(null)
    setValue('enteredShares', '')
    setValue('shareQty', 'fullQty')
  }

  const handleSellConfirm = () => {
    // Open the confirmation modal
    setIsModalOpen(true)
  }

  const handleSellCancel = () => {
    handleCloseModal()
  }

  const columns = [
    {
      field: 'sellButton',
      headerName: '',
      flex: 1,
      renderCell: (params) => {
        if (params.row.localSymbol !== '__USD') {
          return (
            <Button size='small' sx={{ fontSize: '14px', fontWeight: 600, color: '#0982F4', marginLeft: '8px' }} onClick={() => handleSellButtonClick(params.row)}>Sell</Button>
          )
        } else {
          return null
        }
      }
    },
    { field: 'localSymbol', headerName: 'Local Symbol', flex: 1 },
    { field: 'instrName', headerName: 'Security Name', flex: 1 },
    { field: 'shares', headerName: 'Shares', type: 'number', flex: 1 },
    {
      field: 'purchaseDate',
      headerName: 'Purchase Date',
      type: 'date',
      flex: 1,
      valueGetter: (params) => new Date(params.row.purchaseDate),
      valueFormatter: (params) => {
        if (params?.value instanceof Date) {
          return `${params.value.getFullYear()}-${(params.value.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${params.value.getDate().toString().padStart(2, '0')}`
        }
        return ''
      }
    },
    {
      field: 'orgPurchasePrice',
      headerName: 'Purchase Price',
      type: 'number',
      flex: 1,
      valueFormatter: (params) => {
        if (params?.value) {
          return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.value)
        }
        return ''
      }
    }
  ]
  const column1 = [
    {
      field: 'sellButton',
      headerName: '',
      flex: 1,
      renderCell: (params) => {
        if (params.row.localSymbol !== '__USD') {
          return (
            <Button size='small' sx={{ fontSize: '14px', fontWeight: 600, color: '#0982F4', marginLeft: '8px' }} onClick={() => handleSellButtonClick(params.row)}>Sell</Button>
          )
        } else {
          return null
        }
      }
    },
    { field: 'localSymbol', headerName: 'Local Symbol', flex: 1 },
    { field: 'instrumentName', headerName: 'Security Name', flex: 1 },
    { field: 'shares', headerName: 'Shares', type: 'number', flex: 1 },
    {
      field: 'purchaseDate',
      headerName: 'Purchase Date',
      type: 'date',
      flex: 1,
      valueGetter: (params) => new Date(params.row.purchaseDate),
      valueFormatter: (params) => {
        if (params?.value instanceof Date) {
          return `${params.value.getFullYear()}-${(params.value.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${params.value.getDate().toString().padStart(2, '0')}`
        }
        return ''
      }
    },
    {
      field: 'orgPurchasePrice',
      headerName: 'Purchase Price',
      type: 'number',
      flex: 1,
      valueFormatter: (params) => {
        if (params?.value) {
          return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.value)
        }
        return ''
      }
    }
  ]

  const onInputChange = (query, setSourceOptions, setIsLoadingSource, showError) => {
    if (query?.toString().trim() === '') {
      setSourceOptions([])
    }
    if (query && query?.toString()?.trim()?.length > 2) {
      setIsLoadingSource(true)
      API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup}/instruments`, {
        queryStringParameters: { search: query }
      })
        .then((response) => {
          if (response?.data) {
            setSourceOptions([...response.data])
            setIsLoadingSource(false)
          }
        })
        .catch((error) => {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          setIsLoadingSource(false)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        })
    }
  }

  const onSubmitHandler = (values) => {
    if (values.tradeSide === 'buy' && values?.accountType !== 'adhoc') {
      sendAdhocTrade(true)
    } else {
      if (additionalError) {
        showError(additionalError)
      } else {
        setIsModalOpen(true)
      }
    }
  }

  const handleTradeQtyChange = (event) => {
    setValue('shareQty', event.target.value)
  }

  const sendAdhocTrade = async (validateOnly = false) => {
    const formValues = getValues()

    let payload = {
      type: formValues.accountType === 'account' ? 'ACCOUNT' : formValues.accountType === 'aggregate' ? 'AGG' : 'NONARIS'
    }

    if (formValues.accountType === 'adhoc') {
      payload = {
        ...payload,
        masterAccountId: formValues.masterId,
        custodianId: formValues.custodian,
        custodianAccountNumber: formValues.custodianId,
        sponsorId: selectedSponsor?.sponsorId
      }
    }

    if (formValues.tradeSide === 'buy') {
      payload = {
        ...payload,
        instrId: buyTradeAutocompleteValue?.instrId || formValues?.securityName,
        localSymbol: formValues?.localSymbol || buyTradeAutocompleteValue?.instrId || formValues?.securityName,
        trdCode: formValues?.tradeSide?.toUpperCase(),
        quantity: formValues?.quantity
      }
    }

    if (formValues?.tradeSide === 'sell' && formValues?.tradeSellOption === 'adhoc') {
      payload = {
        ...payload,
        instrId: sellAdhocAutocompleteValue?.instrId || formValues?.sellAdhocSecurityName,
        localSymbol: formValues?.sellAdhocLocalSymbol || sellAdhocAutocompleteValue?.instrId || formValues?.sellAdhocSecurityName,
        trdCode: formValues?.tradeSide?.toUpperCase(),
        quantity: formValues?.sellAdhocQuantity,
        custodianId: formValues.custodian
      }
      if (formValues.purchaseDate) {
        payload.additional = {
          vspPrice: Number(formValues.orgPurchaseCost || 0),
          vspDate: dayjs.utc(formValues.purchaseDate).format('YYYY-MM-DD')
        }
      }
    }

    if (formValues?.tradeSide === 'sell' && formValues?.tradeSellOption === 'taxlot') {
      payload = {
        ...payload,
        instrId: selectedRow?.instrId,
        localSymbol: selectedRow?.localSymbol,
        trdCode: formValues?.tradeSide?.toUpperCase(),
        quantity: formValues.shareQty === 'custom' ? formValues.enteredShares : selectedRow?.shares,
        custodianId: formValues.custodian,
        additional: {
          vspPrice: Number(selectedRow?.orgPurchasePrice || 0),
          vspDate: selectedRow?.purchaseDate?.split('T')[0]?.slice(0, 11)
        }
      }
    }

    if (formValues.accountType === 'account' || formValues.accountType === 'aggregate') {
      payload = {
        ...payload,
        accountId: formValues?.accountType === 'account' ? accountAutocompleteValue?.accountId : formValues?.accountType === 'aggregate' ? aggTaggedAccountId?.accountId : '',
        custodianId: formValues.custodian
      }
      if (formValues.accountType === 'aggregate') {
        payload.aggId = aggregateAutocompleteValue?.aggId
      }
    }
    setLoading(true)
    API.post('baseUriTrade', `trade/v1/${user.userGroup}/adhoc/trades`, {
      body: {
        ...payload
      },
      queryStringParameters: { ...{ ...validateOnly ? { validation: true } : {} } }
    })
      .then((response) => {
        if (validateOnly && response?.data) {
          setBuyValidationData(response?.data)
          setIsModalOpen(true)
        } else {
          setBuyValidationData({})
          if (response.data) {
            onConfirm()
          }
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      }).finally(() => {
        setLoading(false)
      })
  }

  const getPortfolioAccounts = async () => {
    const aggId = aggregateAutocompleteValue?.aggId
    setLoading(true)
    API.get(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user?.userGroup}/aggregate-portfolio/${aggId}`
    )
      .then((response) => {
        if (response?.data?.accountData) {
          const newData = response.data.accountData.map((account) => ({ ...account, id: randomId() }))
          setAggregateAccountData(newData)
          setAggregatePortfolio(newData?.filter(account => account?.accountId === aggTaggedAccountId?.accountId))
          setLoading(false)
        } else {
          setAggregateAccountData([])
          setLoading(false)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (watch('aggregateName') && watch('tradeSellOption') === 'taxlot') {
      getPortfolioAccounts()
    }
  }, [watch('tradeSellOption'), watch('aggregateName')])

  const getTaxlotData = async () => {
    const accountId = accountAutocompleteValue?.accountId
    setLoading(true)
    API
      .get('baseURL', `account-master/v1/${user?.userGroup}/accounts/taxlots/${accountId}`)
      .then(response => {
        if (response?.data) {
          const taxlotData = response.data.map((taxlot) => ({ ...taxlot, id: randomId() }))
          setTaxlot(taxlotData)
          setLoading(false)
        } else {
          setTaxlot([])
          setLoading(false)
        }
      })
      .catch(error => {
        showError(error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        setLoading(false)
      })
  }

  useEffect(() => {
    if ((watch('accountName') && watch('tradeSellOption') === 'taxlot')) {
      getTaxlotData()
    }
  }, [watch('tradeSellOption'), watch('accountName')])

  useEffect(() => {
    if (watch('accountType') === 'adhoc') {
      if (!isCustodianLoading) {
        const custodianValue = custodianList.find(custodian => custodian.custodianName === 'Charles Schwab')
        setValue('custodian', custodianValue?.custodianId || '')
        setCustodianValue(custodianValue)
        trigger('custodian')
      }
    } else if (watch('accountType') === 'account' || watch('accountType') === 'aggregate') {
      setValue('custodian', '')
      setCustodianValue('')
    }
    setValue('securityName', '')
    setValue('localSymbol', '')
    setValue('quantity', '')
    setValue('accountName', '')
    setValue('masterId', '')
    setValue('custodianId', '')
    setValue('selectedOption', '')
    setValue('tradeSide', '')
    setValue('tradeSellOption', '')
    setValue('sellAdhocSecurityName', '')
    setValue('sellAdhocLocalSymbol', '')
    setValue('sellAdhocQuantity', '')
    setValue('orgPurchaseCost', '')
    setValue('aggregateName', '')
    setValue('purchaseDate', '')
    setValue('shareQty', '')
    setSelectedRow({})
    setAggregateAutocompleteValue('')
    setSellAdhocAutocompleteValue('')
    setBuyTradeAutocompleteValue('')
    setAccountAutocompleteValue('')
    setAdditionalError(null)
  }, [watch('accountType')])

  useEffect(() => {
    setValue('sellAdhocSecurityName', '')
    setValue('sellAdhocQuantity', '')
    setValue('orgPurchaseCost', '')
    setValue('purchaseDate', '')
    setPurchaseDate({})
  }, [watch('tradeSellOption')])

  useEffect(() => {
    setValue('tradeSellOption', '')
    setValue('securityName', '')
    setValue('quantity', '')
    if ((watch('accountType') === 'adhoc' && watch('tradeSide') === 'sell')) {
      setValue('tradeSellOption', 'adhoc')
    }
  }, [watch('tradeSide')])

  useEffect(() => {
    if (watch('tradeSellOption') === 'adhoc') {
      if ((watch('purchaseDate') && !watch('orgPurchaseCost')) || (!watch('purchaseDate') && watch('orgPurchaseCost'))) {
        setAdditionalError('Either Both Purchase Date and Purchase cost should be passed or None of them should be passed')
      } else {
        setAdditionalError(null)
      }
    } else {
      setAdditionalError(null)
    }
  }, [watch('purchaseDate'), watch('orgPurchaseCost'), watch('tradeSellOption')])

  useEffect(() => {
    setValue('enteredShares', watch('shareQty') === 'custom' ? selectedRow?.shares.toString() : '')
  }, [watch('shareQty'), selectedRow])

  useEffect(() => {
    if (watch('accountType' === 'adhoc')) {
      if (watch('custodianId')) {
        trigger('custodianId')
      }
      if (watch('masterId')) {
        trigger('masterId')
      }
    }
  }, [watch('accountType'), watch('custodian')])

  useEffect(() => {
    clearErrors('masterId')
    clearErrors('custodianId')
  }, [watch('accountType')])

  useEffect(() => {
    setValue('aggTaggedId', '')
    setAggTaggedAccountId({})
    setValue('tradeSide', '')
    setValue('securityName', '')
    setBuyTradeAutocompleteValue('')
    setValue('quantity', '')
    setValue('tradeSellOption', '')
    setValue('sellAdhocSecurityName', '')
    setSellAdhocAutocompleteValue('')
    setValue('sellAdhocQuantity', '')
    setValue('orgPurchaseCost', '')
    setValue('purchaseDate', '')
  }, [watch('aggregateName')])

  return (
    <Box sx={{ position: 'relative' }}>
      {loading ? <Loader /> : ''}
      <form noValidate onSubmit={handleSubmit(onSubmitHandler)} autoComplete='off'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography className='form-label-header' mb={2}>
                  Account Details
                </Typography>
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name='accountType'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          id='accountType'
                          name='accountType'
                          select
                          value={field.value || ''}
                          label='Account Type *'
                          sx={{
                            width: '100%'
                          }}
                          error={Boolean(errors.accountType)}
                          helperText={errors.accountType && errors.accountType.message}
                          {...field}
                        >
                          <MenuItem value='account'>Account</MenuItem>
                          <MenuItem value='aggregate'>Aggregate</MenuItem>
                          <MenuItem value='adhoc'>Adhoc</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                  {watch('accountType') === 'account' && (
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        id='account-autocomplete'
                        options={accountsList}
                        loading={isLoadingSource}
                        getOptionLabel={(option) => option?.accountName || ''}
                        onChange={(event, newValue) => {
                          setAccountAutocompleteValue(newValue)
                          setValue('accountName', newValue?.accountName || '')
                          trigger('accountName')
                          setValue('masterId', newValue?.masterAccountId || '')
                          trigger('masterId')
                          setValue('custodianId', newValue?.custodianAccountNumber || '')
                          trigger('custodianId')
                          setValue('custodian', newValue?.custodianId || '')
                          const selectedCustodian = custodianList.find(custodian => custodian.custodianId === newValue?.custodianId)
                          setCustodianValue(selectedCustodian)
                          trigger('custodian')
                        }}
                        value={accountAutocompleteValue}
                        sx={{
                          width: '100%'
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label='Account Name *'
                            {...register('accountName')}
                            error={Boolean(errors.accountName)}
                            helperText={errors.accountName && errors.accountName.message}
                            InputProps={{ ...params.InputProps, placeholder: 'Account Name' }}
                            sx={{
                              width: '100%'
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {watch('accountType') === 'aggregate' && (
                    <>
                      <Grid item xs={12} sm={4}>
                        <Autocomplete
                          id='aggregate-autocomplete'
                          options={aggregateFamilyData}
                          loading={isLoadingSource}
                          getOptionLabel={(option) => option.aggName || ''}
                          onChange={(event, newValue) => {
                            setAggregateAutocompleteValue(newValue)
                            if (newValue) {
                              setValue('aggregateName', newValue.aggName)
                              trigger('aggregateName')
                            }
                            setValue('masterId', newValue?.masterAccountId || '')
                            trigger('masterId')
                            setValue('custodianId', newValue?.custodianAccountNumber || '')
                            trigger('custodianId')
                            setValue('custodian', newValue?.custodianId || '')
                            const selectedCustodian = custodianList.find(custodian => custodian.custodianId === newValue?.custodianId)
                            setCustodianValue(selectedCustodian)
                            trigger('custodian')
                          }}
                          value={aggregateAutocompleteValue}
                          sx={{
                            width: '100%'
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              {...register('aggregateName')}
                              label='Aggregate Name *'
                              error={Boolean(errors.aggregateName)}
                              helperText={errors.aggregateName && errors.aggregateName.message}
                              InputProps={{ ...params.InputProps, placeholder: 'Aggregate Name' }}
                              sx={{
                                width: '100%'
                              }}
                            />
                          )}
                        />
                      </Grid>
                      {watch('accountType') === 'aggregate'
                        ? <Grid item xs={12} sm={4}>
                          <Autocomplete
                            id='aggregate-autocomplete'
                            options={aggregateAutocompleteValue?.accounts || []} // Flattening accounts arrays from aggregateFamilyData
                            loading={isLoadingSource}
                            getOptionLabel={(option) => option.accountName || ''}
                            {...register('aggTaggedId')}
                            onChange={(event, newValue) => {
                              setAggTaggedAccountId(newValue)
                              if (newValue) {
                                setValue('aggTaggedId', newValue.accountId)
                                trigger('aggTaggedId')
                              }
                              setAggregatePortfolio(aggregateAccountData?.filter(account => account?.accountId === newValue?.accountId))
                            }}
                            value={aggTaggedAccountId}
                            sx={{
                              width: '100%'
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant='outlined'
                                label='Tagged Account *'
                                error={Boolean(errors.aggTaggedId)}
                                helperText={errors.aggTaggedId && errors.aggTaggedId.message}
                                InputProps={{ ...params.InputProps, placeholder: 'Tagged Account' }}
                                sx={{
                                  width: '100%'
                                }}
                              />
                            )}
                          />
                          </Grid> : ''}
                    </>
                  )}

                  {watch('accountType') === 'adhoc' && (
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        id='account-autocomplete'
                        options={sponsorsList}
                        loading={isLoadingSource}
                        getOptionLabel={(option) => option?.sponsorName || ''}
                        onChange={(event, newValue) => {
                          setSelectedSponsor(newValue)
                          setValue('sponsorId', newValue?.sponsorId || '')
                          trigger('sponsorId')
                        }}
                        value={selectedSponsor}
                        sx={{
                          width: '100%'
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label='Sponsor Name *'
                            {...register('sponsorId')}
                            error={Boolean(errors.sponsorId)}
                            helperText={errors.sponsorId && errors.sponsorId.message}
                            InputProps={{ ...params.InputProps, placeholder: 'Sponsor Name' }}
                            sx={{
                              width: '100%'
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
                {watch('accountType')
                  ? <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {watch('accountType') && (
                        <Grid item xs={12} sm={4}>
                          <Autocomplete
                            id='custodian-autocomplete'
                            options={custodianList}
                            loading={isCustodianLoading}
                            {...register('custodian')}
                            getOptionLabel={(option) => option.custodianName || ''}
                            onChange={(event, newValue) => {
                              setCustodianValue(newValue)
                              if (newValue) {
                                setValue('custodian', newValue?.custodianId)
                              } else {
                                setValue('custodian', '')
                              }
                            }}
                            value={custodianList.find(custodian => custodian.custodianId === custodianValue?.custodianId) || null}
                            readOnly={watch('accountType') === 'account' || watch('accountType') === 'aggregate'}
                            sx={{
                              width: '100%'
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant='outlined'
                                label='Custodian *'
                                error={Boolean(errors?.custodian)}
                                helperText={errors?.custodian && errors?.custodian.message}
                                InputProps={{
                                  ...params.InputProps,
                                  placeholder: 'Custodian',
                                  readOnly: watch('accountType') === 'account' || watch('accountType') === 'aggregate'
                                }}
                                sx={{
                                  width: '100%'
                                }}
                              />
                            )}
                          />
                        </Grid>
                      )}

                      <Grid item xs={12} sm={4}>
                        <Controller
                          name='custodianId'
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id='custodianId'
                              name='custodianId'
                              type='text'
                              label='Custodian Number *'
                              placeholder='Custodian Number'
                              sx={{
                                width: '100%'
                              }}
                              error={Boolean(errors?.custodianId)}
                              helperText={errors?.custodianId?.message}
                              InputProps={{
                                readOnly: watch('accountType') === 'account' || watch('accountType') === 'aggregate'
                              }}
                              onKeyDown={(e) =>
                                custodianValue?.custodianName === 'Charles Schwab' ? isWholeNumber(e) : true}
                              onPaste={(event) => {
                                const inputValue = event.clipboardData.getData('text')
                                const regex = /^\d+$/
                                if (!regex.test(inputValue)) {
                                  event.target.value = ''
                                  event.preventDefault()
                                  return false
                                }
                                return true
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name='masterId'
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id='masterId'
                              name='masterId'
                              type='text'
                              label='Master ID *'
                              placeholder='Master ID'
                              sx={{
                                width: '100%'
                              }}
                              error={Boolean(errors?.masterId)}
                              helperText={errors?.masterId?.message}
                              InputProps={{
                                readOnly: watch('accountType') === 'account' || watch('accountType') === 'aggregate'
                              }}
                              onKeyDown={(e) =>
                                custodianValue?.custodianName === 'Charles Schwab' ? isWholeNumber(e) : true} onPaste={(event) => {
                                  const inputValue = event.clipboardData.getData('text')
                                  const regex = /^\d+$/
                                  if (!regex.test(inputValue)) {
                                    event.target.value = ''
                                    event.preventDefault()
                                    return false
                                  }
                                  return true
                                }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    </Grid>
                  : ''}
              </CardContent>
            </Card>
          </Grid>
          {watch('accountType') === 'adhoc' || (watch('accountType') === 'account' && watch('accountName')) || (watch('accountType') === 'aggregate' && watch('aggregateName') && watch('aggTaggedId'))
            ? <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography className='form-label-header' mb={2}>
                    Trade Details
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Controller
                        name='tradeSide'
                        control={control}
                        render={({ field }) => (
                          <TextField
                            id='tradeSide'
                            name='tradeSide'
                            select
                            value={field.value || ''}
                            label='Trade Side *'
                            sx={{
                              width: '100%'
                            }}
                            error={Boolean(errors.tradeSide)}
                            helperText={errors.tradeSide && errors.tradeSide.message}
                            {...field}
                          >
                            <MenuItem value='buy'>Buy</MenuItem>
                            <MenuItem value='sell'>Sell</MenuItem>
                          </TextField>
                        )}
                      />
                    </Grid>
                    {watch('tradeSide') === 'buy'
                      ? <>
                        <Grid item xs={12} sm={4}>
                          <Autocomplete
                            id='tradeSideTicker-autocomplete'
                            freeSolo
                            options={sourceOptions}
                            loading={isLoadingSource}
                            getOptionLabel={(option) => option.name || ''}
                            onInputChange={(event, newInputValue) => {
                              onInputChange(newInputValue, setSourceOptions, setIsLoadingSource, showError)
                              setIsBuyTickerValueEnteredManually(true) // Set to true when typing in the freeSolo input
                            }}
                            onChange={(event, newValue) => {
                              setBuyTradeAutocompleteValue(newValue)
                              setValue('securityName', newValue?.name)
                              setValue('localSymbol', newValue?.localSymbol)
                              setIsBuyTickerValueEnteredManually(false) // Reset to false when an option is selected from autocomplete
                            }}
                            value={buyTradeAutocompleteValue}
                            onInput={(e) => e.target.value = e.target.value.toUpperCase()}
                            sx={{
                              width: '100%'
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name='securityName'
                                label='Ticker *'
                                variant='outlined'
                                {...register('securityName')}
                                error={Boolean(errors.securityName)}
                                helperText={errors.securityName && errors.securityName.message}
                                InputProps={{ ...params.InputProps, placeholder: 'Ticker here' }}
                                sx={{
                                  width: '100%'
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id='quantity'
                            name='quantity'
                            type='text'
                            label='Quantity *'
                            {...register('quantity')}
                            onKeyDown={(event) => isWholeNumber(event)}
                            error={Boolean(errors.quantity)}
                            helperText={errors.quantity && errors.quantity.message}
                            sx={{
                              width: '100%'
                            }}
                          />
                        </Grid>
                        </>
                      : ''}
                    {watch('tradeSide') === 'sell'
                      ? <>
                        {watch('accountType') !== 'adhoc'
                          ? <Grid item xs={12} sm={4}>
                            <Controller
                              name='tradeSellOption'
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  id='tradeSellOption'
                                  name='tradeSellOption'
                                  select
                                  value={field.value || ''}
                                  label='Sell Type *'
                                  sx={{
                                    width: '100%'
                                  }}
                                  error={Boolean(errors.tradeSellOption)}
                                  helperText={errors.tradeSellOption && errors.tradeSellOption.message}
                                  {...field}
                                >
                                  <MenuItem value='taxlot'>Existing Taxlot</MenuItem>
                                  <MenuItem value='adhoc'>Adhoc</MenuItem>
                                </TextField>
                              )}
                            />
                            </Grid>
                          : ''}
                        {watch('tradeSellOption') === 'adhoc' || (watch('accountType') === 'adhoc' && watch('tradeSide') === 'sell')
                          ? <>
                            <Grid item xs={12} sm={4}>
                              <Autocomplete
                                id='adhocTicker-autocomplete'
                                freeSolo
                                options={sourceOptions}
                                loading={isLoadingSource}
                                getOptionLabel={(option) => option.name || ''}
                                onInputChange={(event, newInputValue) => {
                                  onInputChange(newInputValue, setSourceOptions, setIsLoadingSource, showError)
                                  setIsSellTickerValueEnteredManually(true) // Set to true when typing in the freeSolo input
                                }}
                                onChange={(event, newValue) => {
                                  setSellAdhocAutocompleteValue(newValue)
                                  setValue('sellAdhocSecurityName', newValue?.name)
                                  setValue('sellAdhocLocalSymbol', newValue?.localSymbol)
                                  setIsSellTickerValueEnteredManually(false) // Reset to false when an option is selected from autocomplete
                                }}
                                onInput={(e) => e.target.value = e.target.value.toUpperCase()}
                                value={sellAdhocAutocompleteValue}
                                sx={{
                                  width: '100%'
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label='Ticker *'
                                    variant='outlined'
                                    {...register('sellAdhocSecurityName')}
                                    error={Boolean(errors.sellAdhocSecurityName)}
                                    helperText={errors.sellAdhocSecurityName && errors.sellAdhocSecurityName.message}
                                    InputProps={{ ...params.InputProps, placeholder: 'Ticker here' }}
                                    sx={{
                                      width: '100%'
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                id='quantity'
                                name='quantity'
                                type='text'
                                label='Quantity *'
                                onKeyDown={(event) => isNumberOnly(event)}
                                {...register('sellAdhocQuantity')}
                                error={Boolean(errors.sellAdhocQuantity)}
                                helperText={errors.sellAdhocQuantity && errors.sellAdhocQuantity.message}
                                sx={{
                                  width: '100%'
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  name='purchaseDate'
                                  label='Purchase date'
                                  format='YYYY-MM-DD'
                                  disableFuture
                                  onChange={(date) => {
                                    setPurchaseDate(date)
                                    setValue('purchaseDate', date)
                                  }}
                                  sx={{ width: '100%' }}
                                  textField={(params) => (
                                    <TextField
                                      {...params}
                                      error={Boolean(errors.purchaseDate)}
                                      helperText={errors.purchaseDate && errors.purchaseDate.message}
                                      sx={{ width: '100%' }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Controller
                                name='orgPurchaseCost'
                                control={control}
                                render={({ field }) => (
                                  <InputAdornmentTextField
                                    id='orgPurchaseCost'
                                    name='orgPurchaseCost'
                                    type='text'
                                    label='Original Purchase Cost'
                                    {...register('orgPurchaseCost')}
                                    value={field.value}
                                    onChange={field.onChange}
                                    onKeyDown={(event) => isNumberOnly(event)}
                                    sx={{ width: '100%', mt: 0 }}
                                    adornment={<AttachMoneyOutlinedIcon />}
                                    error={Boolean(errors.orgPurchaseCost)}
                                    helperText={errors.orgPurchaseCost && errors.orgPurchaseCost.message}
                                  />
                                )}
                              />
                            </Grid>
                            </>
                          : ''}
                        {watch('tradeSellOption') === 'taxlot' && (
                          <Grid item xs={12} spacing={2}>
                            <Card className='card-layout' style={{ marginLeft: '20px !important' }}>
                              <CardContent>
                                <div style={{ width: '100%' }}>
                                  <DataGridPro
                                    autoHeight
                                    rows={
                                      watch('accountType') === 'account'
                                        ? taxlot
                                        : watch('accountType') === 'aggregate'
                                          ? aggregatePortfolio.filter(row => row.instrId !== null && row.localSymbol !== null)
                                          : []
                                    }
                                    getRowId={(row) => row.id}
                                    columns={watch('accountType') === 'account' ? columns : column1}
                                    pageSizeOptions={[10]}
                                    initialState={watch('accountType') === 'aggregate' && aggregatePortfolio
                                      ? {
                                          ...(aggregatePortfolio?.initialState),
                                          pagination: { paginationModel: { pageSize: 10 } }
                                        }
                                      : watch('accountType') === 'account' && taxlot
                                        ? {
                                            ...taxlot?.initialState,
                                            pagination: { paginationModel: { pageSize: 10 } }
                                          }
                                        : {}}
                                    pagination
                                    disableRowSelectionOnClick
                                    sx={(theme) => ({
                                      [`.${gridClasses.main}`]: {
                                        overflow: 'unset'
                                      },
                                      [`.${gridClasses.columnHeaders}`]: {
                                        position: 'sticky',
                                        top: 0,
                                        backgroundColor: theme.palette.background.paper,
                                        zIndex: 1
                                      },
                                      [`.${gridClasses.footerContainer}`]: {
                                        position: 'sticky',
                                        bottom: -20,
                                        backgroundColor: theme.palette.background.paper,
                                        zIndex: 1
                                      },
                                      [`.${gridClasses.toolbarContainer}`]: {
                                        position: 'relative',
                                        top: 0,
                                        backgroundColor: theme.palette.background.paper,
                                        zIndex: 1
                                      }
                                    })}
                                  />
                                </div>
                              </CardContent>
                            </Card>
                            <Modal open={openModal} onClose={handleCloseModal}>
                              <Box sx={{
                                ...style
                              }}
                              >
                                <Box>
                                  <Typography variant='h6' component='h5' sx={{ fontSize: '18px', fontWeight: 600, color: '#002A59' }} mb='14px'>Existing Taxlot</Typography>
                                  <Box display='flex' flexDirection='column' backgroundColor='#002A5912' justifyContent='start' alignItems='baseline' width='100% !important'>
                                    <Box display='flex' flexDirection='row' alignItems='center' mt={1}>
                                      <Typography mb={1} sx={{ color: '#74788D', padding: '10px' }}>Quantity:</Typography>
                                      <Box display='flex' alignItems='center' marginBottom='12px' width='100%'>
                                        <RadioGroup sx={{ display: 'flex', flexDirection: 'row', gap: '15px' }} value={watch('shareQty') || 'fullQty'} onChange={handleTradeQtyChange}>
                                          <FormControlLabel
                                            value='fullQty'
                                            control={<Radio size='small' />}
                                            label='Max'
                                            sx={{
                                              '& .MuiTypography-root': {
                                                color: '#2F2F2F'
                                              }
                                            }}
                                          />
                                          <FormControlLabel
                                            value='custom'
                                            control={<Radio size='small' />}
                                            label='Custom'
                                            sx={{
                                              '& .MuiTypography-root': {
                                                color: '#2F2F2F'
                                              }
                                            }}
                                          />
                                        </RadioGroup>
                                      </Box>
                                    </Box>
                                    {watch('shareQty') === 'custom'
                                      ? <Box sx={{ display: 'flex', position: 'relative', marginBottom: '18px', alignItems: 'center', width: '100%' }}>
                                        <TextField
                                          id='enteredShares'
                                          name='enteredShares'
                                          type='text'
                                          size='small'
                                          placeholder='No. of Shares'
                                          {...register('enteredShares')}
                                          onKeyDown={(event) => isNumberOnly(event)}
                                          error={Boolean(errors.enteredShares) || (watch('shareQty') === 'custom' && parseFloat(watch('enteredShares')) > (selectedRow?.shares)) || (watch('shareQty') === 'custom' && (watch('enteredShares')) === '0')}
                                          helperText={(errors.enteredShares && errors?.enteredShares?.message) || ((watch('shareQty') === 'custom' && parseFloat(watch('enteredShares')) > (selectedRow?.shares)) && 'Shares exceed available quantity') || (watch('shareQty') === 'custom' && (watch('enteredShares') === '0' && 'Shares should be greater than 0'))}
                                          onChange={(e) => setValue('enteredShares', e.target.value)}
                                          required
                                          sx={{
                                            width: '50%',
                                            opacity: '1',
                                            borderRadius: '2px',
                                            border: 'none',
                                            mb: 2,
                                            ml: 1,
                                            height: '28px',
                                            '& .MuiFormHelperText-root': {
                                              fontSize: '11px'
                                            }
                                          }}
                                        />
                                        <FormHelperText sx={{ position: 'absolute', top: -20, right: 270, color: '#2F2F2F' }}>{`Max Qty : ${selectedRow?.shares}`}</FormHelperText>
                                        </Box>
                                      : (
                                        <Box sx={{ display: 'flex', position: 'relative', marginBottom: '18px', alignItems: 'center', width: '100%' }}>
                                          <TextField
                                            id='shares'
                                            name='shares'
                                            type='text'
                                            size='small'
                                            placeholder='No. of Shares'
                                            value={selectedRow?.shares}
                                            disabled
                                            sx={{
                                              width: '50%',
                                              opacity: '1',
                                              borderRadius: '2px',
                                              border: 'none',
                                              mb: 2,
                                              ml: 1,
                                              height: '28px'
                                            }}
                                          />
                                        </Box>
                                        )}
                                  </Box>
                                  <Divider sx={{ padding: '12px' }} />
                                  <Box sx={{ display: 'flex' }}>
                                    <Box my={2} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                      <Typography sx={{ color: '#74788D' }}>Security</Typography>
                                      <Typography sx={{ color: '#74788D' }}>Local Symbol</Typography>
                                      <Typography sx={{ color: '#74788D' }}>Original Purchase Price</Typography>
                                      <Typography sx={{ color: '#74788D' }}>Purchase Date</Typography>
                                    </Box>
                                    <Box my={2} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                      <Typography sx={{ color: '#2F2F2F' }}>{selectedRow?.instrId}</Typography>
                                      <Typography sx={{ color: '#2F2F2F' }}>{selectedRow?.localSymbol}</Typography>
                                      <Typography sx={{ color: '#2F2F2F' }}>{selectedRow?.orgPurchasePrice ? formatCurrencyWithSymbol(selectedRow?.orgPurchasePrice, 2, '$') : ''}</Typography>
                                      <Typography sx={{ color: '#2F2F2F' }}>{selectedRow?.purchaseDate ? dayjs(selectedRow?.purchaseDate).format('YYYY-MM-DD') : ''}</Typography>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '5px', marginTop: '0.5px' }}>
                                  <Button variant='text' size='small' onClick={handleSellCancel} sx={{ color: '#0586FF', fontWeight: 'normal', textDecoration: 'none', textTransform: 'none', minWidth: 'unset', minHeight: 'unset' }}>CANCEL</Button>
                                  <Button
                                    variant='contained' size='small' onClick={handleSellConfirm}
                                    disabled={
                                      Boolean(errors.enteredShares) ||
                                      (watch('shareQty') === 'custom' &&
                                        (
                                          parseFloat(watch('enteredShares')) > (selectedRow?.shares || 0) ||
                                          watch('enteredShares').trim() === '' ||
                                          parseFloat(watch('enteredShares')) === 0
                                        ))
                                    } sx={{ height: '28px', backgroundColor: '#3A76D4', borderRadius: '5.59px', color: 'white', '&:hover': { backgroundColor: '#314080' } }}
                                  >Submit
                                  </Button>
                                </Box>
                              </Box>
                            </Modal>
                          </Grid>
                        )}
                        </>
                      : ''}
                  </Grid>
                </CardContent>
              </Card>
              </Grid>
            : ''}
          <Grid item xs={12}>
            {watch('tradeSellOption') !== 'taxlot' && (
              <Box sx={{ textAlign: 'center', position: 'absolute', right: '0px', top: '-58px' }}>
                <Button type='submit' variant='contained' onClick={handleSubmit}>Submit</Button>
              </Box>)}
            <Modal open={isModalOpen} onClose={handleCloseModalOnSubmit}>
              <Box sx={style}>
                <Typography sx={{ fontSize: '18px', fontWeight: 600, color: '#002A59' }} mb='14px'>Confirm Trade</Typography>
                {watch('tradeSide') === 'buy' && buyValidationData && buyValidationData?.cashDifference < 0
                  ? <>
                    <Typography sx={{ fontSize: '14px', textAlign: 'left !important', color: 'red' }}>
                      {buyValidationData?.message}
                    </Typography>
                    </>
                  : <Typography sx={{ fontSize: '16px', textAlign: 'left !important' }}>
                    Are you sure you want to confirm?
                    </Typography>}
                <Divider sx={{ padding: '10px' }} />
                <Box sx={{ marginTop: '16px' }}>
                  {watch('accountType') === 'account' || watch('accountType') === 'aggregate'
                    ? <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ color: '#74788D', width: '50%' }}>
                        {watch('accountType') === 'account' ? 'Account Name' : watch('accountType') === 'aggregate' ? 'Aggregate Name' : 'N/A'}
                      </Typography>
                      <Typography sx={{ color: '#2F2F2F', textTransform: 'capitalize', width: '50%' }}>
                        {watch('accountType') === 'account' ? watch('accountName') : watch('accountType') === 'aggregate' ? watch('aggregateName') : 'N/A'}
                      </Typography>
                      </Box>
                    : ''}
                  {watch('accountType') === 'aggregate'
                    ? <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ color: '#74788D', width: '50%' }}>Tagged Account Name</Typography>
                      <Typography sx={{ color: '#2F2F2F', textTransform: 'capitalize', width: '50%' }}>
                        {aggTaggedAccountId?.accountName || 'N/A'}
                      </Typography>
                      </Box>
                    : ''}
                  {watch('accountType') === 'adhoc'
                    ? <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ color: '#74788D', width: '50%' }}>Sponsor</Typography>
                      <Typography sx={{ color: '#2F2F2F', textTransform: 'capitalize', width: '50%' }}>{selectedSponsor?.sponsorName || 'N/A'}</Typography>
                      </Box>
                    : ''}
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ color: '#74788D', width: '50%' }}>Custodian</Typography>
                    <Typography sx={{ color: '#2F2F2F', textTransform: 'capitalize', width: '50%' }}>{custodianValue?.custodianName || 'N/A'}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ color: '#74788D', width: '50%' }}>Custodian Number</Typography>
                    <Typography sx={{ color: '#2F2F2F', textTransform: 'capitalize', width: '50%' }}>{watch('custodianId')}</Typography>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ color: '#74788D', width: '50%' }}>Master ID</Typography>
                    <Typography sx={{ color: '#2F2F2F', textTransform: 'capitalize', width: '50%' }}>{watch('masterId')}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ color: '#74788D', width: '50%' }}>Trade Side</Typography>
                    <Typography sx={{ color: '#2F2F2F', textTransform: 'capitalize', width: '50%' }}>{watch('tradeSide')}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ color: '#74788D', width: '50%' }}>Ticker</Typography>
                    <Typography sx={{ color: '#2F2F2F', width: '50%', overflowWrap: 'break-word' }}>
                      {watch('tradeSide') === 'buy'
                        ? buyTradeAutocompleteValue?.localSymbol || watch('securityName')
                        : (watch('tradeSellOption') === 'adhoc' || (watch('accountType') === 'adhoc' && watch('tradeSide') === 'sell'))
                            ? sellAdhocAutocompleteValue?.localSymbol || watch('sellAdhocSecurityName')
                            : (watch('accountType') === 'account'
                                ? (selectedRow && selectedRow?.localSymbol) || ''
                                : selectedRow?.localSymbol
                              )}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ color: '#74788D', width: '50%' }}>Quantity</Typography>
                    <Typography sx={{ color: '#2F2F2F', width: '50%' }}>{
                      watch('tradeSide') === 'buy'
                        ? watch('quantity')
                        : watch('tradeSellOption') === 'adhoc' || (watch('accountType') === 'adhoc' && watch('tradeSide') === 'sell')
                          ? watch('sellAdhocQuantity')
                          : watch('shareQty') === 'custom'
                            ? watch('enteredShares')
                            : selectedRow?.shares
                    }

                    </Typography>
                  </Box>
                  {watch('tradeSellOption') === 'adhoc' && watch('tradeSide') !== 'buy' && watch('tradeSide') === 'sell' && watch('orgPurchaseCost')
                    ? <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ color: '#74788D', width: '50%' }}>Original Purchase Price</Typography>
                      <Typography sx={{ color: '#2F2F2F', width: '50%' }}> {formatCurrencyWithSymbol(watch('orgPurchaseCost') || 0, 2, '$')}</Typography>
                      </Box>
                    : ''}
                  {watch('tradeSellOption') === 'adhoc' && watch('tradeSide') !== 'buy' && watch('tradeSide') === 'sell' && watch('purchaseDate')
                    ? <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ color: '#74788D', width: '50%' }}>Purchase Date</Typography>
                      <Typography sx={{ color: '#2F2F2F', width: '50%' }}>{dayjs(watch('purchaseDate')).format('YYYY-MM-DD')}</Typography>
                      </Box>
                    : ''}
                  {watch('tradeSellOption') === 'taxlot'
                    ? <>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ color: '#74788D', width: '50%' }}>Original Purchase Price</Typography>
                        <Typography sx={{ color: '#2F2F2F', width: '50%' }}>{formatCurrencyWithSymbol(selectedRow?.orgPurchasePrice || 0, 2, '$')}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ color: '#74788D', width: '50%' }}>Purchase Date</Typography>
                        <Typography sx={{ color: '#2F2F2F', width: '50%' }}>{dayjs(selectedRow?.purchaseDate).format('YYYY-MM-DD')}</Typography>
                      </Box>
                      </>
                    : ''}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                  <Button onClick={handleCloseModalOnSubmit} m={1} sx={{ fontWeight: 600, fontSize: '12px' }}>Cancel</Button>
                  <Button variant='contained' size='small' onClick={handleOnConfirm} m={1} sx={{ fontSize: '12px' }}>Confirm</Button>
                </Box>
              </Box>

            </Modal>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default CreateAdhocModal
