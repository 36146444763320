import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Modal, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import { moduleConfig } from '../../../contexts/data'
import StyledTradePopupBox from '../components/StyledTradePopupBox'
import { tradeModalStyle } from './TradeApprovalIconCells/tradeModalStyle'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'

const TradeBlotterPopUp = (props) => {
  const { open, setOpen, finalArray } = props
  const { user, checkAccess } = useAuth()
  const [tradeBlotterDataLoading, setTradeBlotterDataLoading] = useState(true)
  const { showError } = useErrorToast()
  const [tradeBlotterData, setTradeBlotterData] = useState([])

  useEffect(() => {
    if (finalArray && finalArray?.length) {
      if (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS,
        { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.VIEW_TRADE_BLOTTER })) {
        getTradeBlotterData()
      }
    }
  }, [finalArray])

  const tradeBlotterColumns = [
    {
      field: 'securityName',
      headerName: 'Security Name',
      flex: 1
    },
    {
      field: 'localSymbol',
      headerName: 'Local Symbol',
      flex: 1
    },
    {
      field: 'side',
      headerName: 'Trade Code',
      renderCell: (params) => (<>
        <span style={{ color: params.formattedValue === 'SELL' ? '#FF6161' : params.formattedValue === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.formattedValue}</span>
      </>),
      flex: 1
    },
    {
      field: 'noOfAccountsInTrade',
      headerName: 'Accounts Trading',
      flex: 1,
      renderCell: (props) => props?.row?.noOfAccountsInTrade !== undefined && props?.row?.noOfAccountsInTrade !== null && finalArray && finalArray?.length
        ? `${props?.row?.noOfAccountsInTrade}/${finalArray?.length}`
        : '0/0',
      align: 'right',
      headerAlign: 'right'
    }
  ]

  const getTradeBlotterData = () => {
    API.post('baseUriTrade', `trade/v1/${user?.userGroup}/trade-blotter`,
      { body: { accountIds: [...finalArray.map(acc => acc?.accountId)] } })
      .then((res) => {
        setTradeBlotterData(res?.data && res?.data?.length ? res?.data?.map((row) => ({ ...row, id: randomId() })) : [])
      }).catch((error) => {
        setTradeBlotterDataLoading(false)
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => setTradeBlotterDataLoading(false))
  }

  const handleCloseTradeErrorModal = () => {
    setOpen(false)
  }

  const renderTableSkeleton = (header) => {
    return (
      <TableContainer mt={5}>
        <Table className='risk-page-table'>
          <TableHead>
            <TableRow>
              {header.map((item, index) => {
                return (
                  <TableCell key={index}>{item.headerName}</TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from({ length: 5 }).map((_, index) => (
              <TableRow key={index}>
                {Array.from({ length: header.length }).map((_, index) => (
                  <TableCell key={index}>
                    <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                  </TableCell>))}
              </TableRow>))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <Modal
      open={open}
      onClose={handleCloseTradeErrorModal}
    >
      <Box
        sx={{ ...tradeModalStyle, maxWidth: '900px', py: 2 }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='h6' component='h2'>
            Trade Blotter
          </Typography>
          <IconButton aria-label='close' onClick={handleCloseTradeErrorModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <StyledTradePopupBox
          sx={{
            height: '650px',
            maxHeight: '700px',
            overflow: 'auto',
            scrollbarGutter: 'stable'
          }}
        >
          {tradeBlotterDataLoading
            ? (
                renderTableSkeleton(tradeBlotterColumns)
              )
            : (
              <DataGridPro
                autoHeight
                rows={tradeBlotterData}
                columns={tradeBlotterColumns}
                density='compact'
                pagination
                pageSizeOptions={[15]}
                disableRowSelectionOnClick
                initialState={{
                  ...tradeBlotterData?.initialState,
                  pagination: { paginationModel: { pageSize: 15 } }
                }}
                sx={(theme) => ({
                  [`.${gridClasses.main}`]: {
                    overflow: 'unset'
                  },
                  [`.${gridClasses.columnHeaders}`]: {
                    position: 'sticky',
                    top: -1,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1,
                    minHeight: '60px !important'
                  },
                  [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                    color: '#74788d',
                    fontWeight: 600
                  },
                  [`.${gridClasses.footerContainer}`]: {
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1
                  },
                  [`.${gridClasses.toolbarContainer}`]: {
                    position: 'sticky',
                    top: 0,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1
                  }
                })}
              />
              )}
        </StyledTradePopupBox>
      </Box>
    </Modal>

  )
}

export default TradeBlotterPopUp
