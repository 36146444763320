import { getUserRole } from '../../../utils/getUserRole'
import { GetAPI } from '../components/Actions'

const accountOptimizationStatusAPI = (id) => {
  const userRole = getUserRole()
  return GetAPI(
    'baseUriOptimizationDataMaintenance',
    `data-maintenance/v1/${userRole}/optimizations/account-opt-status/${id}`
  )
}

const accountPortSpecAPI = (path) => {
  const userRole = getUserRole()
  return GetAPI(
    'baseAccountPortSpecURL',
    `data-maintenance/v1/${userRole}/${path}`
  )
}

const accountPropertiesAPI = () => {
  const userRole = getUserRole()
  return GetAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/properties`
  )
}

const accountAPI = (id, parameter) => {
  const userRole = getUserRole()
  return GetAPI(
    'baseAccountURL',
    `data-maintenance/v1/${userRole}/accounts/${id}/${parameter}`
  )
}

export const optimizationAPI = () => {
  const userRole = getUserRole()
  return GetAPI('baseUriOptimizationDataMaintenance', `data-maintenance/v1/${userRole}/optimizations/all-status`)
}

export const GetAccountData = async (id) => {
  const apiCalls = [
    accountAPI(id, 'master'),
    accountAPI(id, 'external-gain-loss'),
    accountAPI(id, 'int-gl'),
    accountAPI(id, 'parameter'),
    accountPortSpecAPI('accounts/port-spec'),
    accountPortSpecAPI('portfolio/specification/properties'),
    accountAPI(id, 'instr-substitution'),
    accountAPI(id, 'instr-restriction'),
    accountAPI(id, 'tax-rates'),
    accountPropertiesAPI('properties'),
    accountOptimizationStatusAPI(id)
  ]
  const responses = await Promise.allSettled(apiCalls)

  const [
    accountMasterAPIData,
    accountExternalGLAPIData,
    accountInternalGLAPIData,
    accountParametersAPIData,
    accountPortSpecAPIData,
    accountPortSpecPropertiesAPIData,
    accountSubstitutionAPIData,
    accountRestrictionsAPIData,
    accountTaxRatesAPIData,
    accountPropertiesAPIData,
    accountOptimizationStatusAPIData
  ] = responses

  // If any API is falling then try passing/returning [] instead of undefined
  return [
    accountMasterAPIData?.value,
    accountExternalGLAPIData?.value,
    accountInternalGLAPIData?.value,
    accountParametersAPIData?.value,
    accountPortSpecAPIData?.value,
    accountPortSpecPropertiesAPIData?.value,
    accountSubstitutionAPIData?.value,
    accountRestrictionsAPIData?.value,
    accountTaxRatesAPIData?.value,
    accountPropertiesAPIData?.value,
    accountOptimizationStatusAPIData?.value
  ]
}
