import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { useAuth } from '../../../../contexts/AuthContext'
import { addChartValues, createPageForOverflowingElements, handleTableOverflow, storeCustomTableRows } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { CUSTOM_TABLE_TYPE } from '../../../../store/pdf-customizer-reducer/Action/chartState.types'
import { chartDataState } from '../../../../store/pdf-customizer-reducer/Action/chartStateAction'
import { queueChange } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { customTableData } from '../../../../utils/pdf-customizer/_data'
import { CUSTOM_TABLE_EXAMPLE_DATA, DATA_TYPE } from '../../common/componentData'
import { MAX_GRID_COUNT } from '../../PDFLayout'
import Table from './Table'

// this function returns the number of rows that can be shown inside the element
// add one row and calculate the remaining space in the element height
// if no space is there then try to increase the element height only if shoOverflow is true
// if element overflows from container then note the last index and create new component and start from last index
const getDisplayRows = (elementHeight, containerHeight, remainingGridRowsCount, rows, rowHeight, startIndex, lastIndex, showOverflow, isResponseElements) => {
  let renderRows = []
  let currentDataIndex = startIndex || 0
  let modifiedElementHeight = elementHeight
  let newContainerCount = 0 // container count to increase
  let newCellCount = 0 // cell count to increase
  if (lastIndex !== null && lastIndex !== undefined) {
    renderRows = rows.slice(startIndex, lastIndex + 1)
    return { renderRows, newCellCount, newContainerCount, lastIndex }
  }
  // if no overflow in element and container and current iterator is not at last row that means there is still space available in the element and rows too
  while (modifiedElementHeight >= 0 && remainingGridRowsCount >= 0 && currentDataIndex < rows.length) {
    // reduce element height
    modifiedElementHeight = modifiedElementHeight - rowHeight
    // element height is in negative after adding row
    if (modifiedElementHeight < 0 && showOverflow && isResponseElements) {
      // add extra height in element height same as one row height of react grid
      modifiedElementHeight = modifiedElementHeight + parseFloat(containerHeight / MAX_GRID_COUNT)
      // remove height from container's height same as one row height of react grid
      remainingGridRowsCount--

      // if available space in container becomes negative after increasing height of element, then break execution and create new container
      if (remainingGridRowsCount < 0) {
        newContainerCount = newContainerCount + 1
        break
      }
      // if available space is not negative then increase cell count of element
      // this cell count will be added in elements height 
      newCellCount = newCellCount + 1
    }
    else if (!showOverflow && modifiedElementHeight < 0) {
      break
    }
    // store current data index as last index and push that row in renderable rows array 
    lastIndex = currentDataIndex
    renderRows.push(rows[currentDataIndex])
    currentDataIndex++
  }
  return { renderRows, newCellCount, newContainerCount, lastIndex }
}

const customTableApiInstance = {}

const CustomTable = ({
  data,
  chartIndex,
  index,
  chartHeight,
  chartWidth,
  clickEvent
}) => {
  const dispatch = useDispatch()
  const { isResponseElements, customTableResponse } = useSelector(
    (state) => state.chartState
  )
  const templateData = useSelector(state => state.templateData)
  const { customTableRows, customTableRowHeight, overlappingElementsWithTable, customTableOverflowElementsQueue } = useSelector(state => state.elementStates)
  const { user } = useAuth()
  const [displayRows, setDisplayRows] = useState([])

  const componentName = data?.value ? data?.value?.dataSource?.split(' ').map(word => word?.toLowerCase()).join('-') : ''

  async function fetchAndStoreData(fetchDataCallback, action) {
    const response = await fetchDataCallback()
    if (response?.success) {
      dispatch(chartDataState({ dataSource: componentName, data: response?.data[componentName] ? response?.data[componentName].data : [], loading: false }, action))
      customTableApiInstance[componentName] = false
    } else {
      dispatch(chartDataState({ dataSource: componentName, data: [], loading: false }))
      customTableApiInstance[componentName] = false
    }
  }

  useEffect(() => {
    if (isResponseElements && componentName && !customTableResponse[componentName]?.loading && !customTableResponse[componentName]?.data && !customTableApiInstance[componentName]) {
      customTableApiInstance[componentName] = true
      dispatch(chartDataState({ dataSource: componentName, data: [], loading: true }, CUSTOM_TABLE_TYPE))
      fetchAndStoreData(() => customTableData({ accountId: templateData?.accountId, componentName: [componentName] }, user?.userGroup), CUSTOM_TABLE_TYPE, customTableResponse)
    }
  }, [data?.value?.dataSource, isResponseElements, customTableResponse])

  const datasets = data?.value?.datasets ? [...data?.value?.datasets] : []
  const group = data?.value?.group ? [...data?.value?.group] : []
  let filterColumns = data?.value?.filters ? [...data?.value?.filters] : []
  const filterType = data?.value?.filterType ? data?.value?.filterType : 'include'

  const groupColumns = group?.map(g => g.field) || []
  const headingColumns = datasets || []
  const element = document.getElementById(`${data?.id}`)
  // current container's height
  const container = document.querySelector(`.container-${data?.pageIndex}`)
  // const lastColumn = data?.value?.level
  // const dataLabel = CUSTOM_TABLE_EXAMPLE_DATA[data?.value?.dataSource] || []

  const formatValue = (value, colObj) => {
    switch (colObj.displayType) {
      case 'Currency':
        return applyCurrencyFormat(colObj.formatObj, value)
      case 'Percentage':
        return applyPercentageFormat(colObj.formatObj, value)
      case 'Number':
        return applyNumberFormat(colObj.formatObj, value)
      case 'Date':
        return applyDateFormat(colObj.formatObj, value)
      case 'Text':
        return applyTextFormat(colObj.formatObj, value)
      default:
        return value
    }
  }

  const doesFilterValueSatisfyOperator = (filterObj, formattedValue, headerType) => {
    if (headerType === DATA_TYPE.DATE) {
      switch (filterObj?.operator) {
        case 'Equals':
          return dayjs(formattedValue).isSame(filterObj?.value)
        case 'Does not equal':
          return !dayjs(formattedValue).isSame(filterObj?.value)
        case 'Before':
          return dayjs(formattedValue).isBefore(filterObj?.value, 'day')
        case 'After':
          return dayjs(formattedValue).isAfter(filterObj?.value, 'day')
        case 'Between':
          return dayjs(formattedValue).isAfter(filterObj?.value?.from) && dayjs(formattedValue).isBefore(filterObj?.value?.to)
        case 'Blank':
          return formattedValue === ''
        case 'Not blank':
          return formattedValue !== ''
        default:
          return false
      }
    }
    switch (filterObj?.operator) {
      case 'Equals':
        return formattedValue === filterObj?.value
      case 'Does not equal':
        return formattedValue !== filterObj?.value
      case 'Starts with':
        return formattedValue.toLowerCase().startsWith(filterObj?.value.toLowerCase())
      case 'Ends with':
        return formattedValue.toLowerCase().endsWith(filterObj?.value.toLowerCase())
      case 'Contains':
        return formattedValue.includes(filterObj?.value)
      case 'Does not contain':
        return !formattedValue.includes(filterObj?.value)
      case 'Greater than':
        return formattedValue > filterObj?.value
      case 'Greater than or equal to':
        return formattedValue >= filterObj?.value
      case 'Less than':
        return formattedValue < filterObj?.value
      case 'Less than or equal to':
        return formattedValue <= filterObj?.value
      case 'Between':
        return formattedValue >= filterObj?.value?.from && formattedValue <= filterObj?.value?.to
      case 'Blank':
        return formattedValue === ''
      case 'Not blank':
        return formattedValue !== ''
      default:
        return false
    }
  }

  const isFilterAvailable = (filterObj) => {
    if (filterObj.operator === 'Blank' || filterObj.operator === 'Not blank') {
      return true
    }
    if (filterObj.operator === 'Between' && (filterObj.value.from === '' || filterObj.value.from === null || filterObj.value.to === '' || filterObj.value.to === null)) {
      return false
    } else if (filterObj.operator !== 'Between' && filterObj.value === '' || filterObj.value === null) {
      return false
    } else {
      return true
    }
  }

  const filterValues = (columns, rows) => {
    const filteredRows = []
    rows.forEach((rowObj) => {
      let multipleColumnFiltersPassed = 1
      columns.forEach((colObj) => {
        // apply filters if filters are correct (i.e. has valid data based on operation)
        const allFiltersOnColumn = colObj?.filterObj?.filters.filter(filter => isFilterAvailable(filter))
        if (allFiltersOnColumn?.length) {
          const logicalOperator = colObj?.filterObj?.logicalOperator || 'AND'
          let multipleFiltersPassed = logicalOperator === 'OR' ? 0 : 1
          const formattedVal = formatValue(rowObj[colObj.field], colObj)
          colObj?.filterObj?.filters?.forEach((filter) => {
            if (logicalOperator === 'AND')
              multipleFiltersPassed = multipleFiltersPassed && doesFilterValueSatisfyOperator(filter, formattedVal, colObj?.headerType)
            else if (logicalOperator === 'OR')
              multipleFiltersPassed = multipleFiltersPassed || doesFilterValueSatisfyOperator(filter, formattedVal, colObj?.headerType)
          })
          // compulsory AND operation on different column filter
          multipleColumnFiltersPassed = multipleColumnFiltersPassed && multipleFiltersPassed
        }
      })
      if (multipleColumnFiltersPassed) {
        filteredRows.push(rowObj)
      }
    })
    return filteredRows
  }

  // if (!group.length)
  //   tableRows = filterValues(datasets, tableRows)

  function groupByField(array, field) {
    const group = {}
    const visited = []
    array.forEach((element) => {
      if (visited.includes(element[field])) {
        group[element[field]].push(element)
      } else {
        group[element[field]] = [element]
        visited.push(element[field])
      }
    })
    return group
  }

  const createHierarchyUtil = (data, index) => {
    if (index === groupColumns.length) {
      return data
    }
    const group = groupByField(data, groupColumns[index])
    for (let key in group) {
      group[key] = createHierarchyUtil(group[key], index + 1)
    }
    return group
  }

  // const ignoredColumns = headingColumns.reduce((acc, curr) => {
  //   if (curr.ignoreGrouping === false)
  //     acc[curr.field] = null
  //   return acc
  // }, {})

  const applyAggregateFunction = (aggregateFunction, inputArray) => {
    switch (aggregateFunction) {
      case 'sum':
        return inputArray.reduce((acc, val) => acc + val, 0)
      case 'average':
        return inputArray.reduce((acc, val) => acc + val, 0) / inputArray.length
      case 'min':
        return Math.min(...inputArray)
      case 'max':
        return Math.max(...inputArray)
      case 'first':
        return inputArray.length > 0 ? inputArray[0] : ''
      case 'last':
        return inputArray.length > 0 ? inputArray[inputArray.length - 1] : ''
      case 'blank':
        return ''
      default:
        return ''
    }
  }

  // apply aggregate functions
  const aggregateData = (inputArray, flag) => {
    // returns an object with aggregation applied and filter
    const aggregateObj = {}
    let multipleColumnFiltersPassed = 1 // flag to filter data on multiple columns
    headingColumns.forEach(colObj => {
      if (colObj?.field === 'groupName') return
      const fieldValueArray = inputArray.map(obj => obj[colObj.field]) // take out data of one column
      const value = applyAggregateFunction(colObj?.formatObj?.aggregateFunction, fieldValueArray)
      // no need to filter the data if flag is not set
      // don't want to filter if all the rows are hidden
      if (!flag) {
        const allFiltersOnColumn = colObj?.filterObj?.filters?.filter(filter => isFilterAvailable(filter))
        if (allFiltersOnColumn?.length) {
          const formattedValue = formatValue(value, colObj)
          const logicalOperator = colObj?.filterObj?.logicalOperator || 'AND'
          let multipleFiltersPassed = logicalOperator === 'OR' ? 0 : 1 // flag to filter data on same column
          colObj?.filterObj?.filters?.forEach((filter) => {
            if (isFilterAvailable(filter)) {
              if (logicalOperator === 'AND')
                multipleFiltersPassed = multipleFiltersPassed && doesFilterValueSatisfyOperator(filter, formattedValue, colObj?.headerType)
              else if (logicalOperator === 'OR')
                multipleFiltersPassed = multipleFiltersPassed || doesFilterValueSatisfyOperator(filter, formattedValue, colObj?.headerType)
            }
          })
          multipleColumnFiltersPassed = multipleColumnFiltersPassed && multipleFiltersPassed
        }
      }
      // add aggregated value in the object
      aggregateObj[colObj?.field] = value
    })
    // if the aggregated value statisfies with filters applied then don't hide this row
    if (multipleColumnFiltersPassed) {
      aggregateObj.hide = false
    }
    else {
      aggregateObj.hide = true
    }
    return aggregateObj
  }

  const generateArrayFromHierarchyUtil = (hierarchy, array, level) => {
    if (Array.isArray(hierarchy)) {
      // return aggregated object
      return aggregateData(hierarchy)
    }
    // create current level array to store sibling nodes aggregate result
    let resultArray = []
    let isAnyRowNotHidden = 0 // flag to check if any of the row is not hidden on current level
    for (const key in hierarchy) {
      const tempArray = []
      const result = generateArrayFromHierarchyUtil(hierarchy[key], tempArray, level + 1)
      // unshift parent row with aggregated object from child
      isAnyRowNotHidden = isAnyRowNotHidden || !result?.hide
      tempArray.unshift({ ...result, groupName: hierarchy[key][groupColumns[level]] || key, level, hide: result?.hide })
      // push generated array into original array
      tempArray.forEach(obj => {
        array.push(obj)
      })
      resultArray.push(result)
    }
    // in exclude aggregate only those data which is not hidden
    if (filterType === 'include') {
      resultArray = resultArray?.filter(obj => !obj?.hide)
    }
    // anything other than exclude apply aggregate on all the data
    return aggregateData(resultArray, isAnyRowNotHidden)
  }

  // const createHierarchy = () => {
  //   return createHierarchyUtil(dataLabel, 0)
  // }

  const generateArrayFromHierarchy = (dataRows) => {
    const ans = []
    const hierarchy = createHierarchyUtil(dataRows, 0)
    generateArrayFromHierarchyUtil(hierarchy, ans, 0)
    return ans
  }

  // if (group.length > 0) {
  //   tableRows = generateArrayFromHierarchy()
  //   tableRows = tableRows?.filter(row => row.hide === false)
  // }

  function applyCurrencyFormat(formatObj, value) {
    if (value === null || value === undefined || value === '') {
      return ''
    }
    let finalCurrencyValue = Number.isInteger(value) ? parseInt(value) : parseFloat(value)
    if (formatObj?.decimal !== undefined) {
      finalCurrencyValue = Number(finalCurrencyValue?.toFixed(formatObj?.decimal))
    }
    return finalCurrencyValue
  }

  function applyPercentageFormat(formatObj, value) {
    if (value === null || value === undefined || value === '') {
      return ''
    }
    let finalNumber = parseFloat(value) * 100
    if (formatObj?.decimal !== undefined) {
      finalNumber = Number(finalNumber.toFixed(formatObj?.decimal))
    }
    return finalNumber
  }

  function applyNumberFormat(formatObj, value) {
    if (value === null || value === undefined || value === '') {
      return ''
    }
    let isInteger = Number.isInteger(value)
    let finalNumber = isInteger ? parseInt(value) : parseFloat(value)
    if (formatObj?.decimal !== undefined) {
      finalNumber = Number(finalNumber.toFixed(formatObj?.decimal))
    }
    return finalNumber
  }

  function applyDateFormat(formatObj, value) {
    if (value === null || value === undefined || value === '') {
      return ''
    }
    return value.split('T')[0]
  }

  function applyTextFormat(formatObj, value) {
    return value
  }

  const chartStyle = {
    height: '100%',
    width: '100%',
  }

  function addTotalRows(rows, groupFlag) {
    const lastTotalRow = {}
    if (groupFlag) {
      datasets.forEach((colObj, colIndex) => {
        if (colIndex === 0) {
          lastTotalRow[colObj.field] = 'Total'
          lastTotalRow['level'] = 0
          lastTotalRow['lastTotalRow'] = true
          return
        }
        if (colObj.headerType === DATA_TYPE.NUMBER) {
          let sum = 0
          rows.forEach((row, index) => {
            if (row.level === 0) {
              sum += row[colObj?.field]
            }
          })
          lastTotalRow[colObj.field] = sum
        }
        else {
          lastTotalRow[colObj.field] = ''
        }
      })
      rows.push(lastTotalRow)
    }
  }

  const [tableRows, setTableRows] = useState([])

  // table row creation
  useEffect(() => {
    if (data.value?.dataSource && data?.overflowElementId) {
      // if table rows are already present in redux state, use globally stored rows
      if (customTableRows[`${data.value?.dataSource}-${data?.overflowElementId}`] && customTableRows[`${data.value?.dataSource}-${data?.overflowElementId}`]?.length) {
        setTableRows(customTableRows[`${data.value?.dataSource}-${data?.overflowElementId}`])
        return
      }
      // if report is in preview mode, use API response mapping else use dummy data
      let dataRows = isResponseElements ? (customTableResponse[componentName]?.data || []) : (CUSTOM_TABLE_EXAMPLE_DATA[data?.value?.dataSource] || [])
      if (!dataRows?.length) {
        setDisplayRows([])
      }
      if (!group.length && datasets?.length && dataRows?.length) {
        // if no group is present but columns are there, apply filter on the rows
        const updatedTableRows = filterValues(datasets, dataRows)
        setTableRows(updatedTableRows)
        // push table into queue if overflow is enabled
        if (isResponseElements && !customTableOverflowElementsQueue.includes(data?.id) && data?.value?.showOverflow) {
          dispatch(queueChange({ queueAction: 'push', item: data?.id }))
        }
        dispatch(storeCustomTableRows({ [`${data?.value?.dataSource}-${data?.overflowElementId}`]: updatedTableRows }))
      }
      else if (group.length > 0 && datasets?.length && dataRows?.length) {
        // if group is present, generate hierarchy rows as per the group
        let updatedTableRows = generateArrayFromHierarchy(dataRows)?.filter(row => row?.hide === false)
        addTotalRows(updatedTableRows, 1)
        setTableRows(updatedTableRows)
        // push table into queue if overflow is enabled
        if (isResponseElements && !customTableOverflowElementsQueue.includes(data?.id) && data?.value?.showOverflow) {
          dispatch(queueChange({ queueAction: 'push', item: data?.id }))
        }
        dispatch(storeCustomTableRows({ [`${data.value?.dataSource}-${data?.overflowElementId}`]: updatedTableRows }))
      }
    }
  }, [data.value, customTableResponse])

  const handleOverflow = () => {
    if (element?.clientHeight && container?.clientHeight && data?.value?.dataSource && data?.overflowElementId && tableRows?.length && customTableRows && customTableRows[`${data?.value?.dataSource}-${data?.overflowElementId}`]?.length) {
      let maxHeaderHeight = customTableRowHeight
      const updatedTableRows = customTableRows[`${data.value?.dataSource}-${data?.overflowElementId}`] || tableRows
      element?.querySelectorAll('.header-data-parent')?.forEach(node => {
        if (node.clientHeight > maxHeaderHeight) {
          maxHeaderHeight = node?.clientHeight
        }
      })
      const remainingSpaceInContainer = container?.clientHeight - (data?.position?.y * parseFloat(container?.clientHeight / MAX_GRID_COUNT))
      const elementHeight = element?.clientHeight > remainingSpaceInContainer ? remainingSpaceInContainer : element?.clientHeight
      const dynamicRowsObj = getDisplayRows(
        elementHeight - maxHeaderHeight - 12 - 15, // reduce table header height, table top padding and element title height from available space of element height
        container?.clientHeight,
        MAX_GRID_COUNT - (data?.position?.y + data?.position?.h),
        updatedTableRows,
        customTableRowHeight,
        data?.value?.showOverflow ? data?.startIndex : 0,
        data?.lastIndex,
        data?.value?.showOverflow,
        isResponseElements
      )
      // set rows as display rows which is returned as per element and container height
      setDisplayRows(dynamicRowsObj.renderRows)
      // add extra cells required to be added in element for showing rows and add new container if all remaining rows can not be shown in current page
      if (dynamicRowsObj.newCellCount || dynamicRowsObj.newContainerCount) {
        dispatch(handleTableOverflow({ cellCount: dynamicRowsObj.newCellCount, hasRemainingData: Boolean(dynamicRowsObj.newContainerCount), elementIndex: data?.chartIndex, pageIndex: data?.pageIndex, lastIndex: dynamicRowsObj.lastIndex }))
      }
      // if no extra cells or page needs to be added and overlapping elements are there with current table, create page for overlapping elements.
      if (!dynamicRowsObj?.newContainerCount && !dynamicRowsObj?.newCellCount && dynamicRowsObj?.lastIndex === updatedTableRows?.length - 1 && overlappingElementsWithTable?.length) {
        dispatch(createPageForOverflowingElements({ pageIndex: data?.pageIndex }))
      }
      // if no extra cells or page needs to be added, remove current element from queue
      if (!dynamicRowsObj?.newContainerCount && !dynamicRowsObj?.newCellCount && (data?.value?.showOverflow && dynamicRowsObj?.lastIndex === updatedTableRows?.length - 1)) {
        if (isResponseElements && customTableOverflowElementsQueue.length > 0 && (customTableOverflowElementsQueue[0] === data?.id || customTableOverflowElementsQueue[0] === data?.overflowElementId))
          dispatch(queueChange({ queueAction: 'pop' }))
      }
    }
  }

  useEffect(() => {
    // if overflow is enabled in element, then only check for queue
    if (isResponseElements && customTableOverflowElementsQueue.length > 0) {
      if (customTableOverflowElementsQueue[0] === data?.overflowElementId) {
        handleOverflow()
      }
    }
    // if report is not in preview mode or overflow is not enabled in table, process row calculation
    else if (!isResponseElements || !data?.value?.showOverflow) {
      handleOverflow()
    }
  }, [element?.clientHeight, container?.clientHeight, tableRows, data?.value?.showOverflow, isResponseElements, customTableOverflowElementsQueue])

  const storeColumnWidthInRedux = (datasets) => {
    dispatch(addChartValues({ pageIndex: data?.pageIndex, chartIndex, datasets }))
  }


  return (
    <div
      className={'group'}
      style={chartStyle}
    >
      <p
        style={{
          margin: 0, fontSize: '10px', fontWeight: 500
        }}>
        {data?.headingText || data?.name}
      </p>
      {customTableResponse && customTableResponse[componentName]?.loading
        ? <span className='loading-text'>Loading...</span>
        : <Table columns={datasets} rows={displayRows} storeColumnWidth={storeColumnWidthInRedux} columnWidth={data?.value?.width} />
      }
    </div>
  )
}

export default CustomTable