import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Typography, Autocomplete, TextField, Select, MenuItem, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import 'rc-slider/assets/index.css'
import '../../assets/styles/topbar.scss'
import ManageAccountsTable from './ManageAccountsTable'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import ManageStrategyTable from './ManageStrategyTable'

const RoleAndAccountManagePopUp = (props) => {
  const {
    showModal, showRoleModel, closeModal, allSpnAccounts, data,
    allSpnTradingAccounts, spnAccountLoading, strategyListLoading, allStrategiesList,
    modelType
  } = props
  const { user } = useAuth()
  const [roleListOptions, setRoleListOptions] = useState([])
  const [selectedRoleValue, setSelectedRoleValue] = useState(null)
  const [resourceIdList, setResourceIdList] = useState([])
  const [showCopyResIdList, setShowCopyResIdList] = useState(false)
  const { showError } = useErrorToast()
  const [selectedValue, setSelectedValue] = useState(null)
  const [selectionModel, setSelectionModel] = useState([])
  const [allSpnAccountsFlag, setAllSpnAccountsFlag] = useState(false)
  const [allStrIdsFlag, setAllStrIdsFlag] = useState(false)
  const [allActiveStrIdsFlag, setAllActiveStrIdsFlag] = useState(false)
  const [defaultCheckBoxApiLoading, setDefaultCheckBoxApiLoading] = useState(true)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: showRoleModel?.show ? '350px' : '950px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: '4px',
    '.MuiDataGrid-root': {
      border: 'none'
    },
    '.MuiDataGrid-main': {
      overflow: 'unset'
    },
    '.MuiDataGrid-columnHeaders': {
      position: 'sticky',
      top: '-4px',
      zIndex: 99,
      background: 'white'
    },
    '.MuiDataGrid-virtualScroller': {
      marginTop: '-4px!important'
    }
  }

  useEffect(() => {
    if (user && showRoleModel) {
      setRoleListOptions(showRoleModel?.props?.roleList || showRoleModel?.props)
    }
  }, [user, showRoleModel])

  useEffect(() => {
    if (user && showModal) {
      const newResourceIdList = data
        ?.map((service) => (
          service?.resources?.map((resource) => ({
            resourceName: resource?.resourceName,
            serviceId: resource?.serviceId,
            resId: resource?.resourceId
          }))
        ))
        .flat()
      setResourceIdList(newResourceIdList)
    }

    if (!showModal?.show) {
      setShowCopyResIdList(false)
      setSelectedValue(null)
      setSelectionModel([])
    }

    if (showModal?.props) {
      if ((showModal?.props?.resourceId === 'strategy-onboarding' || showModal?.props?.resourceId === 'strategy-portfolio' || showModal?.props?.resourceId === 'port-spec') && !strategyListLoading) {
        getUserStrategiesApi(showModal?.props?.resId)
      } else if (!spnAccountLoading && showModal?.props?.resourceId !== 'strategy-onboarding' && showModal?.props?.resourceId !== 'strategy-portfolio' && showModal?.props?.resourceId !== 'port-spec') {
        getUserAccountAccessApi(showModal?.props?.resId)
      }
    }
  }, [user, showModal, data, spnAccountLoading, strategyListLoading])

  const onChangeHandler = (e, value) => {
    setSelectedRoleValue(value)
  }

  const handleResourceChange = (event, newValue) => {
    setSelectedValue(newValue?.props?.value)
    getUserAccountAccessApi(newValue?.props?.className)
    getUserStrategiesApi(newValue?.props?.className)
  }

  // this api mainly used to show default selection on radio buttons and rows
  // store flags to check if all the accountIds are true then show all rows as default selected
  const getUserAccountAccessApi = (resId) => {
    setDefaultCheckBoxApiLoading(true)
    API.get('baseAclURL', `user-access-control/v1/${user?.userGroup}/user/accounts/${showModal?.props?.userId}`)
      .then((res) => {
        if (res && res?.success && res?.data) {
          const accIds = res?.data?.filter((r) => r?.resourceId + '-' + r?.serviceId === resId)?.map((accIds) => accIds?.accountIds)?.flat()

          const isAllSpnAccounts =
          res?.data?.filter((r) => r?.resourceId + '-' + r?.serviceId === resId) &&
          res?.data?.filter((r) => r?.resourceId + '-' + r?.serviceId === resId)[0]?.allSpnAccounts

          if (accIds?.some(id => allSpnAccounts?.some(account => account.accountId === id))) {
            setSelectionModel(accIds)
            setAllSpnAccountsFlag(!!isAllSpnAccounts)
          }
          setDefaultCheckBoxApiLoading(false)
        }
      }).catch((error) => {
        setDefaultCheckBoxApiLoading(false)
        showError(error?.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
      }).finally(() => setDefaultCheckBoxApiLoading(false))
  }

  // this api mainly used to show default selection on radio buttons and rows
  // store flags to check if all the strategyIds and all activeStrategyIds are true then show all rows as default selected
  const getUserStrategiesApi = (resId) => {
    setDefaultCheckBoxApiLoading(true)
    API.get('baseAclURL', `user-access-control/v1/${user?.userGroup}/user/strategies/${showModal?.props?.userId}`)
      .then((res) => {
        if (res && res?.success && res?.data) {
          const strategyId = res?.data?.filter((r) => r?.resourceId + '-' + r?.serviceId === resId)?.map((res) => res?.strategyIds)?.flat()

          const isAllStrgs =
          res?.data?.filter((r) => r?.resourceId + '-' + r?.serviceId === resId) &&
          res?.data?.filter((r) => r?.resourceId + '-' + r?.serviceId === resId)[0]?.allStrategies

          const isAllActiveStrgs =
          res?.data?.filter((r) => r?.resourceId + '-' + r?.serviceId === resId) &&
          res?.data?.filter((r) => r?.resourceId + '-' + r?.serviceId === resId)[0]?.allActiveStrategies

          if (strategyId?.some(id => allStrategiesList?.some(account => account.strategyId === id))) {
            setSelectionModel(strategyId)
            setAllStrIdsFlag(!!isAllStrgs)
            setAllActiveStrIdsFlag(!!isAllActiveStrgs)
          }
          setDefaultCheckBoxApiLoading(false)
        }
      }).catch((error) => {
        setDefaultCheckBoxApiLoading(false)
        showError(error?.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
      }).finally(() => setDefaultCheckBoxApiLoading(false))
  }

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={showModal?.show || showRoleModel?.show}
      onClose={() => {
        setAllSpnAccountsFlag(false)
        closeModal()
      }}
    >
      <Box sx={style}>
        <IconButton aria-label='close' edge='end' style={{ float: 'right', marginTop: '-10px' }} onClick={() => closeModal()}>
          <CloseIcon />
        </IconButton>
        {showCopyResIdList && modelType !== 'Strategy' && (
          <Select
            labelId='label-id'
            margin='dense'
            size='small'
            id='roleCode'
            name='roleCode'
            sx={{ position: 'absolute', top: '16px', right: '100px', width: '150px', visibility: showModal?.show && showCopyResIdList }}
            value={selectedValue || showModal?.props?.resourceName}
            fullWidth
            onChange={(event, newValue) => handleResourceChange(event, newValue)}
          >
            {resourceIdList && resourceIdList?.length
              ? (
                  resourceIdList?.map((res, index) => (
                    <MenuItem value={res.resourceName} key={index} className={res?.resId + '-' + res?.serviceId} itemID={res?.serviceId}>
                      {res?.resourceName}
                    </MenuItem>
                  ))
                )
              : []}
          </Select>
        )}
        <Tooltip title='Copy Accounts from other service'>
          <ContentCopyIcon
            sx={{ cursor: 'pointer', position: 'absolute', top: '22px', right: '60px', visibility: modelType === 'Strategy' ? 'hidden' : 'visible' }} onClick={() => setShowCopyResIdList(true)}
          />
        </Tooltip>
        {
        showModal?.show
          ? (
            <>
              <Typography variant='h6' style={{ fontFamily: 'Open Sans', fontWeight: 400, color: '#34475A', marginLeft: '16px' }}>{modelType === 'Strategy' ? 'Choose Strategies' : 'Choose Accounts'}</Typography>
              {
                 modelType === 'Strategy'
                   ? (
                     <ManageStrategyTable
                       allStrategiesList={allStrategiesList}
                       strategyListLoading={strategyListLoading}
                       selectionModel={selectionModel}
                       closeModal={closeModal}
                       allStrIdsFlag={allStrIdsFlag}
                       setSelectionModel={setSelectionModel}
                       setAllStrIdsFlag={setAllStrIdsFlag}
                       allActiveStrIdsFlag={allActiveStrIdsFlag}
                       setAllActiveStrIdsFlag={setAllActiveStrIdsFlag}
                       defaultCheckBoxApiLoading={defaultCheckBoxApiLoading}
                       modelProps={showModal?.props}
                     />
                     )
                   : (
                     <ManageAccountsTable
                       modelProps={showModal?.props}
                       allSpnTradingAccounts={allSpnTradingAccounts}
                       spnAccountLoading={spnAccountLoading}
                       allSpnAccounts={allSpnAccounts}
                       allSpnAccountsFlag={allSpnAccountsFlag}
                       selectionModel={selectionModel}
                       closeModal={closeModal}
                       setAllSpnAccountsFlag={setAllSpnAccountsFlag}
                       defaultCheckBoxApiLoading={defaultCheckBoxApiLoading}
                       setSelectionModel={setSelectionModel}
                     />
                     )
              }
            </>
            )
          : (
            <>
              <Typography variant='h6' style={{ fontFamily: 'Open Sans', fontWeight: 400, color: '#34475A', marginLeft: '-10px', marginBottom: '20px' }}>Select Role</Typography>
              <Autocomplete
                disablePortal
                id='roles'
                sx={{
                  width: 300,
                  '& .MuiInputBase-input': {
                    height: '10px'
                  },
                  '& label': {
                    margin: '-5px 3px 2px 3px'
                  },
                  marginLeft: '-10px'
                }}
                options={roleListOptions || []}
                value={selectedRoleValue}
                onChange={(event, value) => {
                  onChangeHandler(event, value)
                }}
                isOptionEqualToValue={(option, value) => {
                  return option.roleName === value
                }}
                getOptionLabel={(option) => typeof option === 'string' || option instanceof String ? option : option?.roleName}
                renderInput={(params) => <TextField sx={{ height: '40px' }} {...params} label='Roles' />}
              />
              {/* <Button
                onClick={handleRoleSave}
                sx={{
                  margin: '17px 0 0 -12px',
                  width: '100px',
                  backgroundColor: '#3369A6',
                  color: 'white',
                  '&:hover': { backgroundColor: '#3369A6' }
                }}
              >
                Save
              </Button> */}
            </>
            )
        }
      </Box>
    </Modal>
  )
}

export default RoleAndAccountManagePopUp
