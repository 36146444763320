import React from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'

export const TransitionPortfolioTable = ({ data = {} }) => {
  const { securityDataTableColumn, securityRows } = data

  return (
    <Grid item xs={12}>
      <Box className='trade-table-list-header' mb={2}>Portfolio</Box>
      {securityRows?.length > 0
        ? (
          <Box
            className='table-responsive'
            sx={{
              width: '100%',
              scrollbarWidth: 'none',
              '.MuiDataGrid-cell': {
                fontSize: '12px',
                color: '#34475A',
                fontWeight: 600
              },
              '.MuiDataGrid-cellContent': {
                fontFamily: 'Open Sans',
                color: '#34475A',
                fontWeight: 600
              },
              '.MuiDataGrid-root': {
                border: 'none'
              }
            }}
          >
            <DataGridPro
              density='compact'
              autoHeight
              rows={securityRows}
              columns={securityDataTableColumn}
              pagination
              initialState={{
                ...securityRows?.initialState,
                pagination: { paginationModel: { pageSize: 10 } }
              }}
              pageSizeOptions={[10, 25, 50, 75, 100]}
              disableRowSelectionOnClick
              sx={(theme) => ({
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none'
                },
                [`.${gridClasses.columnHeaderTitle}`]: {
                  color: '#74788d',
                  fontWeight: 600
                }
              })}
            />
          </Box>
          )
        : (
          <Stack direction='row' pt='0px' pl='20px'>
            <Typography variant='outlined' sx={{ fontSize: 14, color: '#34475A' }}>
              No Portfolio available.
            </Typography>
          </Stack>
          )}

    </Grid>
  )
}
