import { Fragment, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Box, Grid, FormControl, RadioGroup, FormControlLabel, Radio, Button, Skeleton, TableBody, TableCell, TableHead, TableRow, Table } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useAuth } from '../../contexts/AuthContext'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import { useErrorToast } from '../../hooks/useErrorToast'

const ManageAccountsTable = (props) => {
  const {
    modelProps, allSpnAccountsFlag, allSpnAccounts, selectionModel, defaultCheckBoxApiLoading,
    setSelectionModel, closeModal, allSpnTradingAccounts, spnAccountLoading, setAllSpnAccountsFlag
  } = props
  const { user } = useAuth()
  const [currentPage, setCurrentPage] = useState(0)
  const [row, setRow] = useState([])
  const { showSuccess } = useSuccessToast()
  const { showError } = useErrorToast()
  const [isSortProcessLoading, setIsSortProcessLoading] = useState(true)
  const [currentTab, setCurrentTab] = useState('')
  const [idSaveLoading, setIdSaveLoading] = useState(false)
  const [allSpnClients, setAllSpnClients] = useState([])
  const [isAccountsSorting, setIsAccountsSorting] = useState(true)
  const [sortAccountsFlag, setSortAccountFlag] = useState(true)

  const columns = [
    {
      field: 'accountName',
      headerName: 'Account Name',
      flex: 2
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      flex: 2
    },
    {
      field: 'accountId',
      headerName: 'AccountId',
      flex: 2
    },
    {
      field: 'accountEmail',
      headerName: 'Account Email',
      flex: 2
    }
  ]

  useEffect(() => {
    if (!spnAccountLoading && !defaultCheckBoxApiLoading) {
      if (allSpnAccountsFlag) {
        setCurrentTab('All Sponsor Accounts')
        // const selectedAccounts = allSpnAccounts?.map((data) => data.accountId)
        const selectedAccounts = (modelProps?.serviceId === 'trade' ? allSpnTradingAccounts : allSpnAccounts).map((data) => data.accountId)
        setAllSpnClients(selectedAccounts)
      } else {
        setCurrentTab('Select Manually')
      }
    }
  }, [allSpnAccountsFlag, spnAccountLoading, defaultCheckBoxApiLoading])

  useEffect(() => {
    if (selectionModel?.length > 0) {
      if (allSpnTradingAccounts && modelProps?.serviceId === 'trade') {
        if (sortAccountsFlag && selectionModel?.length > 0) {
          const reorderedRows = [...allSpnTradingAccounts].sort((a, b) => {
            const isSelectedA = sortAccountsFlag && selectionModel?.includes(a?.accountId)
            const isSelectedB = sortAccountsFlag && selectionModel?.includes(b?.accountId)
            return isSelectedB - isSelectedA
          })
          if (reorderedRows && reorderedRows.length > 0 && selectionModel?.length > 0) {
            setIsAccountsSorting(false)
            setRow(reorderedRows)
          }
          setIsSortProcessLoading(false)
        }
      } else {
        if (sortAccountsFlag && selectionModel?.length > 0) {
          const reorderedRows = [...allSpnAccounts].sort((a, b) => {
            const isSelectedA = sortAccountsFlag && selectionModel?.includes(a?.accountId)
            const isSelectedB = sortAccountsFlag && selectionModel?.includes(b?.accountId)
            return isSelectedB - isSelectedA
          })
          if (reorderedRows && reorderedRows.length > 0 && selectionModel?.length > 0) {
            setIsAccountsSorting(false)
            setRow(reorderedRows)
          }
          setIsSortProcessLoading(false)
        }
      }
    } else {
      if (allSpnTradingAccounts && modelProps?.serviceId === 'trade') {
        setRow(allSpnTradingAccounts)
        setIsAccountsSorting(false)
        setIsSortProcessLoading(false)
      } else {
        setRow(allSpnAccounts)
        setIsAccountsSorting(false)
        setIsSortProcessLoading(false)
      }
    }
  }, [allSpnAccounts, allSpnTradingAccounts, modelProps, sortAccountsFlag, selectionModel])

  const handleAccountTypeChange = (e) => {
    if (e.target.value === 'Select Manually') {
      setCurrentTab(e.target.value)
      setAllSpnAccountsFlag(false)
    } else {
      setCurrentTab(e.target.value)
      const selectedAccounts = (modelProps?.serviceId === 'trade' ? allSpnTradingAccounts : allSpnAccounts).map((data) => data.accountId)
      setAllSpnClients(selectedAccounts)
      setAllSpnAccountsFlag(true)
    }
  }

  const handleAccountIdsSave = () => {
    setIdSaveLoading(true)
    API.patch('baseAclURL', `user-access-control/v1/${user?.userGroup}/user/accounts/${modelProps?.userId}`, {
      body: [{
        serviceId: modelProps?.serviceId,
        resourceId: modelProps?.resourceId,
        allSpnAccounts: currentTab === 'All Sponsor Accounts',
        accountIds: currentTab === 'Select Manually' ? selectionModel : allSpnClients
      }]
    }).then((res) => {
      if (res && res?.success) {
        showSuccess(res?.message)
        setIdSaveLoading(false)
      }
    }).catch((error) => {
      setIdSaveLoading(false)
      showError(error?.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
    }).finally(() => {
      setIdSaveLoading(false)
      closeModal()
    })
  }

  const AccountLoading = () => {
    return (
      <>
        <Box className='Accounts-acl table-responsive'>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '20%' }}>Account Name</TableCell>
                <TableCell sx={{ width: '20%' }}>Account Type</TableCell>
                <TableCell sx={{ width: '20%' }}>Account ID</TableCell>
                <TableCell sx={{ width: '15%' }}>Account Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
            [...Array(5)].map((e, i) => (
              <Fragment key={i}>
                <TableRow>
                  <TableCell>
                    <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                  </TableCell>
                </TableRow>
              </Fragment>
            ))
              }
            </TableBody>
          </Table>
        </Box>
      </>
    )
  }

  return (
    <Box sx={{ width: 'auto', height: '600px', minHeight: '600px', maxHeight: '600px' }}>
      <Grid container xs={12} justifyContent='space-between' alignItems='center' mb={2} mt={2}>
        <FormControl style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '5px 0 0 16px' }}>
          <RadioGroup
            name='radio-buttons-group'
            style={{ display: 'flex', flexDirection: 'row', marginLeft: '0px' }}
          >
            <FormControlLabel
              checked={currentTab === 'Select Manually' && !allSpnAccountsFlag && !isAccountsSorting && !isSortProcessLoading && !spnAccountLoading && !defaultCheckBoxApiLoading} value='Select Manually' onChange={handleAccountTypeChange} control={<Radio />} label='Select Manually'
              disabled={defaultCheckBoxApiLoading || spnAccountLoading || isAccountsSorting || isSortProcessLoading}
            />
            {/* <FormControlLabel value='Own Created Clients' onChange={handleAccountTypeChange} control={<Radio />} label='Own Created Clients' /> */}
            <FormControlLabel
              checked={currentTab === 'All Sponsor Accounts' && allSpnAccountsFlag && !isAccountsSorting && !isSortProcessLoading && !spnAccountLoading && !defaultCheckBoxApiLoading} value='All Sponsor Accounts' onChange={handleAccountTypeChange} control={<Radio />} label='All Sponsor Accounts'
              disabled={defaultCheckBoxApiLoading || spnAccountLoading || isAccountsSorting || isSortProcessLoading}
            />
          </RadioGroup>
        </FormControl>
        {idSaveLoading
          ? (
            <LoadingButton
              loading
              loadingPosition='start'
              variant='outlined'
              sx={{ float: 'right', width: '120px' }}
            >
              Apply
            </LoadingButton>
            )
          : (
            <Button
              disabled={defaultCheckBoxApiLoading || spnAccountLoading || row?.length < 1 || isAccountsSorting}
              variant='contained' color='primary' sx={{ float: 'right', width: '100px' }} onClick={handleAccountIdsSave}
            >Apply
            </Button>
            )}
      </Grid>
      <Grid container xs={12} justifyContent='space-between' alignItems='center' mb={2}>
        {(isSortProcessLoading || defaultCheckBoxApiLoading || spnAccountLoading || isAccountsSorting || !currentTab)
          ? (
            <>
              <AccountLoading />
            </>
            )
          : (
            <DataGridPro
              autoHeight
              rows={row}
              columns={columns}
              rowSelectionModel={currentTab === 'Select Manually' ? selectionModel : allSpnClients}
              onRowSelectionModelChange={(e) => {
                setSortAccountFlag(false)
                setSelectionModel(e)
              }}
              getRowId={(row) => row?.accountId}
              checkboxSelection
              density='compact'
              sx={{
                '&.MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                  display: (currentTab === 'Own Created Clients' || currentTab === 'All Sponsor Accounts') ? 'none' : ''
                }
              }}
              pageSizeOptions={[10]}
              isRowSelectable={(params) => currentTab !== 'Own Created Clients' && currentTab !== 'All Sponsor Accounts'}
              disableMultipleRowSelection={currentTab === 'Own Created Clients' || currentTab === 'All Sponsor Accounts'}
              onPaginationModelChange={(event) => setCurrentPage(event.page)}
              pagination
              paginationModel={{ pageSize: 10, page: currentPage }}
              initialState={{
                ...row?.initialState
              }}
            />
            )}
      </Grid>
    </Box>
  )
}

export default ManageAccountsTable
