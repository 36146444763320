import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Box, Button, Card, CardContent, Collapse, Divider, Grid, Link , Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import { CSVLink } from 'react-csv'
import { ErrorBoundary } from 'react-error-boundary'
import { useParams } from 'react-router-dom'
import arrowIcon from '../../../assets/images/arrow.svg'
import downloadIcon from '../../../assets/images/icon-download.svg'
import AccountTitle from '../../../components/AccountTitle'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import ErrorFallback from '../../ErrorFallback'
import '../import-portfolio.scss'
import { CurrentImport } from './CurrentImport'
import UploadForm from './UploadForm'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

const headers = [
  { label: 'Name', key: 'instrName' },
  { label: 'Symbol', key: 'instrId' },
  { label: 'Purchase Date', key: 'purchaseDate' },
  { label: 'Total Purchase Cost', key: 'purchaseCost' },
  { label: 'Quantity / Shares', key: 'shares' },
  { label: 'ISO Country Code', key: 'isoCountryCode' }
]
// headers for Download Portfolio In Import Format
const importHeaders = [
  { label: 'Symbol', key: 'localSymbol' },
  { label: 'Purchase Date', key: 'purchaseDate' },
  { label: 'Total Purchase Cost', key: 'purchaseCost' },
  { label: 'Quantity / Shares', key: 'shares' },
  { label: 'ISO Country Code', key: 'isoCountryCode' }
]

const ImportPortPage = () => {
  const [showCurrent, setShowCurrent] = useState(true)
  const [showImport, setShowImport] = useState(false)
  const { user, checkAccess } = useAuth()
  const showError = useErrorToast()
  const [getTxlot, setGetTaxlot] = useState()
  const [downloadTaxLotData, setDownloadTaxLotData] = useState()
  const [showCashValue, setShowCashValue] = useState()
  const [noDataMsg, setNoDataMsg] = useState()
  const params = useParams()
  const [loading, setLoading] = useState(true)

  const dataSecure = localStorage.getItem('object')
  const getStoreData = dataSecure ? JSON.parse(dataSecure) : []
  const filterSecureData = getStoreData?.filter((item) => item?.accountId === params?.accountId)

  // CSV config for Download Portfolio In Displayed Format
  const displayedCsvConfig = {
    filename: 'Export-Current-Portfolio.csv',
    headers,
    data: downloadTaxLotData || getTxlot
  }
  // CSV config for Download Portfolio In Import Format
  const importFormatCsvConfig = {
    filename: 'Import-Current-Portfolio-format.csv',
    headers: importHeaders,
    data: downloadTaxLotData || getTxlot
  }

  useEffect(() => {
    if (user) {
      if (
        (filterSecureData && filterSecureData[0]?.accountType === 'TRADING' &&
             checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
               { subModuleName: moduleConfig.IMPORT_PORTFOLIO, component_name: moduleConfig.IMPORT_TRADING_PORTFOLIO })) ||
             ((filterSecureData && filterSecureData[0]?.accountType === 'TRANSITION' &&
              checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
                { subModuleName: moduleConfig.IMPORT_PORTFOLIO, component_name: moduleConfig.IMPORT_TRANSITION_PORTFOLIO }))) ||
              checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
                { subModuleName: moduleConfig.IMPORT_PORTFOLIO, component_name: moduleConfig.CURRENT_PORTFOLIO })) {
        getTaxlot()
      }
    }
  }, [user, params?.accountId])

  const screen = 'not main'
  const getTaxlot = async () => {
    try {
      const response = await API.get(
        'baseURL',
        `account-master/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/accounts/taxlots/${params.accountId}`
      )

      if (response?.data?.length === 0) {
        setNoDataMsg(response.message)
      }

      if (response.data) {
        const CashValue = '__CASH'
        const totalValuse = response.data.filter((el) => el.instrId === CashValue)
        const withOutCashFilter = [...response.data]
        const downloadFileData = withOutCashFilter.map((item, index) => {
          const dateObject = new Date(item.purchaseDate)
          const formattedDate = `${dateObject.getUTCFullYear()}${(dateObject.getUTCMonth() + 1).toString().padStart(2, '0')}${dateObject.getUTCDate().toString().padStart(2, '0')}`
          return { ...item, purchaseDate: formattedDate }
        })
        setGetTaxlot(withOutCashFilter)
        setDownloadTaxLotData(downloadFileData)
        setShowCashValue(totalValuse)
      }
    } catch (error) {
      showError(error.response?.userMessage)
      Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
    }
  }

  return (
    <>
      <Box className='import-portfolio'>
        <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)} onReset={getTaxlot}>
          <AccountTitle title='Import Portfolio' />
        </ErrorBoundary>
        {checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
          { subModuleName: moduleConfig.IMPORT_PORTFOLIO, component_name: moduleConfig.CURRENT_PORTFOLIO })
          ? (
            <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Current Portfolio' />)} onReset={getTaxlot}>
              <Grid container spacing={2} marginBottom={6}>
                <Grid item xs={12}>
                  <Stack style={{ display: 'flex' }}>
                    <Typography component='div' sx={{ fontSize: '24px', color: '#212529', fontWeight: 600, fontFamily: 'Open Sans', display: 'flex', alignItems: 'center' }}>
                      <Button
                        size='small'
                        className='collapse-btn'
                        onClick={() => setShowCurrent(!showCurrent)}
                        sx={{ minWidth: '24px' }}
                      >
                        <img
                          src={arrowIcon}
                          alt=''
                          height={15}
                          width={15}
                          className={showCurrent ? 'rotate-0' : 'rotate-90degn'}
                        />
                      </Button>
                      Current Portfolio
                      {!getTxlot
                        ? (
                            ''
                          )
                        : (
                          <>
                            <span className='import-portfolio-download-button'>
                              <CSVLink {...displayedCsvConfig}>
                                <Button className='action-clock-btn' sx={{ minWidth: '24px' }}>
                                  <FileDownloadOutlinedIcon sx={{ color: '#3A76D4', fontSize: '16px' }} />
                                </Button>
                              </CSVLink>
                              Download Current Taxlot Portfolio
                            </span>
                            <span className='import-portfolio-download-button'>
                              <CSVLink {...importFormatCsvConfig}>
                                <Button className='action-clock-btn' sx={{ minWidth: '24px' }}>
                                  <FileDownloadOutlinedIcon sx={{ color: '#3A76D4', fontSize: '16px' }} />
                                </Button>
                              </CSVLink>
                              Download Portfolio In Import Format
                            </span>
                          </>
                          )}
                    </Typography>
                  </Stack>
                  <hr style={{ borderBottom: '1px solid #dee2e6', borderTop: '0' }} />
                  <Collapse in={showCurrent}>
                    {getTxlot?.length === 0 || getTxlot === undefined
                      ? (
                        <Box>
                          <Grid container spacing={2} marginTop={2}>
                            <Grid item xs={12}>
                              <Card>
                                <CardContent>
                                  <Box className='table-responsive-import'>
                                    {loading
                                      ? (
                                        <Table>
                                          <TableHead>
                                            <TableRow>
                                              <TableCell>Name</TableCell>
                                              <TableCell>Symbol</TableCell>
                                              <TableCell>Share</TableCell>
                                              <TableCell>Total Purchase Cost</TableCell>
                                              <TableCell>Purchase Date</TableCell>
                                              <TableCell>Country Code</TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            <TableRow>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                              <TableCell>
                                                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} />
                                              </TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                        )
                                      : (
                                        <Stack style={{ minHeight: '30px' }}>
                                          {noDataMsg}
                                        </Stack>
                                        )}
                                  </Box>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        </Box>
                        )
                      : (
                        <CurrentImport taxData={getTxlot || []} />
                        )}
                  </Collapse>
                </Grid>
              </Grid>
            </ErrorBoundary>
            )
          : null}
        {
            (
              (filterSecureData && filterSecureData[0]?.accountType === 'TRADING' &&
             checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
               { subModuleName: moduleConfig.IMPORT_PORTFOLIO, component_name: moduleConfig.IMPORT_TRADING_PORTFOLIO })) ||
             ((filterSecureData && filterSecureData[0]?.accountType === 'TRANSITION' &&
              checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
                { subModuleName: moduleConfig.IMPORT_PORTFOLIO, component_name: moduleConfig.IMPORT_TRANSITION_PORTFOLIO }))))
              ? (
                <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Import Portfolio' />)} onReset={() => window.location.reload()}>
                  <Grid container spacing={2} marginBottom={6}>
                    <Grid item xs={12}>
                      <Stack style={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography sx={{ fontSize: '24px', color: '#212529', fontWeight: 600, fontFamily: 'Open Sans' }}>
                          <Button
                            className='collapse-btn'
                            onClick={() => setShowImport(!showImport)}
                            sx={{ minWidth: '24px' }}
                          >
                            <img
                              src={arrowIcon}
                              alt=''
                              height={15}
                              width={15}
                              className={showImport ? 'rotate-0' : 'rotate-90degn'}
                            />
                          </Button>
                          Import Portfolio
                        </Typography>
                        <span className='import-portfolio-download-button'>
                          <Button className='action-clock-btn' sx={{ minWidth: '24px', fontWeight: 500 }}>
                            <Link
                              href={process.env.REACT_APP_ACCOUNT_SAMPLE_URL}
                              download='TaxlotImportSample.csv'
                            >
                              <FileDownloadOutlinedIcon sx={{ color: '#3A76D4', fontSize: '16px', mt: '4px' }} />
                            </Link>
                          </Button>
                          Download Sample Taxlot Portfolio
                        </span>
                      </Stack>
                      <Divider />
                      <Collapse in={showImport}>
                        <Stack>
                          {showCashValue
                            ? showCashValue?.length === 0
                              ? <UploadForm getTaxlot={getTaxlot} />
                              : <UploadForm
                                  CashValue={showCashValue[0].shares}
                                  getTaxlot={getTaxlot}
                                />
                            : ''}
                        </Stack>
                      </Collapse>
                    </Grid>
                  </Grid>
                </ErrorBoundary>
                )
              : null
          }
      </Box>
    </>
  )
}
export default ImportPortPage
