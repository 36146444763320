import React, { useContext, useEffect, useState } from 'react'
// import { useParams } from 'react-router-dom'
// import { API } from 'aws-amplify'
import { Box, Grid, Menu, MenuItem } from '@mui/material'
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
// import * as Sentry from '@sentry/react'
import Chart from 'chart.js/auto'
import annotationPlugin from 'chartjs-plugin-annotation'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import { isHoliday } from 'nyse-holidays'
// import { useAuth } from '../../../../contexts/AuthContext'
// import { useErrorToast } from '../../../../hooks/useErrorToast'
// import { performanceDataContext } from '../../Index'
// import { benchmarkDataProcess, performanceDataProcess } from '../../DataFormatting'
// import { formatCurrencyWithSymbol, formatCurrency } from '../dataProcess/DataProcess'
import IconButton from '@mui/material/IconButton'
import 'chartjs-adapter-moment'
import Tooltip from '@mui/material/Tooltip'
// import LineChart from './LightWeightChart'
import ApexLineChart from './ApexLineChart'
import MarketValueAreaChart from './MarketValueChart'
import { performanceDataContext, benchmarkDataContext, metricDataContext } from '../../Index'
import BenchmarkChart from './BenchmarkChart'
import { RefreshOutlined } from '@mui/icons-material'
import { FaChartColumn } from 'react-icons/fa6'
import { IoIosExpand } from 'react-icons/io'
import { useAuth } from '../../../../contexts/AuthContext'
import { useDispatch, useSelector } from 'react-redux'
import { storeExpandedChartName } from '../../../../store/user-reducer/user-reducer'
import { moduleConfig } from '../../../../contexts/data'
import { ACCESS_LEVEL } from '../../../../contstants/constants'

dayjs.extend(isoWeek)
Chart.register(annotationPlugin)

export const LineChartPerformance = ({ accountData }) => {
  const { performanceData } = useContext(performanceDataContext)
  const { benchmarkReturnsData } = useContext(benchmarkDataContext)
  const { metricData } = useContext(metricDataContext)
  const [xAxisUnit, setXAxisUnit] = useState('day')
  const [currentGraph, setCurrentGraph] = useState(
    accountData?.accountType !== 'TRADING' ? 'BENCHMARK_RETURNS' : 'PERFORMANCE'
  )
  const [chartDate, setChartDate] = useState([dayjs.utc().subtract(1, 'month'), dayjs.utc()])
  // const [graphData, setGraphData] = useState()
  const [resetKey, setResetKey] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorChart, setAnchorChart] = useState(null)
  const open = Boolean(anchorEl)
  const openChart = Boolean(anchorChart)
  const [isChartZoomed, setIsChartZoomed] = useState(false)

  const [performanceChartData, setPerformanceChartData] = useState()
  const [marketChartData, setMarketChartData] = useState()
  const [benchmarkChartData, setBenchmarkChartData] = useState()
  const { checkAccess } = useAuth()
  const { expandedChartName } = useSelector(state => state.user)
  const dispatch = useDispatch()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleChartClick = (event) => {
    setAnchorChart(event.currentTarget)
    document.body.classList.add('model-open')
  }

  const openExpandedView = () => {
    if (accountData?.openExpandedView) {
      if (currentGraph === 'PERFORMANCE') {
        accountData?.openExpandedView(currentGraph, performanceChartData)
      }
      if (currentGraph === 'MV') {
        accountData?.openExpandedView(currentGraph, marketChartData)
      }
      if (currentGraph === 'BENCHMARK_RETURNS') {
        accountData?.openExpandedView(currentGraph, benchmarkChartData)
      }
    }
  }

  const handleChartChange = (event) => {
    setCurrentGraph(event)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const resetChart = () => {
    setResetKey((prevKey) => prevKey + 1)
  }

  const handleChartDropdownClose = () => {
    setAnchorChart(null)
    document.body.classList.remove('model-open')
  }

  const getDataByUnit = (data, currentGraph) => {
    // if graph is Market value then add value based on time selected
    if (currentGraph === 'MV') {
      const metricDate = []
      const metricData = []
      for (let i = 0; i < data?.length; i++) {
        const date = dayjs(data[i].dataDate)
        let key
        if (xAxisUnit === 'year') {
          key = date.year().toString() // 2020, 2022, 2023
        } else if (xAxisUnit === 'month') {
          key = `${date.format('MMM')}-${date.year()}` // Jan-2021, Apr-2023
        } else if (xAxisUnit === 'week') {
          key = `${date.year()}-${date.isoWeek()}` // 2022-52, 2023-53
        } else if (xAxisUnit === 'day') {
          key = date.format('YYYY-MM-DD') // 2023-12-31, 2024-01-23
        }
        const currentData = metricDate.findIndex((date) => date === key)
        if (currentData === -1) {
          metricDate.push(key)
          metricData.push({ ...data[i]?.metric })
          // metricData.push({ DIV_AND_INT: data[i].metric?.DIV_AND_INT || 0, NET_MV_PLUS_CASH: data[i].metric?.NET_MV_PLUS_CASH })
        }
        // else {
        //   metricData[currentData].DIV_AND_INT += data[i].metric?.DIV_AND_INT !== undefined ? parseFloat(data[i].metric?.DIV_AND_INT) : 0
        //   if (metricData[currentData].NET_MV_PLUS_CASH) { metricData[currentData].NET_MV_PLUS_CASH += data[i].metric?.NET_MV_PLUS_CASH !== undefined ? parseFloat(data[i].metric?.NET_MV_PLUS_CASH) : null }
        // }
      }
      if (xAxisUnit === 'week') {
        for (let i = 0; i < metricDate?.length; i++) {
          const data = metricDate[i].split('-')
          const year = data[0]
          const weekNumber = data[1]
          metricDate[i] = dayjs(new Date(year, 0, (1 + (weekNumber - 1) * 7))).format('YYYY-MM-DD')
        }
      }

      // don't show zero point on graph
      // returning empty array to show 'Insufficient Data'
      if (metricDate?.length <= 0) {
        return { metricDate: [], metricData: [] }
      }

      return { metricDate, metricData }
    }

    if (xAxisUnit === 'day') {
      // no need to add value as all the values are in day format
      return data
    }

    const xAxisTicks = []
    const preTaxReturns = []
    const postTaxReturns = []

    for (let i = 0; i < data?.date?.length; i++) {
      const date = dayjs(data.date[i])
      let key
      if (xAxisUnit === 'year') {
        key = date.year().toString() // 2020, 2022, 2023
      } else if (xAxisUnit === 'month') {
        key = `${date.format('MMM')}-${date.format('YYYY')}` // Jan-2021, Apr-2023
      } else if (xAxisUnit === 'week') {
        key = `${date.year()}-${date.isoWeek()}` // 2022-52, 2023-53
      }
      const indexOfKey = xAxisTicks.findIndex((date) => date === key)
      if (indexOfKey === -1) {
        xAxisTicks.push(key)
        preTaxReturns.push(data.preTaxReturns[i])
        if (currentGraph === 'PERFORMANCE') { postTaxReturns.push(data.postTaxReturns[i]) }
      } else {
        preTaxReturns[indexOfKey] += (data.preTaxReturns[i] !== null ? parseFloat(data.preTaxReturns[i]) : null)
        if (currentGraph === 'PERFORMANCE') { postTaxReturns[indexOfKey] += (data.postTaxReturns[i] !== null ? parseFloat(data.postTaxReturns[i]) : null) }
      }
    }
    // change xAxisTick array data from week number to day from that week
    if (xAxisUnit === 'week') {
      for (let i = 0; i < xAxisTicks?.length; i++) {
        const data = xAxisTicks[i].split('-')
        const year = data[0]
        const weekNumber = data[1]
        xAxisTicks[i] = dayjs(new Date(year, 0, (1 + (weekNumber - 1) * 7))).format('YYYY-MM-DD')
      }
    }
    if (xAxisTicks?.length <= 0) {
      return { date: [], preTaxReturns: [], postTaxReturns: [] }
    }
    return { date: xAxisTicks, preTaxReturns, postTaxReturns }
  }

  useEffect(() => {
    if (performanceData) {
      const data = getDataByUnit(performanceData, 'PERFORMANCE')
      const graphData = {
        date: data?.date,
        data: data?.preTaxReturns,
        postTaxReturns: data?.postTaxReturns
      }
      setPerformanceChartData((graphData?.data || []).map((dataPoint, index) => ({
        x: new Date(graphData.date[index]).getTime(),
        y: dataPoint,
        postTaxReturns: graphData?.postTaxReturns ? graphData?.postTaxReturns[index] : ''
      })).filter((point) => point.y !== null))
    }
  }, [performanceData])

  useEffect(() => {
    if (metricData) {
      // filter data-points which has valid market value and store it for chart data point
      const filteredMetricData = metricData?.marketData?.filter(marketValueObj => marketValueObj?.metric?.NET_MV_PLUS_CASH !== undefined && marketValueObj?.metric?.NET_MV_PLUS_CASH !== null)
      const data = getDataByUnit(filteredMetricData, 'MV')
      // split the properties so mvData will be passed to graph and dividend array will be passed for annotations to show on graph
      const mvData = data?.metricData?.map(obj => obj.NET_MV_PLUS_CASH)
      const graphData = { date: data.metricDate, data: mvData, annotations: data?.metricData }
      setMarketChartData((graphData?.data || []).map((dataPoint, index) => ({
        x: new Date(graphData.date[index]).getTime(),
        y: dataPoint,
        annotation: graphData?.annotations ? graphData?.annotations[index] : ''
      })).filter((point) => point.y !== null))
    }
  }, [metricData])

  useEffect(() => {
    if (benchmarkReturnsData) {
      const data = getDataByUnit(benchmarkReturnsData, 'BENCHMARK_RETURNS')
      const graphData = {
        date: data?.date,
        data: data?.preTaxReturns,
        accountReturnData: data?.accPreTaxReturns
      }
      setBenchmarkChartData((graphData?.data || []).map((dataPoint, index) => ({
        x: new Date(graphData.date[index]).getTime(),
        y: dataPoint,
        accPreTax: accountData?.accountType !== 'TRADING' ? null : graphData?.accountReturnData[index]
      })).filter((point) => point.y !== null))
    }
  }, [benchmarkReturnsData])

  useEffect(() => {
    if (expandedChartName && expandedChartName !== '') {
      setCurrentGraph(expandedChartName)
      dispatch(storeExpandedChartName(null))
    }
  }, [expandedChartName])

  const handleDateChange = (date) => {
    if (!date[0] || !date[1]) {
      return
    }
    setChartDate(date)
  }

  const handleGraphChange = (element) => {
    handleChartDropdownClose()
    setCurrentGraph(element)
  }

  const handleZoomChange = (isZoomed) => {
    setIsChartZoomed(isZoomed)
  }

  // const disableHolidays = (date) => {
  //   date = new Date(date)
  //   return isHoliday(date) || date.getDay() === 0 || date.getDay() === 6
  // }

  const formatChartTooltipLabel = (graph) => {
    switch (graph) {
      case 'PERFORMANCE':
        return 'Performance'
      case 'MV':
        return 'Market Value'
      case 'BENCHMARK_RETURNS':
        return 'Benchmark Returns'
      default:
        return graph
    }
  }

  return (
    <Grid container>
      <Grid container xs={12} sm={12} px={1} pt={1} sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Box className='trade-table-list-header' sx={{ my: '0 !important' }}>{formatChartTooltipLabel(currentGraph)}</Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* <Box>
              <Tooltip title='Select Date Range'>
                <IconButton
                  id='basic-button'
                  aria-controls={openChart ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={openChart ? 'true' : undefined}
                  onClick={handleClick}
                  size='small'

                >
                  <CalendarTodayIcon sx={{ height: 20, width: 20, color: '#74788D' }} />
                </IconButton>
              </Tooltip>
              <Button
                id='basic-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                size='small'
              >
                D
              </Button>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
                value={xAxisUnit}
                size='small'
              >
                <Box display='flex'>
                  <MenuItem onClick={() => handleUnitChange('day')} value='day'>Day</MenuItem>
                  <MenuItem onClick={() => handleUnitChange('week')} value='week'>Week</MenuItem>
                  <MenuItem onClick={() => handleUnitChange('month')} value='month'>Month</MenuItem>
                  <MenuItem onClick={() => handleUnitChange('year')} value='year'>Year</MenuItem>
                </Box>
                <Box padding='5px' height='360px'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['StaticDateRangePicker']}>
                      <StaticDateRangePicker
                        value={chartDate}
                        shouldDisableDate={disableHolidays}
                        onChange={handleDateChange}
                        disableFuture
                        format='YYYY-MM-DD'
                        views={['year', 'month', 'date']}
                        openTo='year'
                        slots={{ field: SingleInputDateRangeField }}
                        name='allowedRange'
                        sx={{
                          '.MuiPickersToolbar-root': {
                            padding: '0px',
                            marginTop: '-12px !important'
                          },
                          '.MuiPickersToolbar-root span': {
                            fontSize: '14px !important',
                            paddingTop: '4px'
                          },
                          '.MuiPickersToolbar-root h5': {
                            fontSize: '12px !important',
                            marginTop: '-10px !important'
                          },
                          'MuiDateCalendar-root': {
                            marginTop: '-30px !important'
                          },
                          '.MuiPickersCalendarHeader-root': {
                            marginTop: '0px',
                            marginBottom: '0px'
                          },
                          '.MuiDayCalendar-root': {
                            height: '250px'
                          },
                          '.MuiDialogActions-root': {
                            padding: '0px',
                            marginTop: '-10px'
                          }
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </Menu>
            </Box> */}
            {isChartZoomed
              ? <Tooltip title='Reset Chart'>
                <IconButton
                  id='basic-button'
                  aria-controls={openChart ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={openChart ? 'true' : undefined}
                  onClick={resetChart}
                >
                  <RefreshOutlined sx={{ height: 20, width: 20, color: '#74788D' }} />
                </IconButton>
              </Tooltip>
              : ''}
            {accountData?.accountType === 'TRADING'
              ? <>
                <Tooltip title={formatChartTooltipLabel(currentGraph)}>
                  <IconButton
                    id='basic-button'
                    aria-controls={openChart ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={openChart ? 'true' : undefined}
                    onClick={handleChartClick}
                  >
                    <FaChartColumn size='15px' color='#74788D' />
                  </IconButton>
                </Tooltip>
                <Menu
                  id='basic-menu'
                  anchorEl={anchorChart}
                  open={openChart}
                  onClose={handleChartDropdownClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                  size='small'
                >
                  <MenuItem onClick={() => handleGraphChange('PERFORMANCE')} value='PERFORMANCE'>
                    Performance
                  </MenuItem>
                  <MenuItem onClick={() => handleGraphChange('MV')} value='MV'>
                    Market Value
                  </MenuItem>
                  <MenuItem onClick={() => handleGraphChange('BENCHMARK_RETURNS')} value='BENCHMARK_RETURNS'>
                    Benchmark Returns
                  </MenuItem>
                </Menu>
                </>
              : ''}
            {
              checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: moduleConfig.EXPANDED_CHARTS })
                ? <Tooltip title='Expand'>
                  <IconButton
                  id='expand-button'
                  // aria-controls={openChart ? 'basic-menu' : undefined}
                  // aria-haspopup='true'
                  // aria-expanded={openChart ? 'true' : undefined}
                  onClick={openExpandedView}
                >
                  <IoIosExpand size='15px' color='#74788D' />
                </IconButton>
                  </Tooltip> : ''
            }
            {/* <Box sx={{ minWidth: 120 }} mt={2}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Selected Chart</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={currentGraph}
                  label='Selected Chart'
                  onChange={handleChartChange}
                  size='small'
                >
                  <MenuItem value='PERFORMANCE'>Performance</MenuItem>
                  <MenuItem value='MV'>Market Value</MenuItem>
                  <MenuItem value='BENCHMARK_RETURNS'>Benchmark Returns</MenuItem>
                </Select>
              </FormControl>
            </Box> */}

          </Box>
        </Box>
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DateRangePicker']}>
            <DateRangePicker
              localeText={{ start: 'Start Date', end: 'End Date' }}
              value={chartDate}
              shouldDisableDate={disableHolidays}
              onChange={handleDateChange}
              disableFuture
              format='YYYY-MM-DD'
              slotProps={{ textField: { size: 'small' } }}
              sx={{
                '.MuiMultiInputDateRangeField-separator': {
                  ml: '8px'
                },
                '.MuiTextField-root:last-child': {
                  ml: '8px'
                },
                '.MuiInputBase-input': {
                  p: '6px 14px'
                }
              }}
            />
          </DemoContainer>
        </LocalizationProvider> */}
      </Grid>
      <Grid xs={12} sm={12} pt={1} id='chart-container' height={500}>
        {currentGraph === 'PERFORMANCE' ? <ApexLineChart data={performanceChartData} resetKey={resetKey} onZoomChange={handleZoomChange} /> : ''}
        {currentGraph === 'MV' ? <MarketValueAreaChart data={marketChartData} resetKey={resetKey} onZoomChange={handleZoomChange} showAnnotations={true} /> : ''}
        {currentGraph === 'BENCHMARK_RETURNS' ? <BenchmarkChart data={benchmarkChartData} resetKey={resetKey} onZoomChange={handleZoomChange} /> : ''}
      </Grid>
    </Grid>
  )
}
