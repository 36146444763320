import React, { useEffect, useRef, useState } from 'react'
import { Routes, Route, Outlet } from 'react-router-dom'
import NewReport from './NewReport/NewReport.js'
import { useDispatch, useSelector } from 'react-redux'
import GetHeaderButton from './common/GetHeaderButton/GetHeaderButton.js'
import './pdf-customizer.css'
import './pdf-customizer-common.scss'
import AllReportTemplates from './AllTemplates/AllReportTemplates.js'
import { disableLegacyViewReducer, enableLegacyViewReducer } from '../../store/trade-reducer/trade-reducer.js'
import { resetTemplateData } from '../../store/pdf-customizer-reducer/Action/chartDropAction'
import { resetThemeCustomization } from '../../store/pdf-customizer-reducer/Action/allPageAction'
import { chartDataState } from '../../store/pdf-customizer-reducer/Action/chartStateAction'

export const MAX_GRID_COUNT = 24

const PDFLayout = () => {
  const dispatch = useDispatch()

  const { isLoading } = useSelector((state) => state.allPageStyle)
  const pdfExportComponent = useRef(null)
  const { showLegacyView } = useSelector((state) => state.trade)
  const [zoomLevel, setZoomLevel] = useState(100)
  const handleZoomLevelChange = (newValue) => {
    setZoomLevel(newValue)
  }

  useEffect(() => {
    if (!showLegacyView) {
      dispatch(enableLegacyViewReducer())
    }
    return () => {
      dispatch(disableLegacyViewReducer())
      reSetStore()
    }
  }, [])

  useEffect(() => {
    if (showLegacyView) {
      document.body.setAttribute('data-legacy-view', showLegacyView)
    } else {
      document.body.removeAttribute('data-legacy-view')
    }
    return () => {
      document.body.removeAttribute('data-legacy-view')
    }
  }, [showLegacyView])

  const reSetStore = () => {
    dispatch(resetTemplateData())
    dispatch(resetThemeCustomization())
    dispatch(chartDataState(false, 'IS_API_CALL'))
  }
  return (
    <>
      <div className='main-background'>
        <div className='sub-background'>
          <GetHeaderButton pdfExportComponent={pdfExportComponent} resetZoomLevel={zoomLevel} />
          <div className='main-contain'>
            <Routes>
              <Route path='/' element={<AllReportTemplates />} />
              <Route
                path='/report'
                element={
                  <NewReport
                    pdfExportComponent={pdfExportComponent}
                    handleZoomLevelChange={handleZoomLevelChange}
                  />
                }
              />
            </Routes>
            <Outlet />
          </div>
        </div>
        {isLoading && (
          <div className='black_blur'>
            <div className='pdf-loader'>
              <div className='circle' />
              <div className='circle' />
              <div className='circle' />
              <div className='circle' />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default PDFLayout
