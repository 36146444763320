import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Box, Breadcrumbs, Link, Stack, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import Loader from '../Loader'
import { DataMaintenance } from './DataMaintenance'
import { GetKeyData } from './GetKeyData'
import './data-maintenance.css'

export const Index = (props) => {
  const { name, linkColumn, breadcrumb } = props
  const [data, setData] = useState({ columns: [], rows: [] })
  const [activeId, setActiveId] = React.useState('')
  const { showError } = useErrorToast()
  const [loading, setLoading] = useState(false)
  const { user } = useAuth()

  useEffect(() => {
    if (user) {
      setLoading(true)
      setData({ columns: [], rows: [] })
      if (activeId !== '') {
        setActiveId('')
      }
      const fetchData = async () => {
        const responseData = await GetKeyData(name)
        if (Object.keys(responseData).length === 0) {
          setActiveId('-')
          setLoading(false)
          return
        }
        if (responseData?.error) {
          showError(responseData.error)
          setLoading(false)
          return
        }
        setLoading(false)
        const columns = responseData?.data?.data
          ? Object.keys(responseData?.data?.data[0]).map((field) => ({
            field,
            headerName: field,
            flex: 1,
            renderCell: (params) => {
              if (params.field === linkColumn) {
                const handleClick = () => {
                  setActiveId(params.value)
                }
                return (
                  <Link
                    component={NavLink}
                    to='#' onClick={handleClick} underline='none'
                  >
                    {params.value}
                  </Link>
                )
              }
              return params.value
            }
          }))
          : []

        const rows = responseData?.data?.data
          ? responseData?.data?.data?.map((row) => ({
            ...row,
            id: row[linkColumn]
          }))
          : []
        setData({ columns, rows })
      }
      fetchData()
    }
    return () => {
      setActiveId('')
    }
  }, [name, linkColumn, user])

  const handleBreadcrumbClick = () => {
    setActiveId('')
  }

  return (
    <Box className='book-of-business-page'>
      {loading ? <Loader /> : ''}
      <Stack direction='row' sx={{ justifyContent: 'space-between', padding: '8px 8px 5px 0px' }}>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link
            component={NavLink}
            underline='hover'
            color='inherit'
            to='#'
            onClick={handleBreadcrumbClick}
          >
            {name.charAt(0).toUpperCase() + name.slice(1)}
          </Link>
          {activeId === '-' ? null : !breadcrumb ? null : <Typography color='text.primary'>{activeId}</Typography>}
        </Breadcrumbs>
        <Alert
          severity='info'
          sx={{
            right: '45px',
            '& .MuiAlert-icon': {
              margin: '-13px 0'
            },
            '& .MuiAlert-message': {
              margin: '-13px 8px'
            }
          }}
        >All displayed dates are in UTC timezone
        </Alert>
      </Stack>
      <Stack sx={{
        '.MuiDataGrid-root': {
          border: '1px solid #e0e0e0'
        }
      }}
      >
        {
          !loading
            ? activeId === '' && breadcrumb
              ? <DataGridPro
                  autoHeight
                  rows={data.rows}
                  columns={data.columns}
                  pageSizeOptions={[10, 25, 50]}
                  initialState={{
                  pagination: { paginationModel: { pageSize: 10 } }
                }}
                  pagination
                />
              : <DataMaintenance name={name} id={activeId} />
            : ''
        }
      </Stack>
    </Box>
  )
}
