import { useRef } from 'react'
import { toast } from 'react-toastify'

export function useErrorToast() {
    const toastId = useRef(null)
    const options = {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: false,
        theme: 'colored',
        whiteSpace: 'pre-line',
        pauseOnFocusLoss: false
    }

  const showError = (error, sessionExpiredToast = false) => {
      // Show only one toast for session expired message
        if (sessionExpiredToast) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(error, options)
            }
        }
        else {
            toast.error(error, options)
        }
    }

    return { showError }
}