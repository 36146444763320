import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { addChartValues, createPageForOverflowingElements, handleTableOverflow, queueChange, storeCustomTableRows } from '../../../../store/pdf-customizer-reducer/Action/chartDropAction'
import { chartDataState } from '../../../../store/pdf-customizer-reducer/Action/chartStateAction'
import { AGGREGATE_MARKET_VALUE, AGGREGATE_PERFORMANCE } from '../../../../store/pdf-customizer-reducer/Action/chartState.types'
import { aggregateMarketValue, aggregatePerformance } from '../../../../utils/pdf-customizer/_data'
import { useAuth } from '../../../../contexts/AuthContext'
import { DATA_TYPE } from '../../common/componentData'
import { MAX_GRID_COUNT } from '../../PDFLayout'
import { umaMarketValueData, aggregatePerformanceData } from '../APIResponseConverts/chartDefaultsData'
import Table from '../CustomTable/Table'
import './AggregateTable.css'

function applyCurrencyFormat(formatObj, value) {
  if (value === null || value === undefined || value === '') {
    return ''
  }
  let finalCurrencyValue = Number.isInteger(value) ? parseInt(value) : parseFloat(value)
  if (formatObj?.decimal !== undefined) {
    finalCurrencyValue = Number(finalCurrencyValue?.toFixed(formatObj?.decimal))
  }
  return finalCurrencyValue
}

function applyPercentageFormat(formatObj, value) {
  if (value === null || value === undefined || value === '') {
    return ''
  }
  let finalNumber = parseFloat(value) * 100
  if (formatObj?.decimal !== undefined) {
    finalNumber = Number(finalNumber.toFixed(formatObj?.decimal))
  }
  return finalNumber
}

function applyNumberFormat(formatObj, value) {
  if (value === null || value === undefined || value === '') {
    return ''
  }
  let isInteger = Number.isInteger(value)
  let finalNumber = isInteger ? parseInt(value) : parseFloat(value)
  if (formatObj?.decimal !== undefined) {
    finalNumber = Number(finalNumber.toFixed(formatObj?.decimal))
  }
  return finalNumber
}

function applyDateFormat(formatObj, value) {
  if (value === null || value === undefined || value === '') {
    return ''
  }
  return value.split('T')[0]
}

function applyTextFormat(formatObj, value) {
  return value
}

const formatValue = (value, colObj) => {
  switch (colObj.displayType) {
    case 'Currency':
      return applyCurrencyFormat(colObj.formatObj, value)
    case 'Percentage':
      return applyPercentageFormat(colObj.formatObj, value)
    case 'Number':
      return applyNumberFormat(colObj.formatObj, value)
    case 'Date':
      return applyDateFormat(colObj.formatObj, value)
    case 'Text':
      return applyTextFormat(colObj.formatObj, value)
    default:
      return value
  }
}

const isFilterAvailable = (filterObj) => {
  if (filterObj.operator === 'Blank' || filterObj.operator === 'Not blank') {
    return true
  }
  if (filterObj.operator === 'Between' && (filterObj.value.from === '' || filterObj.value.from === null || filterObj.value.to === '' || filterObj.value.to === null)) {
    return false
  } else if (filterObj.operator !== 'Between' && filterObj.value === '' || filterObj.value === null) {
    return false
  } else {
    return true
  }
}

const doesFilterValueSatisfyOperator = (filterObj, formattedValue, headerType) => {
  if (headerType === DATA_TYPE.DATE) {
    switch (filterObj?.operator) {
      case 'Equals':
        return dayjs(formattedValue).isSame(filterObj?.value)
      case 'Does not equal':
        return !dayjs(formattedValue).isSame(filterObj?.value)
      case 'Before':
        return dayjs(formattedValue).isBefore(filterObj?.value, 'day')
      case 'After':
        return dayjs(formattedValue).isAfter(filterObj?.value, 'day')
      case 'Between':
        return dayjs(formattedValue).isAfter(filterObj?.value?.from) && dayjs(formattedValue).isBefore(filterObj?.value?.to)
      case 'Blank':
        return formattedValue === ''
      case 'Not blank':
        return formattedValue !== ''
      default:
        return false
    }
  }
  switch (filterObj?.operator) {
    case 'Equals':
      return formattedValue === filterObj?.value
    case 'Does not equal':
      return formattedValue !== filterObj?.value
    case 'Starts with':
      return formattedValue.toLowerCase().startsWith(filterObj?.value.toLowerCase())
    case 'Ends with':
      return formattedValue.toLowerCase().endsWith(filterObj?.value.toLowerCase())
    case 'Contains':
      return formattedValue.includes(filterObj?.value)
    case 'Does not contain':
      return !formattedValue.includes(filterObj?.value)
    case 'Greater than':
      return formattedValue > filterObj?.value
    case 'Greater than or equal to':
      return formattedValue >= filterObj?.value
    case 'Less than':
      return formattedValue < filterObj?.value
    case 'Less than or equal to':
      return formattedValue <= filterObj?.value
    case 'Between':
      return formattedValue >= filterObj?.value?.from && formattedValue <= filterObj?.value?.to
    case 'Blank':
      return formattedValue === ''
    case 'Not blank':
      return formattedValue !== ''
    default:
      return false
  }
}

const filterValues = (columns, rows) => {
  const filteredRows = []
  rows.forEach((rowObj) => {
    let multipleColumnFiltersPassed = 1
    columns.forEach((colObj) => {
      // apply filters if filters are correct (i.e. has valid data based on operation)
      const allFiltersOnColumn = colObj?.filterObj?.filters.filter(filter => isFilterAvailable(filter))
      if (allFiltersOnColumn?.length) {
        const logicalOperator = colObj?.filterObj?.logicalOperator || 'AND'
        let multipleFiltersPassed = logicalOperator === 'OR' ? 0 : 1
        const formattedVal = formatValue(rowObj[colObj.field], colObj)
        colObj?.filterObj?.filters?.forEach((filter) => {
          if (logicalOperator === 'AND')
            multipleFiltersPassed = multipleFiltersPassed && doesFilterValueSatisfyOperator(filter, formattedVal, colObj?.headerType)
          else if (logicalOperator === 'OR')
            multipleFiltersPassed = multipleFiltersPassed || doesFilterValueSatisfyOperator(filter, formattedVal, colObj?.headerType)
        })
        // compulsory AND operation on different column filter
        multipleColumnFiltersPassed = multipleColumnFiltersPassed && multipleFiltersPassed
      }
    })
    if (multipleColumnFiltersPassed) {
      filteredRows.push(rowObj)
    }
  })
  return filteredRows
}

const applyAggregateFunction = (aggregateFunction, inputArray) => {
  switch (aggregateFunction) {
    case 'sum':
      return inputArray.reduce((acc, val) => acc + val, 0)
    case 'average':
      return inputArray.reduce((acc, val) => acc + val, 0) / inputArray.length
    case 'min':
      return Math.min(...inputArray)
    case 'max':
      return Math.max(...inputArray)
    case 'first':
      return inputArray.length > 0 ? inputArray[0] : ''
    case 'last':
      return inputArray.length > 0 ? inputArray[inputArray.length - 1] : ''
    case 'blank':
      return ''
    default:
      return ''
  }
}

const getDisplayRows = (elementHeight, containerHeight, remainingGridRowsCount, rows, rowHeight, startIndex, lastIndex, showOverflow, isResponseElements) => {
  let renderRows = []
  let currentDataIndex = startIndex || 0
  let modifiedElementHeight = elementHeight
  let newContainerCount = 0 // container count to increase
  let newCellCount = 0 // cell count to increase
  if (lastIndex !== null && lastIndex !== undefined) {
    renderRows = rows.slice(startIndex, lastIndex + 1)
    return { renderRows, newCellCount, newContainerCount, lastIndex }
  }
  // if no overflow in element and container and current iterator is not at last row that means there is still space available in the element and rows too
  while (modifiedElementHeight >= 0 && remainingGridRowsCount >= 0 && currentDataIndex < rows.length) {
    // reduce element height
    modifiedElementHeight = modifiedElementHeight - rowHeight
    // element height is in negative after adding row
    if (modifiedElementHeight < 0 && showOverflow && isResponseElements) {
      // add extra height in element height same as one row height of react grid
      modifiedElementHeight = modifiedElementHeight + parseFloat(containerHeight / MAX_GRID_COUNT)
      // remove height from container's height same as one row height of react grid
      remainingGridRowsCount--

      // if available space in container becomes negative after increasing height of element, then break execution and create new container
      if (remainingGridRowsCount < 0) {
        newContainerCount = newContainerCount + 1
        break
      }
      // if available space is not negative then increase cell count of element
      // this cell count will be added in elements height 
      newCellCount = newCellCount + 1
    }
    else if (!showOverflow && modifiedElementHeight < 0) {
      break
    }
    // store current data index as last index and push that row in renderable rows array 
    lastIndex = currentDataIndex
    renderRows.push(rows[currentDataIndex])
    currentDataIndex++
  }
  return { renderRows, newCellCount, newContainerCount, lastIndex }
}

const aggregateTableApiInstance = {}

const AggregateTable = ({ data }) => {
  const { user } = useAuth()
  const dispatch = useDispatch()
  const { isResponseElements, aggregateMarketValueResponse, aggregatePerformanceResponse } = useSelector(state => state.chartState)
  const templateData = useSelector(state => state.templateData)
  const [tableRows, setTableRows] = useState([])
  const [availableGroups, setAvailableGroup] = useState([])
  const [umaData, setUmaData] = useState([])
  const { customTableRows, customTableRowHeight, overlappingElementsWithTable, customTableOverflowElementsQueue } = useSelector(state => state.elementStates)
  const selectedGroups = data?.value?.selectedGroups || []
  const value = data?.value || {}
  const lastLevel = 'account'
  const componentName = data?.name || ''
  const [displayRows, setDisplayRows] = useState([])
  const filterType = data?.value?.filterType ? data?.value?.filterType : 'include'
  const element = document.getElementById(`${data?.id}`)
  // current container's height
  const container = document.querySelector(`.container-${data?.pageIndex}`)

  const datasets = data?.value?.datasets ? [...data?.value?.datasets] : []

  async function fetchAndStoreData(fetchDataCallback, action) {
    const response = await fetchDataCallback()
    if (response?.success) {
      dispatch(chartDataState({ data: response?.data ? response?.data : [], loading: false }, action))
      aggregateTableApiInstance[componentName] = false
    } else {
      dispatch(chartDataState({ data: [], loading: false }, action))
      aggregateTableApiInstance[componentName] = false
    }
  }

  useEffect(() => {
    if (isResponseElements && componentName && componentName === 'Aggregate Assets Table' && !aggregateMarketValueResponse?.loading && !aggregateMarketValueResponse?.data && !aggregateTableApiInstance[componentName]) {
      aggregateTableApiInstance[componentName] = true
      dispatch(chartDataState({ loading: true, data: [] }, AGGREGATE_MARKET_VALUE))
      fetchAndStoreData(() => aggregateMarketValue(templateData, user?.userGroup), AGGREGATE_MARKET_VALUE)
    }
    else if (isResponseElements && componentName && componentName === 'Aggregate Performance' && !aggregatePerformanceResponse?.loading && !aggregatePerformanceResponse?.data && !aggregateTableApiInstance[componentName]) {
      aggregateTableApiInstance[componentName] = true
      dispatch(chartDataState({ loading: true, data: [] }, AGGREGATE_PERFORMANCE))
      fetchAndStoreData(() => aggregatePerformance(templateData, user?.userGroup), AGGREGATE_PERFORMANCE)

    }
  }, [componentName, isResponseElements, aggregateMarketValueResponse, aggregatePerformanceResponse])

  useEffect(() => {
    if (componentName && !(customTableRows[`${componentName}-${data?.overflowElementId}`] && customTableRows[`${componentName}-${data?.overflowElementId}`]?.length)) {
      if (componentName === 'Aggregate Assets Table') {
        if (isResponseElements) {
          setAvailableGroup(aggregateMarketValueResponse?.data?.groupBy || [])
        }
        else {
          setAvailableGroup(umaMarketValueData.data.groupBy || [])
        }
      }
      else if (componentName === 'Aggregate Performance') {
        if (isResponseElements) {
          setAvailableGroup(aggregatePerformanceResponse?.data?.groupBy || [])
        }
        else {
          setAvailableGroup(aggregatePerformanceData.data.groupBy || [])
        }
      }
    }
  }, [aggregateMarketValueResponse, aggregatePerformanceResponse, isResponseElements, componentName])

  useEffect(() => {
    if (availableGroups?.length) {
      const columns = datasets.filter(colObj => colObj?.field !== 'groupName')
      if (value?.availableGroups && !value?.availableGroups?.every(grp => availableGroups.includes(grp))) {
        dispatch(addChartValues({ pageIndex: data?.pageIndex, chartIndex: data?.chartIndex, datasets: columns, availableGroups, selectedGroups: [], clearTableRows: true, dataSource: data?.value?.dataSource }))
      } else {
        dispatch(addChartValues({ pageIndex: data?.pageIndex, chartIndex: data?.chartIndex, availableGroups }))
      }
    } else if (!value?.availableGroups) {
      dispatch(addChartValues({ pageIndex: data?.pageIndex, chartIndex: data?.chartIndex, availableGroups, selectedGroups: [] }))
    }
  }, [availableGroups])

  const aggregateData = (inputArray, flag) => {
    // returns an object with aggregation applied and filter
    const aggregateObj = {}
    let multipleColumnFiltersPassed = 1 // flag to filter data on multiple columns
    datasets?.forEach(colObj => {
      if (colObj?.field === 'groupName') return
      const fieldValueArray = inputArray.map(obj => obj[colObj.field]) // take out data of one column
      const value = applyAggregateFunction(colObj?.formatObj?.aggregateFunction, fieldValueArray)
      // no need to filter the data if flag is not set
      // don't want to filter if all the rows are hidden
      if (!flag) {
        const allFiltersOnColumn = colObj?.filterObj?.filters?.filter(filter => isFilterAvailable(filter))
        if (allFiltersOnColumn?.length) {
          const formattedValue = formatValue(value, colObj)
          const logicalOperator = colObj?.filterObj?.logicalOperator || 'AND'
          let multipleFiltersPassed = logicalOperator === 'OR' ? 0 : 1 // flag to filter data on same column
          colObj?.filterObj?.filters?.forEach((filter) => {
            if (isFilterAvailable(filter)) {
              if (logicalOperator === 'AND')
                multipleFiltersPassed = multipleFiltersPassed && doesFilterValueSatisfyOperator(filter, formattedValue, colObj?.headerType)
              else if (logicalOperator === 'OR')
                multipleFiltersPassed = multipleFiltersPassed || doesFilterValueSatisfyOperator(filter, formattedValue, colObj?.headerType)
            }
          })
          multipleColumnFiltersPassed = multipleColumnFiltersPassed && multipleFiltersPassed
        }
      }
      // add aggregated value in the object
      aggregateObj[colObj?.field] = value
    })
    // if the aggregated value statisfies with filters applied then don't hide this row
    if (multipleColumnFiltersPassed) {
      aggregateObj.hide = false
    }
    else {
      aggregateObj.hide = true
    }
    return aggregateObj
  }

  function groupByField(array, field) {
    const group = {}
    const visited = []
    array.forEach((element) => {
      if (visited.includes(element[field])) {
        group[element[field]].push(element)
      } else {
        group[element[field]] = [element]
        visited.push(element[field])
      }
    })
    return group
  }

  const createHierarchyUtil = (data, index) => {
    if (index === selectedGroups.length) {
      return data
    }
    const group = groupByField(data, selectedGroups[index])
    for (let key in group) {
      group[key] = createHierarchyUtil(group[key], index + 1)
    }
    return group
  }

  const generateArrayFromHierarchyUtil = (hierarchy, array, level) => {
    if (Array.isArray(hierarchy)) {
      return aggregateData(hierarchy)
    }
    let resultArray = [], nullFlag = false, isAnyRowNotHidden = 0
    for (const key in hierarchy) {
      if (key === 'null') {
        nullFlag = true
        continue
      }
      const tempArray = []
      const result = generateArrayFromHierarchyUtil(hierarchy[key], tempArray, level + 1)
      isAnyRowNotHidden = isAnyRowNotHidden || !result?.hide
      tempArray.unshift({ ...result, groupName: hierarchy[key][selectedGroups[level]] || key, level, hide: result?.hide })
      tempArray.forEach(obj => {
        array.push(obj)
      })
      resultArray.push(result)
    }
    if (nullFlag) {
      const tempArray = []
      const result = generateArrayFromHierarchyUtil(hierarchy['null'], tempArray, level + 1)
      isAnyRowNotHidden = isAnyRowNotHidden || !result?.hide
      tempArray.unshift({ ...result, groupName: 'Uncategorized', level, hide: result?.hide })
      tempArray.forEach(obj => {
        array.push(obj)
      })
      resultArray.push(result)
    }
    if (filterType === 'include') {
      resultArray = resultArray?.filter(obj => !obj?.hide)
    }
    return aggregateData(resultArray, isAnyRowNotHidden)
  }

  const generateArrayFromHierarchy = (dataRows) => {
    const ans = []
    const hierarchy = createHierarchyUtil(dataRows, 0)
    generateArrayFromHierarchyUtil(hierarchy, ans, 0)
    return ans
  }

  function addTotalRows(rows, groupFlag) {
    if (!rows?.length) return
    const lastTotalRow = {}
    if (groupFlag) {
      datasets.forEach((colObj, colIndex) => {
        if (colIndex === 0) {
          lastTotalRow[colObj.field] = 'Total'
          lastTotalRow['level'] = 0
          lastTotalRow['lastTotalRow'] = true
          return
        }
        if (colObj.headerType === DATA_TYPE.NUMBER) {
          let sum = 0
          rows.forEach((row, index) => {
            if (row.level === 0) {
              sum += row[colObj?.field]
            }
          })
          lastTotalRow[colObj.field] = sum
        }
        else {
          lastTotalRow[colObj.field] = ''
        }
      })
      rows.push(lastTotalRow)
    }
  }

  useEffect(() => {
    if (componentName && data?.overflowElementId) {
      // if table rows are already present in redux state, use globally stored rows
      if (customTableRows[`${componentName}-${data?.overflowElementId}`] && customTableRows[`${componentName}-${data?.overflowElementId}`]?.length) {
        setTableRows(customTableRows[`${componentName}-${data?.overflowElementId}`])
        return
      }
      let dataRows = []
      if (componentName === 'Aggregate Assets Table') {
        dataRows = isResponseElements ? (aggregateMarketValueResponse?.data?.data || []) : (umaMarketValueData.data.data || [])
      }
      else if (componentName === 'Aggregate Performance') {
        dataRows = isResponseElements ? (aggregatePerformanceResponse?.data?.data || []) : (aggregatePerformanceData.data.data || [])
      }
      if (!dataRows?.length) {
        setTableRows([])
        setDisplayRows([])
      }
      if (selectedGroups?.length && availableGroups?.length) {
        let updatedTableRows = generateArrayFromHierarchy(dataRows)?.filter(row => row?.hide === false)
        addTotalRows(updatedTableRows, 1)
        setTableRows(updatedTableRows || [])
        // push table into queue if overflow is enabled
        if (isResponseElements && !customTableOverflowElementsQueue.includes(data?.id) && data?.value?.showOverflow) {
          dispatch(queueChange({ queueAction: 'push', item: data?.id }))
        }
        dispatch(storeCustomTableRows({ [`${componentName}-${data?.overflowElementId}`]: updatedTableRows }))
      }
      else if (!selectedGroups?.length) {
        const updatedTableRows = filterValues(datasets, dataRows)
        setTableRows(updatedTableRows)
        // push table into queue if overflow is enabled
        if (isResponseElements && !customTableOverflowElementsQueue.includes(data?.id) && data?.value?.showOverflow) {
          dispatch(queueChange({ queueAction: 'push', item: data?.id }))
        }
        dispatch(storeCustomTableRows({ [`${componentName}-${data?.overflowElementId}`]: updatedTableRows }))
      }
    }
  }, [data.value, aggregateMarketValueResponse, aggregatePerformanceResponse])


  const handleOverflow = () => {
    if (element?.clientHeight && container?.clientHeight && componentName && data?.overflowElementId) {
      let maxHeaderHeight = customTableRowHeight
      const updatedTableRows = customTableRows[`${componentName}-${data?.overflowElementId}`] || tableRows || []
      if (!updatedTableRows?.length) {
        setDisplayRows([])
        return
      }
      element?.querySelectorAll('.header-data-parent')?.forEach(node => {
        if (node.clientHeight > maxHeaderHeight) {
          maxHeaderHeight = node?.clientHeight
        }
      })
      const remainingSpaceInContainer = container?.clientHeight - (data?.position?.y * parseFloat(container?.clientHeight / MAX_GRID_COUNT))
      const elementHeight = element?.clientHeight > remainingSpaceInContainer ? remainingSpaceInContainer : element?.clientHeight
      const dynamicRowsObj = getDisplayRows(
        elementHeight - maxHeaderHeight - 12 - 15, // reduce table header height, table top padding and element title height from available space of element height
        container?.clientHeight,
        MAX_GRID_COUNT - (data?.position?.y + data?.position?.h),
        updatedTableRows,
        customTableRowHeight,
        data?.value?.showOverflow ? data?.startIndex : 0,
        data?.lastIndex,
        data?.value?.showOverflow,
        isResponseElements
      )
      // set rows as display rows which is returned as per element and container height
      setDisplayRows(dynamicRowsObj.renderRows)
      // add extra cells required to be added in element for showing rows and add new container if all remaining rows can not be shown in current page
      if (dynamicRowsObj.newCellCount || dynamicRowsObj.newContainerCount) {
        dispatch(handleTableOverflow({ cellCount: dynamicRowsObj.newCellCount, hasRemainingData: Boolean(dynamicRowsObj.newContainerCount), elementIndex: data?.chartIndex, pageIndex: data?.pageIndex, lastIndex: dynamicRowsObj.lastIndex }))
      }
      // if no extra cells or page needs to be added and overlapping elements are there with current table, create page for overlapping elements.
      if (!dynamicRowsObj?.newContainerCount && !dynamicRowsObj?.newCellCount && dynamicRowsObj?.lastIndex === updatedTableRows?.length - 1 && overlappingElementsWithTable?.length) {
        dispatch(createPageForOverflowingElements({ pageIndex: data?.pageIndex }))
      }
      // if no extra cells or page needs to be added, remove current element from queue
      if (!dynamicRowsObj?.newContainerCount && !dynamicRowsObj?.newCellCount && (data?.value?.showOverflow && dynamicRowsObj?.lastIndex === updatedTableRows?.length - 1)) {
        if (isResponseElements && customTableOverflowElementsQueue.length > 0 && (customTableOverflowElementsQueue[0] === data?.id || customTableOverflowElementsQueue[0] === data?.overflowElementId))
          dispatch(queueChange({ queueAction: 'pop' }))
      }
    }
  }

  useEffect(() => {
    // if overflow is enabled in element, then only check for queue
    if (isResponseElements && customTableOverflowElementsQueue.length > 0) {
      if (customTableOverflowElementsQueue[0] === data?.overflowElementId) {
        handleOverflow()
      }
    }
    // if report is not in preview mode or overflow is not enabled in table, process row calculation
    else if (!isResponseElements || !data?.value?.showOverflow) {
      handleOverflow()
    }
  }, [element?.clientHeight, container?.clientHeight, tableRows, data?.value?.showOverflow, isResponseElements, customTableOverflowElementsQueue])

  const storeColumnWidthInRedux = (datasets) => {
    dispatch(addChartValues({ pageIndex: data?.pageIndex, chartIndex: data?.chartIndex, datasets }))
  }
  return (
    <div
      className={'group'}
      style={{
        height: '100%',
        width: '100%'
      }}
    >
      <p
        style={{
          margin: 0, fontSize: '10px', fontWeight: 500
        }}>
        {data?.headingText || data?.name}
      </p>
      {
        aggregateTableApiInstance[componentName]
          ? <span className='loading-text'>Loading...</span>
          : <Table columns={datasets} rows={displayRows} storeColumnWidth={storeColumnWidthInRedux} columnWidth={data?.value?.width} />

      }
    </div >
  )
}

export default AggregateTable